import { GridState } from "@material-ui/data-grid";
import { createSelector } from "reselect";

// https://github.com/mui-org/material-ui-x/blob/docs-v4/packages/grid/_modules_/grid/hooks/features/density/densitySelector.ts
export const densitySelector = (state: GridState) => state.density;

export const gridDensityValueSelector = createSelector(
    densitySelector,
    (density) => density.value
);

export const gridDensityRowHeightSelector = createSelector(
    densitySelector,
    (density) => density.rowHeight
);

export const gridDensityHeaderHeightSelector = createSelector(
    densitySelector,
    (density) => density.headerHeight
);
