import { DetailedFormTableRow } from "../../components/FormDataGrid/DetailedForm/DetailedFormTable/DetailedFormTable";
import {
    formatEnumValue,
    formatResourcePersonObjectType,
} from "../../components/FormDataGrid/formDataUtils";
import { useStore } from "../../storage/StoreProvider";
import { useTranslation } from "react-i18next";
import { TableCellValue } from "../../components/FormDataGrid/DetailedForm/DetailedFormTable/DetailedFormTableCell/CellContent/TableCellContent";

export function DefaultRows(invalidations: any, printView?: boolean) {
    const [state, ,] = useStore();
    const invalidationEnums = state.trademarkInvalidationEnums;
    const { t } = useTranslation();
    return invalidations.map((invalidation) => {
        return [
            {
                caption: " ",
                name: t("trademarkInvalidationForm:title"),
                value: invalidation.title,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkInvalidationForm:number"),
                value: invalidation.number,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkInvalidationForm:owner"),
                value: invalidation.persons?.length
                    ? formatResourcePersonObjectType(invalidation.persons, [1])
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkInvalidationForm:applicant"),
                value: invalidation.persons?.length
                    ? formatResourcePersonObjectType(invalidation.persons, [2])
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkInvalidationForm:result"),
                value:
                    invalidationEnums && invalidation.result !== undefined
                        ? formatEnumValue(
                            invalidation.result,
                            "result",
                            invalidationEnums
                        )
                        : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkInvalidationForm:groundsOfDecision"),
                value: invalidation.substantiations?.join(" "),
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkInvalidationForm:decision"),
                value: invalidation.decision,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkInvalidationForm:nica"),
                value: invalidation.nica?.length
                    ? invalidation.nica.join(",")
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkInvalidationForm:firstCourtDecision"),
                value: invalidation.firstCourtDecision,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkInvalidationForm:secondCourtDecision"),
                value: invalidation.secondCourtDecision,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkInvalidationForm:oppossiteObjects"),
                value: invalidation.oppossiteObjects?.length
                    ? {
                        headers: [
                            t("trademarkInvalidationForm:numberHeader"),
                            t("trademarkInvalidationForm:titleHeader"),
                        ],
                        values: invalidation.oppossiteObjects.map((item) => [
                            {
                                text: item.number,
                            } as TableCellValue,
                            {
                                text: item.title,
                            } as TableCellValue,
                        ]),
                    }
                    : undefined,
                type: "table",
            } as DetailedFormTableRow,
        ];
    });
}
