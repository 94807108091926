import { GridSortItem } from "@material-ui/data-grid";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Href } from "../models/BaseResponseModel";
import DefaultRequestModel from "../models/DefaultRequestModel";
import DefaultResponseModel, {
    Collection,
} from "../models/DefaultResponseModel";
import FilterModel, { OrderType } from "../models/FilterModel";
import { ACTIONS } from "../storage/dataReducer";
import { Store } from "../storage/StoreProvider";
import Resource from "../models/grid/Resource";
import GridData from "../models/grid/GridData";
import DispatchModel from "../models/storage/DispatchModel";
import { LocalStorage } from "../storage/browserStorage";

export const BASE_URL = (window as any)._env_.REACT_APP_API_URL.replace(
    /\/$/,
    ""
); // Removes end slash for URL part combination
export const storage = LocalStorage.getInstance();
export const defaultRequestHeaders = () => {
    return {
        Accept: "application/json",
        "Accept-Language": storage.getLanguage(),
    };
};
export function convertHrefsToDictionary(hrefs: Href[] | any) {
    let dict = {};
    for (let i in hrefs) {
        dict[hrefs[i].rel] = BASE_URL + hrefs[i].url;
    }
    return dict;
}
export interface DefaultProps {
    page: number;
    rowsPerPage: number;
    sortModel: GridSortItem[];
    filter: FilterModel;
}

export interface DefaultFetchDataProps extends DefaultProps {
    action?: string;
    key?: string;
}

export interface ErrorBuilderData {
    error: AxiosError;
    dispatch: (props: any) => any;
}

export const getBackendDefinedAPI = async (
    resource: Resource
): Promise<string | null> => {
    const state = Store.getState();
    const getAPI = async () => {
        Store.dispatch({ type: ACTIONS.GET_API });
        await axios
            .get<null, AxiosResponse<DefaultResponseModel>>(
                BASE_URL.replace(/\/?$/, "/")
            ) // Adding end slash for the first call
            .then((res) => {
                const urls = convertHrefsToDictionary(res.data?.hrefs);
                Store.dispatch({
                    type: ACTIONS.API,
                    payload: {
                        urls: urls,
                    },
                });
            })
            .catch((e: AxiosError) => {
                if (axios.isCancel(e)) return;
                Store.dispatch({
                    type: ACTIONS.SERVER_ERROR,
                    payload: {
                        error: e.response
                            ? `Servera kļūda — ${e.response.data.errors.map(
                                  (e) => e.id.join(", ")
                              )}`
                            : null,
                    },
                });
            });
    };
    if (!state.apis) {
        await getAPI();
    }

    return Store.getState().apis
        ? Store.getState().apis[Resource[resource]]
        : null;
};

export const getDataKey = (resource: Resource): string | null => {
    switch (resource) {
        case Resource.Patent:
            return "patentBasicInfo";
        case Resource.Persons:
            return "Persons";
        case Resource.PAS:
            return "pasBasicInfo";
        case Resource.Enums:
            return "Enums";
        case Resource.Documents:
            return "Documents";
        case Resource.Events:
            return "Events";
        case Resource.Referenced:
            return "Referenced";
        case Resource.SearchMeta:
            return "SearchMeta";
        case Resource.Payments:
            return "Payments";
        default:
            return null;
    }
};

export const getFrontendDefinedAPI = (resource: Resource): string => {
    let api = "/";
    switch (resource) {
        case Resource.Trademark:
            api = "/api/TradeMarks";
            break;
        case Resource.TrademarkAppeal:
            api = "/api/Appeals/trademarks";
            break;
        case Resource.Patent:
            api = "/api/Patents";
            break;
        case Resource.PatentAppeal:
            api = "/api/Appeals/patents";
            break;
        case Resource.Design:
            api = "/api/Designs";
            break;
        case Resource.DesignAppeal:
            api = "/api/Appeals/designs";
            break;
        case Resource.DesignOpposition:
            api = "/api/objections/designs";
            break;
        case Resource.TrademarkOpposition:
            api = "/api/inter-partes/trademarks/objections";
            break;
        case Resource.PAS:
            api = "/api/PAS";
            break;
        case Resource.RefusedTrademark:
            api = "/api/RefusedTrademarks";
            break;
        case Resource.TrademarkInvalidation:
            api = "/api/inter-partes/trademarks/invalidations";
            break;
        case Resource.TrademarkRevocation:
            api = "/api/inter-partes/trademarks/revocations";
            break;
        default:
            console.error("Unsupported resource");
            break;
    }
    return BASE_URL + api;
};

export async function getResourceData<ResponseType>(
    resource: Resource,
    url?: string
): Promise<ResponseType | null> {
    const state = Store.getState();
    const resKey = Resource[resource];
    const endpoint =
        url ??
        (state.urls && resKey in state.urls
            ? state.urls[Resource[resource]]
            : null);
    if (!endpoint) return null;
    try {
        const response = await axios.get<any, AxiosResponse<ResponseType>>(
            endpoint,
            {
                headers: defaultRequestHeaders(),
            }
        );
        return response.data;
    } catch (e) {
        throw e;
    }
}

export function buildGridDataRequest(props: DefaultProps): DefaultRequestModel {
    return {
        order: props.sortModel?.length
            ? `[${props.sortModel
                  .map((model, index) => {
                      return JSON.stringify({
                          field: model.field,
                          descending: OrderType[model.sort],
                          priority: 1,
                      });
                  })
                  .join(", ")}]`
            : null,
        skip: props.page * props.rowsPerPage,
        take: props.rowsPerPage,
        filter: props.filter?.length
            ? `[${props.filter
                  ?.map((item) => JSON.stringify(item))
                  .join(", ")}]`
            : null,
    };
}

export async function buildGridData(
    response: DefaultResponseModel
): Promise<GridData> {
    const pageUrls = response.hrefs
        ? convertHrefsToDictionary(response.hrefs)
        : null;
    return {
        rows: await Promise.all(
            response.collection.map(async (collection: Collection) => {
                const entityUrls = collection.hrefs
                    ? convertHrefsToDictionary(collection.hrefs)
                    : null;
                // Adds all the required grid rendering parameters to one level.
                // Gets additional resources for grid generation
                if (entityUrls && Resource[Resource.Images] in entityUrls) {
                    const url = entityUrls[Resource[Resource.Images]];
                    try {
                        const response =
                            await getResourceData<DefaultResponseModel>(
                                Resource.Images,
                                url
                            );
                        return {
                            ...collection.entity,
                            urls: entityUrls,
                            images: response.collection.map((images) => {
                                const imgUrls = images.hrefs
                                    ? convertHrefsToDictionary(images.hrefs)
                                    : null;
                                return imgUrls &&
                                    Resource[Resource.Image] in imgUrls
                                    ? imgUrls[Resource[Resource.Image]]
                                    : [];
                            }),
                        };
                    } catch (e) {
                        console.error(`Could not get an image list.`);
                    }
                }
                return {
                    ...collection.entity,
                    urls: entityUrls,
                };
            })
        ),
        urls: pageUrls,
        pageCount: response.meta?.totalPages,
        rowCount: response.meta?.totalRecords,
        currentPage: response.meta?.pageNumber,
        notifications: response.notifications ?? [],
    };
}

export async function getResourcesAsync(resources: Resource[], urls: {[resource: number]: string}) {
    return Object.assign(
        {},
        ...(await Promise.all(
            resources.map(async (res) => {
                const resKey = Resource[res];
                // Be careful with default response model ~ make sure it is the right model for each of the defined resources.
                // TODO: Make the response model dynamic.
                const resData = await getResourceData<DefaultResponseModel>(
                    res,
                    urls && resKey in urls ? urls[resKey] : null
                );
                return { [res]: resData };
            })
        ))
    );
}

export function formatErrorResponse(error: AxiosError) {
    return error.response
        ? `Servera kļūda:  ${
              error.response?.data?.title
          } ${error.response?.data?.errors?.id.join(", ")}`
        : undefined;
}

export function buildGridDataError(
    error: AxiosError,
    message: string
): DispatchModel {
    if (axios.isCancel(error)) return;
    if (error.response?.data?.Title) {
        const validationErrorPayload = {
            validationError: error.response.data.Title,
        };
        return {
            type: ACTIONS.VALIDATION_ERROR,
            payload: validationErrorPayload,
        };
    } else {
        const errorPayload = { error: formatErrorResponse(error) ?? message };
        return {
            type: ACTIONS.SERVER_ERROR,
            payload: errorPayload,
        };
    }
}
