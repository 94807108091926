import ImageDialog from '../../../../ImageDialog';

export interface ImageCellContentProps {
    url: string;
}

export default function ImageCellContent(props: ImageCellContentProps) {
    const { url } = props;

    return <ImageDialog src={url} />;
}
