import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import DetailedForm from "../../components/FormDataGrid/DetailedForm/DetailedForm";
import { ACTIONS } from "../../storage/dataReducer";
import { DefaultRows } from "../Trademarks/detailedFormRowDefinitions";
import { useStore } from "../../storage/StoreProvider";
import { FilterOperandType, FilterValue } from "../../models/FilterModel";
import { fetchTrademarkData } from "../../services/trademarkService";
import { useTranslation } from "react-i18next";
import TrademarkStatusTimeline from "../../components/FormDataGrid/Timeline/TrademarkStatusTimeline/TrademarkStatusTimeline";

interface TrademarkParamTypes {
    id: string;
}

export default function TrademarkDetailedForm({ match }) {
    const [state, dispatch, getState] = useStore();
    const [statusId, setStatusId] = useState(null);
    const [trademark, setTrademark] = useState({});
    const { id } = useParams<TrademarkParamTypes>();
    const { i18n } = useTranslation();
    const [usedLanguage, setUsedLanguage] = useState(i18n.language);
    const trademarkRows = trademark ? DefaultRows([trademark])[0] : [];

    useEffect(() => {
        async function GetData() {
            const filter = [
                {
                    // TODO: This is a dirty fix for trademark appeals. Improve the solution.
                    // This may break if the format of registrationNumber or applicationNumber will change.
                    Field: id.includes(" ")
                        ? "registrationNumber"
                        : "applicationNumber",
                    Operator: FilterOperandType.Equal,
                    Value1: id,
                    Value2: null,
                } as FilterValue,
            ];
            await fetchTrademarkData({
                page: 0,
                rowsPerPage: 1,
                sortModel: null,
                filter: filter,
                action: ACTIONS.GET_ROWS,
            });
        }

        const currentState = getState();
        const trademark = currentState.trademarks.find(
            (t) => t.applicationNumber === id || t.registrationNumber === id
        );
        if (!trademark || i18n.language !== usedLanguage) {
            //     dispatch({
            //         type: ACTIONS.TRADEMARK_ROWS,
            //         payload: { rows: [trademark] },
            //     });
            // } else {
            GetData();
            setUsedLanguage(i18n.language);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    useEffect(() => {
        async function getRows() {
            const currentState = getState();
            const trademark = currentState.trademarks.find(
                (t) => t.applicationNumber === id || t.registrationNumber === id
            );
            if (!trademark) return;

            setStatusId(trademark.status);
            setTrademark(trademark);
        }
        getRows();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.trademarks, id]);

    return (
        <DetailedForm
            rows={trademarkRows}
            ids={[id]}
            match={match}
            showTimeline
            showBackButton
            timeline={<TrademarkStatusTimeline statusId={statusId} />}
            title={
                trademark
                    ? (trademark as any).registrationNumber
                        ? (trademark as any).registrationNumber
                        : (trademark as any).applicationNumber
                    : ""
            }
        />
    );
}
