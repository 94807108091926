import {
    Box,
    StepConnector,
    StepConnectorProps,
    StepIconProps,
} from "@material-ui/core";
import {
    makeStyles,
    Theme,
    createStyles,
    withStyles,
} from "@material-ui/core/styles";
import clsx from "clsx";
import Image from 'material-ui-image'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        timeline: {
            padding: 0,
            "& .MuiPaper-root.MuiStepper-root": {
                padding: 0,
            },
        },
        tableCell: {
            borderBottom: 0,
        },
        connector: {
            width: 20,
        },
        label: {
            "& .MuiStepLabel-label": {
                marginTop: 0,
            },
        },
        topLabel: {
            "& .MuiStepLabel-label": {
                marginTop: 0,
            },
        },
        emptyStep: {
            "& .MuiStepConnector-line": {
                maxWidth: "70px",
            },
        },
        icon: {
            fontSize: 50,
            color: "#7a7a7a",
            strokeWidth: 0.5,
            stroke: "black",
            strokeLinejoin: "round",
            WebkitTextStrokeWidth: 0.5,
        },
    })
);

export const LabelConnector = (props: StepConnectorProps) => {
    return <span></span>;
};

export const useColorlibStepIconStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: "white",
            zIndex: 1,
            color: "#fff",
            width: 40,
            height: 40,
            display: "flex",
            borderRadius: "50%",
            justifyContent: "center",
            alignItems: "center",
            border: "4px solid #7a7a7a",
            position: "absolute",
            right: 0,
            marginRight: "-21px",
            boxShadow: "0px 0px 0px 1px #000000;",
            WebkitBoxShadow: "0px 0px 0px 1px #000000",
        },
        active: {
            backgroundColor: theme.palette.primary.main,
        },
        completed: {
            backgroundColor: "#7a7a7a",
        },
        vline: {
            borderRight: "2px dashed #7a7a7a",
            height: "80px",
            position: "relative",
        },
        line: {
            height: "80px",
            position: "relative",
        },
        shortLabelWrapper: {
            marginLeft: "15%",
            bottom: 0,
            position: "absolute",
            top: -55,
            maxWidth: 100
        },
        longLabelWrapper: {
            marginLeft: "120%",
            bottom: 0,
            position: "absolute",
            top: -55,
            minWidth: 200
        },
        labelWrapper: {
            marginLeft: "30%",
            bottom: 0,
            position: "absolute",
            top: -55,
            minWidth: 150
        },
        logoLabelWrapper: {
            bottom: -15,
            position: "absolute",
        },
        lastlabel: {
            width: 350,
            color: theme.palette.accent.main,
        },
        label: {
            position: "relative",
        },
        labelContainer: {
            paddingTop: 40,
        },
    })
);

export function EmptyStepIcon(props: StepIconProps, label: string) {
    const classes = useColorlibStepIconStyles();
    return (
        label !== "" && (<div className={classes.labelContainer}>
            <Box
                display="flex"
                alignItems="flex-end"
                className={classes.labelWrapper}
            >
                <Box className={label}>{label}</Box>
            </Box>
        </div>)
    );
}

export function StartEmptyStepIcon(props: StepIconProps, label: string) {
    const classes = useColorlibStepIconStyles();
    return (
        label !== "" && (<div className={classes.labelContainer}>
            <Box
                display="flex"
                alignItems="flex-end"
                className={classes.shortLabelWrapper}
            >
                <Box className={label}>{label}</Box>
            </Box>
        </div>)
    );
}

export function EndEmptyStepIcon(props: StepIconProps, label: string) {
    const classes = useColorlibStepIconStyles();
    return (
        label !== "" && (<div className={classes.labelContainer}>
            <Box
                display="flex"
                alignItems="flex-end"
                className={classes.longLabelWrapper}
            >
                <Box className={label}>{label}</Box>
            </Box>
        </div>)
    );
}

export function EmptyStepIconWithLogo(props: StepIconProps, src: string) {
    const classes = useColorlibStepIconStyles();
    return (
        <div className={classes.labelContainer}>
            <Box
                display="flex"
                alignItems="flex-start"
                className={classes.logoLabelWrapper}
            >
                <Box>
                    <Image src={src} style={{ objectFit: 'contain', width: 80 }} />
                </Box>
            </Box>
        </div>
    );
}

export const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 14,
    },
    active: {
        "& $line": {
            backgroundColor: "#7a7a7a",
        },
    },
    completed: {
        "& $line": {
            backgroundColor: "#7a7a7a",
        },
    },
    line: {
        height: 15,
        border: "1px solid black",
        backgroundColor: "white",
        borderRadius: 6,
        marginLeft: -10,
        marginRight: -10,
    },
})(StepConnector);

export function ColorlibStepIcon(props: StepIconProps, label?: string) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    return (
        <div className={label ? classes.vline: classes.line}>
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed,
                })}
            ></div>
        </div>
    );
}

export function LastEmptyStepIcon(props: StepIconProps, label: string) {
    const classes = useColorlibStepIconStyles();
    return (
        <div className={classes.labelContainer}>
            <Box
                display="flex"
                alignItems="flex-end"
                pl={5}
                className={clsx(classes.labelWrapper, classes.lastlabel)}
            >
                <Box className={label}>{label}</Box>
            </Box>
        </div>
    );
}