// constants & types
import SearchType from "../../models/grid/SearchType";

// utils
import { useTranslation } from "react-i18next";
import { useStore } from "../../storage/StoreProvider";
import { GridStorage } from "../../storage/browserStorage";

import { SearchField } from "../../components/FormDataGrid/SearchForm";
import FieldWithTooltip from "../../components/FieldWithTooltip";

export function PatentsSearchFields(): SearchField[] {
    const [state, ,] = useStore();
    const enums = state.patentEnums;
    const { t } = useTranslation();
    const storage = GridStorage.getInstance();
    const filterModel = storage.getFilterModel();

    return [
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="DocumentKindId"
                    label={t("patentForm:documentKindLabel")}
                    tooltipText={t("patentForm:documentKindTooltip")}
                    multiple
                    type={SearchType.list}
                    options={
                        enums && enums.patentDocumentKinds
                            ? Object.keys(enums.patentDocumentKinds).map(
                                  (key) => ({
                                      label: enums.patentDocumentKinds[key],
                                      value: key,
                                  })
                              )
                            : []
                    }
                    defaultValue={filterModel && filterModel["DocumentKindId"]}
                />
            ),
            label: t("patentForm:documentKindLabel"),
            name: "DocumentKindId",
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="Number"
                    label={t("patentForm:numberLabel")}
                    tooltipText={t("patentForm:numberTooltip")}
                    type={SearchType.string}
                    defaultValue={filterModel && filterModel["Number"]}
                />
            ),
            label: t("patentForm:numberLabel"),
            name: "Number",
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name={t("patentForm:titleAndDescription")} // TODO: Add also search for english verison.
                    label={t("patentForm:titleAndDescriptionLabel")}
                    tooltipText={t("patentForm:titleAndDescriptionTooltip")}
                    multiple
                    type={SearchType.string}
                    defaultValue={
                        filterModel &&
                        filterModel[t("patentForm:titleAndDescription")]
                    }
                />
            ),
            label: t("patentForm:titleAndDescriptionLabel"),
            name: "TitleAndDescriptionLV",
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="applicationDate"
                    label={t("patentForm:applicationDateLabel")}
                    tooltipText={t("patentForm:applicationDateTooltip")}
                    type={SearchType.dateRange}
                    defaultValue={filterModel && filterModel["applicationDate"]}
                />
            ),
            label: t("patentForm:applicationDateLabel"),
            name: "applicationDate",
            showOnExtendedSearch: true,
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="PublicationADate"
                    label={t("patentForm:publicationADateLabel")}
                    tooltipText={t("patentForm:publicationADateTooltip")}
                    type={SearchType.dateRange}
                    defaultValue={
                        filterModel && filterModel["PublicationADate"]
                    }
                />
            ),
            label: t("patentForm:publicationADateLabel"),
            name: "PublicationADate",
            showOnExtendedSearch: true,
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="PublicationDate"
                    label={t("patentForm:publicationDateLabel")}
                    tooltipText={t("patentForm:publicationDateTooltip")}
                    type={SearchType.dateRange}
                    defaultValue={filterModel && filterModel["PublicationDate"]}
                />
            ),
            label: t("patentForm:publicationDateLabel"),
            name: "PublicationDate",
            showOnExtendedSearch: true,
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="Priorities%refillingNumber"
                    label={t("patentForm:priorityRefillingNumberLabel")}
                    tooltipText={t("patentForm:priorityRefillingNumberTooltip")}
                    type={SearchType.string}
                    defaultValue={
                        filterModel && filterModel["Priorities%refillingNumber"]
                    }
                />
            ),
            label: t("patentForm:priorityRefillingNumberLabel"),
            name: "Priorities%refillingNumber",
            showOnExtendedSearch: true,
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="Priorities%FilingDate"
                    label={t("patentForm:priorityFilingDateLabel")}
                    tooltipText={t("patentForm:priorityFilingDateTooltip")}
                    type={SearchType.date}
                    defaultValue={
                        filterModel && filterModel["Priorities%FilingDate"]
                    }
                />
            ),
            label: t("patentForm:priorityFilingDateLabel"),
            name: "Priorities%FilingDate",
            showOnExtendedSearch: true,
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="statusId"
                    label={t("patentForm:statusIdLabel")}
                    tooltipText={t("patentForm:statusIdTooltip")}
                    multiple
                    type={SearchType.list}
                    options={
                        enums && enums.statusSearch
                            ? Object.keys(enums.statusSearch).map((key) => ({
                                  label: enums.statusSearch[key],
                                  value: key,
                              }))
                            : []
                    }
                    defaultValue={filterModel && filterModel["statusId"]}
                />
            ),
            label: t("patentForm:statusIdLabel"),
            name: "statusId",
            showOnExtendedSearch: true,
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="nextAnnualFee"
                    label={t("patentForm:nextAnnualFeeLabel")}
                    tooltipText={t("patentForm:nextAnnualFeeTooltip")}
                    type={SearchType.dateRange}
                    defaultValue={filterModel && filterModel["nextAnnualFee"]}
                />
            ),
            label: t("patentForm:nextAnnualFeeLabel"),
            name: "nextAnnualFee",
            showOnExtendedSearch: true,
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="Applicant"
                    label={t("patentForm:applicantLabel")}
                    tooltipText={t("patentForm:applicantTooltip")}
                    type={SearchType.string}
                    multiple
                    defaultValue={filterModel && filterModel["Applicant"]}
                />
            ),
            label: t("patentForm:applicantLabel"),
            name: "Applicant",
            showOnExtendedSearch: true,
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="Designer"
                    label={t("patentForm:designerLabel")}
                    tooltipText={t("patentForm:designerTooltip")}
                    type={SearchType.string}
                    multiple
                    defaultValue={filterModel && filterModel["Designer"]}
                />
            ),
            label: t("patentForm:designerLabel"),
            name: "Designer",
            showOnExtendedSearch: true,
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="Representative"
                    label={t("patentForm:representativeLabel")}
                    tooltipText={t("patentForm:representativeTooltip")}
                    type={SearchType.string}
                    multiple
                    defaultValue={filterModel && filterModel["Representative"]}
                />
            ),
            label: t("patentForm:representativeLabel"),
            name: "Representative",
            showOnExtendedSearch: true,
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="ipcs%ipcnumber"
                    label={t("patentForm:ipcNumberLabel")}
                    tooltipText={t("patentForm:ipcNumberTooltip")}
                    multiple
                    type={SearchType.string}
                    defaultValue={filterModel && filterModel["ipcs%ipcnumber"]}
                />
            ),
            label: t("patentForm:ipcNumberLabel"),
            name: "ipcs%ipcnumber",
            showOnExtendedSearch: true,
            classificationUrl: "",
        },
    ];
}
