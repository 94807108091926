import { createStyles, makeStyles, Theme, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, Link } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
    cell: {
        fontWeight: "bold",
    },
    cellPadding: {
        paddingTop: 0,
        paddingBottom: 0,
    },
}));

export interface TableCellLabel {
    inidCode: string | number;
    text: string;
}

export interface TableCellValue {
    text: string;
    url?: string;
}

export interface TableCellContentProps {
    component: any;
    labels: TableCellLabel[];
    headers: string[];
    values: TableCellValue[][];
}

export default function TableCellContent(props: TableCellContentProps, printView?: boolean) {
    const classes = useStyles();
    const { component, labels, headers, values } = props;

    return (
        <Box display="flex">
            <Box>
                <TableContainer component={component} variant="outlined" elevation={0}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {labels && (
                                    <TableCell align="right" className={classes.cell} />
                                )}
                                {headers?.map((header, index) => {
                                    return (
                                        <TableCell key={index} className={classes.cell}>
                                            {header}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {values?.map((rows, index) => {
                                return (
                                    <TableRow key={index}>
                                        {labels && labels[index] &&
                                            (<TableCell>{labels[index].inidCode ? `(${labels[index].inidCode})` : ''} {labels[index].text}</TableCell>)}
                                        {rows.map((value: TableCellValue, index) => {
                                            return value.url ?
                                                <TableCell className={printView ? classes.cellPadding : ""}>
                                                    <Link target="_blank" href={value.url} >
                                                        {value.text}
                                                    </Link>
                                                </TableCell> :
                                                <TableCell className={printView ? classes.cellPadding : ""} key={index}>{value.text}</TableCell>
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box flexGrow={1} />
        </Box>
    );
}
