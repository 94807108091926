export const ACTIONS = {
    GET_API: "get-api",
    GET_COLUMNS: "get-columns",
    GET_ROWS: "get-rows",
    GET_TRADEMARK: "get-trademark",
    GET_PATENT: "get-patent",
    GET_PAS: "get-pas",
    GET_PAS_ENUMS: "get-pas-enums",
    GET_TRADEMARK_ENUMS: "get-trademark-enums",
    GET_PATENT_ENUMS: "get-patent-enums",
    GET_DESIGN_ENUMS: "get-design-enums",
    GET_DESIGN_APPEAL_ENUMS: "get-design-appeal-enums",
    GET_TRADEMARK_APPEAL_ENUMS: "get-trademark-appeal-enums",
    GET_PATENT_APPEAL_ENUMS: "get-patent-appeal-enums",
    GET_DESIGN_SEARCH_META: "get-design-search-meta",
    GET_PAS_SEARCH_META: "get-pas-search-meta",
    GET_TRADEMARK_APPEAL_SEARCH_META: "get-trademark-appeal-search-meta",
    GET_DESIGN_APPEAL_SEARCH_META: "get-design-appeal-search-meta",
    GET_PATENT_APPEAL_SEARCH_META: "get-patent-appeal-search-meta",
    GET_TRADEMARK_OPPOSITION_ENUMS: "get-trademark-opposition-enums",
    GET_TRADEMARK_INVALIDATION_ENUMS: "get-trademark-invalidation-enums",
    GET_TRADEMARK_REVOCATION_ENUMS: "get-trademark-revocation-enums",
    GET_DESIGN_OPPOSITION_ENUMS: "get-design-opposition-enums",
    GET_REFUSED_TRADEMARK_ENUMS: "get-refused-trademark-enums",
    GET_REFUSEDTRADEMARK_SEARCH_META: "get-refusedtrademark-search-meta",
    GET_TRADEMARK_OPPOSITION_SEARCH_META:
        "get-trademark-opposition-search-meta",
    GET_TRADEMARK_INVALIDATION_SEARCH_META:
        "get-trademark-invalidation-search-meta",
    GET_TRADEMARK_REVOCATION_SEARCH_META:
        "get-trademark-revocation-search-meta",
    GET_DESIGN_OPPOSITION_SEARCH_META: "get-design-opposition-search-meta",
    GET_TRADEMARK_FIELD_INFO: "get-trademark-field-info",
    GET_PATENT_FIELD_INFO: "get-patent-field-info",
    GET_PAS_FIELD_INFO: "get-pas-field-info",
    GET_DESIGN_FIELD_INFO: "get-design-field-info",
    GET_DESIGN_APPEAL_FIELD_INFO: "get-design-appeal-field-info",
    GET_PATENT_APPEAL_FIELD_INFO: "get-patent-appeal-field-info",
    GET_REFUSED_TRADEMARK_FIELD_INFO: "get-refused-trademark-field-info",
    GET_TRADEMARK_APPEAL_FIELD_INFO: "get-trademark-appeal-field-info",
    GET_DESIGN_OPPOSITION_FIELD_INFO: "get-design-opposition-field-info",
    GET_TRADEMARK_OPPOSITION_FIELD_INFO: "get-trademark-opposition-field-info",
    GET_TRADEMARK_INVALIDATION_FIELD_INFO:
        "get-trademark-invalidation-field-info",
    GET_TRADEMARK_REVOCATION_FIELD_INFO: "get-trademark-revocation-field-info",
    TRADEMARK_ROWS: "trademark-rows",
    PATENT_ROWS: "patent-rows",
    PAS_ROWS: "pas-rows",
    DESIGN_ROWS: "design-rows",
    TRADEMARK_APPEAL_ROWS: "trademark-appeal-rows",
    DESIGN_APPEAL_ROWS: "design-appeal-rows",
    PATENT_APPEAL_ROWS: "patent-appeal-rows",
    REFUSEDTRADEMARK_ROWS: "refusedtrademark-rows",
    TRADEMARK_OPPOSITION_ROWS: "trademark-opposition-rows",
    TRADEMARK_INVALIDATION_ROWS: "trademark-invalidation-rows",
    TRADEMARK_REVOCATION_ROWS: "trademark-revocation-rows",
    DESIGN_OPPOSITION_ROWS: "design-opposition-rows",
    SERVER_ERROR: "server-error",
    VALIDATION_ERROR: "validation-error",
    ERROR_TRADEMARK: "error-trademark",
    ERROR_PATENT: "error-patent",
    ERROR_TRADEMARK_ENUMS: "error-patents-enums",
    ERROR_PATENT_ENUMS: "error-trademark-enums",
    ERROR_DESIGN_ENUMS: "error-design-enums",
    ERROR_PAS_ENUMS: "error-pas-enums",
    ERROR_DESIGN_APPEAL_ENUMS: "error-design-appeal-enums",
    ERROR_TRADEMARK_APPEAL_ENUMS: "error-trademark-appeal-enums",
    ERROR_PATENT_APPEAL_ENUMS: "error-patent-appeal-enums",
    ERROR_PAS_SEARCH_META: "error-pas-search-meta",
    ERROR_DESIGN_SEARCH_META: "error-design-search-meta",
    ERROR_TRADEMARK_APPEAL_SEARCH_META: "error-trademark-appeal-search-meta",
    ERROR_DESIGN_APPEAL_SEARCH_META: "error-design-appeal-search-meta",
    ERROR_PATENT_APPEAL_SEARCH_META: "error-patent-appeal-search-meta",
    ERROR_TRADEMARK_OPPOSITION_ENUMS: "error-trademark-opposition-enums",
    ERROR_TRADEMARK_INVALIDATION_ENUMS: "error-trademark-invalidation-enums",
    ERROR_TRADEMARK_REVOCATION_ENUMS: "error-trademark-revocation-enums",
    ERROR_DESIGN_OPPOSITION_ENUMS: "error-design-opposition-enums",
    ERROR_REFUSED_TRADEMARK_ENUMS: "error-refused-trademark-enums",
    ERROR_REFUSEDTRADEMARK_SEARCH_META: "error-refusedtrademark-search-meta",
    ERROR_TRADEMARK_OPPOSITION_SEARCH_META:
        "error-trademark-opposition-search-meta",
    ERROR_TRADEMARK_INVALIDATION_SEARCH_META:
        "error-trademark-invalidation-search-meta",
    ERROR_TRADEMARK_REVOCATION_SEARCH_META:
        "error-trademark-revocation-search-meta",
    ERROR_DESIGN_OPPOSITION_SEARCH_META: "error-design-opposition-search-meta",
    ERROR_TRADEMARK_FIELD_INFO: "error-trademark-field-info",
    ERROR_PATENT_FIELD_INFO: "error-patent-field-info",
    ERROR_PAS_FIELD_INFO: "error-pas-field-info",
    ERROR_DESIGN_FIELD_INFO: "error-design-field-info",
    ERROR_DESIGN_APPEAL_FIELD_INFO: "error-design-appeal-field-info",
    ERROR_PATENT_APPEAL_FIELD_INFO: "error-patent-appeal-field-info",
    ERROR_REFUSED_TRADEMARK_FIELD_INFO: "error-refused-trademark-field-info",
    ERROR_TRADEMARK_APPEAL_FIELD_INFO: "error-trademark-appeal-field-info",
    ERROR_DESIGN_OPPOSITION_FIELD_INFO: "error-design-opposition-field-info",
    ERROR_TRADEMARK_OPPOSITION_FIELD_INFO:
        "error-trademark-opposition-field-info",
    ERROR_TRADEMARK_INVALIDATION_FIELD_INFO:
        "error-trademark-invalidation-field-info",
    ERROR_TRADEMARK_REVOCATION_FIELD_INFO:
        "error-trademark-revocation-field-info",
    API: "apis",
    COLUMNS: "columns",
    TRADEMARK: "trademark",
    PATENT: "patent",
    PAS: "pas",
    TRADEMARK_ENUMS: "trademark-enums",
    PATENT_ENUMS: "patent-enums",
    PAS_ENUMS: "pas-enums",
    DESIGN_APPEAL_ENUMS: "design-appeal-enums",
    TRADEMARK_APPEAL_ENUMS: "trademark-appeal-enums",
    PATENT_APPEAL_ENUMS: "patent-appeal-enums",
    PAS_SEARCH_META: "pas-search-meta",
    DESIGN_ENUMS: "design-enums",
    TRADEMARK_OPPOSITION_ENUMS: "trademark-opposition-enums",
    TRADEMARK_INVALIDATION_ENUMS: "trademark-invalidation-enums",
    TRADEMARK_REVOCATION_ENUMS: "trademark-revocation-enums",
    DESIGN_OPPOSITION_ENUMS: "design-opposition-enums",
    REFUSED_TRADEMARK_ENUMS: "refused-trademark-enums",
    DESIGN_SEARCH_META: "design-search-meta",
    TRADEMARK_APPEAL_SEARCH_META: "trademark-appeal-search-meta",
    DESIGN_APPEAL_SEARCH_META: "design-appeal-search-meta",
    PATENT_APPEAL_SEARCH_META: "patent-appeal-search-meta",
    REFUSEDTRADEMARK_SEARCH_META: "refusedtrademark-search-meta",
    TRADEMARK_OPPOSITION_SEARCH_META: "trademark-opposition-search-meta",
    TRADEMARK_INVALIDATION_SEARCH_META: "trademark-invalidation-search-meta",
    TRADEMARK_REVOCATION_SEARCH_META: "trademark-revocation-search-meta",
    DESIGN_OPPOSITION_SEARCH_META: "design-opposition-search-meta",
    ROWS: "rows",
    TRADEMARK_FIELD_INFO: "trademark-field-info",
    PATENT_FIELD_INFO: "patent-field-info",
    PAS_FIELD_INFO: "pas-field-info",
    DESIGN_FIELD_INFO: "design-field-info",
    DESIGN_APPEAL_FIELD_INFO: "design-appeal-field-info",
    PATENT_APPEAL_FIELD_INFO: "patent-appeal-field-info",
    REFUSED_TRADEMARK_FIELD_INFO: "refused-trademark-field-info",
    TRADEMARK_APPEAL_FIELD_INFO: "trademark-appeal-field-info",
    DESIGN_OPPOSITION_FIELD_INFO: "design-opposition-field-info",
    TRADEMARK_OPPOSITION_FIELD_INFO: "trademark-opposition-field-info",
    TRADEMARK_INVALIDATION_FIELD_INFO: "trademark-invalidation-field-info",
    TRADEMARK_REVOCATION_FIELD_INFO: "trademark-revocation-field-info",
    UPDATE_COLUMNS: "update-columns",
    PURGE: "purge",
    SELECTED_ROWS: "selected-rows",
    PATENT_SEARCH_REDIRECT_CLOSE: "patent-search-redirect-close",
};

export const initialState = {
    rowsLoading: false,
    rows: [],
    trademarks: [],
    patents: [],
    designs: [],
    pases: [],
    trademarkOppositions: [],
    trademarkInvalidations: [],
    trademarkRevocations: [],
    designOppositions: [],
    designSearchMeta: [],
    pasSearchMeta: [],
    trademarkOppositionSearchMeta: [],
    trademarkInvalidationSearchMeta: [],
    trademarkRevocationSearchMeta: [],
    designOppositionSearchMeta: [],
    patentEnums: [],
    trademarkEnums: [],
    designEnums: [],
    pasEnums: [],
    trademarkOppositionEnums: [],
    trademarkRevocationEnums: [],
    trademarkInvalidationEnums: [],
    designOppositionEnums: [],
    trademarkFieldInfoLoading: null,
    trademarkFieldInfo: null,
    patentFieldInfoLoading: null,
    patentFieldInfo: null,
    pasFieldInfoLoading: null,
    pasFieldInfo: null,
    designFieldInfoLoading: null,
    designFieldInfo: null,
    designAppealFieldInfoLoading: null,
    designAppealFieldInfo: null,
    patentAppealFieldInfoLoading: null,
    patentAppealFieldInfo: null,
    refusedTrademarkFieldInfoLoading: null,
    refusedTrademarkFieldInfo: null,
    trademarkAppealFieldInfoLoading: null,
    trademarkAppealFieldInfo: null,
    designOppositionFieldInfo: null,
    designOppositionFieldInfoLoading: null,
    trademarkOppositionFieldInfo: null,
    trademarkOppositionFieldInfoLoading: null,
    trademarkInvalidationFieldInfo: null,
    trademarkInvalidationFieldInfoLoading: null,
    trademarkRevocationFieldInfo: null,
    trademarkRevocationFieldInfoLoading: null,
    urls: null,
    pageCount: 0,
    rowCount: 0,
    notifications: [],
    patentEnumsLoading: false,
    trademarkEnumsLoading: false,
    trademarkOppositionEnumsLoading: false,
    trademarkInvalidationEnumsLoading: false,
    trademarkRevocationEnumsLoading: false,
    designOppositionEnumsLoading: false,
    pasEnumsLoading: false,
    designLoading: false,
    designEnumsLoading: false,
    error: null,
    validationError: null,
    apis: null,
    columns: [],
    selectedRows: [],
    patentAppeals: [],
    trademarkAppeals: [],
    refusedTrademarks: [],
    designAppeals: [],
    patentNumber: undefined,
    patentApplicationNumber: undefined,
};

export function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.GET_API:
            return {
                ...state,
                error: null, // Reset error state.
                validationError: null,
            };
        case ACTIONS.GET_ROWS:
            return {
                ...state,
                rowsLoading: true,
                error: null, // Reset error state.
                validationError: null,
            };
        case ACTIONS.GET_TRADEMARK_ENUMS:
            return {
                ...state,
                trademarkEnumsLoading: true,
                error: null, // Reset error state.
                validationError: null,
            };
        case ACTIONS.GET_PATENT_ENUMS:
            return {
                ...state,
                patentEnumsLoading: true,
                error: null, // Reset error state.
                validationError: null,
            };
        case ACTIONS.GET_DESIGN_ENUMS:
            return {
                ...state,
                designEnumsLoading: true,
                error: null,
                validationError: null,
            };
        case ACTIONS.GET_PAS_ENUMS:
            return {
                ...state,
                pasEnumsLoading: true,
                error: null, // Reset error state.
                validationError: null,
            };
        case ACTIONS.GET_DESIGN_APPEAL_ENUMS:
            return {
                ...state,
                designAppealEnumsLoading: true,
                error: null, // Reset error state.
                validationError: null,
            };
        case ACTIONS.GET_DESIGN_APPEAL_FIELD_INFO:
            return {
                ...state,
                designAppealFieldInfoLoading: true,
                error: null, // Reset error state.
                validationError: null,
            };
        case ACTIONS.GET_TRADEMARK_APPEAL_ENUMS:
            return {
                ...state,
                trademarkAppealEnumsLoading: true,
                error: null, // Reset error state.
                validationError: null,
            };
        case ACTIONS.GET_TRADEMARK_APPEAL_FIELD_INFO:
            return {
                ...state,
                trademarkAppealFieldInfoLoading: true,
                error: null, // Reset error state.
                validationError: null,
            };
        case ACTIONS.GET_PATENT_APPEAL_ENUMS:
            return {
                ...state,
                patentAppealEnumsLoading: true,
                error: null, // Reset error state.
            };
        case ACTIONS.GET_PATENT_APPEAL_FIELD_INFO:
            return {
                ...state,
                patentAppealFieldInfoLoading: true,
                error: null, // Reset error state.
            };
        case ACTIONS.GET_TRADEMARK_OPPOSITION_ENUMS:
            return {
                ...state,
                trademarkOppositionEnumsLoading: true,
                error: null,
                validationError: null,
            };
        case ACTIONS.GET_TRADEMARK_INVALIDATION_ENUMS:
            return {
                ...state,
                trademarkInvalidationEnumsLoading: true,
                error: null,
                validationError: null,
            };
        case ACTIONS.GET_TRADEMARK_REVOCATION_ENUMS:
            return {
                ...state,
                trademarkRevocationEnumsLoading: true,
                error: null,
                validationError: null,
            };
        case ACTIONS.GET_TRADEMARK_OPPOSITION_FIELD_INFO:
            return {
                ...state,
                trademarkOppositionFieldInfoLoading: true,
                error: null,
                validationError: null,
            };
        case ACTIONS.GET_TRADEMARK_INVALIDATION_FIELD_INFO:
            return {
                ...state,
                trademarkInvalidationFieldInfoLoading: true,
                error: null,
                validationError: null,
            };
        case ACTIONS.GET_TRADEMARK_REVOCATION_FIELD_INFO:
            return {
                ...state,
                trademarkRevocationFieldInfoLoading: true,
                error: null,
                validationError: null,
            };
        case ACTIONS.GET_DESIGN_OPPOSITION_ENUMS:
            return {
                ...state,
                designOppositionEnumsLoading: true,
                error: null,
                validationError: null,
            };
        case ACTIONS.GET_DESIGN_OPPOSITION_FIELD_INFO:
            return {
                ...state,
                designOppositionFieldInfoLoading: true,
                error: null,
                validationError: null,
            };
        case ACTIONS.GET_REFUSED_TRADEMARK_ENUMS:
            return {
                ...state,
                refusedTrademarkEnumsLoading: true,
                error: null,
                validationError: null,
            };
        case ACTIONS.GET_REFUSED_TRADEMARK_FIELD_INFO:
            return {
                ...state,
                refusedTrademarkFieldInfoLoading: true,
                error: null,
                validationError: null,
            };
        case ACTIONS.GET_DESIGN_SEARCH_META:
            return {
                ...state,
                designSearchMetaLoading: true,
                error: null,
            };
        case ACTIONS.GET_PAS_SEARCH_META:
            return {
                ...state,
                pasSearchMetaLoading: true,
                error: null,
            };
        case ACTIONS.GET_TRADEMARK_APPEAL_SEARCH_META:
            return {
                ...state,
                trademarkAppealSearchMetaLoading: true,
                error: null,
            };
        case ACTIONS.GET_DESIGN_APPEAL_SEARCH_META:
            return {
                ...state,
                designAppealSearchMetaLoading: true,
                error: null,
            };
        case ACTIONS.GET_PATENT_APPEAL_SEARCH_META:
            return {
                ...state,
                patentAppealSearchMetaLoading: true,
                ...state,
                pasSearchMetaLoading: true,
                error: null,
            };
        case ACTIONS.GET_REFUSEDTRADEMARK_SEARCH_META:
            return {
                ...state,
                refusedTrademarkSearchMetaLoading: true,
                error: null,
            };
        case ACTIONS.GET_TRADEMARK_OPPOSITION_SEARCH_META:
            return {
                ...state,
                trademarkOppositionSearchMetaLoading: true,
                error: null,
            };
        case ACTIONS.GET_TRADEMARK_INVALIDATION_SEARCH_META:
            return {
                ...state,
                trademarkInvalidationSearchMetaLoading: true,
                error: null,
            };
        case ACTIONS.GET_TRADEMARK_REVOCATION_SEARCH_META:
            return {
                ...state,
                trademarkRevocationSearchMetaLoading: true,
                error: null,
            };
        case ACTIONS.GET_DESIGN_OPPOSITION_SEARCH_META:
            return {
                ...state,
                designOppositionSearchMetaLoading: true,
                error: null,
            };
        case ACTIONS.GET_TRADEMARK_FIELD_INFO:
            return {
                ...state,
                trademarkFieldInfoLoading: true,
                error: null, // Reset error state.
                validationError: null,
            };
        case ACTIONS.GET_PAS_FIELD_INFO:
            return {
                ...state,
                pasFieldInfoLoading: true,
                error: null, // Reset error state.
                validationError: null,
            };
        case ACTIONS.GET_DESIGN_FIELD_INFO:
            return {
                ...state,
                designFieldInfoLoading: true,
                error: null, // Reset error state.
                validationError: null,
            };
        case ACTIONS.SERVER_ERROR:
            return {
                ...state,
                error: action.payload.error,
                rowsLoading: false,
            };
        case ACTIONS.VALIDATION_ERROR:
            return {
                ...state,
                error: null,
                validationError: action.payload.validationError,
                rowsLoading: false,
            };
        case ACTIONS.ERROR_TRADEMARK_ENUMS:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                trademarkEnumsLoading: false,
            };
        case ACTIONS.ERROR_PATENT_ENUMS:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                patentEnumsLoading: false,
            };
        case ACTIONS.ERROR_DESIGN_ENUMS:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                designEnumsLoading: false,
            };
        case ACTIONS.ERROR_PAS_ENUMS:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                pasEnumsLoading: false,
            };
        case ACTIONS.ERROR_TRADEMARK_OPPOSITION_ENUMS:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                trademarkOppositionEnumsLoading: false,
            };
        case ACTIONS.ERROR_TRADEMARK_INVALIDATION_ENUMS:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                trademarkInvalidationEnumsLoading: false,
            };
        case ACTIONS.ERROR_TRADEMARK_REVOCATION_ENUMS:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                trademarkRevocationEnumsLoading: false,
            };
        case ACTIONS.ERROR_TRADEMARK_OPPOSITION_FIELD_INFO:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                trademarkOppositionFieldInfoLoading: false,
            };
        case ACTIONS.ERROR_TRADEMARK_INVALIDATION_FIELD_INFO:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                trademarkInvalidationFieldInfoLoading: false,
            };
        case ACTIONS.ERROR_TRADEMARK_REVOCATION_FIELD_INFO:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                trademarkRevocationFieldInfoLoading: false,
            };
        case ACTIONS.ERROR_DESIGN_APPEAL_ENUMS:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                designAppealEnumsLoading: false,
            };
        case ACTIONS.ERROR_DESIGN_APPEAL_FIELD_INFO:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                designAppealFieldInfoLoading: false,
            };
        case ACTIONS.ERROR_TRADEMARK_APPEAL_ENUMS:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                trademarkAppealEnumsLoading: false,
            };
        case ACTIONS.ERROR_TRADEMARK_APPEAL_FIELD_INFO:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                trademarkAppealFieldInfoLoading: false,
            };
        case ACTIONS.ERROR_DESIGN_OPPOSITION_ENUMS:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                designOppositionEnumsLoading: false,
            };
        case ACTIONS.ERROR_DESIGN_OPPOSITION_FIELD_INFO:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                designOppositionFieldInfoLoading: false,
            };
        case ACTIONS.ERROR_PATENT_APPEAL_ENUMS:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                patentAppealEnumsLoading: false,
            };
        case ACTIONS.ERROR_PATENT_APPEAL_FIELD_INFO:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                patentAppealFieldInfoLoading: false,
            };
        case ACTIONS.ERROR_REFUSED_TRADEMARK_ENUMS:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                refusedTrademarkEnumsLoading: false,
            };
        case ACTIONS.ERROR_REFUSED_TRADEMARK_FIELD_INFO:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                refusedTrademarkFieldInfoLoading: false,
            };
        case ACTIONS.ERROR_DESIGN_SEARCH_META:
            return {
                ...state,
                error: action.payload.error,
                designSearchMetaLoading: false,
            };
        case ACTIONS.ERROR_PAS_SEARCH_META:
            return {
                ...state,
                error: action.payload.error,
                pasSearchMetaLoading: false,
            };
        case ACTIONS.ERROR_REFUSEDTRADEMARK_SEARCH_META:
            return {
                ...state,
                error: action.payload.error,
                refusedTrademarkSearchMetaLoading: false,
            };
        case ACTIONS.ERROR_TRADEMARK_OPPOSITION_SEARCH_META:
            return {
                ...state,
                error: action.payload.error,
                trademarkOppositionSearchMetaLoading: false,
            };
        case ACTIONS.ERROR_TRADEMARK_INVALIDATION_SEARCH_META:
            return {
                ...state,
                error: action.payload.error,
                trademarkInvalidationSearchMetaLoading: false,
            };
        case ACTIONS.ERROR_TRADEMARK_REVOCATION_SEARCH_META:
            return {
                ...state,
                error: action.payload.error,
                trademarkRevocationSearchMetaLoading: false,
            };
        case ACTIONS.ERROR_DESIGN_OPPOSITION_SEARCH_META:
            return {
                ...state,
                error: action.payload.error,
                designOppositionSearchMetaLoading: false,
            };
        case ACTIONS.ERROR_TRADEMARK_FIELD_INFO:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                trademarkFieldInfoLoading: false,
            };
        case ACTIONS.ERROR_PATENT_FIELD_INFO:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                patentFieldInfoLoading: false,
            };
        case ACTIONS.ERROR_PAS_FIELD_INFO:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                pasFieldInfoLoading: false,
            };
        case ACTIONS.ERROR_DESIGN_FIELD_INFO:
            return {
                ...state,
                error: action.payload.error,
                validationError: null,
                designFieldInfoLoading: false,
            };
        case ACTIONS.API:
            return {
                ...state,
                apis: action.payload.urls,
            };
        case ACTIONS.PAS_ROWS:
            return {
                ...state,
                rowsLoading: false,
                pases: action.payload.rows,
                patents: action.payload.patents,
                urls: action.payload.urls,
                pageCount: action.payload.pageCount,
                rowCount: action.payload.rowCount,
                notifications: action.payload.notifications,
            };
        case ACTIONS.TRADEMARK_ROWS:
            return {
                ...state,
                rowsLoading: false,
                trademarks: action.payload.rows,
                urls: action.payload.urls,
                pageCount: action.payload.pageCount,
                rowCount: action.payload.rowCount,
                notifications: action.payload.notifications,
            };
        case ACTIONS.PATENT_ROWS:
            return {
                ...state,
                rowsLoading: false,
                patents: action.payload.rows,
                urls: action.payload.urls,
                pageCount: action.payload.pageCount,
                rowCount: action.payload.rowCount,
                notifications: action.payload.notifications,
                patentNumber: action.payload.patentNumber,
                patentApplicationNumber: action.payload.patentApplicationNumber,
            };
        case ACTIONS.DESIGN_ROWS:
            return {
                ...state,
                rowsLoading: false,
                designs: action.payload.rows,
                urls: action.payload.urls,
                pageCount: action.payload.pageCount,
                rowCount: action.payload.rowCount,
                notifications: action.payload.notifications,
            };
        case ACTIONS.DESIGN_APPEAL_ROWS:
            return {
                ...state,
                rowsLoading: false,
                designAppeals: action.payload.rows,
                urls: action.payload.urls,
                pageCount: action.payload.pageCount,
                rowCount: action.payload.rowCount,
                notifications: action.payload.notifications,
            };
        case ACTIONS.REFUSEDTRADEMARK_ROWS:
            return {
                ...state,
                rowsLoading: false,
                refusedTrademarks: action.payload.rows,
                urls: action.payload.urls,
                pageCount: action.payload.pageCount,
                rowCount: action.payload.rowCount,
                notifications: action.payload.notifications,
            };
        case ACTIONS.PATENT_APPEAL_ROWS:
            return {
                ...state,
                rowsLoading: false,
                patentAppeals: action.payload.rows,
                urls: action.payload.urls,
                pageCount: action.payload.pageCount,
                rowCount: action.payload.rowCount,
                notifications: action.payload.notifications,
            };
        case ACTIONS.TRADEMARK_OPPOSITION_ROWS:
            return {
                ...state,
                rowsLoading: false,
                trademarkOppositions: action.payload.rows,
                urls: action.payload.urls,
                pageCount: action.payload.pageCount,
                rowCount: action.payload.rowCount,
                notifications: action.payload.notifications,
            };
        case ACTIONS.TRADEMARK_INVALIDATION_ROWS:
            return {
                ...state,
                rowsLoading: false,
                trademarkInvalidations: action.payload.rows,
                urls: action.payload.urls,
                pageCount: action.payload.pageCount,
                rowCount: action.payload.rowCount,
                notifications: action.payload.notifications,
            };
        case ACTIONS.TRADEMARK_REVOCATION_ROWS:
            return {
                ...state,
                rowsLoading: false,
                trademarkRevocations: action.payload.rows,
                urls: action.payload.urls,
                pageCount: action.payload.pageCount,
                rowCount: action.payload.rowCount,
                notifications: action.payload.notifications,
            };
        case ACTIONS.TRADEMARK_APPEAL_ROWS:
            return {
                ...state,
                rowsLoading: false,
                trademarkAppeals: action.payload.rows,
                urls: action.payload.urls,
                pageCount: action.payload.pageCount,
                rowCount: action.payload.rowCount,
                notifications: action.payload.notifications,
            };
        case ACTIONS.DESIGN_OPPOSITION_ROWS:
            return {
                ...state,
                rowsLoading: false,
                designOppositions: action.payload.rows,
                urls: action.payload.urls,
                pageCount: action.payload.pageCount,
                rowCount: action.payload.rowCount,
                notifications: action.payload.notifications,
            };
        case ACTIONS.DESIGN_SEARCH_META:
            return {
                ...state,
                designSearchMetaLoading: false,
                designSearchMeta: action.payload.searchMeta,
            };
        case ACTIONS.PAS_SEARCH_META:
            return {
                ...state,
                pasSearchFieldsLoading: false,
                pasSearchMeta: action.payload.searchMeta,
            };
        case ACTIONS.TRADEMARK_APPEAL_SEARCH_META:
            return {
                ...state,
                trademarkAppealSearchFieldsLoading: false,
                trademarkAppealSearchMeta: action.payload.searchMeta,
            };
        case ACTIONS.DESIGN_APPEAL_SEARCH_META:
            return {
                ...state,
                designAppealSearchFieldsLoading: false,
                designAppealSearchMeta: action.payload.searchMeta,
            };
        case ACTIONS.PATENT_APPEAL_SEARCH_META:
            return {
                ...state,
                patentAppealSearchFieldsLoading: false,
                patentAppealSearchMeta: action.payload.searchMeta,
            };
        case ACTIONS.REFUSEDTRADEMARK_SEARCH_META:
            return {
                ...state,
                refusedTradmarkSearchFieldsLoading: false,
                refusedTrademarkSearchMeta: action.payload.searchMeta,
            };
        case ACTIONS.TRADEMARK_OPPOSITION_SEARCH_META:
            return {
                ...state,
                trademarkOppositionSearchMetaLoading: false,
                trademarkOppositionSearchMeta: action.payload.searchMeta,
            };
        case ACTIONS.TRADEMARK_INVALIDATION_SEARCH_META:
            return {
                ...state,
                trademarkInvalidationSearchMetaLoading: false,
                trademarkInvalidationSearchMeta: action.payload.searchMeta,
            };
        case ACTIONS.TRADEMARK_REVOCATION_SEARCH_META:
            return {
                ...state,
                trademarkRevocationSearchMetaLoading: false,
                trademarkRevocationSearchMeta: action.payload.searchMeta,
            };
        case ACTIONS.DESIGN_OPPOSITION_SEARCH_META:
            return {
                ...state,
                designOppositionSearchMetaLoading: false,
                designOppositionSearchMeta: action.payload.searchMeta,
            };
        case ACTIONS.TRADEMARK_ENUMS:
            return {
                ...state,
                trademarkEnumsLoading: false,
                trademarkEnums: action.payload.enums,
            };
        case ACTIONS.TRADEMARK_FIELD_INFO:
            return {
                ...state,
                trademarkFieldInfoLoading: false,
                trademarkFieldInfo: action.payload.fields,
            };
        case ACTIONS.PATENT_FIELD_INFO:
            return {
                ...state,
                patentFieldInfoLoading: false,
                patentFieldInfo: action.payload.fields,
            };
        case ACTIONS.PAS_FIELD_INFO:
            return {
                ...state,
                pasFieldInfoLoading: false,
                pasFieldInfo: action.payload.fields,
            };
        case ACTIONS.DESIGN_FIELD_INFO:
            return {
                ...state,
                designFieldInfoLoading: false,
                designFieldInfo: action.payload.fields,
            };
        case ACTIONS.PATENT_ENUMS:
            return {
                ...state,
                patentEnumsLoading: false,
                patentEnums: action.payload.enums,
            };
        case ACTIONS.DESIGN_ENUMS:
            return {
                ...state,
                designEnumsLoading: false,
                designEnums: action.payload.enums,
            };
        case ACTIONS.PAS_ENUMS:
            return {
                ...state,
                pasEnumsLoading: false,
                pasEnums: action.payload.enums,
            };
        case ACTIONS.DESIGN_APPEAL_ENUMS:
            return {
                ...state,
                designAppealEnumsLoading: false,
                designAppealEnums: action.payload.enums,
            };
        case ACTIONS.DESIGN_APPEAL_FIELD_INFO:
            return {
                ...state,
                designAppealFieldInfoLoading: false,
                designAppealFieldInfo: action.payload.fields,
            };
        case ACTIONS.TRADEMARK_APPEAL_ENUMS:
            return {
                ...state,
                trademarkAppealEnumsLoading: false,
                trademarkAppealEnums: action.payload.enums,
            };
        case ACTIONS.TRADEMARK_APPEAL_FIELD_INFO:
            return {
                ...state,
                trademarkAppealFieldInfoLoading: false,
                trademarkAppealFieldInfo: action.payload.fields,
            };
        case ACTIONS.PATENT_APPEAL_ENUMS:
            return {
                ...state,
                patentAppealEnumsLoading: false,
                patentAppealEnums: action.payload.enums,
            };
        case ACTIONS.PATENT_APPEAL_FIELD_INFO:
            return {
                ...state,
                patentAppealFieldLoading: false,
                patentAppealFieldInfo: action.payload.fields,
            };
        case ACTIONS.DESIGN_OPPOSITION_ENUMS:
            return {
                ...state,
                designOppositionEnumsLoading: false,
                designOppositionEnums: action.payload.enums,
            };
        case ACTIONS.DESIGN_OPPOSITION_FIELD_INFO:
            return {
                ...state,
                designOppositionFieldInfoLoading: false,
                designOppositionFieldInfo: action.payload.fields,
            };
        case ACTIONS.TRADEMARK_OPPOSITION_ENUMS:
            return {
                ...state,
                trademarkOppositionEnumsLoading: false,
                trademarkOppositionEnums: action.payload.enums,
            };
        case ACTIONS.TRADEMARK_INVALIDATION_ENUMS:
            return {
                ...state,
                trademarkInvalidationEnumsLoading: false,
                trademarkInvalidationEnums: action.payload.enums,
            };
        case ACTIONS.TRADEMARK_REVOCATION_ENUMS:
            return {
                ...state,
                trademarkRevocationEnumsLoading: false,
                trademarkRevocationEnums: action.payload.enums,
            };
        case ACTIONS.TRADEMARK_OPPOSITION_FIELD_INFO:
            return {
                ...state,
                trademarkOppositionFieldInfoLoading: false,
                trademarkOppositionFieldInfo: action.payload.fields,
            };
        case ACTIONS.TRADEMARK_INVALIDATION_FIELD_INFO:
            return {
                ...state,
                trademarkInvalidationFieldInfoLoading: false,
                trademarkInvalidationFieldInfo: action.payload.fields,
            };
        case ACTIONS.TRADEMARK_REVOCATION_FIELD_INFO:
            return {
                ...state,
                trademarkRevocationFieldInfoLoading: false,
                trademarkRevocationFieldInfo: action.payload.fields,
            };
        case ACTIONS.REFUSED_TRADEMARK_ENUMS:
            return {
                ...state,
                refusedTrademarkEnumsLoading: false,
                refusedTrademarkEnums: action.payload.enums,
            };
        case ACTIONS.REFUSED_TRADEMARK_FIELD_INFO:
            return {
                ...state,
                refusedTrademarkFieldInfoLoading: false,
                refusedTrademarkFieldInfo: action.payload.fields,
            };
        case ACTIONS.UPDATE_COLUMNS:
            return {
                ...state,
                error: action.payload.error,
                columns: action.payload.columns,
            };
        case ACTIONS.PURGE:
            return {
                ...state,
                columns: [],
                pageCount: 0,
                rowCount: 0,
                urls: null,
                meta: null,
                rows: [],
            };
        case ACTIONS.SELECTED_ROWS:
            return {
                ...state,
                selectedRows: action.payload.selectedRows,
            };
        case ACTIONS.PATENT_SEARCH_REDIRECT_CLOSE:
            return {
                ...state,
                patentNumber: undefined,
                patentApplicationNumber: undefined,
            };
        default:
            return state;
    }
}
