import { useLocation } from 'react-router-dom';
import { parse } from 'qs';
import { useStore } from '../../storage/StoreProvider';
import { DefaultRows } from './detailedFormRowDefinitions';
import { fetchTrademarkData } from '../../services/trademarkService';
import React from 'react';
import TrademarkStatusTimeline from '../../components/FormDataGrid/Timeline/TrademarkStatusTimeline/TrademarkStatusTimeline';
import GridBasePrintForm from '../../components/FormDataGrid/GridBasePrintForm';
import { DefaultFetchDataProps } from '../../services/baseService';

export default function TrademarksPrintForm({ match }) {
    const [state] = useStore();

    const rowSets = DefaultRows(state.trademarks, true);

    const StatusTimelines = React.useMemo(
        () =>
            state.trademarks?.map((trademark, index) => (
                <TrademarkStatusTimeline key={index} statusId={trademark.status} />
            )),
        [state.trademarks]
    );

    const dataResolver = async (props: DefaultFetchDataProps) => {
        await fetchTrademarkData({
            page: props.page,
            rowsPerPage: props.filter?.length,
            sortModel: props.sortModel,
            filter: props.filter,
            action: props.action,
        });
    };

    return (
        <GridBasePrintForm
            filterBy="applicationNumber"
            rowsPerPage={rowSets.length}
            dataResolver={dataResolver}
            rowSets={rowSets}
            match={match}
            isDataResolved={state.trademarks.length === 0}
            timelines={StatusTimelines}
        />
    );
}