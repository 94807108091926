import axios, { AxiosError, AxiosResponse } from "axios";
import { ACTIONS } from "../storage/dataReducer";
import DefaultRequestModel from "../models/DefaultRequestModel";
import DefaultResponseModel from "../models/DefaultResponseModel";
import * as Base from './baseService';
import { Store } from '../storage/StoreProvider';
import EnumsResponseModel from "../models/responses/EnumsResponseModel";
import Resource from "../models/grid/Resource";
import FieldInfoResponseModel from "../models/responses/FieldInfoResponseModel";
import i18n from "../i18n/config";

export async function fetchPatentAppealData(props: Base.DefaultFetchDataProps): Promise<any> {
    const resource = Resource.PatentAppeal;
    const endpoint = (await Base.getBackendDefinedAPI(resource)) ?? Base.getFrontendDefinedAPI(resource);
    // https://github.com/facebook/react/issues/15344
    // To improve current solution due to react bug - use React Redux.
    return new Promise(async resolve => {
        Store.dispatch({ type: ACTIONS.GET_ROWS });
        // Check if we should return promise immediately
        // It is used for data retrieval without filter validation
        if (props.action === ACTIONS.GET_ROWS) resolve({});

        await axios.get<DefaultRequestModel, AxiosResponse<DefaultResponseModel>>(endpoint, {
            params: Base.buildGridDataRequest(props as Base.DefaultProps),
            headers: Base.defaultRequestHeaders(),
        })
            .then(async response => {
                const payload = await Base.buildGridData(response.data);
                Store.dispatch({
                    type: ACTIONS.PATENT_APPEAL_ROWS, payload: payload,
                });
                resolve(payload);

                // Fetch enums after getting urls to other resources                
                const enumsResourceKey = Resource[Resource.Enums];
                fetchPatentAppealEnums(payload.urls && enumsResourceKey in payload.urls ? payload.urls[enumsResourceKey] : null);
                // Fetch search meta after getting urls to other resources
                const searchMetaResourceKey = Resource[Resource.SearchMeta];
                fetchPatentAppealSearchMeta(payload.urls && searchMetaResourceKey in payload.urls ? payload.urls[searchMetaResourceKey] : null);

                const fieldsResourceKey = Resource[Resource.FieldInfo];
                fetchPatentAppealFieldInfo(payload.urls && fieldsResourceKey in payload.urls ? payload.urls[fieldsResourceKey] : null);
            })
            .catch((e: AxiosError) => {
                const gridError = Base.buildGridDataError(e, i18n.t(['patentAppealForm:failedToLoad']));
                Store.dispatch(gridError);
                resolve(gridError.payload);
            });
    });
}

export async function fetchPatentAppealEnums(url?: string) {
    Store.dispatch({ type: ACTIONS.GET_PATENT_APPEAL_ENUMS });
    try {
        const enumResponse = await Base.getResourceData<EnumsResponseModel>(Resource.Enums, url);
        Store.dispatch({
            type: ACTIONS.PATENT_APPEAL_ENUMS, payload: {
                enums: enumResponse.enums,
            }
        });
    } catch (e) {
        Store.dispatch({
            type: ACTIONS.ERROR_PATENT_APPEAL_ENUMS,
            payload: { error: Base.formatErrorResponse(e) ?? 'Nesanāca ielādēt patentu apelāciju enum vērtības.' }
        });
    }
}

export async function fetchPatentAppealFieldInfo(url?: string) {
    Store.dispatch({ type: ACTIONS.GET_PATENT_APPEAL_FIELD_INFO });
    try {
        const fieldResponse = await Base.getResourceData<FieldInfoResponseModel>(Resource.FieldInfo, url);
        Store.dispatch({
            type: ACTIONS.PATENT_APPEAL_FIELD_INFO, payload: {
                fields: fieldResponse.fields,
            }
        });
    } catch (e) {
        Store.dispatch({
            type: ACTIONS.ERROR_PATENT_APPEAL_FIELD_INFO,
            payload: { error: Base.formatErrorResponse(e) ?? 'Nesanāca ielādēt patentu apelāciju field info vērtības.' }
        });
    }
}

export async function fetchPatentAppealSearchMeta(url?: string) {
    Store.dispatch({ type: ACTIONS.GET_PATENT_APPEAL_SEARCH_META });
    try {
        const response = await Base.getResourceData<DefaultResponseModel>(Resource.SearchMeta, url);
        Store.dispatch({
            type: ACTIONS.PATENT_APPEAL_SEARCH_META, payload: {
                searchMeta: response.collection,
            }
        });
    } catch (e) {
        Store.dispatch({
            type: ACTIONS.ERROR_PATENT_APPEAL_SEARCH_META,
            payload: { error: e.response ? `Servera kļūda — ${e.response.data}` : 'Nesanāca ielādēt meklēšanas lauku meta informāciju.' }
        });
    }
}