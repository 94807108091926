export enum PatentStatus {
    // TODO: Check values after bug fix (132047) on BE side
    EXAMINATION_OF_PUBLISHED_PATENT_APPLICATION = 5,
    WITHDRAWN_PUBLISHED_PATENT_APPLICATION = 6,
    REJECTED_PUBLISHED_PATENT_APPLICATION = 7,
    PATENT_APPLICATION_UNDER_APPEAL = 8,
    DECISION_TO_GRANT_A_PATENT = 9,
    PATENT_IS_IN_FORCE = 10,
    MISSING_PAYMENT_OF_RENEWAL_FEE = 11,
    WITHDRAWN_PATENT = 12,
    EARLY_PATENT_LAPSE = 13,
    EXPIRED_PATENT = 14,
    APPLICATION_FOR_SPC_FILED = 20,
    APPLICATION_FOR_SPC_REJECTED = 21,
    APPLICATION_FOR_SPC_WITHDRAWN = 22,
    APPLICATION_FOR_SPC_CONSIDERED_NOT_FILED = 23,
    APPLICATION_FOR_SPC_UNDER_APPEAL = 24,
    SPC_IS_IN_FORCE = 25,
    MISSING_PAYMENT_OF_RENEWAL_FEE_2 = 26, // BE duplicate status ?
    EARLY_LAPSE_OF_SPC = 27,
    EXPIRED_SPC = 28,
    SPC_APPLICATION_UNDER_EXAMINATION = 29,
    DECISION_TO_GRANT_SPC = 30,
    SPC_NOT_YET_IN_FORCE = 31,
    EXAMINATION_OF_SPC_APPLICATION_POSTPONED = 32,
    SPC_RECALLED = 33,
    REQUEST_FOR_PUBLICATION_OF_EP_APPLICATION_FILED = 101,
    EP_APPLICATION_PUBLISHED_TEMPORARY_PROTECTION_APPLIED = 103,
    PUBLISHING_OF_EP_APPLICATION_REJECTED = 105,
    APPLYING_FOR_EP_VALIDATION_EXTENSION_IN_LATVIA_ALLOWED = 110,
    EP_IS_NOT_EXTENDED_IN_LATVIA = 111,
    REQUEST_FOR_EP_VALIDATION_EXTENSION_IN_LATVIA_FILED = 112,
    REQUEST_FOR_EP_VALIDATION_EXTENSION_IN_LATVIA_REJECTED = 114,
    EP_IS_VALIDATED_EXTENDED_IN_LATVIA = 120,
    MISSING_PAYMENT_OF_EP_RENEWAL_FEE = 121,
    EP_IS_NOT_VALIDATED_IN_LATVIA = 122,
    VALIDATION_EXTENSION_OF_EP_IN_LATVIA_IS_UNDER_APPEAL = 123,
    UNITARY_EP = 124,
    EP_IS_NOT_IN_FORCE_POSSIBLE_RE_ESTABLISHMENT_OF_RIGHTS = 125,
    EARLY_LAPSE_OF_EP = 190,
    EXPIRED_EP = 191,
    WITHDRAWN_EP = 192,
    LAPSE_OF_EP_AFTER_OPPOSITION = 193,
    PATENT_IS_IN_FORCE_2 = 210, // BE duplicate status ?
    LAPSE_OF_EP_LV_AFTER_OPPOSITION = 222,
}

export const getActiveStep = (statusId: number) => {
    switch (statusId) {
        case PatentStatus.REQUEST_FOR_EP_VALIDATION_EXTENSION_IN_LATVIA_FILED:
            return 2;
        case PatentStatus.EP_IS_VALIDATED_EXTENDED_IN_LATVIA:
        case PatentStatus.MISSING_PAYMENT_OF_EP_RENEWAL_FEE:
        case PatentStatus.REQUEST_FOR_EP_VALIDATION_EXTENSION_IN_LATVIA_REJECTED:        
        case PatentStatus.EXAMINATION_OF_PUBLISHED_PATENT_APPLICATION:
        case PatentStatus.VALIDATION_EXTENSION_OF_EP_IN_LATVIA_IS_UNDER_APPEAL:
            return 3;
        case PatentStatus.WITHDRAWN_EP:
        case PatentStatus.EARLY_LAPSE_OF_EP:
        case PatentStatus.LAPSE_OF_EP_AFTER_OPPOSITION:
        case PatentStatus.DECISION_TO_GRANT_A_PATENT:
        case PatentStatus.PATENT_APPLICATION_UNDER_APPEAL:
        case PatentStatus.LAPSE_OF_EP_LV_AFTER_OPPOSITION:
            return 4;
        case PatentStatus.REJECTED_PUBLISHED_PATENT_APPLICATION:
        case PatentStatus.EXPIRED_EP:
        case PatentStatus.PATENT_IS_IN_FORCE:
        case PatentStatus.MISSING_PAYMENT_OF_RENEWAL_FEE:
            return 5;
        case PatentStatus.EXPIRED_PATENT:
        case PatentStatus.WITHDRAWN_PATENT:
        case PatentStatus.EARLY_PATENT_LAPSE:
            return 6;
        default:
            return -1; // No status
    }
};