import React from 'react';
import { Box } from '@material-ui/core';
export interface CustomizedSteppersProps {
    component: any;
}

export default function CustomizedSteppers({ component }: CustomizedSteppersProps) {
    return (
        <React.Fragment>
            <Box display="flex">
                <Box flexGrow={1}></Box>
                {component}
                <Box flexGrow={1}></Box>
            </Box>
        </React.Fragment>
    );
}