import { Box, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

export default function CustomGridErrorOverlay(props) {
    const { t } = useTranslation();
    return (
        <Alert variant="outlined" severity="error">
            <AlertTitle>{t("customGrid:alertMessage")}</AlertTitle>
            <Typography component="div">
                <Box fontWeight="fontWeightBold">
                    {props.loadError}
                </Box>
            </Typography>
        </Alert>
    );
}