import {
    Accordion,
    AccordionSummary,
    Box,
    Button,
    createStyles,
    Grid,
    GridSize,
    makeStyles,
    Theme,
    Typography,
    AccordionDetails,
    FormControlLabel,
    Checkbox,
    Paper,
} from "@material-ui/core";
import React, { useState } from "react";
import { Form } from "react-final-form";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import { useTranslation } from "react-i18next";
import { GridStorage } from "../../storage/browserStorage";
import NotificationField from "../../components/NotificationField";
import { useStore } from "../../storage/StoreProvider";

// Theme-dependent styles.
// createStyles is a helper function for TS compile.
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        clearButton: {
            "&:hover": {
                backgroundColor: "transparent",
            },
        },
        container: {
            margin: theme.spacing(2, 0, 2, 0),
        },
        margin: {
            margin: theme.spacing(2),
            minWidth: "12.5rem",
        },
        root: {
            width: "100%",
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        icon: {
            verticalAlign: "bottom",
            height: 20,
            width: 20,
        },
        details: {
            alignItems: "center",
            paddingTop: "0",
            paddingBottom: "0",
        },
        column: {
            flex: 1,
        },
        helper: {
            borderLeft: `2px solid ${theme.palette.divider}`,
            padding: theme.spacing(1, 2),
        },
        link: {
            color: theme.palette.primary.main,
            textDecoration: "none",
            "&:hover": {
                textDecoration: "underline",
            },
        },
        paper: {
            paddingRight: 16,
            paddingLeft: 16,
            paddingTop: 12,
        },
    })
);

export interface SearchField {
    size: GridSize;
    field: JSX.Element;
    label: string;
    name: string;
    showOnExtendedSearch?: boolean;
    defaultStateOnExtendedSearch?: boolean;
    tooltip?: JSX.Element;
    show?: boolean;
    enum?: string;
    classificationUrl: string;
}
interface SearchFormProps {
    formFields: SearchField[];
    onSubmit: any;
    hidePreciseSearch?: boolean;
}

export default function SearchForm({
    formFields,
    onSubmit,
    hidePreciseSearch,
}: SearchFormProps) {
    const classes = useStyles();
    const storage = GridStorage.getInstance();
    const [activeCheckboxes, setActiveCheckboxes] = useState(
        storage.getExtendedSearchFields() ?? []
    );
    const [preciseSearch, setPreciseSearch] = React.useState(false);
    const [expanded, setExpanded] = useState<boolean>(
        activeCheckboxes?.length > 0
    );
    const [state, dispatch] = useStore();

    const gridFragment = (submitError: any) => {
        return (
            <Grid
                item
                container
                xs={12}
                md={12}
                spacing={2}
                onClick={(event) => event.stopPropagation()}
                style={{ cursor: "default" }}
                onFocus={(event) => event.stopPropagation()}
            >
                <Grid item xs={12} md={12}>
                    {state.notifications?.length > 0 && <NotificationField />}
                </Grid>
                {formFields?.map(
                    (item, index) =>
                        (!item.showOnExtendedSearch ||
                            (expanded &&
                                item.showOnExtendedSearch &&
                                isChecked(
                                    item.name,
                                    item.defaultStateOnExtendedSearch
                                ))) && (
                            <Grid item container xs={12} md={6} key={index}>
                                <Grid item xs={12} md={item.size} key={index}>
                                    {item.field}
                                </Grid>
                            </Grid>
                        )
                )}
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    alignContent="center"
                    justify="center"
                >
                    {submitError && renderErrors(submitError)}
                </Grid>
                <Grid item container xs={12} md={12} justify="space-between">
                    <Grid item>
                        {!hidePreciseSearch ? (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={preciseSearch}
                                        onChange={handleSearchTypeChange}
                                        name="preciseSearch"
                                        color="primary"
                                    />
                                }
                                label={t("searchForm:preciseSearchMessage")}
                            />
                        ) : null}
                    </Grid>
                    {formFields?.some((item) => item.showOnExtendedSearch) ? (
                        <Grid item>
                            <Button
                                onClick={() => {
                                    setExpanded((e) => !e);
                                }}
                                endIcon={
                                    expanded ? <ExpandLess /> : <ExpandMore />
                                }
                            >
                                <Typography>
                                    {expanded
                                        ? t("searchForm:closeExtendedSearch")
                                        : t("searchForm:openExtendedSearch")}
                                </Typography>
                            </Button>
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
        );
    };

    const handleSearchTypeChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setPreciseSearch(!preciseSearch);
    };

    const handleChange =
        () => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
            setExpanded(isExpanded);
        };

    const handleCheckbox = (id: string) => {
        const found = activeCheckboxes.includes(id);
        const exf = storage.getExtendedSearchFields() ?? [];
        if (found) {
            setActiveCheckboxes(activeCheckboxes.filter((x) => x !== id));
            const index = exf.indexOf(id);
            exf.splice(index, 1);
        } else {
            setActiveCheckboxes([...activeCheckboxes, id]);
            exf.push(id);
        }
        storage.setExtendedSearchFields(exf);
    };

    const isChecked = (id: string, defaultValue?: boolean) => {
        const found = activeCheckboxes.includes(id);
        return defaultValue ? !found : found;
    };

    const renderErrors = (errors) =>
        errors ? (
            <Box>
                <span style={{ color: "red" }}>{errors}</span>
            </Box>
        ) : (
            ""
        );

    const { t } = useTranslation();

    const resetForm = (form: any, event?: any) => {
        form.reset();
        storage.clearSearchFilter();
    };

    return (
        <React.Fragment>
            <Form
                onSubmit={(params) => onSubmit(params, preciseSearch)}
                render={({
                    submitError,
                    handleSubmit,
                    form,
                    submitting,
                    pristine,
                    values,
                }) => (
                    <form onSubmit={handleSubmit} autoComplete="off">
                        {formFields?.find((f) => f.showOnExtendedSearch) ? (
                            <Accordion expanded={expanded} variant="outlined">
                                <AccordionSummary>
                                    {gridFragment(submitError)}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid item container xs={12} md={12}>
                                        <Grid item xs={12} md={12}>
                                            <Typography
                                                style={{
                                                    fontWeight: "bold",
                                                    paddingBottom: "1em",
                                                }}
                                            >
                                                {t(
                                                    "searchForm:extendedSearchMessage"
                                                )}
                                            </Typography>
                                        </Grid>
                                        {formFields?.map(
                                            (item, index) =>
                                                item.showOnExtendedSearch && (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={4}
                                                        key={index}
                                                    >
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={isChecked(
                                                                        item.name,
                                                                        item.defaultStateOnExtendedSearch
                                                                    )}
                                                                    onChange={() =>
                                                                        handleCheckbox(
                                                                            item.name
                                                                        )
                                                                    }
                                                                    name={
                                                                        item.label
                                                                    }
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={
                                                                <label>
                                                                    {item.label}{" "}
                                                                    <a
                                                                        href={
                                                                            item.classificationUrl
                                                                        }
                                                                        style={{
                                                                            color: "#840b55",
                                                                        }}
                                                                        target="_blank"
                                                                    >
                                                                        {item.classificationUrl &&
                                                                            t(
                                                                                "trademarkForm:classLabel"
                                                                            )}
                                                                    </a>
                                                                </label>
                                                            }
                                                        />
                                                    </Grid>
                                                )
                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        ) : (
                            <Paper
                                className={classes.paper}
                                elevation={0}
                                variant="outlined"
                            >
                                {gridFragment(submitError)}
                            </Paper>
                        )}
                        <Box display="flex" justifyContent="center">
                            <Button
                                variant="contained"
                                size="large"
                                color="primary"
                                type="submit"
                                disabled={submitting}
                                className={classes.margin}
                            >
                                {t("searchForm:searchMessage")}
                            </Button>
                            <Button
                                color="primary"
                                type="button"
                                onClick={(event) => resetForm(form, event)}
                                className={classes.clearButton}
                            >
                                {t("searchForm:clearMessage") || pristine}
                            </Button>
                        </Box>
                    </form>
                )}
            />
        </React.Fragment>
    );
}
