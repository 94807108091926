import { useGridSlotComponentProps } from '@material-ui/data-grid';
import Pagination from '@material-ui/lab/Pagination';
import { GridStorage } from '../../storage/browserStorage';

export default function CustomGridPagination() {
    const { state, apiRef } = useGridSlotComponentProps();
    const storage = GridStorage.getInstance();

    return (
        <div>
            <Pagination
                count={state.pagination.pageCount}
                // Take page value from storage because of some bug in Material-UI (?)
                page={storage.getPage() + 1}
                onChange={(event, value) => apiRef.current.setPage(value - 1)}
                shape="rounded"
                size="small"
                color="primary"
                showFirstButton
                showLastButton
            />
        </div>
    );
}