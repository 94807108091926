import * as React from "react";
// @ts-expect-error fixed in Material-UI v5, types definitions were added.
import { unstable_useId as useId } from "@material-ui/core/utils";
import MenuList from "@material-ui/core/MenuList";
import Button, { ButtonProps } from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {
    GridDensity,
    GridDensityOption,
    GridDensityTypes,
    GridMenu,
    useGridSelector,
    useGridSlotComponentProps,
} from "@material-ui/data-grid";
import { gridDensityValueSelector } from "../../hooks/density/densitySelector";
import { GridStorage } from "../../storage/browserStorage";

export const isEscapeKey = (key: string): boolean => key === "Escape";
export const isHideMenuKey = (key) => isTabKey(key) || isEscapeKey(key);
export const isTabKey = (key: string): boolean => key === "Tab";

// https://github.com/mui-org/material-ui-x/blob/aa11570d5fd3633a9ab3eaa73ffe049fd5d64443/packages/grid/_modules_/grid/components/toolbar/GridToolbarDensitySelector.tsx
export const GridToolbarDensitySelector = React.forwardRef<
    HTMLButtonElement,
    ButtonProps
>(function GridToolbarDensitySelector(props, ref) {
    const { onClick, ...other } = props;
    const { apiRef } = useGridSlotComponentProps();
    const storage = GridStorage.getInstance();
    // Get current grid density
    const densityValue = useGridSelector(apiRef, gridDensityValueSelector);
    // Get density from the storage if available
    const density = storage.getRowSize() ?? storage.setRowSize(densityValue);
    // If storage value differs from the current grid density, then update the grid density
    if (densityValue !== density) {
        apiRef!.current.setDensity(density as GridDensity);
    }
    const densityButtonId = useId();
    const densityMenuId = useId();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const DensityCompactIcon = apiRef!.current.components!.DensityCompactIcon!;
    const DensityStandardIcon =
        apiRef!.current.components!.DensityStandardIcon!;
    const DensityComfortableIcon =
        apiRef!.current.components!.DensityComfortableIcon!;

    const DensityOptions: Array<GridDensityOption> = [
        {
            icon: <DensityCompactIcon />,
            label: apiRef!.current.getLocaleText("toolbarDensityCompact"),
            value: GridDensityTypes.Compact,
        },
        {
            icon: <DensityStandardIcon />,
            label: apiRef!.current.getLocaleText("toolbarDensityStandard"),
            value: GridDensityTypes.Standard,
        },
        {
            icon: <DensityComfortableIcon />,
            label: apiRef!.current.getLocaleText("toolbarDensityComfortable"),
            value: GridDensityTypes.Comfortable,
        },
    ];

    const getSelectedDensityIcon = React.useCallback((): React.ReactElement => {
        switch (density) {
            case GridDensityTypes.Compact:
                return <DensityCompactIcon />;
            case GridDensityTypes.Comfortable:
                return <DensityComfortableIcon />;
            default:
                return <DensityStandardIcon />;
        }
    }, [
        density,
        DensityCompactIcon,
        DensityComfortableIcon,
        DensityStandardIcon,
    ]);

    const handleDensitySelectorOpen = (event) => {
        setAnchorEl(event.currentTarget);
        onClick?.(event);
    };
    const handleDensitySelectorClose = () => setAnchorEl(null);
    const handleDensityUpdate = (newDensity: GridDensity) => {
        storage.setRowSize(newDensity);
        apiRef!.current.setDensity(newDensity);
        setAnchorEl(null);
    };

    const handleListKeyDown = (event: React.KeyboardEvent) => {
        if (isTabKey(event.key)) {
            event.preventDefault();
        }
        if (isHideMenuKey(event.key)) {
            handleDensitySelectorClose();
        }
    };

    const renderDensityOptions: Array<React.ReactElement> = DensityOptions.map(
        (option, index) => (
            <MenuItem
                key={index}
                onClick={() => handleDensityUpdate(option.value)}
                selected={option.value === density}
            >
                <ListItemIcon>{option.icon}</ListItemIcon>
                {option.label}
            </MenuItem>
        )
    );

    return (
        <React.Fragment>
            <Button
                ref={ref}
                color="primary"
                size="small"
                startIcon={getSelectedDensityIcon()}
                aria-label={apiRef!.current.getLocaleText(
                    "toolbarDensityLabel"
                )}
                aria-expanded={anchorEl ? "true" : undefined}
                aria-haspopup="menu"
                aria-labelledby={densityMenuId}
                id={densityButtonId}
                {...other}
                onClick={handleDensitySelectorOpen}
            >
                {apiRef!.current.getLocaleText("toolbarDensity")}
            </Button>
            <GridMenu
                open={Boolean(anchorEl)}
                target={anchorEl}
                onClickAway={handleDensitySelectorClose}
                position="bottom-start"
            >
                <MenuList
                    id={densityMenuId}
                    className="MuiDataGrid-gridMenuList"
                    aria-labelledby={densityButtonId}
                    onKeyDown={handleListKeyDown}
                    autoFocusItem={Boolean(anchorEl)}
                >
                    {renderDensityOptions}
                </MenuList>
            </GridMenu>
        </React.Fragment>
    );
});
