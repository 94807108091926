import React from "react";
import { PrintForm } from "../../../forms/trademarksForm";
import DetailedFormTable, {
    DetailedFormTableRow,
} from "./DetailedFormTable/DetailedFormTable";

export interface DetailedFormProps {
    ids: string[];
    rows: DetailedFormTableRow[];
    match: any;
    timeline?: any;
    showBackButton?: boolean;
    showTimeline?: boolean;
    printView?: boolean;
    title?: string;
}

export default function DetailedForm({
    ids,
    rows,
    match,
    timeline,
    showBackButton,
    showTimeline,
    printView,
    title,
}: DetailedFormProps) {
    return (
        <React.Fragment>
            <DetailedFormTable
                ids={ids}
                rows={rows}
                match={match}
                timeline={timeline}
                showBackButton={showBackButton}
                showTimeline={showTimeline}
                printView={printView}
                title={title}
            />
        </React.Fragment>
    );
}
