import FormDataGrid from "../../components/FormDataGrid/FormDataGrid";
import { DefaultColumns } from "./columnDefinitions";
import { formatFilterValue } from "./recordFilter";
import { defaultColumnTypes } from "./columnTypes";
import { TrademarkOppositionsSearchFields } from "./TrademarkOppositionSearchFields";
import { useStore } from "../../storage/StoreProvider";
import { fetchTrademarkOppositionData } from "../../services/trademarkOppositionService";
import { FilterOperandType, FilterValue } from "../../models/FilterModel";
import { useParams } from "react-router-dom";
import { useMemo } from "react";

export interface TrademarkOppositionsGridParams {
    id?: string;
}

export default function TrademarkOppositionsGrid() {
    const [state, ,] = useStore();
    const { id } = useParams<TrademarkOppositionsGridParams>();

    function rowIdSelector(rows: any) {
        return rows.map((tOpposition) => {
            return tOpposition.number;
        });
    }

    function rowFilterBuilder(value: any) {
        return {
            Field: "number",
            Operator: FilterOperandType.Equal,
            Value1: value?.number ?? value,
            Value2: null,
        } as FilterValue;
    }

    const initialFilter = useMemo(() => {
        return [rowFilterBuilder(id)];
    }, [id]);

    const initialValues = useMemo(() => {
        return id ? { Number: id } : null;
    }, [id]);

    return (
        <FormDataGrid
            defaultColumns={DefaultColumns()}
            searchFields={TrademarkOppositionsSearchFields(initialValues)}
            onFetchData={fetchTrademarkOppositionData}
            filterValueFormatter={formatFilterValue}
            customColumnTypes={defaultColumnTypes(
                state.trademarkOppositionEnums
            )}
            loadError={state.error}
            rows={state.trademarkOppositions ?? []}
            rowIdSelector={rowIdSelector}
            initialFilter={id ? initialFilter : null}
            hidePreciseSearch
        />
    );
}
