import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
} from "@material-ui/data-grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { GridToolbarDensitySelector } from "./GridToolbarDensitySelector";

// Theme-dependent styles.
// createStyles is a helper function for TS compile.
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: "10px",
        },
    })
);

export default function CustomGridToolbar() {
    const classes = useStyles();
    return (
        <GridToolbarContainer className={classes.root}>
            <GridToolbarColumnsButton />
            <GridToolbarDensitySelector />
        </GridToolbarContainer>
    );
}
