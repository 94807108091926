import { GridColDef, GridColumnTypesRecord, GridValueFormatterParams } from "@material-ui/data-grid";
import { GetShortDateString } from "../../global/utils";

export const defaultColumnTypes = (enums?: any): GridColumnTypesRecord => {
    return {
        "dateType": {
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    return GetShortDateString(params.value as string);
                }
                return undefined;
            }
        } as GridColDef,
        "enumType": {
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value && enums) {
                    return enums && enums[params.field] ? enums[params.field][params.value as string] : params.value;
                }
                return undefined;
            }
        } as GridColDef,
        "statusType": {
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value && enums) {
                    return Object.keys(enums).length && enums['status'] !== undefined ? enums['status'][params.value.toString()] : params.value;
                }
                return undefined;
            }
        } as GridColDef,
        "array": {
            valueFormatter: (params: GridValueFormatterParams) => {
                if (Array.isArray(params.value)) {
                    return params.value.join(', ');
                }
                return undefined;
            }
        } as GridColDef,
        "nicaType": {
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    return (params.value as Array<number>).join(', ');
                }
                return undefined;
            }
        } as GridColDef,
        "viennaType": {
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    return params.value ? (params.value as string[]).join(', ') : "";
                }
                return undefined;
            }
        } as GridColDef,
    }
}