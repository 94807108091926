import FormDataGrid from "../../components/FormDataGrid/FormDataGrid";
import { DefaultColumns } from "./columnDefinitions";
import { defaultColumnTypes } from "./columnTypes";
import { formatFilterValue } from "./recordFilter";
import { SearchFields } from "./RefusedTrademarksSearchFields";
import { useStore } from "../../storage/StoreProvider";
import { fetchRefusedTrademarkData } from "../../services/refusedTrademarkService";
import { FilterOperandType, FilterValue } from "../../models/FilterModel";
import { useParams } from "react-router-dom";
import { useMemo } from "react";

export interface RefusedTrademarksGridParams {
    id?: string;
}

export default function RefusedTrademarksGrid() {
    // API calls / data retrieval.
    const [state, ,] = useStore();
    const { id } = useParams<RefusedTrademarksGridParams>();

    function rowIdSelector(rows: any) {
        return rows.map((patent) => {
            return patent.number;
        });
    }

    function rowFilterBuilder(value: any) {
        return {
            Field: "number",
            Operator: FilterOperandType.Equal,
            Value1: value?.number ?? value,
            Value2: null,
        } as FilterValue;
    }

    const initialFilter = useMemo(() => {
        return [rowFilterBuilder(id)];
    }, [id]);

    const initialValues = useMemo(() => {
        return id ? { Number: id } : null;
    }, [id]);

    return (
        <FormDataGrid
            defaultColumns={DefaultColumns()}
            searchFields={SearchFields(initialValues)}
            onFetchData={fetchRefusedTrademarkData}
            filterValueFormatter={formatFilterValue}
            customColumnTypes={defaultColumnTypes()}
            loadError={state.error}
            rows={state.refusedTrademarks ?? []}
            rowIdSelector={rowIdSelector}
            initialFilter={id ? initialFilter : null}
            hidePreciseSearch
        />
    );
}
