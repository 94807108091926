export enum TrademarkStatus {
    NO_STATUS,
    APPLICATION_RECEIVED,
    APPLICATION_DEEMED_AS_NOT_FILLED,
    APPLICATION_REJECTED,
    APPLICATION_ACCEPTED,
    REGISTRATION_ALLOWED,
    REGISTRATION_REFUSED,
    APPEAL_FILLED_FOR_NOT_FILLED_APPLICATION,
    APPLICATION_WITHDRAWN,
    REGISTERED,
    OPPOSITION_FILLED,
    REGISTRATION_RENEWED,
    SURRENDERED,
    INVALIDATED,
    REVOKED,
    DELETED_FROM_THE_REGISTER_DUE_TO_EXPIRY,
    REPLACEMENT_BY_INTERNATIONAL_REGISTRATION,
    REGISTRATION_DIVIDED,
    APPEAL_FILLED_FOR_REJECTED_APPLICATION,
    APPEAL_FILLED,
    APPLICATION_DIVIDED,
    REGISTRATION_EXPIRED,
    REQUEST_FOR_THE_CONTINUED_PROCESSING_FILLED,
    APPEAL_FILLED_FOR_ACCEPTED_APPLICATION,
    REQUEST_FOR_REINSTATEMENT_OF_RIGHTS,
    REQUEST_FOR_INVALIDATION_FILLED,
    REQUEST_FOR_REVOCATION_FILLED,
    REGISTRATION_PROCESS_SUSPENDED
}

export const getActiveStep = (statusId: number) => {
    switch (statusId) {
        case TrademarkStatus.APPLICATION_RECEIVED:
            return 1;
        case TrademarkStatus.APPLICATION_DEEMED_AS_NOT_FILLED:
        case TrademarkStatus.APPLICATION_REJECTED:
        case TrademarkStatus.APPLICATION_ACCEPTED:
        case TrademarkStatus.APPLICATION_WITHDRAWN:
        case TrademarkStatus.REQUEST_FOR_THE_CONTINUED_PROCESSING_FILLED:
        case TrademarkStatus.REGISTRATION_PROCESS_SUSPENDED:
            return 2;
        case TrademarkStatus.REGISTRATION_ALLOWED:
        case TrademarkStatus.REGISTRATION_REFUSED:
        case TrademarkStatus.APPEAL_FILLED_FOR_REJECTED_APPLICATION:
        case TrademarkStatus.APPEAL_FILLED_FOR_NOT_FILLED_APPLICATION:
        case TrademarkStatus.APPEAL_FILLED_FOR_ACCEPTED_APPLICATION:
            return 3;
        case TrademarkStatus.REGISTERED:
        case TrademarkStatus.APPEAL_FILLED:
            return 4;
        case TrademarkStatus.REGISTRATION_EXPIRED:
        case TrademarkStatus.DELETED_FROM_THE_REGISTER_DUE_TO_EXPIRY:
        case TrademarkStatus.SURRENDERED:
        case TrademarkStatus.OPPOSITION_FILLED:
        case TrademarkStatus.REQUEST_FOR_INVALIDATION_FILLED:
        case TrademarkStatus.REQUEST_FOR_REVOCATION_FILLED:
            return 5;
        case TrademarkStatus.INVALIDATED:
        case TrademarkStatus.REVOKED:
            return 6;
        default:
            return -1; // No status
    }
}