import { useLocation } from 'react-router-dom';
import { parse } from 'qs';
import { useStore } from '../../storage/StoreProvider';
import { DefaultRows } from './detailedFormRowDefinitions';
import { fetchDesignOppositionData } from "../../services/designOppositionService";
import React from 'react';
import GridBasePrintForm from '../../components/FormDataGrid/GridBasePrintForm';
import { DefaultFetchDataProps } from '../../services/baseService';

export default function DesignOppositionsPrintForm({ match }) {
    const [state] = useStore();

    const rowSets = DefaultRows(state.designOppositions, true);

    const dataResolver = async (props: DefaultFetchDataProps) => {
        await fetchDesignOppositionData({
            page: props.page,
            rowsPerPage: props.filter?.length,
            sortModel: props.sortModel,
            filter: props.filter,
            action: props.action,
        });
    };

    return (
        <GridBasePrintForm
            filterBy="number"
            rowsPerPage={rowSets.length}
            dataResolver={dataResolver}
            rowSets={rowSets}
            match={match}
            isDataResolved={state.designOppositions.length === 0}
        />
    );
}