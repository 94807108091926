import React from "react";
import { createStyles, makeStyles, Theme, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
    label: {
        textAlign: "center",
        fontSize: "0.875rem",
        fontWeight: "bold",
    }
}));

export interface GridRowContent {
    label: string;
    value: string;
}

export interface GridCellContentProps {
    rows: GridRowContent[];
}

export default function GridCellContent(props: GridCellContentProps) {
    const classes = useStyles();
    const { rows } = props;

    return (
        <React.Fragment>
            {rows.map((row, index) => (
                <Grid item container xs={12} md={12} key={index}>
                    <Grid item xs={1} md={1} className={classes.label}>{row.label}</Grid>
                    <Grid item xs={9} md={9}>{row.value}</Grid>
                </Grid>
            ))}
        </React.Fragment>
    );
}
