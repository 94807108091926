import FieldWithTooltip from "../../components/FieldWithTooltip";
import { SearchField } from "../../components/FormDataGrid/SearchForm";
import { Collection } from "../../models/DefaultResponseModel";
import SearchType from "../../models/grid/SearchType";
import { GridStorage } from "../../storage/browserStorage";

export const DesignsSearchFields = (
    searchMeta: Collection[],
    enums?: any
): SearchField[] => {
    const storage = GridStorage.getInstance();
    const filterModel = storage.getFilterModel();

    return searchMeta.map((fieldMeta) => {
        const field = fieldMeta.entity;
        const fieldName = field.name.replace(".", "%");
        return {
            size: 11,
            field: (
                <FieldWithTooltip
                    name={fieldName}
                    label={field.label}
                    tooltipText={field.tooltip}
                    multiple
                    type={
                        (field.type as SearchType) === SearchType.date
                            ? SearchType.dateRange
                            : (field.type as SearchType)
                    }
                    preciseSearch={[
                        "RegistrationNumber",
                        "ApplicationNumber",
                        "LocarnoClasses%LocarnoClass",
                    ].includes(fieldName)}
                    options={
                        enums && enums[field.enum]
                            ? Object.keys(enums[field.enum]).map((key) => ({
                                  label: enums[field.enum][key],
                                  value: key,
                              }))
                            : []
                    }
                    defaultValue={filterModel && filterModel[fieldName]}
                />
            ),
            label: field.label,
            name: field.name,
            showOnExtendedSearch: !field.show,
            defaultStateOnExtendedSearch: field.defaultStateOnExtendedSearch,
            classificationUrl: field.classificationUrl,
        };
    });
};
