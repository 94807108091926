import RobustaTLProRegularWoff2 from './356EBC_27_0.woff2';
import RobustaTLProRegularWoff from './356EBC_27_0.woff';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const robustaTLProRegularFont: CSSProperties = {
    fontFamily: 'RobustaTLPro-Regular',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
    url(${RobustaTLProRegularWoff2}) format('woff2'),
    url(${RobustaTLProRegularWoff}) format('woff')
  `,
};

export default robustaTLProRegularFont;