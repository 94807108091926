import { DetailedFormTableRow } from "../../components/FormDataGrid/DetailedForm/DetailedFormTable/DetailedFormTable";
import { useStore } from "../../storage/StoreProvider";
import { useTranslation } from "react-i18next";

export function DefaultRows(refusedTrademarks: any, printView?: boolean) {
    const [state, ,] = useStore();
    const { t } = useTranslation();

    return refusedTrademarks.map((refusedTrademark) => {
        if (refusedTrademark === {}) return [];
        return [
            {
                caption: " ",
                name: t("refusedTrademarkForm:title"),
                value: refusedTrademark.title,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("refusedTrademarkForm:number"),
                value: refusedTrademark.number,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("refusedTrademarkForm:notes"),
                value: refusedTrademark.notes,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("refusedTrademarkForm:nica"),
                value: refusedTrademark.nica?.length
                    ? refusedTrademark.nica.join(";")
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("refusedTrademarkForm:vienna"),
                value: refusedTrademark.vienna?.join(" "),
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("refusedTrademarkForm:substantiations"),
                value: refusedTrademark.substantiations?.join(", "),
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("refusedTrademarkForm:decision"),
                value: refusedTrademark.decision,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("refusedTrademarkForm:firstCourtDecision"),
                value: refusedTrademark.firstCourtDecision,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("refusedTrademarkForm:secondCourtDecision"),
                value: refusedTrademark.secondCourtDecision,
                type: "string",
            } as DetailedFormTableRow,
        ];
    });
}
