import { DetailedFormTableRow } from "../../components/FormDataGrid/DetailedForm/DetailedFormTable/DetailedFormTable";
import {
    formatEnumValue,
    formatResourcePersonObjectType,
} from "../../components/FormDataGrid/formDataUtils";
import { useStore } from "../../storage/StoreProvider";
import { useTranslation } from "react-i18next";
import { TableCellValue } from "../../components/FormDataGrid/DetailedForm/DetailedFormTable/DetailedFormTableCell/CellContent/TableCellContent";

export function DefaultRows(oppositions: any, printView?: boolean) {
    const [state, ,] = useStore();
    const oppositionEnums = state.trademarkOppositionEnums;
    const { t } = useTranslation();
    return oppositions.map((opposition) => {
        return [
            {
                caption: " ",
                name: t("trademarkOppositionForm:title"),
                value: opposition.title,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkOppositionForm:number"),
                value: opposition.number,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkOppositionForm:owner"),
                value: opposition.persons?.length
                    ? formatResourcePersonObjectType(opposition.persons, [1])
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkOppositionForm:applicant"),
                value: opposition.persons?.length
                    ? formatResourcePersonObjectType(opposition.persons, [2])
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkOppositionForm:result"),
                value:
                    oppositionEnums && opposition.result !== undefined
                        ? formatEnumValue(
                              opposition.result,
                              "result",
                              oppositionEnums
                          )
                        : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkOppositionForm:groundsOfDecision"),
                value: opposition.substantiations?.join(" "),
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkOppositionForm:decision"),
                value: opposition.decision,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkOppositionForm:nica"),
                value: opposition.nica?.length
                    ? opposition.nica.join(",")
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkOppositionForm:firstCourtDecision"),
                value: opposition.firstCourtDecision,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkOppositionForm:secondCourtDecision"),
                value: opposition.secondCourtDecision,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkOppositionForm:oppossiteObjects"),
                value: opposition.oppossiteObjects?.length
                    ? {
                          headers: [
                              t("trademarkOppositionForm:numberHeader"),
                              t("trademarkOppositionForm:titleHeader"),
                          ],
                          values: opposition.oppossiteObjects.map((item) => [
                              {
                                  text: item.number,
                              } as TableCellValue,
                              {
                                  text: item.title,
                              } as TableCellValue,
                          ]),
                      }
                    : undefined,
                type: "table",
            } as DetailedFormTableRow,
        ];
    });
}
