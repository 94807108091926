import {
    Box,
    Container,
    createStyles,
    makeStyles,
    Paper,
    Theme,
    Typography,
    Link,
    ListItemText,
    ListItem,
    ListItemIcon,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

// Theme-dependent styles.
// createStyles is a helper function for TS compile.
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            textAlign: "center",
            width: "100%",
        },
        footer: {
            position: "static",
            padding: theme.spacing(2, 0, 0, 0),
        },
        version: {
            padding: theme.spacing(0.5, 0.5, 0.5, 0),
        },
    })
);

export default function Footer() {
    // Applies theme to the style classes by default.
    const classes = useStyles();

    const { t } = useTranslation();

    const version = (window as any).version;

    const copyright = () => {
        return (
            <Paper variant="outlined" square={true} className={classes.footer}>
                <Container>
                    <Box>
                        <Typography variant="caption">
                            © {new Date().getFullYear()} Patentu valde, Visas
                            tiesības aizsargātas.
                        </Typography>
                        <Typography className={classes.root}>
                            <Link
                                href={
                                    "https://www.lrpv.gov.lv/" +
                                    t("common:lang") +
                                    t("common:privLink")
                                }
                                target="_blank"
                                color="inherit"
                                variant="body2"
                            >
                                {t("common:priv")}
                            </Link>
                        </Typography>
                    </Box>
                </Container>
                <Box display="flex" justifyContent="flex-end">
                    <Typography variant="caption" className={classes.version}>
                        {version}
                    </Typography>
                </Box>
            </Paper>
        );
    };

    return <footer className={classes.root}>{copyright()}</footer>;
}
