import axios, { AxiosError, AxiosResponse } from "axios";
import { ACTIONS } from "../storage/dataReducer";
import DefaultRequestModel from "../models/DefaultRequestModel";
import DefaultResponseModel from "../models/DefaultResponseModel";
import EnumsResponseModel from "../models/responses/EnumsResponseModel";
import * as Base from "./baseService";
import { Store } from "../storage/StoreProvider";
import RefPatentsResponseModel from "../models/responses/RefPatentsResponseModel";
import FieldInfoResponseModel from "../models/responses/FieldInfoResponseModel";
import Resource from "../models/grid/Resource";
import i18n from "../i18n/config";
import { FilterValue } from "../models/FilterModel";

export interface PatentDataFetchProps extends Base.DefaultFetchDataProps {
    includeDocuments?: boolean;
    includeEvents?: boolean;
    includeReferencedPatents?: boolean;
    includeFieldInfo?: boolean;
}

export async function fetchPatentData(
    props: PatentDataFetchProps
): Promise<any> {
    const {
        includeDocuments,
        includeEvents,
        includeReferencedPatents,
        includeFieldInfo,
    } = props;
    const resource = Resource.Patent;
    const endpoint =
        (await Base.getBackendDefinedAPI(resource)) ??
        Base.getFrontendDefinedAPI(resource);
    return new Promise(async (resolve) => {
        Store.dispatch({ type: ACTIONS.GET_ROWS });
        // Check if we should return promise immediately
        // It is used for data retrieval without filter validation
        if (props.action === ACTIONS.GET_ROWS) resolve({});

        await axios
            .get<DefaultRequestModel, AxiosResponse<DefaultResponseModel>>(
                endpoint,
                {
                    params: Base.buildGridDataRequest(
                        props as Base.DefaultProps
                    ),
                    headers: Base.defaultRequestHeaders(),
                }
            )
            .then(async (response) => {
                const data = await Base.buildGridData(response.data);
                // Fetch enums after getting urls to other resources
                const gridResources = [Resource.Enums];
                if (includeFieldInfo) gridResources.push(Resource.FieldInfo);

                gridResources.forEach(async (res) => {
                    const resKey = Resource[res];
                    switch (res) {
                        case Resource.Enums:
                            fetchPatentEnums(
                                data.urls && resKey in data.urls
                                    ? data.urls[resKey]
                                    : null
                            );
                            break;
                        case Resource.FieldInfo:
                            fetchPatentFieldInfo(
                                data.urls && resKey in data.urls
                                    ? data.urls[resKey]
                                    : null
                            );
                            break;
                        default:
                            console.log(
                                `Resource ${res} fetch function is not defined.`
                            );
                            break;
                    }
                });

                const rows = await Promise.all(
                    data.rows.map(async (row) => {
                        // All grid data is placed under dataKey for easier data extraction
                        // Following actions are to fill the needs of the grid component
                        const dataKey = Base.getDataKey(Resource.Patent);
                        const rowData = row[dataKey];
                        if (rowData) {
                            // Gets additional data
                            const rowResources = [
                                Resource.Persons,
                                Resource.PAS,
                            ];
                            if (includeDocuments)
                                rowResources.push(Resource.Documents);
                            if (includeEvents)
                                rowResources.push(Resource.Events);
                            if (includeReferencedPatents)
                                rowResources.push(Resource.Referenced);

                            const resData = await Base.getResourcesAsync(
                                rowResources,
                                row.urls
                            );

                            // Builds grid row
                            let patentRow = { ...row };
                            // Sets additional data
                            patentRow[dataKey] = {
                                ...rowData,
                                persons: resData[Resource.Persons]?.collection,
                                pas: resData[Resource.PAS]?.collection,
                                ipc: row.ipc,
                                licencees: row.licencees,
                                urls: row.urls,
                                documents:
                                    resData[Resource.Documents]?.collection,
                                events: resData[Resource.Events]?.collection,
                                refPatents:
                                    resData[Resource.Referenced]?.collection,
                            };
                            return patentRow;
                        }
                        return row;
                    })
                );

                // PPS:
                // Tikai tad, kad neviens patents nav atrasts, tiek analizēts  ievadītais numurs:  
                // Ja ievadītās vērtības formāts ir [NNNNNNN], kur N - jebkuri cipari no 0 līdz 9, 
                // tad EPO Patentu reģistrā tiks veikta meklēšana pēc Patenta numura.  
                // Ja ievadītās vērtības formāts ir [NNNNNNNN.N], kur N - jebkuri cipari no 0 līdz 9,
                // tad EPO Patentu reģistrā tiks veikta meklēšana pēc Patenta pieteikuma numura. 

                let patentNumber : string | undefined;
                let patentApplicationNumber : string | undefined;

                if (rows.length === 0) {
                    const numberFilter: FilterValue | undefined = props.filter.find(x => x.Field === 'Number');
                    if (numberFilter) {
                        if (/^\d{7}$/.test(numberFilter.Value1)) {
                            patentNumber = numberFilter.Value1;
                        }                                      
                        else if (/^\d{8}\.\d$/.test(numberFilter.Value1))
                        {
                            patentApplicationNumber = numberFilter.Value1;
                        }
                    }
                }

                const payload = {
                    ...data,
                    patentNumber,
                    patentApplicationNumber,
                    rows: rows,
                };

                Store.dispatch({
                    type: ACTIONS.PATENT_ROWS,
                    payload: payload,
                });
                resolve(payload);
            })
            .catch((e: AxiosError) => {
                const gridError = Base.buildGridDataError(
                    e,
                    i18n.t(['patentForm:failedToLoad'])
                );
                Store.dispatch(gridError);
                resolve(gridError.payload);
            });
    });
}

export async function fetchPatentEnums(url?: string) {
    Store.dispatch({ type: ACTIONS.GET_PATENT_ENUMS });
    try {
        const enumResponse = await Base.getResourceData<EnumsResponseModel>(
            Resource.Enums,
            url
        );
        Store.dispatch({
            type: ACTIONS.PATENT_ENUMS,
            payload: {
                enums: enumResponse.enums,
            },
        });
    } catch (e) {
        Store.dispatch({
            type: ACTIONS.ERROR_PATENT_ENUMS,
            payload: {
                error:
                    Base.formatErrorResponse(e) ??
                    // TODO: Localize this
                    "Nesanāca ielādēt patentu enum vērtības.",
            },
        });
    }
}

export async function fetchPatentFieldInfo(url?: string) {
    Store.dispatch({ type: ACTIONS.GET_PATENT_FIELD_INFO });
    try {
        const fieldResponse =
            await Base.getResourceData<FieldInfoResponseModel>(
                Resource.FieldInfo,
                url
            );
        Store.dispatch({
            type: ACTIONS.PATENT_FIELD_INFO,
            payload: {
                fields: fieldResponse.fields,
            },
        });
    } catch (e) {
        Store.dispatch({
            type: ACTIONS.ERROR_PATENT_FIELD_INFO,
            payload: {
                error:
                    Base.formatErrorResponse(e) ??
                    "Nesanāca ielādēt preču zīmju field info vērtības.",
            },
        });
    }
}

export async function getPatentDocuments(url: string) {
    try {
        const documents = await Base.getResourceData<DefaultResponseModel>(
            Resource.Documents,
            url
        );
        return documents?.collection;
    } catch (e) {
        console.error(e);
        return null;
    }
}

export async function getPatentEvents(url: string) {
    try {
        const events = await Base.getResourceData<DefaultResponseModel>(
            Resource.Events,
            url
        );
        return events?.collection;
    } catch (e) {
        console.error(e);
        return null;
    }
}

export async function getReferencedPatents(url: string) {
    try {
        const refPatents = await Base.getResourceData<RefPatentsResponseModel>(
            Resource.Referenced,
            url
        );
        return refPatents;
    } catch (e) {
        console.error(e);
        return null;
    }
}
