import { IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { getParentPath } from './FormDataGrid/formDataUtils';

const LinkButton = props => (
    <IconButton component={Link} aria-label="back" {...props}>
        <ArrowBack />
    </IconButton>
);

const BackButton = ({ match, destination }) => {
    const parentPath = getParentPath(match.path, destination);
    return (
        <LinkButton to={parentPath}>
            {match.path === '/'
                ? `<-- ${destination.charAt(0).toUpperCase() + destination.slice(1)}`
                : '<-- Back'}
        </LinkButton>
    );
};

export default BackButton;