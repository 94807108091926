import {
    Grid,
    Hidden,
    Container,
    createStyles,
    makeStyles,
    Theme,
    Box,
} from "@material-ui/core";
import Header from "../Header";
import AppMenu from "../AppMenu";
import Footer from "../Footer";
import ScrollToTop from "../ScrollToTop";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexGrow: 1,
            padding: theme.spacing(0, 0, 0, 3),
            [theme.breakpoints.down("sm")]: {
                padding: 0,
            },
        },
        container: {
            display: "flex",
            alignItems: "flex-start",
            padding: theme.spacing(3, 0, 3, 0),
            flexGrow: 1,
        },
        offsetBackground: {
            backgroundColor: theme.palette.accent.light,
            height: "170px",
            position: "absolute",
            width: "100%",
            zIndex: -1,
        },
    })
);

const ActionBarTemplate = (props: React.PropsWithChildren<{}>) => {
    const { children } = props;
    const classes = useStyles();

    return (
        <Box display="flex" flexDirection="column" flexGrow={1} minHeight="100vh">
            <Header />
            <div className={classes.offsetBackground} />
            <ScrollToTop />
            <Container className={classes.container}>
                <Hidden only={["xs", "sm"]}>
                    <AppMenu />
                </Hidden>
                <Box className={classes.root}>{children}</Box>
            </Container>
            <Footer />
        </Box>
    );
};

export default ActionBarTemplate;
