// Customized from https://github.com/lookfirst/mui-rff/blob/master/src/Autocomplete.tsx.
// The whole Autocomplete class is just a workaround to this issue:
// - https://github.com/issues?q=is%3Aissue+author%3Asamantagavare+archived%3Afalse+is%3Aclosed
// TODO: Need to remove this class in the future, update the mui-rff package
// and think on  how to pass null value to the onChange function.
import TextField, {
    TextFieldProps as MuiTextFieldProps,
} from "@material-ui/core/TextField";
import {
    AutocompleteChangeDetails,
    AutocompleteChangeReason,
    AutocompleteProps as MuiAutocompleteProps,
    default as MuiAutocomplete,
} from "@material-ui/lab/Autocomplete";
import {
    AutocompleteInputChangeReason,
    UseAutocompleteProps as MuiUseAutocompleteProps,
    Value,
} from "@material-ui/lab/useAutocomplete";
import React, { ReactNode } from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import { ShowErrorFunc, showErrorOnChange } from "mui-rff";

export type AutocompleteData = {
    [key: string]: any | null;
};

export interface AutocompleteProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
> extends Omit<
        MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> &
            MuiUseAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
        "renderInput"
    > {
    name: string;
    label: ReactNode;
    helperText?: string;
    required?: boolean;
    getOptionValue?: (option: T) => any;
    options: T[];
    fieldProps?: Partial<FieldProps<any, any>>;
    textFieldProps?: Partial<MuiTextFieldProps>;
    showError?: ShowErrorFunc;
}

export function Autocomplete<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
>(
    props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
): JSX.Element {
    const { name, fieldProps, ...rest } = props;

    return (
        <Field
            name={name}
            defaultValue={rest.defaultValue}
            render={(fieldRenderProps) => (
                <AutocompleteWrapper
                    {...fieldRenderProps}
                    {...rest}
                    value={fieldRenderProps.input.value}
                />
            )}
            {...fieldProps}
        />
    );
}

interface AutocompleteWrapperProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
    label: ReactNode;
    required?: boolean;
    textFieldProps?: Partial<MuiTextFieldProps>;
    getOptionValue?: (option: T) => any;
}

function AutocompleteWrapper<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
>(
    props: AutocompleteWrapperProps<T, Multiple, DisableClearable, FreeSolo> &
        FieldRenderProps<MuiTextFieldProps, HTMLElement>
): JSX.Element {
    const {
        input: { name, onChange, value },
        meta,
        options,
        label,
        required,
        multiple,
        textFieldProps,
        getOptionValue,
        showError = showErrorOnChange,
        placeholder,
        onChange: onChangeCallback,
        onInputChange: onInputChangeCallback,
        ...rest
    } = props;

    function getValue(values: any) {
        if (!getOptionValue) {
            return values;
        }

        // ternary hell...
        return multiple
            ? values
                ? values.map(getOptionValue)
                : null
            : values
            ? getOptionValue(values)
            : null;
    }

    const { helperText, ...lessrest } = rest;
    const { variant, ...restTextFieldProps } = textFieldProps || {};

    // yuck...
    let defaultValue: Value<T, Multiple, DisableClearable, FreeSolo> = multiple
        ? ([] as any)
        : null;

    if (!getOptionValue) {
        defaultValue = value as Value<T, Multiple, DisableClearable, FreeSolo>;
    } else if (value) {
        options.forEach((option) => {
            const optionValue = getOptionValue(option);
            if (multiple) {
                (value as any).forEach((v: any) => {
                    if (v === optionValue) {
                        (defaultValue as any).push(option);
                    }
                });
            } else {
                if (value === optionValue) {
                    defaultValue = option as any;
                }
            }
        });
    }

    const onChangeFunc = (
        // eslint-disable-next-line @typescript-eslint/ban-types
        event: React.ChangeEvent<{}>,
        value: Value<T, Multiple, DisableClearable, FreeSolo>,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<any>
    ) => {
        const gotValue = getValue(value);
        if (gotValue === "") return;
        if (
            gotValue.length > 0 &&
            (gotValue as string[]).some((v) => v.trim() === "")
        )
            return;
        onChange(gotValue);

        if (onChangeCallback) {
            onChangeCallback(event, value, reason, details);
        }
    };

    const onInputChangeFunc = (
        // eslint-disable-next-line @typescript-eslint/ban-types
        event: React.ChangeEvent<{}>,
        value: string,
        reason: AutocompleteInputChangeReason
    ) => {
        if (onInputChangeCallback) {
            onInputChangeCallback(event, value, reason);
        }

        if (!value) onChangeFunc(event, getValue(value), null); // TODO: Need to improve this solution.
    };

    const { error, submitError } = meta;
    const isError = showError({ meta });

    return (
        <MuiAutocomplete
            multiple={multiple}
            onChange={onChangeFunc}
            onInputChange={onInputChangeFunc}
            disableClearable
            forcePopupIcon={false}
            options={options}
            value={defaultValue}
            renderInput={(params) => (
                <TextField
                    label={label}
                    required={required}
                    helperText={isError ? error || submitError : helperText}
                    error={isError}
                    name={name}
                    placeholder={placeholder}
                    variant={variant}
                    {...params}
                    {...restTextFieldProps}
                    InputProps={{
                        ...params.InputProps,
                        ...restTextFieldProps.InputProps,
                        endAdornment: (
                            <>
                                {restTextFieldProps.InputProps.endAdornment}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                    value={value}
                    fullWidth={true}
                />
            )}
            {...lessrest}
        />
    );
}
