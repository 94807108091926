import React from "react";
import {
    createStyles,
    Theme,
    makeStyles,
    OutlinedInputProps,
    InputLabel,
    Grid,
    useMediaQuery,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import lvLocale from "date-fns/locale/lv";
import enLocale from "date-fns/locale/en-US";
import add from "date-fns/add";
import { DatePickerToolbar } from "../DatePicker/DatePickerToolbar";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import moment from "moment";

interface DateRangePickerBaseProps {
    startDate: Date | null;
    endDate: Date | null;
    StartDateInputProps?: Partial<OutlinedInputProps>;
    EndDateInputProps?: Partial<OutlinedInputProps>;
    label?: string;
    name?: string;
    className?: string;
    startDateLimit?: number;
    onChange?: (value: any) => void;
}

const localeMap = {
    "en-US": enLocale,
    "lv-LV": lvLocale,
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        startDateField: {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
        endDateField: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        },
        label: {
            padding: theme.spacing(0, 0, 1, 0),
            color: theme.palette.secondary.contrastText,
        },
        dateInput: {
            minWidth: 190,
        },
    })
);

if (localeMap[i18next.language] && localeMap[i18next.language].options) {
    localeMap[i18next.language].options.weekStartsOn = 1;
}

export default function DateRangePickerBase(props: DateRangePickerBaseProps) {
    const classes = useStyles();
    const useMobileView = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down(1450)
    );
    const { t } = useTranslation();
    const startDateLimit = props?.startDateLimit ? props.startDateLimit : 0;

    // TODO: Optimize the code.
    const handleStartDateChange = (startDate?: Date) => {
        if (!startDate) {
            props.onChange(
                props.endDate ? { endDate: props.endDate } : undefined
            );
            return;
        }

        const newFilterValue = {
            startDate: startDate,
            endDate: props.endDate,
        };

        props.onChange(newFilterValue);
    };

    const handleEndDateChange = (endDate?: Date) => {
        if (!endDate) {
            props.onChange(
                props.startDate ? { startDate: props.startDate } : undefined
            );
            return;
        }

        const newFilterValue = {
            startDate: props.startDate,
            endDate: endDate,
        };
        props.onChange(newFilterValue);
    };

    const getMaxYear = (date: Date, startDateLimit: any) => {
        if (startDateLimit) {
            return add(date, {
                years: startDateLimit,
            });
        }

        return add(date, {
            years: 1,
        });
    };

    const startDatePicker = () => {
        return (
            <KeyboardDatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label={t("datePicker:dateFrom")}
                format="dd/MM/yyyy"
                placeholder="dd/mm/yyyy"
                InputProps={{
                    ...props.StartDateInputProps,
                    classes: {
                        notchedOutline: useMobileView
                            ? ""
                            : classes.startDateField,
                    },
                }}
                value={props.startDate}
                InputAdornmentProps={{ position: "start" }}
                KeyboardButtonProps={{ size: "small" }}
                onChange={(date) => {
                    if (!date) {
                        handleStartDateChange(null);
                    }
                }}
                onAccept={(date) => {
                    handleStartDateChange(date);
                }}
                onBlur={(e) => {
                    handleStartDateChange(
                        e.target.value
                            ? moment(e.target.value, "DD/MM/YYYY").toDate()
                            : null
                    );
                }}
                margin="dense"
                size="small"
                fullWidth
                invalidDateMessage={t("datePicker:invalidDateMessage")}
                maxDate={
                    props.endDate ?? getMaxYear(new Date(), startDateLimit)
                }
                ToolbarComponent={(props) => DatePickerToolbar(props)}
                views={["year", "month", "date"]}
                maxDateMessage={t("datePicker:maxDateMessage2", {
                    date: format(
                        getMaxYear(new Date(), startDateLimit),
                        "dd.MM.yyyy"
                    ),
                })}
                minDateMessage={t("datePicker:minDate", {
                    date: format(
                        getMaxYear(new Date(), startDateLimit),
                        "dd.MM.yyyy"
                    ),
                })}
            />
        );
    };

    const endDatePicker = () => {
        return (
            <KeyboardDatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label={t("datePicker:dateTo")}
                format="dd/MM/yyyy"
                placeholder="dd/mm/yyyy"
                InputProps={{
                    ...props.EndDateInputProps,
                    classes: {
                        notchedOutline: useMobileView
                            ? ""
                            : classes.endDateField,
                    },
                }}
                className={props.className}
                value={props.endDate}
                InputAdornmentProps={{ position: "start" }}
                KeyboardButtonProps={{ size: "small" }}
                onChange={(date) => {
                    if (!date) {
                        handleEndDateChange(null);
                    }
                }}
                onAccept={(date) => {
                    handleEndDateChange(date);
                }}
                onBlur={(e) => {
                    console.log("date:", e.target.value);

                    handleEndDateChange(
                        e.target.value
                            ? moment(e.target.value, "DD/MM/YYYY").toDate()
                            : null
                    );
                }}
                margin="dense"
                size="small"
                fullWidth
                invalidDateMessage={t("datePicker:invalidDateMessage")}
                maxDate={getMaxYear(new Date(), startDateLimit)}
                minDate={props.startDate ?? undefined}
                ToolbarComponent={(props) => DatePickerToolbar(props)}
                views={["year", "month", "date"]}
                minDateMessage={t("datePicker:minDateMessage", {
                    date: format(
                        getMaxYear(new Date(), startDateLimit),
                        "dd.MM.yyyy"
                    ).toString(),
                })}
                maxDateMessage={t("datePicker:maxDateMessage", {
                    date: format(
                        getMaxYear(new Date(), startDateLimit),
                        "dd.MM.yyyy"
                    ),
                })}
            />
        );
    };

    // Desktop view.
    const displayDesktop = () => {
        return (
            <React.Fragment>
                <InputLabel htmlFor={props.name} className={classes.label}>
                    {props.label}
                </InputLabel>
                <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={localeMap[i18next.language]}
                >
                    <Grid item container>
                        <Grid item md={6} className={classes.dateInput}>
                            {startDatePicker()}
                        </Grid>
                        <Grid item md={6} className={classes.dateInput}>
                            {endDatePicker()}
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
            </React.Fragment>
        );
    };

    // Mobile view.
    const displayMobile = () => {
        return (
            <React.Fragment>
                <InputLabel htmlFor={props.name} className={classes.label}>
                    {props.label}
                </InputLabel>
                <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={localeMap[i18next.language]}
                >
                    {startDatePicker()}
                    {endDatePicker()}
                </MuiPickersUtilsProvider>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {useMobileView ? displayMobile() : displayDesktop()}
        </React.Fragment>
    );
}
