import { useTranslation } from "react-i18next";
import { DetailedFormTableRow } from "../../components/FormDataGrid/DetailedForm/DetailedFormTable/DetailedFormTable";
import { TableCellValue } from "../../components/FormDataGrid/DetailedForm/DetailedFormTable/DetailedFormTableCell/CellContent/TableCellContent";
import {
    formatEnumValue,
    formatPersonObjectType,
    formatResourcePersonObjectType,
    formatSeniorityOrPriorityValue,
} from "../../components/FormDataGrid/formDataUtils";
import { GetShortDateString } from "../../global/utils";
import { useStore } from "../../storage/StoreProvider";
import qs from "qs";

export function DefaultRows(designs: any, printView?: boolean) {
    const [state, ,] = useStore();
    const designEnums = state.designEnums;
    const { t } = useTranslation();

    function GetSeniorityOrPriority(items: any) {
        if (!items.length) return undefined;
        return {
            headers: [
                t("common:number"),
                t("common:date"),
                t("common:country"),
            ],
            values: formatSeniorityOrPriorityValue(items),
        };
    }

    return designs.map((design) => {
        if (design === {}) return [];
        return [
            {
                caption: " ",
                name: t("designForm:status"),
                value:
                    designEnums && design.status !== undefined
                        ? formatEnumValue(design.status, "status", designEnums)
                        : undefined,
                field: "",
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: "",
                value: design.images?.length ? design.images : undefined,
                type: "images",
            } as DetailedFormTableRow,
            {
                caption: 11,
                name: t("designForm:registrationNumber"),
                value: design.registrationNumber,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 15,
                name: t("designForm:registrationDate"),
                value: design.registrationDate
                    ? GetShortDateString(design.registrationDate)
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 21,
                name: t("designForm:applicationNumber"),
                value: design.applicationNumber,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 22,
                name: t("designForm:applicationDate"),
                value: design.applicationDate
                    ? GetShortDateString(design.applicationDate)
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designForm:receptionDate"),
                value: design.receptionDate
                    ? GetShortDateString(design.receptionDate)
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 62,
                name: t("designForm:applicationDivision"),
                value: design.division?.applicationNumber
                    ? `${design.division.applicationNumber} ${
                          design.division.applicationDate
                              ? GetShortDateString(
                                    design.division.applicationDate
                                )
                              : ""
                      }`
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 62,
                name: t("designForm:registrationDivision"),
                value: design.division?.registrationNumber
                    ? `${design.division.registrationNumber} ${
                          design.division.registrationNumber
                              ? GetShortDateString(
                                    design.division?.registrationDate
                                )
                              : ""
                      }`
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 18,
                name: t("designForm:potentialExpirationDate"),
                value: design.potentialExpirationDate
                    ? GetShortDateString(design.potentialExpirationDate)
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            // {
            //     caption: " ",
            //     name: t("designForm:publicationDate"),
            //     value: design.publicationDate
            //         ? GetShortDateString(design.publicationDate)
            //         : undefined,
            //     type: "string",
            // } as DetailedFormTableRow,
            {
                caption: 51,
                name: t("designForm:locarno"),
                value: design.locarno?.join("; "),
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designForm:registrationEndDate"),
                value: design.registrationEndDate
                    ? GetShortDateString(design.registrationEndDate)
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designForm:oppositionPeriod"),
                value:
                    design.oppositionPeriodStart || design.oppositionPeriodEnd
                        ? {
                              headers: [
                                  t("designForm:oppositionPeriodStart"),
                                  t("designForm:oppositionPeriodEnd"),
                              ],
                              values: [
                                  [
                                      {
                                          text: design.oppositionPeriodStart
                                              ? GetShortDateString(
                                                    design.oppositionPeriodStart
                                                )
                                              : "",
                                      } as TableCellValue,
                                      {
                                          text: design.oppositionPeriodEnd
                                              ? GetShortDateString(
                                                    design.oppositionPeriodEnd
                                                )
                                              : "",
                                      } as TableCellValue,
                                  ],
                              ],
                          }
                        : undefined,
                type: "table",
            } as DetailedFormTableRow,
            {
                caption: 23,
                name: t("designForm:exhibitionPriority"),
                value: design.claims?.exhibitionPriority?.length
                    ? design.claims?.exhibitionPriority
                          .map(
                              (exp) =>
                                  `${exp.name} ${GetShortDateString(exp.date)}`
                          )
                          .join("\n")
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 39,
                name: t("designForm:seniority"),
                value: design.claims?.seniority?.length
                    ? // Registration type.
                      GetSeniorityOrPriority(
                          design.claims.seniority.filter((p) => p.type === 1)
                      )
                    : undefined,
                type: "table",
            } as DetailedFormTableRow,
            {
                caption: 30,
                name: t("designForm:priority"),
                value: design.claims?.priority?.length
                    ? GetSeniorityOrPriority(design.claims.priority)
                    : undefined,
                type: "table",
            } as DetailedFormTableRow,
            {
                caption: 60,
                name: t("designForm:seniority"),
                value: design.claims?.seniority?.length
                    ? GetSeniorityOrPriority(
                          design.claims.seniority.filter((p) => p.type === 0)
                      )
                    : undefined,
                type: "table",
            } as DetailedFormTableRow,
            {
                caption: 73,
                name: t("designForm:applicantOrOwner"),
                value: design.persons?.length
                    ? formatPersonObjectType(design.persons, [1])
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 74,
                name: t("designForm:representative"),
                value: design.persons?.length
                    ? formatPersonObjectType(design.persons, [2])
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 72,
                name: t("designForm:designer"),
                value: design.persons?.length
                    ? formatResourcePersonObjectType(design.persons, [3])
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 54,
                name: t("designForm:title"),
                value: design.title,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 28,
                name: t("designForm:objectCount"),
                value: design.objectCount,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designForm:licencees"),
                value: design.persons?.length
                    ? formatResourcePersonObjectType(design.persons, [4])
                    : undefined,
                field: "",
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designForm:distraints"),
                value:
                    designEnums && design.distraints?.length
                        ? design.distraints
                              .map((value, index) =>
                                  formatEnumValue(
                                      value.type,
                                      "distraintType",
                                      designEnums
                                  )
                              )
                              .join(", ")
                        : undefined,
                field: "",
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designForm:pledge"),
                value: design.pledge ? "" : undefined,
                field: "",
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designForm:opposition"),
                value: design.opposition
                    ? {
                          label: t("designForm:view"),
                          url: `/objections/designs?${qs.stringify({
                              Number: design.registrationNumber,
                          })}`,
                      }
                    : undefined,
                field: "",
                type: "link",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designForm:appeal"),
                value: design.appeal
                    ? {
                          label: t("designForm:view"),
                          url: `/appeal/designs?${qs.stringify({
                              Number: design.applicationNumber,
                          })}`,
                      }
                    : undefined,
                field: "",
                type: "link",
            } as DetailedFormTableRow,
        ];
    });
}
