import { useTranslation } from "react-i18next";
import { DetailedFormTableRow } from "../../components/FormDataGrid/DetailedForm/DetailedFormTable/DetailedFormTable";
import {
    formatEnumValue,
    formatResourcePersonObjectType,
} from "../../components/FormDataGrid/formDataUtils";
import { GetShortDateString } from "../../global/utils";
import { useStore } from "../../storage/StoreProvider";

export function DefaultRows(trademarkAppeals: any, printView?: boolean) {
    const [state, ,] = useStore();
    const trademarkAppealEnums = state.trademarkAppealEnums;
    const { t } = useTranslation();

    return trademarkAppeals.map((trademarkAppeal) => {
        if (trademarkAppeal === {}) return [];
        return [
            {
                caption: " ",
                name: t("trademarkAppealForm:title"),
                value: trademarkAppeal.title,
                field: "",
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkAppealForm:number"),
                value: trademarkAppeal.number,
                field: "",
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkAppealForm:applicant"),
                value: trademarkAppeal.persons?.length
                    ? formatResourcePersonObjectType(trademarkAppeal.persons, [
                        2,
                    ])
                    : undefined,
                field: "",
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("patentAppealForm:result"),
                value:
                    trademarkAppealEnums && trademarkAppeal.result
                        ? formatEnumValue(
                            trademarkAppeal.result,
                            "result",
                            trademarkAppealEnums
                        )
                        : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("patentAppealForm:substantiations"),
                value: trademarkAppeal.substantiations?.join(" "),
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("patentAppealForm:decision"),
                value: trademarkAppeal.decision,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designAppealForm:decisionDate"),
                value: trademarkAppeal.decisionDate
                    ? GetShortDateString(trademarkAppeal.decisionDate)
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkAppealForm:nica"),
                value: trademarkAppeal.nica?.join(", "),
                field: "",
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("patentAppealForm:firstCourtDecision"),
                value: trademarkAppeal.firstCourtDecision,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("patentAppealForm:secondCourtDecision"),
                value: trademarkAppeal.secondCourtDecision,
                type: "string",
            } as DetailedFormTableRow,
        ];
    });
}
