import FormDataGrid from "../../components/FormDataGrid/FormDataGrid";
import { DefaultColumns } from "./columnDefinitions";
import { formatFilterValue } from "./recordFilter";
import { defaultColumnTypes } from "./columnTypes";
import { DesignOppositionsSearchFields } from "./DesignOppositionsSearchFields";
import { useStore } from "../../storage/StoreProvider";
import { fetchDesignOppositionData } from "../../services/designOppositionService";
import { FilterOperandType, FilterValue } from "../../models/FilterModel";
import { useLocation, useParams } from "react-router-dom";
import { useMemo } from "react";
import qs from "qs";

export interface DesignOppositionsGridParams {
    id?: string;
}

interface IDesignAppelsQueryString {
    number?: string;
}

function useQuery() {
    const { search } = useLocation();

    return useMemo(
        () =>
            qs.parse(search, {
                ignoreQueryPrefix: true,
            }),
        [search]
    );
}

export default function DesignOppositionsGrid() {
    const [state, ,] = useStore();
    const { id } = useParams<DesignOppositionsGridParams>();

    const queryParams: IDesignAppelsQueryString = useQuery();

    function rowIdSelector(rows: any) {
        return rows.map((dOpposition) => {
            return dOpposition.number;
        });
    }

    const filters = (params: IDesignAppelsQueryString) => {
        const tmp: FilterValue[] = [];

        for (const param in params) {
            if (Object.prototype.hasOwnProperty.call(params, param)) {
                const value = params[param];
                tmp.push({
                    Field: param,
                    Operator: FilterOperandType.Equal,
                    Value1: value,
                    Value2: null,
                });
            }
        }

        return tmp;
    };

    return (
        <FormDataGrid
            defaultColumns={DefaultColumns()}
            searchFields={DesignOppositionsSearchFields(
                state.designOppositionSearchMeta,
                queryParams
            )}
            onFetchData={fetchDesignOppositionData}
            filterValueFormatter={formatFilterValue}
            customColumnTypes={defaultColumnTypes(state.designOppositionEnums)}
            loadError={state.error}
            rows={state.designOppositions ?? []}
            rowIdSelector={rowIdSelector}
            initialFilter={filters(queryParams)}
            hidePreciseSearch
        />
    );
}
