import { Typography, Grid } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";

interface PageTitleProps {
    title?: string;
}

export default function PageTitle(props?: PageTitleProps) {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <Grid item xs={1} md={2} />
            <Grid item xs={9} md={10}>
                {pathname === "/trademark" && (
                    <Typography variant="subtitle1">
                        {t("pageTitle:trademarkMessage")}
                    </Typography>
                )}
                {
                    pathname === "/patents" && (
                        <Typography variant="subtitle1">
                            {t("pageTitle:patentMessage")}
                        </Typography>
                    ) /* TODO: This makes Patents page scrolls.*/
                }
                {pathname === "/pas" && (
                    <Typography variant="subtitle1">
                        {t("pageTitle:pasMessage")}
                    </Typography>
                )}
                {pathname.startsWith("/refusedtrademark") && (
                    <Typography variant="subtitle1">
                        {t("pageTitle:refusedTrademarkMessage")}
                    </Typography>
                )}
                {pathname === "/design" && (
                    <Typography variant="subtitle1">
                        {t("pageTitle:designMessage")}
                    </Typography>
                )}
                {pathname.startsWith("/objections/trademarks") && (
                    <Typography variant="subtitle1">
                        {t("pageTitle:trademarkOppositionsMessage")}
                    </Typography>
                )}
                {pathname === "/objections/designs" && (
                    <Typography variant="subtitle1">
                        {t("pageTitle:designOppositionsMessage")}
                    </Typography>
                )}
                {pathname === "/appeal/patents" && (
                    <Typography variant="subtitle1">
                        {t("pageTitle:patentAppealsMessage")}
                    </Typography>
                )}
                {pathname.startsWith("/appeal/trademarks") && (
                    <Typography variant="subtitle1">
                        {t("pageTitle:trademarkAppealsMessage")}
                    </Typography>
                )}
                {pathname === "/appeal/designs" && (
                    <Typography variant="subtitle1">
                        {t("pageTitle:designAppealsMessage")}
                    </Typography>
                )}
                {pathname.startsWith("/interpartes/trademarks/objections") && (
                    <Typography variant="subtitle1">
                        {t("pageTitle:trademarkOppositionsMessage")}
                    </Typography>
                )}
                {pathname.startsWith(
                    "/interpartes/trademarks/invalidations"
                ) && (
                    <Typography variant="subtitle1">
                        {t("pageTitle:trademarkInvalidationsMessage")}
                    </Typography>
                )}
                {pathname.startsWith("/interpartes/trademarks/revocations") && (
                    <Typography variant="subtitle1">
                        {t("pageTitle:trademarkRevocationsMessage")}
                    </Typography>
                )}
                {props && (
                    <Typography variant="subtitle1">{props?.title}</Typography>
                )}
            </Grid>
        </React.Fragment>
    );
}
