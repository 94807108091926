export enum OrderType {
    asc = 0,
    desc = 1,
}

export interface OrderBy {
    field: string;
    descending: OrderType;
    priority: number;
}

export enum FilterOperandType {
    Equal = 1,
    NotEqual = 2,
    Contains = 3,
    Between = 4,
}

export interface FilterValue {
    Field: string,
    Operator: FilterOperandType,
    Value1: any;
    Value2: any;
}

type FilterModel = FilterValue[];

export default FilterModel;