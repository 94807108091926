enum SearchFieldType {
    list,
    string,
    date,
    dateRange,
    multiList,
    orderedMultiList,
    niceCodes,
    groupedList,
}

export default SearchFieldType;
