import { useLocation } from "react-router-dom";
import { parse } from "qs";
import { useStore } from "../../storage/StoreProvider";
import { DefaultRows } from "./detailedFormRowDefinitions";
import { fetchTrademarkInvalidationData } from "../../services/trademarkInvalidationService";
import React from "react";
import GridBasePrintForm from "../../components/FormDataGrid/GridBasePrintForm";
import { DefaultFetchDataProps } from "../../services/baseService";

export default function TrademarkInvalidationsPrintForm({ match }) {
    const [state] = useStore();

    const rowSets = DefaultRows(state.trademarkInvalidations, true);

    const dataResolver = async (props: DefaultFetchDataProps) => {
        await fetchTrademarkInvalidationData({
            page: props.page,
            rowsPerPage: props.filter?.length,
            sortModel: props.sortModel,
            filter: props.filter,
            action: props.action,
        });
    };

    return (
        <GridBasePrintForm
            filterBy="number"
            rowsPerPage={rowSets.length}
            dataResolver={dataResolver}
            rowSets={rowSets}
            match={match}
            isDataResolved={state.trademarkInvalidations.length === 0}
        />
    );
}
