import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";
import robustaTLProBoldFont from "../fonts/RobustaTLPro/robustaTLProBold";
import robustaTLProMediumFont from "../fonts/RobustaTLPro/robustaTLProMedium";
import robustaTLProRegularFont from "../fonts/RobustaTLPro/robustaTLProRegular";
import { icoMoonFont } from "../fonts/icoMoon/IcoMoon";

// Lilac - default theme.
const theme = createMuiTheme({
    typography: {
        fontFamily: "RobustaTLPro-Regular",
        caption: {
            color: "#71757e",
            fontSize: ".75rem",
        },
        subtitle1: {
            // The same as h1 in base theme (lrpv.gov.lv).
            fontFamily: "RobustaTLPro-Medium",
            fontSize: "1.1rem",
            lineHeight: "1.5",
            "@media (min-width:1200px)": {
                fontSize: "1.3rem",
            },
            "@media (min-width:1300px)": {
                fontSize: "1.5rem",
            },
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1800,
            xl: 1920,
        },
    },
    palette: {
        primary: {
            main: "#840b55",
            contrastText: "#fff",
        },
        secondary: {
            main: "#fff",
        },
        background: {
            default: "#fff",
        },
        accent: {
            main: "#b0036e",
            light: "#f7f7f7",
        },
    },
    props: {
        MuiButton: {
            // `MuiButton` is the global class name for the <Button /> component.
            disableElevation: true, // This prop disables the drop shadow on all Buttons.
        },
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                "@font-face": [
                    robustaTLProRegularFont,
                    robustaTLProBoldFont,
                    robustaTLProMediumFont,
                    icoMoonFont,
                ],
            },
        },
        MuiButton: {
            root: {
                textTransform: "none", // Removes uppercase transformation.
            },
            containedPrimary: {
                "&:hover": {
                    // Changes colors for hover state.
                    backgroundColor: "#b0036e",
                },
                backgroundColor: "#840b55",
            },
        },
        MuiToolbar: {
            root: {
                color: "#4c5059",
            },
        },
        MuiLink: {
            root: {
                "&:hover": {
                    // Changes colors for hover state.
                    color: "#b0036e",
                },
            },
        },
        MuiMenuItem: {
            root: {
                "&:selected": {
                    color: "#b0036e",
                },
            },
        },
        MuiListItem: {
            root: {
                "&:hover": {
                    // Changes colors for hover state.
                    color: "#b0036e",
                },
            },
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    // Changes colors for hover state.
                    backgroundColor: "transparent",
                    color: "#b0036e",
                },
            },
        },
    },
});

export default theme;
