export function GetShortDateString(dateString: string, separator: string = '.') {
    if (!dateString) return null;

    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return [day < 10 ? '0' + day.toString() : day, month < 10 ? '0' + month.toString() : month, year].join(separator);
}

export function numberSuffix (n: number) {
    if (n > 3 && n < 21) return n + "th";
    switch (n % 10) {
        case 1:
            return n + "st";
        case 2:
            return n + "nd";
        case 3:
            return n + "rd";
        default:
            return n + "th";
    }
}

export function GetPublicationDateFormat(dateString: string, separator: string = '/'){
    if (!dateString) return null;

    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return [month.toString(), year].join(separator);
};