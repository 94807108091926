import {
    formatEnumValue,
    formatPasPersonObjectType,
} from "../../components/FormDataGrid/formDataUtils";
import { DetailedFormTableRow } from "../../components/FormDataGrid/DetailedForm/DetailedFormTable/DetailedFormTable";
import { GetShortDateString, numberSuffix } from "../../global/utils";
import { TableCellValue } from "../../components/FormDataGrid/DetailedForm/DetailedFormTable/DetailedFormTableCell/CellContent/TableCellContent";
import { useStore } from "../../storage/StoreProvider";
import { useTranslation } from "react-i18next";
import qs from "qs";

function getEvents(events: any) {
    return events?.map((event) => {
        return [
            {
                text: GetShortDateString(event.entity.eventDate),
            } as TableCellValue,
            {
                text: event.entity.eventDescription,
            } as TableCellValue,
        ];
    });
}

export function DefaultRows(pases: any, printView?: boolean) {
    const [state, ,] = useStore();
    const pasEnums = state.pasEnums;
    const { t, i18n } = useTranslation();

    return pases.map((pas) => {
        if (pas === {}) return [];
        const productName =
            i18n.language === "lv-LV"
                ? pas.pasBasicInfo?.product
                : pas.pasBasicInfo?.productEn;

        console.log("pas:", pas);

        return [
            {
                caption: " ",
                name: t("pasForm:status"),
                value:
                    pasEnums && pas.pasBasicInfo?.pasStatusId
                        ? formatEnumValue(
                              pas.pasBasicInfo.pasStatusId,
                              "status",
                              pasEnums
                          )
                        : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 21,
                name: t("pasForm:pasNumber"),
                value: pas.pasBasicInfo?.pasNumber,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 22,
                name: t("pasForm:pasApplicationDate"),
                value: pas.pasBasicInfo?.pasApplicationDate
                    ? GetShortDateString(pas.pasBasicInfo.pasApplicationDate)
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: "(71)/(73)",
                name: t("pasForm:applicantOrOwner"),
                value: pas.pasBasicInfo?.persons
                    ? (formatPasPersonObjectType(
                          pas.pasBasicInfo.persons,
                          [0, 1]
                      ) as string)
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 74,
                name: t("pasForm:representative"),
                value: pas.pasBasicInfo?.persons
                    ? (formatPasPersonObjectType(pas.pasBasicInfo.persons, [
                          2,
                      ]) as string)
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 95,
                name: t("pasForm:productTradeName"),
                value:
                    productName || pas.pasBasicInfo?.tradeName
                        ? `${productName ? `${productName}` : ""} ${
                              pas.pasBasicInfo?.tradeName
                                  ? ` ${pas.pasBasicInfo?.tradeName}`
                                  : ""
                          }`
                        : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 94,
                name: t("pasForm:validTo"),
                value: pas.pasBasicInfo?.validTo
                    ? GetShortDateString(pas.pasBasicInfo?.validTo)
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("pasForm:pediatricExtension"),
                value:
                    pasEnums && pas.pasBasicInfo?.pedExtStatusCodeId
                        ? formatEnumValue(
                              pas.pasBasicInfo.pedExtStatusCodeId,
                              "pedExtStatus",
                              pasEnums
                          )
                        : "",
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: "",
                name: t("pasForm:marketAuth"),
                value:
                    pas.pasBasicInfo?.marketAuthLVNumber ||
                    pas.pasBasicInfo?.marketAuthEUNumber
                        ? {
                              headers: [t("pasForm:number"), t("pasForm:date")],
                              labels: [
                                  {
                                      inidCode: 92,
                                      text: t("pasForm:inLV"),
                                  },
                                  {
                                      inidCode: 93,
                                      text: t("pasForm:inEU"),
                                  },
                              ],
                              values: [
                                  [
                                      {
                                          text: pas.pasBasicInfo
                                              .marketAuthLVNumber
                                              ? pas.pasBasicInfo
                                                    .marketAuthLVNumber
                                              : "",
                                      } as TableCellValue,
                                      {
                                          text: pas.pasBasicInfo
                                              .marketAuthLVDate
                                              ? GetShortDateString(
                                                    pas.pasBasicInfo
                                                        .marketAuthLVDate
                                                )
                                              : "",
                                      } as TableCellValue,
                                  ],
                                  [
                                      {
                                          text: pas.pasBasicInfo
                                              .marketAuthEUNumber
                                              ? pas.pasBasicInfo
                                                    .marketAuthEUNumber
                                              : "",
                                      } as TableCellValue,
                                      {
                                          text: pas.pasBasicInfo
                                              .marketAuthEUDate
                                              ? GetShortDateString(
                                                    pas.pasBasicInfo
                                                        .marketAuthEUDate
                                                )
                                              : "",
                                      } as TableCellValue,
                                  ],
                              ],
                          }
                        : undefined,
                type: "table",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("pasForm:patentBasicInfo"),
                value: pas.pasBasicInfo?.patent?.patentBasicInfo
                    ? {
                          headers: [t("pasForm:number"), t("pasForm:date")],
                          labels: [
                              {
                                  inidCode: 96,
                                  text: t("pasForm:application"),
                              },
                              {
                                  inidCode: 97,
                                  text: t("pasForm:patent"),
                              },
                              {
                                  inidCode: 54,
                                  text: t("pasForm:inventionTitle"),
                              },
                          ],
                          values: [
                              [
                                  {
                                      text: pas.pasBasicInfo.patent
                                          .patentBasicInfo.applicationNumber,
                                  } as TableCellValue,
                                  {
                                      text: GetShortDateString(
                                          pas.pasBasicInfo.patent
                                              .patentBasicInfo.applicationDate
                                      ),
                                  } as TableCellValue,
                              ],
                              [
                                  {
                                      text: pas.pasBasicInfo.patent
                                          .patentBasicInfo.publicationNumber,
                                      url: `/patents/${pas.pasBasicInfo.patent.patentBasicInfo.applicationNumber}`,
                                  } as TableCellValue,
                                  {
                                      text: "",
                                  } as TableCellValue,
                              ],
                              [
                                  {
                                      text:
                                          i18n.language === "lv-LV"
                                              ? pas.pasBasicInfo.patent
                                                    .patentBasicInfo.titleLV
                                              : pas.pasBasicInfo.patent
                                                    .patentBasicInfo.titleEN,
                                  } as TableCellValue,
                                  {
                                      text: "",
                                  } as TableCellValue,
                              ],
                          ],
                      }
                    : undefined,
                type: "table",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("pasForm:publications"),
                value: pas.publications
                    ? {
                          headers: ["", t("pasForm:date"), t("pasForm:number")],
                          values: pas.publications.map((publication) => {
                              return [
                                  {
                                      text:
                                          pasEnums &&
                                          publication.publicationType
                                              ? formatEnumValue(
                                                    publication.publicationType,
                                                    "publicationType",
                                                    pasEnums
                                                )
                                              : "",
                                  } as TableCellValue,
                                  {
                                      text: GetShortDateString(
                                          publication.publicationDate
                                      ),
                                  } as TableCellValue,
                                  {
                                      text: publication.publicationNumber ?? "",
                                      url: publication.url, // /pas/${encodeURIComponent(publication.publicationNumber)}
                                  } as TableCellValue,
                              ];
                          }),
                      }
                    : undefined,
                type: "table",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("pasForm:eventHistory"),
                value: pas.events
                    ? {
                          headers: [
                              t("pasForm:date"),
                              t("pasForm:eventDescription"),
                          ],
                          values: getEvents(pas.events),
                      }
                    : undefined,
                type: "table",
                collapse: printView ? false : true,
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("pasForm:nextAnnualFee"),
                value:
                    pas.pasBasicInfo?.pasNextAnnualFee ||
                    pas.pasBasicInfo?.pasNextAnnualFeeForYear
                        ? {
                              headers: [
                                  t("pasForm:date"),
                                  t("pasForm:comment"),
                              ],
                              values: [
                                  [
                                      {
                                          text: pas.pasBasicInfo
                                              ?.pasNextAnnualFee
                                              ? GetShortDateString(
                                                    pas.pasBasicInfo
                                                        .pasNextAnnualFee
                                                )
                                              : "",
                                      } as TableCellValue,
                                      {
                                          text: pas.pasBasicInfo
                                              ?.pasNextAnnualFeeForYear
                                              ? pas.pasBasicInfo
                                                    ?.pasNextAnnualFeeForYear &&
                                                i18n.language === "en-US"
                                                  ? `${t(
                                                        "pasForm:for"
                                                    )} ${numberSuffix(
                                                        pas.pasBasicInfo
                                                            .pasNextAnnualFeeForYear
                                                    )} ${t("pasForm:years")}`
                                                  : `${t("pasForm:for")} ${
                                                        pas.pasBasicInfo
                                                            .pasNextAnnualFeeForYear
                                                    }. ${t("pasForm:years")}`
                                              : "",
                                      } as TableCellValue,
                                  ],
                              ],
                          }
                        : undefined,
                type: "table",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("pasForm:renewalFeeHistory"),
                value: pas.pasBasicInfo?.payments
                    ? {
                          headers: [
                              t("pasForm:date"),
                              t("pasForm:sum"),
                              t("pasForm:year"),
                          ],
                          values: pas.pasBasicInfo.payments.map((p) => {
                              return [
                                  {
                                      text: p.entity.paymentDate
                                          ? GetShortDateString(
                                                p.entity.paymentDate
                                            )
                                          : "",
                                  } as TableCellValue,
                                  {
                                      text: p.entity.amount.toFixed(2) ?? "",
                                  } as TableCellValue,
                                  {
                                      text: p.entity.forYear ?? "",
                                  } as TableCellValue,
                              ];
                          }),
                      }
                    : undefined,
                type: "table",
                collapse: printView ? false : true,
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("pasForm:appealInfo"),
                value:
                    pas.pasBasicInfo && pas.pasBasicInfo.appeal
                        ? {
                              label: "Apskatīt",
                              url: `/appeal/patents?${qs.stringify({
                                  Number: pas.pasBasicInfo.pasNumber,
                              })}`,
                          }
                        : undefined,
                field: "",
                type: "link",
            } as DetailedFormTableRow,
        ];
    });
}
