import React from 'react';
import { createStyles, makeStyles, Theme, useTheme, withStyles, WithStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Image from 'material-ui-image'
import { Box, Grid, Paper, useMediaQuery } from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';

// Theme-dependent styles.
// createStyles is a helper function for TS compile.
const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        maxWidth: 200,
        position: 'relative',
    },
    imgRow: {
        breakInside: 'avoid',
    },
    zoomContainer: {
        maxWidth: 200,
        position: 'relative',
        marginTop: "230%"
    },
    actionBar: {
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.05);',
        width: '100%',
        height: '4em',
        bottom: 0,
    },
    item: {
        marginRight: theme.spacing(1),
    }
}));

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
            minHeight: 55,
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
        minWidth: 500,
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export interface ImageDialogProps {
    src: string;
}

export default function ImageDialog({ src }: ImageDialogProps) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const FlexBox = props => <Box display="flex" alignItems="flex-end" justifyContent="flex-end" {...props} />;

    return (
        <div>
            <Grid container spacing={0} className={classes.imgRow}>
                <Grid item xs={3}>
                    <Paper variant={'outlined'} elevation={0} className={classes.container} >
                        <Image src={src} style={{ objectFit: 'contain' }} />
                    </Paper>
                </Grid>
                <Grid item xs={1}>
                    <Paper elevation={0} className={classes.zoomContainer}>
                        <IconButton
                            edge="end"
                            aria-label="Zoom image in"
                            aria-haspopup="true"
                            onClick={handleClickOpen}
                            color="inherit"
                            disableRipple={true}
                        >
                            <ZoomInIcon className={classes.item} />
                        </IconButton>
                    </Paper>
                </Grid>
            </Grid>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullScreen={fullScreen}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose} >
                </DialogTitle>
                <DialogContent dividers>
                    <Image src={src} style={{ objectFit: 'contain' }} />
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </div>
    );
}