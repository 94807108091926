import { GridCellParams, GridValueGetterParams } from "@material-ui/data-grid";
import React from "react";
import { useTranslation } from "react-i18next";
import { getDesignAppealPersonValue } from "../../components/FormDataGrid/formDataUtils";
import renderCellExpand, {
    ILinkValue,
} from "../../components/FormDataGrid/RenderCellExpand";
import { GridStorage } from "../../storage/browserStorage";
import { useStore } from "../../storage/StoreProvider";
import GridColDefExtended from "../GridColDefExtended";

export function DefaultColumns(): GridColDefExtended[] {
    const { t, i18n } = useTranslation();
    const storage = GridStorage.getInstance();
    const visibleColumns = storage.getColumns();
    const [state, ,] = useStore();
    const fieldInfo = state.designAppealFieldInfo?.sortable;

    function getEntityUrlLink(
        params: GridValueGetterParams,
        key: string
    ): ILinkValue {
        if (!params.value) return null;
        const urls = params.getValue(params.id, "urls");
        if (!urls && !urls[key]) return null;
        const pageUrl = urls[key];
        const urlLabel = params.value;
        return { url: pageUrl, label: urlLabel } as ILinkValue;
    }

    function getDesignLinkValue(
        params: GridValueGetterParams,
        label?: string,
        key?: string,
        url?: string
    ): ILinkValue {
        if (!params.value) return null;
        const pageId = (
            key ? (params.getValue(params.id, key) as ILinkValue)?.label : params.value
        ).toString();
        const urlLabel = label || pageId;

        const pageUrl = `${url || "design"}/${pageId}`;
        return { url: pageUrl, label: urlLabel } as ILinkValue;
    }

    return React.useMemo(
        () => [
            {
                field: "title",
                headerName: t("designAppealForm:title"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 400,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("title")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("title") ?? false
                    : false,
            },
            {
                field: "number",
                headerName: t("designAppealForm:number"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, true, false, true),
                valueGetter: (params) =>
                    getDesignLinkValue(params, null, null, "/design"),
                headerAlign: "center",
                width: 200,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("number")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("number") ?? false
                    : false,
            },
            {
                field: "applicant",
                headerName: t("designAppealForm:applicant"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                valueGetter: getDesignAppealPersonValue,
                headerAlign: "center",
                width: 300,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("applicant")
                    : false,
                hideByDefault: false,
            },
            {
                field: "result",
                headerName: t("designAppealForm:result"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 150,
                type: "enumType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("result")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("result") ?? false
                    : false,
            },
            {
                field: "substantiations",
                headerName: t("designAppealForm:substantiations"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 300,
                type: "array",
                hide: visibleColumns
                    ? !visibleColumns?.includes("substantiations")
                    : false,
                hideByDefault: false,
            },
            {
                field: "decision",
                headerName: t("designAppealForm:decision"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, true, false, true),
                valueGetter: (params) =>
                    getEntityUrlLink(params, "DecisionFile"),
                headerAlign: "center",
                width: 300,
                type: "array",
                hide: visibleColumns
                    ? !visibleColumns?.includes("decision")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("decision") ?? false
                    : false,
            },
            {
                field: "decisionDate",
                headerName: t("designAppealForm:decisionDate"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 150,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("decisionDate")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("decisionDate") ?? false
                    : false,
            },
            {
                field: "firstCourtDecision",
                headerName: t("designAppealForm:firstCourtDecision"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 400,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("firstCourtDecision")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("firstCourtDecision") ?? false
                    : false,
            },
            {
                field: "secondCourtDecision",
                headerName: t("designAppealForm:secondCourtDecision"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 400,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("secondCourtDecision")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("secondCourtDecision") ?? false
                    : false,
            },
        ],
        [visibleColumns, fieldInfo, i18n.language]
    );
}
