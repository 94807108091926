export const INIDcodes = {
    "0": 1, //Vārdiska
    "1": 1, // Figurāla
    "2": 555, // Hologrāfiska
    "3": 558, // Krāsa
    "4": 554, // Telpiska zīme
    "5": 556, // Skaņas
    "6": 1, // Kustību
    "7": 1, // Cita
};

export const NICE_GOODS = Array(34)
    .fill("")
    .map((_, index) => (index + 1).toString());
export const NICE_SERVICES = Array(11)
    .fill("")
    .map((_, index) => (index + 35).toString());
