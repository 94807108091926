import FormDataGrid from "../../components/FormDataGrid/FormDataGrid";
import { DefaultColumns } from "./columnDefinitions";
import { defaultColumnTypes } from "./columnTypes";
import { formatFilterValue } from "./recordFilter";
import { PatentsSearchFields } from "./PatentsSearchFields";
import { useStore } from "../../storage/StoreProvider";
import { fetchPatentData } from "../../services/patentService";
import { useMemo } from "react";
import { DefaultFetchDataProps, getDataKey } from "../../services/baseService";
import Resource from "../../models/grid/Resource";
import { FilterOperandType, FilterValue } from "../../models/FilterModel";
import PatentsRedirectDialog from "./PatentsRedirectDialog";

export default function PatentsGrid() {
    // API calls / data retrieval.
    const [state, ,] = useStore();

    const key = getDataKey(Resource.Patent);
    const getRows = useMemo(() => {
        if (!state.patents) return [];
        return state.patents.map((patent) => {
            return patent[key];
        });
    }, [state]);

    function rowIdSelector(rows: any) {
        return rows.map((patent) => {
            return patent.applicationNumber;
        });
    }

    function rowFilterBuilder(value: any) {
        return {
            Field: "applicationNumber",
            Operator: FilterOperandType.Equal,
            Value1: value.applicationNumber,
            Value2: null,
        } as FilterValue;
    }

    const dataResolver = async (props: DefaultFetchDataProps) => {
        await fetchPatentData({
            page: props.page,
            rowsPerPage: props.rowsPerPage,
            sortModel: props.sortModel,
            filter: props.filter,
            action: props.action,
            includeFieldInfo: true,
        });
    };

    return (
        <>
        <FormDataGrid
            defaultColumns={DefaultColumns()}
            searchFields={PatentsSearchFields()}
            onFetchData={dataResolver}
            filterValueFormatter={formatFilterValue}
            customColumnTypes={defaultColumnTypes(state.patentEnums)}
            loadError={state.error}
            rows={getRows}
            rowIdSelector={rowIdSelector}
            initialSort={[
                {
                    field: "applicationDate",
                    sort: "desc",
                },
            ]}
        />
        <PatentsRedirectDialog/>
        </>
    );
}
