import { FilterOperandType } from "../../models/FilterModel";
import SearchFieldType from "../../models/grid/SearchType";
import { Store } from "../../storage/StoreProvider";

export const formatFilterValue = (field: string, value: any, usePreciseSearch: boolean) => {
    const currentState = Store.getState();
    const fieldMeta = currentState.designSearchMeta.find(f => f.entity.name.replace('.', '%') === field);
    if (!fieldMeta) return [];

    switch (fieldMeta.entity.type) {
        case (SearchFieldType.date):
            return {
                Field: field.replace('%', '.'),
                Operator: FilterOperandType.Between,
                Value1: value?.startDate ? value.startDate.toDateString() : (value?.endDate ? value.endDate.toDateString() : ''),
                Value2: value?.endDate ? value.endDate.toDateString() : (value?.startDate ? value.startDate.toDateString() : ''),
            }
        default:
            if (fieldMeta.entity.enum) {
                return {
                    Field: field.replace('%', '.'),
                    Operator: FilterOperandType.Equal,
                    Value1: value?.value ? value.value : '',
                    Value2: null,
                };
            }
            return {
                Field: field.replace('%', '.'),
                Operator: usePreciseSearch ? FilterOperandType.Equal : FilterOperandType.Contains,
                Value1: value.trim(),
                Value2: null,
            };
    }
}