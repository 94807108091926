import { ThemeProvider } from "@material-ui/core/styles";
import React, { useContext } from "react";
import { ThemeContext } from "./ThemeContext";
const { Provider, Consumer } = ThemeContext;

const ThemeContextProvider = (props: any) => {
    const context = useContext(ThemeContext);
    return (
        <Provider value={context}>
            <ThemeProvider theme={context.theme}>{props.children}</ThemeProvider>
        </Provider>
    );
}

export { ThemeContextProvider, Consumer as ThemeContextConsumer };