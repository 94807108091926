import { GridCellParams, GridValueGetterParams } from "@material-ui/data-grid";
import React from "react";
import { useTranslation } from "react-i18next";
import {
    formatSeniorityOrPriorityValue,
    getDesignPersonValue,
} from "../../components/FormDataGrid/formDataUtils";
import renderCellExpand, {
    ILinkValue,
} from "../../components/FormDataGrid/RenderCellExpand";
import { GridStorage } from "../../storage/browserStorage";
import { useStore } from "../../storage/StoreProvider";
import GridColDefExtended from "../GridColDefExtended";

function getSeniorityValue(params: GridValueGetterParams): string {
    const claims = params.getValue(params.id, "claims") as any;
    if (!claims || !claims.seniority?.length) return "";

    const formattedValues = formatSeniorityOrPriorityValue(
        claims.seniority.filter((p) => p.type === 1)
    ); // Registration type.
    return (
        formattedValues
            .map((items) => items.map((item) => item.text).join(", "))
            .join(", ") ?? ""
    );
}

function getPriorityValue(params: GridValueGetterParams): string {
    const claims = params.getValue(params.id, "claims") as any;
    if (!claims || !claims.priority?.length) return "";

    const formattedValues = formatSeniorityOrPriorityValue(claims.priority);
    return (
        formattedValues
            .map((items) => items.map((item) => item.text).join(", "))
            .join(", ") ?? ""
    );
}

function getLinkValue(
    params: GridValueGetterParams,
    label?: string,
    key?: string,
    url?: string
): ILinkValue {
    if (!params.value) return null;
    const pageId = (
        key
            ? (params.getValue(params.id, key) as ILinkValue)?.label
            : params.value
    ).toString();
    const pageUrl = `${url || "design"}/${pageId}`;
    const urlLabel = label || pageId;
    return { url: pageUrl, label: urlLabel } as ILinkValue;
}

function getImagesUrl(params: GridValueGetterParams): string[] | null {
    return params.value ? (params.value as string[]) : null;
}

export function DefaultColumns(): GridColDefExtended[] {
    const { t, i18n } = useTranslation();
    const storage = GridStorage.getInstance();
    const visibleColumns = storage.getColumns();
    const [state, ,] = useStore();
    const fieldInfo = state.designFieldInfo?.sortable;

    return React.useMemo(
        () => [
            {
                field: "status",
                headerName: t("designForm:status"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 300,
                type: "enumType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("status")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("status") ?? false
                    : false,
            },
            {
                field: "images",
                headerName: t("designForm:image"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false, true),
                valueGetter: getImagesUrl,
                headerAlign: "center",
                width: 350,
                sortable: false,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("images")
                    : false,
                hideByDefault: false,
            },
            {
                field: "registrationNumber",
                headerName: t("designForm:registrationNumber"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 150,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("registrationNumber")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("registrationNumber") ?? false
                    : false,
            },
            {
                field: "registrationDate",
                headerName: t("designForm:registrationDate"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 150,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("registrationDate")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("registrationDate") ?? false
                    : false,
            },
            {
                field: "applicationNumber",
                headerName: t("designForm:applicationNumber"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, true),
                valueGetter: getLinkValue,
                headerAlign: "center",
                width: 150,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("applicationNumber")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("applicationNumber") ?? false
                    : false,
            },
            {
                field: "applicationDate",
                headerName: t("designForm:applicationDate"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 130,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("applicationDate")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("applicationDate") ?? false
                    : false,
            },
            {
                field: "receptionDate",
                headerName: t("designForm:receptionDate"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 180,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("receptionDate")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("receptionDate") ?? false
                    : false,
            },
            {
                field: "potentialExpirationDate",
                headerName: t("designForm:potentialExpirationDate"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 170,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("potentialExpirationDate")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("potentialExpirationDate") ?? false
                    : false,
            },
            // {
            //     field: "publicationDate",
            //     headerName: t("designForm:publicationDate"),
            //     renderCell: (params: GridCellParams) =>
            //         renderCellExpand(params, false),
            //     headerAlign: "center",
            //     width: 170,
            //     type: "dateType",
            //     hide: visibleColumns
            //         ? !visibleColumns?.includes("publicationDate")
            //         : true,
            //     hideByDefault: true,
            //     sortable: fieldInfo
            //         ? fieldInfo.includes("publicationDate") ?? false
            //         : false,
            // },
            {
                field: "locarno",
                headerName: t("designForm:locarno"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 170,
                type: "locType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("locarno")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("locarno") ?? false
                    : false,
            },
            {
                field: "registrationEndDate",
                headerName: t("designForm:registrationEndDate"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 170,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("registrationEndDate")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("registrationEndDate") ?? false
                    : false,
            },
            {
                field: "oppositionPeriodStart",
                headerName: t("designForm:oppositionPeriodStart"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 170,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("oppositionPeriodStart")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("oppositionPeriodStart") ?? false
                    : false,
            },
            {
                field: "oppositionPeriodEnd",
                headerName: t("designForm:oppositionPeriodEnd"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 170,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("oppositionPeriodEnd")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("oppositionPeriodEnd") ?? false
                    : false,
            },
            {
                field: "claims%seniority",
                headerName: t("designForm:seniority"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                valueGetter: getSeniorityValue,
                headerAlign: "center",
                width: 350,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("claims%seniority")
                    : true,
                hideByDefault: true,
                sortable: false,
            },
            {
                field: "claims%priority",
                headerName: t("designForm:priority"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                valueGetter: getPriorityValue,
                headerAlign: "center",
                width: 350,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("claims%priority")
                    : true,
                hideByDefault: true,
                sortable: false,
            },
            {
                field: "applicantOrOwner",
                headerName: t("designForm:applicantOrOwner"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                valueGetter: getDesignPersonValue,
                headerAlign: "center",
                width: 300,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("applicantOrOwner")
                    : true,
                hideByDefault: true,
                sortable: false,
            },
            {
                field: "representative",
                headerName: t("designForm:representative"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                valueGetter: getDesignPersonValue,
                headerAlign: "center",
                width: 300,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("representative")
                    : true,
                hideByDefault: true,
                sortable: false,
            },
            {
                field: "designer",
                headerName: t("designForm:designer"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                valueGetter: getDesignPersonValue,
                headerAlign: "center",
                width: 300,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("designer")
                    : true,
                hideByDefault: true,
                sortable: false,
            },
            {
                field: "title",
                headerName: t("designForm:title"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 350,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("title")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("title") ?? false
                    : false,
            },
            {
                field: "objectCount",
                headerName: t("designForm:objectCount"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 150,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("objectCount")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("objectCount") ?? false
                    : false,
            },
            {
                field: "licensees",
                headerName: t("designForm:licencees"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                valueGetter: getDesignPersonValue,
                headerAlign: "center",
                width: 300,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("licensees")
                    : true,
                hideByDefault: true,
                sortable: false,
            },
            {
                field: "distraints",
                headerName: t("designForm:distraints"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 300,
                type: "distraintType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("distraints")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("distraints") ?? false
                    : false,
            },
            {
                field: "oppositionValue",
                headerName: t("designForm:opposition"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, true),
                valueGetter: (params: GridValueGetterParams) =>
                    getLinkValue(
                        params,
                        "Apskatīt",
                        "registrationNumber",
                        "/objections/designs"
                    ),
                headerAlign: "center",
                width: 130,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("oppositionValue")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("oppositionValue") ?? false
                    : false,
            },
            {
                field: "appealValue",
                headerName: t("designForm:appeal"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, true),
                valueGetter: (params: GridValueGetterParams) =>
                    getLinkValue(
                        params,
                        "Apskatīt",
                        "applicationNumber",
                        "/appeal/designs"
                    ),
                headerAlign: "center",
                width: 130,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("appealValue")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("appealValue") ?? false
                    : false,
            },
        ],
        [visibleColumns, fieldInfo, i18n.language]
    );
}
