import React, { useEffect, useState } from "react";

// constants & types
import SearchType from "../models/grid/SearchType";

// utils
import { GridStorage } from "../storage/browserStorage";
import _ from "lodash";
import { useForm } from "react-final-form";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Chip, Checkbox, Tooltip } from "@material-ui/core";

import { Autocomplete } from "./Autocomplete";
import { TextField } from "./TextField";
import DateRangePicker from "./DateRangePicker/DateRangePicker";
import DatePicker from "./DatePicker/DatePicker";
import TooltipAdornment from "./TooltipAdornment";

import NiceClassesField from "./NiceClassesField";
import GroupedListField from "./GroupedListField";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiOutlinedInput-adornedEnd": {
                paddingRight: "10px",
            },
            "&:hover $clearIndicatorDirty, & .Mui-focused $clearIndicatorDirty":
                {
                    visibility: "visible",
                },
            "& .MuiInputLabel-shrink": {
                overflow: "visible !important",
                textOverflow: "clip !important",
            },
        },
        textField: {
            // Date range picker and TextField fields have different paddings than Autocomplete TextField.
            "& .MuiOutlinedInput-adornedEnd": {
                paddingRight: "6px",
            },
            "& .MuiInputLabel-shrink": {
                overflow: "visible !important",
                textOverflow: "clip !important",
            },
        },
        input: {
            marginRight: "0px",
        },
        iconButton: {
            padding: 10,
        },
        chip: {
            border: 0,
            borderRadius: 0,
            marginRight: "0.5em",
            "& .MuiChip-deleteIconColorPrimary": {
                color: theme.palette.primary.contrastText,
            },
        },
        clearIndicatorDirty: {},
        clearIndicator: {
            visibility: "hidden",
        },
    })
);

interface TextFieldWithTooltipProps {
    name: string;
    label: string;
    tooltipText: string;
    type: SearchType;
    multiple?: boolean;
    startDateLimit?: number;
    options?: any[];
    defaultValue?: any;
    preciseSearch?: boolean;
}

export default function FieldWithTooltip(props: TextFieldWithTooltipProps) {
    const classes = useStyles();
    const [value, setValue] = useState(props.defaultValue ?? null);

    const form = useForm();

    const [openDropdown, setOpenDropdown] = useState(false);
    const [inputValue, setInputValue] = React.useState("");
    const storage = GridStorage.getInstance();
    const filterModel = storage.getFilterModel();

    const openDropdownList = () => {
        setOpenDropdown(true);
    };

    const closeDropdownList = () => {
        setOpenDropdown(false);
    };

    return (
        <React.Fragment>
            {props.type === SearchType.string && props.multiple && (
                <Autocomplete
                    name={props.name}
                    label={props.label}
                    defaultValue={
                        filterModel && props.name in filterModel
                            ? filterModel[props.name]
                            : props.defaultValue ?? ""
                    }
                    autoSelect
                    size="small"
                    multiple={props.multiple}
                    options={[]}
                    freeSolo
                    disableClearable
                    forcePopupIcon={false}
                    textFieldProps={{
                        variant: "outlined",
                        size: "small",
                        margin: "dense",
                        className: classes.root,
                        InputLabelProps: props.preciseSearch
                            ? {
                                  style: {
                                      // @ts-ignore
                                      maxWidth: "100%",
                                      paddingRight: 90,
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                  },
                              }
                            : {
                                  style: {
                                      // @ts-ignore
                                      maxWidth: "100%",
                                      paddingRight: 60,
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                  },
                              },
                        InputProps: {
                            endAdornment: (
                                <>
                                    {props.preciseSearch ? (
                                        <PreciseSearchCheckbox
                                            fieldName={props.name}
                                        />
                                    ) : null}
                                    <TooltipAdornment
                                        tooltipText={props.tooltipText}
                                    />
                                </>
                            ),
                        },
                    }}
                    renderTags={(value: string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                            <Chip
                                label={option}
                                size="small"
                                {...getTagProps({ index })}
                                color="primary"
                                className={classes.chip}
                            />
                        ))
                    }
                />
            )}
            {props.type === SearchType.string && !props.multiple && (
                <TextField
                    name={props.name}
                    label={props.label}
                    defaultValue={
                        filterModel && props.name in filterModel
                            ? props.defaultValue
                            : ""
                    }
                    variant="outlined"
                    size="small"
                    margin="dense"
                    className={classes.textField}
                    InputLabelProps={
                        props.preciseSearch
                            ? {
                                  style: {
                                      // @ts-ignore
                                      maxWidth: "100%",
                                      paddingRight: 90,
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                  },
                              }
                            : {
                                  style: {
                                      // @ts-ignore
                                      maxWidth: "100%",
                                      paddingRight: 60,
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                  },
                              }
                    }
                    InputProps={{
                        endAdornment: (
                            <>
                                {props.preciseSearch ? (
                                    <PreciseSearchCheckbox
                                        fieldName={props.name}
                                    />
                                ) : null}
                                <TooltipAdornment
                                    tooltipText={props.tooltipText}
                                />
                            </>
                        ),
                    }}
                />
            )}
            {props.type === SearchType.date && (
                <DatePicker
                    name={props.name}
                    label={props.label}
                    defaultValue={
                        filterModel && props.name in filterModel
                            ? props.defaultValue
                            : ""
                    }
                    className={classes.textField}
                    InputProps={{
                        endAdornment: (
                            <TooltipAdornment tooltipText={props.tooltipText} />
                        ),
                    }}
                />
            )}
            {props.type === SearchType.dateRange && (
                <DateRangePicker
                    name={props.name}
                    label={props.label}
                    defaultValue={
                        filterModel && props.name in filterModel
                            ? props.defaultValue
                            : ""
                    }
                    className={classes.textField}
                    startDateLimit={props.startDateLimit + 20}
                    EndDateInputProps={{
                        endAdornment: (
                            <TooltipAdornment tooltipText={props.tooltipText} />
                        ),
                    }}
                />
            )}
            {props.type === SearchType.list && (
                <Autocomplete
                    name={props.name}
                    label={props.label}
                    className={classes.root}
                    id={props.name}
                    value={
                        filterModel && props.name in filterModel ? value : ""
                    }
                    defaultValue={
                        filterModel && props.name in filterModel
                            ? props.defaultValue
                            : ""
                    }
                    onChange={(event, newValue) => {
                        //https://stackoverflow.com/questions/61701206/how-to-clear-data-of-autocomplete-box-using-onclick
                        // Create a new value from the user input.
                        if (newValue && newValue.inputValue) {
                            setValue({
                                title: newValue.inputValue,
                            });
                            return;
                        }
                        setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(_event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    options={props.options}
                    open={openDropdown}
                    onOpen={openDropdownList}
                    onClose={closeDropdownList}
                    getOptionLabel={(option) => option?.label ?? ""}
                    textFieldProps={{
                        variant: "outlined",
                        size: "small",
                        margin: "dense",
                        InputLabelProps: {
                            style: {
                                // @ts-ignore
                                maxWidth: "100%",
                                paddingRight: 80,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            },
                        },
                        InputProps: {
                            endAdornment: (
                                <TooltipAdornment
                                    tooltipText={props.tooltipText}
                                    clearable
                                    showArrow
                                    toggleDropdown={() => {
                                        setOpenDropdown((e) => !e);
                                    }}
                                />
                            ),
                        },
                    }}
                />
            )}
            {[SearchType.multiList, SearchType.orderedMultiList].includes(
                props.type
            ) && (
                <Autocomplete
                    name={`custom.${props.name}`}
                    label={props.label}
                    className={classes.root}
                    id={props.name}
                    value={(Array.isArray(value) ? value[0] : value) as any}
                    defaultValue={
                        filterModel && props.name in filterModel
                            ? Array.isArray(props.defaultValue)
                                ? props.defaultValue[0]
                                : props.defaultValue
                            : ""
                    }
                    onChange={(event, newValue) => {
                        const valuesByLabel = props.options.filter(
                            (value) => value.label === newValue.label
                        );

                        if (valuesByLabel.length > 1) {
                            setValue(valuesByLabel);
                            form.change(props.name, valuesByLabel);
                        } else {
                            setValue(newValue);
                            form.change(props.name, newValue);
                        }
                    }}
                    getOptionSelected={(option, v) => {
                        if (Array.isArray(v)) {
                            return option.label === v[0].label;
                        }

                        return option.value === v.value;
                    }}
                    inputValue={inputValue}
                    onInputChange={(_event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    options={_.uniqBy(props.options, (value) => value.label)}
                    open={openDropdown}
                    onOpen={openDropdownList}
                    onClose={closeDropdownList}
                    getOptionLabel={(option) => option?.label ?? ""}
                    textFieldProps={{
                        variant: "outlined",
                        size: "small",
                        margin: "dense",
                        InputLabelProps: {
                            style: {
                                // @ts-ignore
                                maxWidth: "100%",
                                paddingRight: 80,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            },
                        },
                        InputProps: {
                            endAdornment: (
                                <TooltipAdornment
                                    tooltipText={props.tooltipText}
                                    clearable
                                    showArrow
                                    toggleDropdown={() => {
                                        setOpenDropdown((e) => !e);
                                    }}
                                />
                            ),
                        },
                    }}
                />
            )}
            {props.type === SearchType.groupedList && (
                <GroupedListField
                    name={props.name}
                    label={props.label}
                    tooltipText={props.tooltipText}
                    options={props.options}
                />
            )}
            {props.type === SearchType.niceCodes && (
                <NiceClassesField
                    name={props.name}
                    label={props.label}
                    tooltipText={props.tooltipText}
                />
            )}
        </React.Fragment>
    );
}

interface IPreciseSearchCheckboxProps {
    fieldName: string;
}

const PreciseSearchCheckbox = ({ fieldName }: IPreciseSearchCheckboxProps) => {
    const storage = GridStorage.getInstance();

    const [preciseSearchFields, setPreciseSearchFields] = useState<
        string[] | undefined
    >(storage.getPreciseSearchFields());

    useEffect(() => {
        storage.setPreciseSearchFields(preciseSearchFields);
    }, [preciseSearchFields]);

    return (
        <Tooltip title="Precīza meklēšana">
            <Checkbox
                color="primary"
                checked={
                    preciseSearchFields &&
                    preciseSearchFields.includes(fieldName)
                }
                size="small"
                style={{ padding: 2 }}
                onChange={() => {
                    if (preciseSearchFields) {
                        if (preciseSearchFields.includes(fieldName)) {
                            setPreciseSearchFields((e) =>
                                e.filter((field) => field !== fieldName)
                            );
                        } else {
                            setPreciseSearchFields((e) => [...e, fieldName]);
                        }
                    } else {
                        setPreciseSearchFields([fieldName]);
                    }
                }}
            />
        </Tooltip>
    );
};
