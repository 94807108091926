import PatentDefaultTimeline from "./PatentDefaultTimeline";
import { PatentStatus, getActiveStep } from "./PatentStatus";
import PatentExtendableTimeline from "./PatentExtendableTimeline";
import EPExtendableTimeline from "./EPExtendableTimeline";
import EPDefaultTimeline from "./EPDefaultTimeline";
import { useTranslation } from "react-i18next";

export interface PatentStatusTimelineProps {
    statusId: number;
}

export default function PatentStatusTimeline({
    statusId,
}: PatentStatusTimelineProps) {
    const activeStep = getActiveStep(statusId) - 1;
    let component = null;
    const { t } = useTranslation();

    // For 5 or more steps - use PatentDefaultTimeline.
    // For 1-5 steps - use PatentExtendableTimeline.
    switch (statusId) {
        case PatentStatus.REQUEST_FOR_EP_VALIDATION_EXTENSION_IN_LATVIA_FILED:
        case PatentStatus.EP_IS_VALIDATED_EXTENDED_IN_LATVIA:
        case PatentStatus.MISSING_PAYMENT_OF_EP_RENEWAL_FEE:
            component = (
                <EPDefaultTimeline activeStep={activeStep} showLastLabel />
            );
            break;
        case PatentStatus.EARLY_LAPSE_OF_EP:
            component = (
                <EPExtendableTimeline
                    activeStep={activeStep}
                    additionalLabels={[
                        t("patentStatusTimeline:stepLabel5"),
                        t("patentStatusTimeline:stepLabel2"),
                    ]}
                    additionalSteps={[
                        t("patentStatusTimeline:additionalStep1"),
                        t("patentStatusTimeline:lastStep11"),
                    ]}
                    lastStep={t("patentStatusTimeline:step5")}
                    showLogo
                    isEnd
                />
            );
            break;
        case PatentStatus.EXPIRED_EP:
            component = (
                <EPExtendableTimeline
                    activeStep={activeStep}
                    additionalLabels={[
                        t("patentStatusTimeline:stepLabel5"),
                        t("patentStatusTimeline:stepLabel2"),
                    ]}
                    additionalSteps={[
                        t("patentStatusTimeline:additionalStep1"),
                        t("patentStatusTimeline:lastStep4"),
                    ]}
                    lastStep={t("patentStatusTimeline:step5")}
                    showLogo
                    isEnd
                />
            );
            break;
        case PatentStatus.VALIDATION_EXTENSION_OF_EP_IN_LATVIA_IS_UNDER_APPEAL:
            component = (
                <EPExtendableTimeline
                    activeStep={activeStep}
                    additionalLabels={[
                        t("patentStatusTimeline:stepLabel2"),
                        t("patentStatusTimeline:stepLabel5"),
                        t("patentStatusTimeline:stepLabel2"),
                    ]}
                    additionalSteps={[t("patentStatusTimeline:lastStep5")]}
                    lastStep={t("patentStatusTimeline:step5")}
                    showLogo
                    showMessage
                    message={t("patentStatusTimeline:defaultLastLabelMessage3")}
                />
            );
            break;
        case PatentStatus.REQUEST_FOR_EP_VALIDATION_EXTENSION_IN_LATVIA_REJECTED:
            component = (
                <EPExtendableTimeline
                    activeStep={activeStep}
                    additionalLabels={[t("patentStatusTimeline:stepLabel5")]}
                    additionalSteps={[t("patentStatusTimeline:lastStep5")]}
                    lastStep={t("patentStatusTimeline:step5")}
                    showLogo
                    isEnd
                />
            );
            break;
        case PatentStatus.WITHDRAWN_EP:
            component = (
                <EPExtendableTimeline
                    activeStep={activeStep}
                    additionalLabels={[
                        t("patentStatusTimeline:stepLabel5"),
                        t("patentStatusTimeline:stepLabel2"),
                    ]}
                    additionalSteps={[
                        t("patentStatusTimeline:additionalStep1"),
                        t("patentStatusTimeline:lastStep6"),
                    ]}
                    lastStep={t("patentStatusTimeline:step5")}
                    isEnd
                    showLogo
                />
            );
            break;
        case PatentStatus.LAPSE_OF_EP_AFTER_OPPOSITION:
            component = (
                <EPExtendableTimeline
                    activeStep={activeStep}
                    additionalLabels={[
                        t("patentStatusTimeline:stepLabel5"),
                        t("patentStatusTimeline:stepLabel2"),
                    ]}
                    additionalSteps={[
                        t("patentStatusTimeline:additionalStep1"),
                        t("patentStatusTimeline:lastStep7"),
                    ]}
                    lastStep={t("patentStatusTimeline:step5")}
                    isEnd
                    showLogo
                />
            );
            break;
        case PatentStatus.LAPSE_OF_EP_LV_AFTER_OPPOSITION:
            component = (
                <EPExtendableTimeline
                    activeStep={activeStep}
                    additionalLabels={[
                        t("patentStatusTimeline:stepLabel5"),
                        t("patentStatusTimeline:stepLabel2"),
                    ]}
                    additionalSteps={[
                        t("patentStatusTimeline:additionalStep1"),
                        t("patentStatusTimeline:lastStep7"),
                    ]}
                    lastStep={t("patentStatusTimeline:step5")}
                    isEnd
                    showLogo
                />
            );
            break;
        case PatentStatus.REJECTED_PUBLISHED_PATENT_APPLICATION:
            component = (
                <PatentExtendableTimeline
                    activeStep={activeStep}
                    additionalSteps={[
                        t("patentStatusTimeline:step3"),
                        t("patentStatusTimeline:lastStep8"),
                    ]}
                    additionalLabels={[
                        t("patentStatusTimeline:stepLabel2"),
                        t("patentStatusTimeline:stepLabel3"),
                    ]}
                    lastStep={t("patentStatusTimeline:step2")}
                    isEnd
                />
            );
            break;
        case PatentStatus.PATENT_APPLICATION_UNDER_APPEAL:
            component = (
                <PatentExtendableTimeline
                    activeStep={activeStep}
                    additionalSteps={[
                        t("patentStatusTimeline:step3"),
                        t("patentStatusTimeline:lastStep9"),
                    ]}
                    additionalLabels={[
                        t("patentStatusTimeline:stepLabel2"),
                        t("patentStatusTimeline:stepLabel2"),
                        t("patentStatusTimeline:stepLabel3"),
                        t("patentStatusTimeline:stepLabel2"),
                    ]}
                    lastStep={t("patentStatusTimeline:step2")}
                    lastStepLabel={t("patentStatusTimeline:lastStepLabel3")}
                />
            );
            break;
        case PatentStatus.WITHDRAWN_PATENT:
            component = (
                <PatentDefaultTimeline
                    activeStep={activeStep}
                    lastStep={t("patentStatusTimeline:lastStep10")}
                    additionalLabels={[t("patentStatusTimeline:stepLabel2")]}
                    isEnd
                />
            );
            break;
        case PatentStatus.EARLY_PATENT_LAPSE:
            component = (
                <PatentDefaultTimeline
                    activeStep={activeStep}
                    lastStep={t("patentStatusTimeline:lastStep3")}
                    additionalLabels={[t("patentStatusTimeline:stepLabel2")]}
                    isEnd
                />
            );
            break;
        case PatentStatus.EXPIRED_PATENT:
            component = (
                <PatentDefaultTimeline
                    activeStep={activeStep}
                    lastStep={t("patentStatusTimeline:lastStep4")}
                    additionalLabels={[t("patentStatusTimeline:stepLabel2")]}
                    isEnd
                />
            );
            break;
        case PatentStatus.UNITARY_EP:
            component = null;
            break;
        default: // 3
            component = (
                <PatentDefaultTimeline activeStep={activeStep} showLastLabel />
            );
            break;
    }

    return component;
}
