import { Link } from "@material-ui/core";

export interface LinkCellContentProps {
    url: string;
    label: string;
}

export default function LinkCellContent(props: LinkCellContentProps) {
    const { url, label } = props;

    return (
        <Link target="_blank" href={url} >
            {label}
        </Link>
    );
}
