import { FilterOperandType } from "../../models/FilterModel";

export const formatFilterValue = (
    field: string,
    value: any,
    usePreciseSearch: boolean
) => {
    // TODO: Improve this.
    console.log("field:", field);

    switch (field) {
        // Date intervals.
        case "applicationDate":
        case "ReceptionDate":
        case "OfficialPublicationDate":
        case "OppositionPeriodStart":
        case "OppositionPeriodEnd":
            return {
                Field: field,
                Operator: FilterOperandType.Between, // TODO: Precise location.
                Value1: value?.startDate
                    ? formatDate(value.startDate)
                    : value?.endDate
                    ? formatDate(value.endDate)
                    : "", // TODO: Add Date parse validation.
                Value2: value?.endDate
                    ? formatDate(value.endDate)
                    : value?.startDate
                    ? formatDate(value.startDate)
                    : "",
            };
        // Enums.
        case "statusId":
        case "markCharacter":
        case "tradeMarkTypeId":
            return {
                Field: field,
                Operator: FilterOperandType.Equal, // Does not work with FilterOperandType.Contains.
                Value1: value?.value ? value.value : "",
                Value2: null,
            };
        case "niceCodes%NiceCode":
            return {
                Field: field.replace("%", "."),
                Operator: FilterOperandType.Equal,
                Value1: value,
                Value2: null,
            };
        default:
            return {
                Field: field.replace("%", "."),
                Operator: usePreciseSearch
                    ? FilterOperandType.Equal
                    : FilterOperandType.Contains,
                Value1: value.trim(),
                Value2: null,
            };
    }
};

const formatDate = (date: Date) => {
    return date.toDateString();
};
