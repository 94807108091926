import {
    AppBar,
    Box,
    Container,
    createStyles,
    Hidden,
    Link,
    makeStyles,
    Theme,
    Toolbar,
    useMediaQuery,
} from "@material-ui/core";
import React from "react";
import logo from "../logo_1_lat_0_0.png";
import logoEn from "../logo_1_eng_2_0.png";
import LanguageSwitcher from "./LanguageSwitcher";
import MobileMenu from "./MobileMenu";
import { useTranslation } from "react-i18next";

// Theme-dependent styles.
// createStyles is a helper function for TS compile.
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        brand: {
            borderLeft: "10px solid",
            borderLeftColor: theme.palette.primary.main,
            paddingLeft: "1em",
            paddingRight: "1em",
            width: "240px",
            height: "5em", // Height is relative to parent element.
            position: "relative",
            alignItems: "center",
            display: "flex",
        },
        brandContainer: {
            width: 267, // It should be the same as menu width.
        },
        logo: {
            transform: "translateZ(0) scale(.5)",
            transformOrigin: "center left",
        },
        header: {
            borderTop: "none",
            position: "fixed",
            backgroundColor: theme.palette.secondary.main,
            // Mobile view.
            [theme.breakpoints.down("sm")]: {
                backgroundColor: theme.palette.accent.dark,
                position: "static",
            },
        },
        // Fixes an issue with content being invisible, behind the app bar.
        offset: theme.mixins.toolbar,
        extension: {
            backgroundColor: theme.palette.accent.light,
            padding: "8em",
            position: "absolute",
            width: "100%",
            zIndex: -1,
        },
        switcher: {
            margin: theme.spacing(0, 0, 0, 0.7),
        },
        container: {
            display: "flex",
            alignItems: "center",
            padding: 0,
        },
    })
);

export default function Header() {
    // Applies theme to the style classes by default.
    const classes = useStyles();
    const useMobileView = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("sm")
    );

    const { i18n } = useTranslation();

    const renderBrand = (
        <div className={classes.brandContainer}>
            <Link href="#" className={classes.brand}>
                <img
                    src={i18n.language === "lv-LV" ? logo : logoEn}
                    className={classes.logo}
                    alt="Logo"
                />
            </Link>
        </div>
    );

    // Desktop view.
    const displayDesktop = () => {
        return (
            <Toolbar disableGutters variant="dense">
                <Container className={classes.container}>
                    {renderBrand}
                    <Box className={classes.grow}>
                        <LanguageSwitcher />
                    </Box>
                </Container>
            </Toolbar>
        );
    };

    // Mobile view.
    const displayMobile = () => {
        return (
            <Toolbar disableGutters variant="dense">
                <Container className={classes.container}>
                    {renderBrand}
                    <Box className={classes.grow}>
                        <LanguageSwitcher />
                    </Box>
                    <Box>
                        <MobileMenu />
                    </Box>
                </Container>
            </Toolbar>
        );
    };

    return (
        <Box className={classes.grow}>
            <AppBar variant="outlined" className={classes.header}>
                {useMobileView ? displayMobile() : displayDesktop()}
            </AppBar>
            <Hidden only={["xs", "sm"]}>
                <div className={classes.offset} />
            </Hidden>
        </Box>
    );
}
