import RobustaTLProRegularWoff from './356EBC_27_0.woff';
import RobustaTLProMediumWoff2 from './356EBC_29_0.woff2';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const robustaTLProMediumFont: CSSProperties = {
    fontFamily: 'RobustaTLPro-Medium',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
      url(${RobustaTLProMediumWoff2}) format('woff2'),
      url(${RobustaTLProRegularWoff}) format('woff')
    `,
};

export default robustaTLProMediumFont;