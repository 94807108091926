import {
    GridColDef,
    GridColumnTypesRecord,
    GridValueFormatterParams,
} from "@material-ui/data-grid";
import { GetShortDateString } from "../../global/utils";

export const defaultColumnTypes = (enums?: any): GridColumnTypesRecord => {
    return {
        enumType: {
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value && enums) {
                    return Object.keys(enums).length &&
                        enums[params.field] !== undefined
                        ? enums[params.field][params.value.toString()]
                        : params.value;
                }
                return undefined;
            },
        } as GridColDef,
        dateType: {
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    return GetShortDateString(params.value as string);
                }
                return undefined;
            },
        } as GridColDef,
        array: {
            valueFormatter: (params: GridValueFormatterParams) => {
                if (Array.isArray(params.value)) {
                    return params.value.join(", ");
                }
                return undefined;
            },
        } as GridColDef,
        string: {
            valueFormatter: (params: GridValueFormatterParams) => {
                return params.value;
            },
        } as GridColDef,
    };
};
