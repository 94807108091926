import FormDataGrid from "../../components/FormDataGrid/FormDataGrid";
import { fetchTrademarkData } from "../../services/trademarkService";
import { DefaultColumns } from "../Trademarks/columnDefinitions";
import { formatFilterValue } from "../Trademarks/recordFilter";
import { defaultColumnTypes } from "../Trademarks/columnTypes";
import { TrademarksSearchFields } from "./TrademarksSearchFields";
import { useStore } from "../../storage/StoreProvider";
import { FilterOperandType, FilterValue } from "../../models/FilterModel";

export default function TrademarksGrid() {
    const [state, ,] = useStore();

    function rowIdSelector(rows: any) {
        return rows.map((patent) => {
            return patent.applicationNumber;
        });
    }

    function rowFilterBuilder(value: any) {
        return {
            Field: "applicationNumber",
            Operator: FilterOperandType.Equal,
            Value1: value.applicationNumber,
            Value2: null,
        } as FilterValue;
    }

    return (
        <FormDataGrid
            defaultColumns={DefaultColumns()}
            searchFields={TrademarksSearchFields()}
            onFetchData={fetchTrademarkData}
            filterValueFormatter={formatFilterValue}
            customColumnTypes={defaultColumnTypes(state.trademarkEnums)}
            loadError={state.error}
            rows={state.trademarks ?? []}
            rowIdSelector={rowIdSelector}
            hidePreciseSearch
            initialSort={[
                {
                    field: "applicationDate",
                    sort: "desc",
                },
            ]}
        />
    );
}
