import { DetailedFormTableRow } from "../../components/FormDataGrid/DetailedForm/DetailedFormTable/DetailedFormTable";
import {
    formatEnumValue,
    formatResourcePersonObjectType,
} from "../../components/FormDataGrid/formDataUtils";
import { useStore } from "../../storage/StoreProvider";
import { useTranslation } from "react-i18next";
import { TableCellValue } from "../../components/FormDataGrid/DetailedForm/DetailedFormTable/DetailedFormTableCell/CellContent/TableCellContent";

export function DefaultRows(oppositions: any, printView?: boolean) {
    const [state, ,] = useStore();
    const oppositionEnums = state.trademarkRevocationEnums;
    const { t } = useTranslation();
    return oppositions.map((opposition) => {
        return [
            {
                caption: " ",
                name: t("trademarkRevocationForm:title"),
                value: opposition.title,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkRevocationForm:number"),
                value: opposition.number,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkRevocationForm:owner"),
                value: opposition.persons?.length
                    ? formatResourcePersonObjectType(opposition.persons, [1])
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkRevocationForm:applicant"),
                value: opposition.persons?.length
                    ? formatResourcePersonObjectType(opposition.persons, [2])
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkRevocationForm:result"),
                value:
                    oppositionEnums && opposition.result !== undefined
                        ? formatEnumValue(
                              opposition.result,
                              "result",
                              oppositionEnums
                          )
                        : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkRevocationForm:groundsOfDecision"),
                value: opposition.substantiations?.join(" "),
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkRevocationForm:decision"),
                value: opposition.decision,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkRevocationForm:nica"),
                value: opposition.nica?.length
                    ? opposition.nica.join(",")
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkRevocationForm:firstCourtDecision"),
                value: opposition.firstCourtDecision,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkRevocationForm:secondCourtDecision"),
                value: opposition.secondCourtDecision,
                type: "string",
            } as DetailedFormTableRow,
        ];
    });
}
