import { useTranslation } from "react-i18next";

export function GetMenuData() {
    const { t } = useTranslation();
    return [
        {
            label: t("mainMenu:home"),
            href: "/home",
            level: 0,
        },
        {
            label: t("mainMenu:patents"),
            href: "/patents",
            level: 0,
        },
        {
            label: t("mainMenu:pas"),
            href: "/pas",
            level: 0,
        },
        {
            label: t("mainMenu:appealPatents"),
            href: "/appeal/patents",
            level: 0,
        },
        {
            label: t("mainMenu:trademarks"),
            href: "/trademark",
            level: 0,
        },
        {
            label: t("mainMenu:trademarksInterPartes"),
            level: 0,
        },
        {
            label: t("mainMenu:oppositionTrademarks"),
            href: "/interpartes/trademarks/objections",
            level: 1,
        },
        {
            label: t("mainMenu:invalidationTrademarks"),
            href: "/interpartes/trademarks/invalidations",
            level: 1,
        },
        {
            label: t("mainMenu:revocationTrademarks"),
            href: "/interpartes/trademarks/revocations",
            level: 1,
        },
        {
            label: t("mainMenu:appealTrademarks"),
            href: "/appeal/trademarks",
            level: 0,
        },
        {
            label: t("mainMenu:refusedTrademarks"),
            href: "/refusedtrademark",
            level: 0,
        },
        {
            label: t("mainMenu:designs"),
            href: "/design",
            level: 0,
        },
        {
            label: t("mainMenu:oppositionDesigns"),
            href: "/objections/designs",
            level: 0,
        },
        {
            label: t("mainMenu:appealDesigns"),
            href: "/appeal/designs",
            level: 0,
        },
    ];
}
