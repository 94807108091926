// constants & types
import SearchType from "../../models/grid/SearchType";

import { SearchField } from "../../components/FormDataGrid/SearchForm";
import FieldWithTooltip from "../../components/FieldWithTooltip";
import NiceClassesField from "../../components/NiceClassesField";
import { useTranslation } from "react-i18next";
import { useStore } from "../../storage/StoreProvider";
import { GridStorage } from "../../storage/browserStorage";

export function TrademarksSearchFields(): SearchField[] {
    const [state, ,] = useStore();
    const enums = state.trademarkEnums;
    const { t } = useTranslation();
    const storage = GridStorage.getInstance();
    const filterModel = storage.getFilterModel();

    return [
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="verbalName"
                    label={t("trademarkForm:verbalNameLabel")}
                    tooltipText={t("trademarkForm:verbalNameTooltip")}
                    multiple
                    type={SearchType.string}
                    defaultValue={filterModel && filterModel["verbalName"]}
                    preciseSearch
                />
            ),
            label: t("trademarkForm:verbalNameLabel"),
            name: "verbalName",
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="ApplicantOrOwner"
                    label={t("trademarkForm:applicantOrOwnerLabel")}
                    tooltipText={t("trademarkForm:applicantOrOwnerTooltip")}
                    multiple
                    type={SearchType.string}
                    defaultValue={
                        filterModel && filterModel["ApplicantOrOwner"]
                    }
                />
            ),
            label: t("trademarkForm:applicantOrOwnerLabel"),
            name: "ApplicantOrOwner",
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="registrationNumber"
                    label={t("trademarkForm:registrationNumberLabel")}
                    tooltipText={t("trademarkForm:registrationNumberTooltip")}
                    type={SearchType.string}
                    defaultValue={
                        filterModel && filterModel["registrationNumber"]
                    }
                />
            ),
            label: t("trademarkForm:registrationNumberLabel"),
            name: "registrationNumber",
            showOnExtendedSearch: true,
            defaultStateOnExtendedSearch: true,
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="applicationNumber"
                    label={t("trademarkForm:applicationNumberLabel")}
                    tooltipText={t("trademarkForm:applicationNumberTooltip")}
                    type={SearchType.string}
                    defaultValue={
                        filterModel && filterModel["applicationNumber"]
                    }
                    preciseSearch
                />
            ),
            label: t("trademarkForm:applicationNumberLabel"),
            name: "applicationNumber",
            showOnExtendedSearch: true,
            defaultStateOnExtendedSearch: true,
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="applicationDate"
                    label={t("trademarkForm:applicationDateLabel")}
                    tooltipText={t("trademarkForm:applicationDateTooltip")}
                    type={SearchType.dateRange}
                    defaultValue={filterModel && filterModel["applicationDate"]}
                />
            ),
            label: t("trademarkForm:applicationDateLabel"),
            name: "applicationDate",
            showOnExtendedSearch: true,
            defaultStateOnExtendedSearch: false,
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="ReceptionDate"
                    label={t("trademarkForm:receptionDateLabel")}
                    tooltipText={t("trademarkForm:receptionDateTooltip")}
                    type={SearchType.dateRange}
                    defaultValue={filterModel && filterModel["ReceptionDate"]}
                />
            ),
            label: t("trademarkForm:receptionDateLabel"),
            name: "ReceptionDate",
            showOnExtendedSearch: true,
            defaultStateOnExtendedSearch: false,
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="Representative"
                    label={t("trademarkForm:representativeLabel")}
                    tooltipText={t("trademarkForm:representativeTooltip")}
                    multiple
                    type={SearchType.string}
                    defaultValue={filterModel && filterModel["Representative"]}
                />
            ),
            label: t("trademarkForm:representativeLabel"),
            name: "Representative",
            showOnExtendedSearch: true,
            defaultStateOnExtendedSearch: false,
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="statusId"
                    label={t("trademarkForm:statusLabel")}
                    tooltipText={t("trademarkForm:statusTooltip")}
                    multiple
                    type={SearchType.list}
                    options={
                        enums && enums.statusSearch
                            ? Object.keys(enums.statusSearch).map((key) => ({
                                  label: enums.statusSearch[key],
                                  value: key,
                              }))
                            : []
                    }
                    defaultValue={filterModel && filterModel["statusId"]}
                />
            ),
            label: t("trademarkForm:statusLabel"),
            name: "statusId",
            showOnExtendedSearch: true,
            defaultStateOnExtendedSearch: false,
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <NiceClassesField
                    name="niceCodes%NiceCode"
                    label={t("trademarkForm:niceClassesLabel")}
                    tooltipText={t("trademarkForm:niceClassesTooltip")}
                    defaultValue={filterModel && filterModel["niceClasses"]}
                />
            ),
            label: t("trademarkForm:niceClassesLabel"),
            name: "niceClasses",
            showOnExtendedSearch: true,
            defaultStateOnExtendedSearch: false,
            classificationUrl: t("trademarkForm:niceUrl"),
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="viennaClasses%ViennaClass"
                    label={t("trademarkForm:viennaClassesLabel")}
                    tooltipText={t("trademarkForm:viennaClassesTooltip")}
                    multiple
                    type={SearchType.string}
                    defaultValue={
                        filterModel && filterModel["viennaClasses%ViennaClass"]
                    }
                />
            ),
            label: t("trademarkForm:viennaClassesLabel"),
            name: "viennaClasses%ViennaClass",
            showOnExtendedSearch: true,
            defaultStateOnExtendedSearch: false,
            classificationUrl: t("trademarkForm:viennaUrl"),
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="terms"
                    label={t("trademarkForm:termsLabel")}
                    tooltipText={t("trademarkForm:termsTooltip")}
                    multiple
                    type={SearchType.string}
                    defaultValue={filterModel && filterModel["terms"]}
                />
            ),
            label: t("trademarkForm:termsLabel"),
            name: "terms",
            showOnExtendedSearch: true,
            defaultStateOnExtendedSearch: true,
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="tradeMarkTypeId"
                    label={t("trademarkForm:tradeMarkTypeIdLabel")}
                    tooltipText={t("trademarkForm:tradeMarkTypeIdTooltip")}
                    options={
                        enums && enums.markTypeSearch
                            ? Object.keys(enums.markTypeSearch).map((key) => ({
                                  label: enums.markTypeSearch[key],
                                  value: key,
                              }))
                            : []
                    }
                    type={SearchType.list}
                    defaultValue={filterModel && filterModel["tradeMarkTypeId"]}
                />
            ),
            label: t("trademarkForm:tradeMarkTypeIdLabel"),
            name: "tradeMarkTypeId",
            showOnExtendedSearch: true,
            defaultStateOnExtendedSearch: false,
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="OfficialPublicationDate"
                    label={t("trademarkForm:officialPublicationDateLabel")}
                    tooltipText={t(
                        "trademarkForm:officialPublicationDateTooltip"
                    )}
                    type={SearchType.dateRange}
                    defaultValue={
                        filterModel && filterModel["OfficialPublicationDate"]
                    }
                />
            ),
            label: t("trademarkForm:officialPublicationDateLabel"),
            name: "OfficialPublicationDate",
            showOnExtendedSearch: true,
            defaultStateOnExtendedSearch: false,
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="markCharacter"
                    label={t("trademarkForm:markCharacterLabel")}
                    tooltipText={t("trademarkForm:markCharacterTooltip")}
                    multiple
                    type={SearchType.list}
                    options={
                        enums && enums.markCharacter
                            ? Object.keys(enums.markCharacter).map((key) => ({
                                  label: enums.markCharacter[key],
                                  value: key,
                              }))
                            : []
                    }
                    defaultValue={filterModel && filterModel["markCharacter"]}
                />
            ),
            label: t("trademarkForm:markCharacterLabel"),
            name: "markCharacter",
            showOnExtendedSearch: true,
            defaultStateOnExtendedSearch: false,
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="OppositionPeriodStart"
                    label={t("trademarkForm:oppositionPeriodStartLabel")}
                    tooltipText={t(
                        "trademarkForm:oppositionPeriodStartTooltip"
                    )}
                    type={SearchType.dateRange}
                    defaultValue={
                        filterModel && filterModel["OppositionPeriodStart"]
                    }
                />
            ),
            label: t("trademarkForm:oppositionPeriodStartLabel"),
            name: "OppositionPeriodStart",
            showOnExtendedSearch: true,
            defaultStateOnExtendedSearch: false,
            classificationUrl: "",
        },
        {
            size: 11,
            field: (
                <FieldWithTooltip
                    name="OppositionPeriodEnd"
                    label={t("trademarkForm:oppositionPeriodEndLabel")}
                    tooltipText={t("trademarkForm:oppositionPeriodEndTooltip")}
                    type={SearchType.dateRange}
                    defaultValue={
                        filterModel && filterModel["OppositionPeriodEnd"]
                    }
                />
            ),
            label: t("trademarkForm:oppositionPeriodEndLabel"),
            name: "OppositionPeriodEnd",
            showOnExtendedSearch: true,
            defaultStateOnExtendedSearch: false,
            classificationUrl: "",
        },
    ];
}
