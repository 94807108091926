import React from "react";
import GridBasePrintForm from "../../components/FormDataGrid/GridBasePrintForm";
import { useStore } from "../../storage/StoreProvider";
import { DefaultRows } from "./detailedFormRowDefinitions";
import { fetchPatentData } from "../../services/patentService";
import PatentStatusTimeline from "../../components/FormDataGrid/Timeline/PatentStatusTimeline/PatentStatusTimeline";
import { DefaultFetchDataProps } from "../../services/baseService";

export default function PatentsPrintForm({ match }) {
    const [state] = useStore();

    const rowSets = DefaultRows(state.patents, true);

    const StatusTimelines = React.useMemo(
        () =>
            state.patents?.map((patent, index) => (
                <PatentStatusTimeline
                    key={index}
                    statusId={patent.patentBasicInfo.statusId}
                />
            )),
        [state.patents]
    );

    const dataResolver = async (props: DefaultFetchDataProps) => {
        await fetchPatentData({
            page: props.page,
            rowsPerPage: props.filter?.length,
            sortModel: props.sortModel,
            filter: props.filter,
            action: props.action,
            includeDocuments: true,
            includeEvents: true,
            includeReferencedPatents: true,
        });
    };

    return (
        <GridBasePrintForm
            filterBy="applicationNumber"
            rowsPerPage={rowSets.length}
            dataResolver={dataResolver}
            rowSets={rowSets}
            match={match}
            isDataResolved={state.patents.length === 0}
            timelines={StatusTimelines}
        />
    );
}
