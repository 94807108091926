import React from "react";
import ActionBarTemplate from "./components/templates/ActionBarTemplate";
import * as Trademarks from "./forms/trademarksForm";
import * as Patents from "./forms/patentsForm";
import * as Designs from "./forms/designsForm";
import * as Pas from "./forms/pasForm";
import * as DesignAppeals from "./forms/designAppealsForm";
import * as PatentAppeals from "./forms/patentAppealsForm";
import * as TrademarkAppeals from "./forms/trademarkAppealsForm";
import * as TrademarkOpposition from "./forms/trademarkOppositionsForm";
import * as DesignOpposition from "./forms/designOppositionsForm";
import * as RefusedTrademarks from "./forms/refusedTrademarksForm";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { GridStorage, LocalStorage } from "./storage/browserStorage";
import DefaultTemplate from "./components/templates/DefaultTemplate";
import InfoBlocks from "./components/InfoBlocks";
import * as TrademarkInvalidation from "./forms/trademarkInvalidationsForm";
import * as TrademarkRevocation from "./forms/trademarkRevocationsForm";

export default function GridRoutes() {
    // Get current location path.
    const location = useLocation();
    // Get current page root name (form name).
    const formPath = location.pathname.split("/")[1];
    // Get access to the Grid local storage variables.
    const gs = GridStorage.getInstance();
    // Get access to the global local storage variables.
    const ls = LocalStorage.getInstance();

    // If location (section, e.g. opening /patent from /trademark page) has changed, then update its value in local storage.
    // TODO: Location is more like a global variable and should be stored separately from the grid values.
    // Resolved partially: What if there's a page that doesn't have a grid at all (like a /home page)? Resetting grid values should only be applied to relevant pages.
    // It is better to use query parameters.
    if (ls.getLocation() !== formPath) {
        // Clear previous page grid variables like filter, sort model, page, etc.
        gs.clear();
        ls.clearTimelineState();
        ls.setLocation(formPath);
    }

    // TODO: Replace /home value with global variable value (e.g. enum or configuration).
    return (
        <Switch>
            <Route exact path="/">
                <Redirect to="/home" />
            </Route>
            <Route path="/home">
                <DefaultTemplate>
                    <InfoBlocks />
                </DefaultTemplate>
            </Route>
            <Route exact path="/appeal">
                <Redirect to="/home" />
            </Route>
            <Route exact path="/opposition">
                <Redirect to="/home" />
            </Route>
            <Route
                path="/patents/print"
                render={({ match }) => {
                    return <Patents.PrintForm match={match} />;
                }}
            ></Route>
            <Route
                path="/patents/:id"
                render={({ match }) => {
                    return (
                        <ActionBarTemplate>
                            <Patents.DetailedForm match={match} />
                        </ActionBarTemplate>
                    );
                }}
            ></Route>
            <Route path="/patents">
                <ActionBarTemplate>
                    <Patents.Grid />
                </ActionBarTemplate>
            </Route>
            <Route
                path="/pas/print"
                render={({ match }) => {
                    return <Pas.PrintForm match={match} />;
                }}
            ></Route>
            <Route
                path="/pas/:id"
                render={({ match }) => {
                    return (
                        <ActionBarTemplate>
                            <Pas.DetailedForm match={match} />
                        </ActionBarTemplate>
                    );
                }}
            ></Route>
            <Route path="/pas">
                <ActionBarTemplate>
                    <Pas.Grid />
                </ActionBarTemplate>
            </Route>
            <Route
                path="/appeal/patents/print"
                render={({ match }) => {
                    return <PatentAppeals.PrintForm match={match} />;
                }}
            ></Route>
            <Route path="/appeal/patents">
                <ActionBarTemplate>
                    <PatentAppeals.Grid />
                </ActionBarTemplate>
            </Route>
            <Route
                path="/trademark/print"
                render={({ match }) => {
                    return <Trademarks.PrintForm match={match} />;
                }}
            ></Route>
            <Route
                path="/trademark/:id"
                render={({ match }) => {
                    return (
                        <ActionBarTemplate>
                            <Trademarks.DetailedForm match={match} />
                        </ActionBarTemplate>
                    );
                }}
            ></Route>
            <Route path="/trademark">
                <ActionBarTemplate>
                    <Trademarks.Grid />
                </ActionBarTemplate>
            </Route>
            <Route
                path="/appeal/trademarks/print"
                render={({ match }) => {
                    return <TrademarkAppeals.PrintForm match={match} />;
                }}
            ></Route>
            <Route path={["/appeal/trademarks/:id", "/appeal/trademarks"]}>
                <ActionBarTemplate>
                    <TrademarkAppeals.Grid />
                </ActionBarTemplate>
            </Route>
            <Route
                path="/refusedtrademark/print"
                render={({ match }) => {
                    return <RefusedTrademarks.PrintForm match={match} />;
                }}
            ></Route>
            <Route path={["/refusedtrademark/:id", "/refusedtrademark"]}>
                <ActionBarTemplate>
                    <RefusedTrademarks.Grid />
                </ActionBarTemplate>
            </Route>
            <Route
                path="/design/print"
                render={({ match }) => {
                    return <Designs.PrintForm match={match} />;
                }}
            ></Route>
            <Route
                path="/design/:id"
                render={({ match }) => {
                    return (
                        <ActionBarTemplate>
                            <Designs.DetailedForm match={match} />
                        </ActionBarTemplate>
                    );
                }}
            ></Route>
            <Route path="/design">
                <ActionBarTemplate>
                    <Designs.Grid />
                </ActionBarTemplate>
            </Route>
            <Route
                path="/objections/designs/print"
                render={({ match }) => {
                    return <DesignOpposition.PrintForm match={match} />;
                }}
            ></Route>
            <Route path="/objections/designs">
                <ActionBarTemplate>
                    <DesignOpposition.Grid />
                </ActionBarTemplate>
            </Route>
            <Route
                path="/appeal/designs/print"
                render={({ match }) => {
                    return <DesignAppeals.PrintForm match={match} />;
                }}
            ></Route>
            <Route path="/appeal/designs">
                <ActionBarTemplate>
                    <DesignAppeals.Grid />
                </ActionBarTemplate>
            </Route>
            <Redirect
                from="/objections/trademarks/:id"
                to="/interpartes/trademarks/objections/:id"
            />
            <Route path="/objections/trademarks">
                <Redirect to="/interpartes/trademarks/objections" />;
            </Route>
            <Route
                path="/interpartes/trademarks/objections/print"
                render={({ match }) => {
                    return <TrademarkOpposition.PrintForm match={match} />;
                }}
            ></Route>
            <Route
                path={[
                    "/interpartes/trademarks/objections/:id",
                    "/interpartes/trademarks/objections",
                ]}
            >
                <ActionBarTemplate>
                    <TrademarkOpposition.Grid />
                </ActionBarTemplate>
            </Route>
            <Route
                path="/interpartes/trademarks/invalidations/print"
                render={({ match }) => {
                    return <TrademarkInvalidation.PrintForm match={match} />;
                }}
            ></Route>
            <Route
                path={[
                    "/interpartes/trademarks/invalidations/:id",
                    "/interpartes/trademarks/invalidations",
                ]}
            >
                <ActionBarTemplate>
                    <TrademarkInvalidation.Grid />
                </ActionBarTemplate>
            </Route>
            <Route
                path="/interpartes/trademarks/revocations/print"
                render={({ match }) => {
                    return <TrademarkRevocation.PrintForm match={match} />;
                }}
            ></Route>
            <Route
                path={[
                    "/interpartes/trademarks/revocations/:id",
                    "/interpartes/trademarks/revocations",
                ]}
            >
                <ActionBarTemplate>
                    <TrademarkRevocation.Grid />
                </ActionBarTemplate>
            </Route>
        </Switch>
    );
}
