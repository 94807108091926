import { useLocation } from "react-router-dom";
import { parse } from "qs";
import { DefaultRows } from "./detailedFormRowDefinitions";
import { useStore } from "../../storage/StoreProvider";
import { fetchPasData } from "../../services/pasService";
import React from "react";
import GridBasePrintForm from "../../components/FormDataGrid/GridBasePrintForm";
import { DefaultFetchDataProps } from "../../services/baseService";

export default function PasPrintForm({ match }) {
    const [state] = useStore();

    const rowSets = DefaultRows(state.pases, true);

    const dataResolver = async (props: DefaultFetchDataProps) => {
        await fetchPasData({
            page: props.page,
            rowsPerPage: props.filter?.length,
            sortModel: props.sortModel,
            filter: props.filter,
            action: props.action,
        });
    };

    return (
        <GridBasePrintForm
            filterBy="PasNumber"
            rowsPerPage={rowSets.length}
            dataResolver={dataResolver}
            rowSets={rowSets}
            match={match}
            isDataResolved={state.pases.length === 0}
        />
    );
}
