import { GridColDef, GridColumnTypesRecord, GridValueFormatterParams } from "@material-ui/data-grid";
import { formatEnumValue } from "../../components/FormDataGrid/formDataUtils";
import { GetShortDateString } from "../../global/utils";

declare interface Nica {
    terms: string[];
    number: number;
}

export const defaultColumnTypes = (enums?: any): GridColumnTypesRecord => {
    return {
        "nicaType": {
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    return (params.value as Array<number>).join(', ');
                }
                return undefined;
            }
        } as GridColDef,
        "viennaType": {
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    return params.value ? (params.value as string[]).join(', ') : "";
                }
                return undefined;
            }
        } as GridColDef,
        "dateType": {
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value) {
                    return GetShortDateString(params.value as string)
                }
                return undefined;
            }
        } as GridColDef,
        "enumType": {
            valueFormatter: (params: GridValueFormatterParams) => {
                if (params.value && enums) {
                    return Object.keys(enums).length && enums[params.field] !== undefined ? enums[params.field][params.value.toString()] : params.value;
                }
                return undefined;
            }
        } as GridColDef,
    }
}