import { GridCellParams, GridValueGetterParams } from "@material-ui/data-grid";
import renderCellExpand, {
    ILinkValue,
} from "../../components/FormDataGrid/RenderCellExpand";
import { useTranslation } from "react-i18next";
import { GridStorage } from "../../storage/browserStorage";
import GridColDefExtended from "../GridColDefExtended";
import { useStore } from "../../storage/StoreProvider";
import React from "react";

export function DefaultColumns(): GridColDefExtended[] {
    const { t, i18n } = useTranslation();
    const storage = GridStorage.getInstance();
    const visibleColumns = storage.getColumns();
    const [state, ,] = useStore();
    const fieldInfo = state.refusedTrademarkFieldInfo?.sortable;

    function getEntityUrlLink(
        params: GridValueGetterParams,
        key: string
    ): ILinkValue {
        if (!params.value) return null;
        const urls = params.getValue(params.id, "urls");
        if (!urls && !urls[key]) return null;
        const pageUrl = urls[key];
        const urlLabel = params.value;
        return { url: pageUrl, label: urlLabel } as ILinkValue;
    }

    function getTrademarkLinkValue(
        params: GridValueGetterParams,
        label?: string,
        key?: string,
        url?: string
    ): ILinkValue {
        if (!params.value) return null;
        const pageId = (
            key ? (params.getValue(params.id, key) as ILinkValue)?.label : params.value
        ).toString();
        const urlLabel = label || pageId;

        const externalLink = getExternalLink(pageId, urlLabel);
        if (externalLink) return externalLink;

        const pageUrl = `${url || "trademark"}/${pageId}`;
        return { url: pageUrl, label: urlLabel } as ILinkValue;
    }

    function getExternalLink(pageId: string, title?: string): ILinkValue {
        // TODO: Dirty fix. This will break if registrationNumber format will change.
        if (pageId?.startsWith("WO")) {
            const idParts = pageId.split(" ");
            const id = idParts.slice(1, idParts.length).join("");
            return {
                url: `https://www3.wipo.int/madrid/monitor/en/showData.jsp?ID=ROM.${id}`,
                label: title ?? pageId,
            } as ILinkValue;
        } else if (pageId?.startsWith("EUTM")) {
            const idParts = pageId.split(" ");
            const id = idParts.slice(1, idParts.length).join("");
            return {
                url: `https://euipo.europa.eu/eSearch/#details/trademarks/${id}`,
                label: title ?? pageId,
            } as ILinkValue;
        }
    }

    return React.useMemo(
        () => [
            {
                field: "title",
                headerName: t("refusedTrademarkForm:title"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 350,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("title")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("title") ?? false
                    : false,
            },
            {
                field: "number",
                headerName: t("refusedTrademarkForm:number"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, true, false, true),
                valueGetter: (params) =>
                    getTrademarkLinkValue(params, null, null, "/trademark"),
                headerAlign: "center",
                width: 170,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("number")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("number") ?? false
                    : false,
            },
            {
                field: "notes",
                headerName: t("refusedTrademarkForm:notes"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 150,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("notes")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("notes") ?? false
                    : false,
            },
            {
                field: "nica", // Create enum class
                headerName: t("refusedTrademarkForm:nica"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 170,
                type: "nicaType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("nica")
                    : false,
                hideByDefault: false,
                sortable: false,
            },
            {
                field: "vienna",
                headerName: t("refusedTrademarkForm:vienna"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 170,
                type: "viennaType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("vienna")
                    : false,
                hideByDefault: false,
                sortable: false,
            },
            {
                field: "substantiations",
                headerName: t("refusedTrademarkForm:substantiations"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 300,
                type: "array",
                hide: visibleColumns
                    ? !visibleColumns?.includes("substantiations")
                    : false,
                hideByDefault: false,
                sortable: false,
            },
            {
                field: "decision",
                headerName: t("refusedTrademarkForm:decision"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, true, false, true),
                valueGetter: (params) =>
                    getEntityUrlLink(params, "DecisionFile"),
                headerAlign: "center",
                width: 300,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("decision")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("decision") ?? false
                    : false,
            },
            {
                field: "firstCourtDecision",
                headerName: t("refusedTrademarkForm:firstCourtDecision"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 400,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("firstCourtDecision")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("firstCourtDecision") ?? false
                    : false,
            },
            {
                field: "secondCourtDecision",
                headerName: t("refusedTrademarkForm:secondCourtDecision"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 400,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("secondCourtDecision")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("secondCourtDecision") ?? false
                    : false,
            },
        ],
        [visibleColumns, fieldInfo, i18n.language]
    );
}
