import FormDataGrid from "../../components/FormDataGrid/FormDataGrid";
import { DefaultColumns } from "./columnDefinitions";
import { defaultColumnTypes } from "./columnTypes";
import { formatFilterValue } from "./recordFilter";
import { TrademarkAppealsSearchFields } from "./TrademarkAppealsSearchFields";
import { useStore } from "../../storage/StoreProvider";
import { fetchTrademarkAppealData } from "../../services/trademarkAppealService";
import { FilterOperandType, FilterValue } from "../../models/FilterModel";
import { useParams } from "react-router-dom";
import { useMemo } from "react";

export interface TrademarkAppealGridParams {
    id?: string;
}

export default function TrademarkAppealGrid() {
    const [state, , getState] = useStore();
    const { id } = useParams<TrademarkAppealGridParams>();

    function rowIdSelector(rows: any) {
        return rows.map((trademarkAppeal) => {
            return trademarkAppeal.number;
        });
    }

    function rowFilterBuilder(value: any) {
        return {
            Field: "number",
            Operator: FilterOperandType.Equal,
            Value1: value?.number ?? value,
            Value2: null,
        } as FilterValue;
    }

    const initialFilter = useMemo(() => {
        return [rowFilterBuilder(id)];
    }, [id]);

    const initialValues = useMemo(() => {
        return id ? { Number: id } : null;
    }, [id]);

    return (
        <FormDataGrid
            defaultColumns={DefaultColumns()}
            searchFields={TrademarkAppealsSearchFields(initialValues)}
            onFetchData={fetchTrademarkAppealData}
            filterValueFormatter={formatFilterValue}
            customColumnTypes={defaultColumnTypes(state.trademarkAppealEnums)}
            loadError={state.error}
            rows={state.trademarkAppeals ?? []}
            rowIdSelector={rowIdSelector}
            initialFilter={id ? initialFilter : null}
            hidePreciseSearch
        />
    );
}
