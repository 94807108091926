// constants & types
import SearchType from "../../models/grid/SearchType";

// utils
import { useTranslation } from "react-i18next";
import { useStore } from "../../storage/StoreProvider";
import { GridStorage } from "../../storage/browserStorage";

import { SearchField } from "../../components/FormDataGrid/SearchForm";
import FieldWithTooltip from "../../components/FieldWithTooltip";

export function TrademarkOppositionsSearchFields(
    initialValues?: any
): SearchField[] {
    const [state, ,] = useStore();
    const enums = state.trademarkOppositionEnums;
    const { t } = useTranslation();
    const storage = GridStorage.getInstance();
    const filterModel = storage.getFilterModel();

    return state.trademarkOppositionSearchMeta.map((fieldMeta) => {
        const field = fieldMeta.entity;
        const fieldName = field.name.replace(".", "%");

        let options = [];

        if (enums && enums[field.enum]) {
            switch (field.type) {
                case SearchType.groupedList:
                    options = enums[field.enum];
                    break;
                default:
                    options = Object.keys(enums[field.enum]).map((key) => ({
                        label: enums[field.enum][key],
                        value: key,
                    }));
            }
        }

        return {
            size: 11,
            field: (
                <FieldWithTooltip
                    name={fieldName}
                    label={field.label}
                    tooltipText={field.tooltip}
                    multiple
                    startDateLimit={15}
                    type={field.type as SearchType}
                    preciseSearch={["Title", "Number"].includes(fieldName)}
                    options={options}
                    defaultValue={
                        initialValues && field.name in initialValues
                            ? [initialValues[field.name]]
                            : filterModel && filterModel[fieldName]
                    }
                />
            ),
            label: field.label,
            name: field.name,
            showOnExtendedSearch: !field.show,
            defaultStateOnExtendedSearch: field.defaultStateOnExtendedSearch,
        };
    });
}
