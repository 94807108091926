import FormDataGrid from "../../components/FormDataGrid/FormDataGrid";
import { DefaultColumns } from "./columnDefinitions";
import { formatFilterValue } from "./recordFilter";
import { defaultColumnTypes } from "./columnTypes";
import { TrademarkRevocationSearchFields } from "./TrademarkRevocationSearchFields";
import { useStore } from "../../storage/StoreProvider";
import { fetchTrademarkRevocationData } from "../../services/trademarkRevocationService";
import { FilterOperandType, FilterValue } from "../../models/FilterModel";
import { useParams } from "react-router-dom";
import { useMemo } from "react";

export interface TrademarkRevocationsGridParams {
    id?: string;
}

export default function TrademarkRevocationsGrid() {
    const [state, ,] = useStore();
    const { id } = useParams<TrademarkRevocationsGridParams>();

    function rowIdSelector(rows: any) {
        return rows.map((tOpposition) => {
            return tOpposition.number;
        });
    }

    function rowFilterBuilder(value: any) {
        return {
            Field: "number",
            Operator: FilterOperandType.Equal,
            Value1: value?.number ?? value,
            Value2: null,
        } as FilterValue;
    }

    const initialFilter = useMemo(() => {
        return [rowFilterBuilder(id)];
    }, [id]);

    const initialValues = useMemo(() => {
        return id ? { Number: id } : null;
    }, [id]);

    return (
        <FormDataGrid
            defaultColumns={DefaultColumns()}
            searchFields={TrademarkRevocationSearchFields(initialValues)}
            onFetchData={fetchTrademarkRevocationData}
            filterValueFormatter={formatFilterValue}
            customColumnTypes={defaultColumnTypes(
                state.trademarkRevocationEnums
            )}
            loadError={state.error}
            rows={state.trademarkRevocations ?? []}
            rowIdSelector={rowIdSelector}
            initialFilter={id ? initialFilter : null}
            hidePreciseSearch
        />
    );
}
