import { GridCellParams, GridValueGetterParams } from "@material-ui/data-grid";
import GridColDefExtended from "../GridColDefExtended";
import {
    formatSeniorityOrPriorityValue,
    getTrademarkPersonValue,
} from "../../components/FormDataGrid/formDataUtils";
import renderCellExpand, {
    ILinkValue,
} from "../../components/FormDataGrid/RenderCellExpand";
import { useTranslation } from "react-i18next";
import { GridStorage } from "../../storage/browserStorage";
import { useStore } from "../../storage/StoreProvider";
import React from "react";

function getLinkValue(
    params: GridValueGetterParams,
    label?: string,
    key?: string,
    url?: string
): ILinkValue {
    if (!params.value) return null;
    const pageId = (
        key
            ? (params.getValue(params.id, key) as ILinkValue)?.label
            : params.value
    ).toString();
    const pageUrl = `${url || "trademark"}/${pageId}`;
    const urlLabel = label || pageId;
    return { url: pageUrl, label: urlLabel } as ILinkValue;
}

function getInterPartesLinkValue(
    params: GridValueGetterParams,
    label: string,
    key: string,
    url: string
): ILinkValue {
    if (!params.value) return null;
    const pageId = params.row[key];
    if (!pageId) return null;
    const pageUrl = `${url}/${pageId}`;
    const urlLabel = label;
    return { url: pageUrl, label: urlLabel } as ILinkValue;
}

function getSeniorityValue(
    translationService: any,
    params: GridValueGetterParams
): string {
    const claims = params.getValue(params.id, "claims") as any;
    if (!claims || !claims.seniority?.length) return "";

    // Registration type
    const values = formatSeniorityOrPriorityValue(
        claims.seniority.filter((p) => p.type === 1)
    );
    return (
        values
            .map((items) => items?.map((item) => item?.text).join(", "))
            .join(",\n") ?? ""
    );
}

function getImageUrl(params: GridValueGetterParams): string | null {
    const urls = params.getValue(params.id, "urls");
    return urls["Image"];
}

export function DefaultColumns(): GridColDefExtended[] {
    const { t, i18n } = useTranslation();
    const storage = GridStorage.getInstance();
    const visibleColumns = storage.getColumns();
    const [state, ,] = useStore();
    const fieldInfo = state.trademarkFieldInfo?.sortable;

    return React.useMemo(
        () => [
            {
                field: "registrationNumber",
                headerName: t("trademarkForm:registrationNumberLabel"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 150,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("registrationNumber")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("registrationNumber") ?? false
                    : false,
            },
            {
                field: "registrationDate", // Create enum class
                headerName: t("trademarkForm:registrationDate"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 140,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("registrationDate")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("registrationDate") ?? false
                    : false,
            },
            {
                field: "applicationNumber",
                headerName: t("trademarkForm:applicationNumberLabel"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, true),
                valueGetter: getLinkValue,
                headerAlign: "center",
                width: 150,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("applicationNumber")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("applicationNumber") ?? false
                    : false,
            },
            {
                field: "applicationDate", // Create enum class
                headerName: t("trademarkForm:applicationDateLabel"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 130,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("applicationDate")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("applicationDate") ?? false
                    : false,
            },
            {
                field: "receptionDate",
                headerName: t("trademarkForm:receptionDateLabel"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 170,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("receptionDate")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("receptionDate") ?? false
                    : false,
            },
            {
                field: "potentialExpirationDate",
                headerName: t("trademarkForm:potentialExpirationDate"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 170,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("potentialExpirationDate")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("potentialExpirationDate") ?? false
                    : false,
            },
            {
                field: "officialPublicationDate",
                headerName: t("trademarkForm:officialPublicationDateLabel"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 170,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("officialPublicationDate")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("officialPublicationDate") ?? false
                    : false,
            },
            {
                field: "vienna",
                headerName: t("trademarkForm:viennaClassesLabel"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 170,
                type: "viennaType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("vienna")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("vienna") ?? false
                    : false,
            },
            {
                field: "registrationFinishDate",
                headerName: t("trademarkForm:registrationFinishDate"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 180,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("registrationFinishDate")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("registrationFinishDate") ?? false
                    : false,
            },
            {
                field: "registrationEndDate",
                headerName: t("trademarkForm:registrationEndDate"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 170,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("registrationEndDate")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("registrationEndDate") ?? false
                    : false,
            },
            {
                field: "oppositionPeriodStart",
                headerName: t("trademarkForm:oppositionPeriodStartLabel"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 170,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("oppositionPeriodStart")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("oppositionPeriodStart") ?? false
                    : false,
            },
            {
                field: "oppositionPeriodEnd",
                headerName: t("trademarkForm:oppositionPeriodEndLabel"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 170,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("oppositionPeriodEnd")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("oppositionPeriodEnd") ?? false
                    : false,
            },
            {
                field: "image",
                headerName: t("trademarkForm:image"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false, true),
                valueGetter: getImageUrl,
                headerAlign: "center",
                width: 170,
                sortable: false,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("image")
                    : false,
                hideByDefault: false,
            },
            {
                field: "verbalName",
                headerName: t("trademarkForm:verbalNameLabel"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 200,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("verbalName")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("verbalName") ?? false
                    : false,
            },
            {
                field: "isCollective",
                headerName: t("trademarkForm:markCharacterLabel"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 200,
                type: "collectiveType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("isCollective")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("isCollective") ?? false
                    : false,
            },
            {
                field: "markType",
                headerName: t("trademarkForm:tradeMarkTypeIdLabel"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 125,
                type: "enumType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("markType")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("markType") ?? false
                    : false,
            },
            {
                field: "description",
                headerName: t("trademarkForm:description"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 170,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("description")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("description") ?? false
                    : false,
            },
            {
                field: "disclamation",
                headerName: t("trademarkForm:disclamation"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 300,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("disclamation")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("disclamation") ?? false
                    : false,
            },
            {
                field: "colourDescription",
                headerName: t("trademarkForm:colourDescription"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 170,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("colourDescription")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("colourDescription") ?? false
                    : false,
            },
            {
                field: "claims%seniority",
                headerName: t("trademarkForm:claimsSeniority"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                valueGetter: (params) => getSeniorityValue(t, params),
                headerAlign: "center",
                width: 250,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("claims%seniority")
                    : true,
                hideByDefault: true,
                sortable: false,
            },
            {
                field: "applicantOrOwner",
                headerName: t("trademarkForm:applicantOrOwnerLabel"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                valueGetter: getTrademarkPersonValue,
                headerAlign: "center",
                width: 300,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("applicantOrOwner")
                    : true,
                hideByDefault: true,
                sortable: false,
            },
            {
                field: "representative",
                headerName: t("trademarkForm:representativeLabel"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                valueGetter: getTrademarkPersonValue,
                headerAlign: "center",
                width: 300,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("representative")
                    : true,
                hideByDefault: true,
                sortable: false,
            },
            {
                field: "nica",
                headerName: t("trademarkForm:niceClassesLabel"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 170,
                type: "nicaType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("nica")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("nica") ?? false
                    : false,
            },
            {
                field: "ctmNumber",
                headerName: t("trademarkForm:hasCTMSeniority"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, true, false, true),
                valueGetter: (params: GridValueGetterParams) =>
                    getLinkValue(
                        params,
                        null,
                        null,
                        "https://euipo.europa.eu/eSearch/#details/trademarks"
                    ),
                headerAlign: "center",
                width: 250,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("ctmNumber")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("ctmNumber") ?? false
                    : false,
            },
            {
                field: "status",
                headerName: t("trademarkForm:statusLabel"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 300,
                type: "enumType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("status")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("status") ?? false
                    : false,
            },
            {
                field: "licensee",
                headerName: t("trademarkForm:licensee"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                valueGetter: getTrademarkPersonValue,
                headerAlign: "center",
                width: 300,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("licensee")
                    : true,
                hideByDefault: true,
                sortable: false,
            },
            {
                field: "distraints",
                headerName: t("trademarkForm:distraints"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 300,
                type: "distraintType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("distraints")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("distraints") ?? false
                    : false,
            },
            {
                field: "opposition",
                headerName: t("trademarkForm:opposition"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, true),
                valueGetter: (params: GridValueGetterParams) =>
                    getInterPartesLinkValue(
                        params,
                        t("trademarkForm:view"),
                        "registrationNumber",
                        "/interpartes/trademarks/objections"
                    ),
                headerAlign: "center",
                width: 130,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("opposition")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("opposition") ?? false
                    : false,
            },
            {
                field: "revocation",
                headerName: t("trademarkForm:revocation"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, true),
                valueGetter: (params: GridValueGetterParams) =>
                    getInterPartesLinkValue(
                        params,
                        t("trademarkForm:view"),
                        "registrationNumber",
                        "/interpartes/trademarks/revocations"
                    ),
                headerAlign: "center",
                width: 130,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("revocation")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("revocation") ?? false
                    : false,
            },
            {
                field: "invalidation",
                headerName: t("trademarkForm:invalidation"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, true),
                valueGetter: (params: GridValueGetterParams) =>
                    getInterPartesLinkValue(
                        params,
                        t("trademarkForm:view"),
                        "registrationNumber",
                        "/interpartes/trademarks/invalidations"
                    ),
                headerAlign: "center",
                width: 130,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("invalidation")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("invalidation") ?? false
                    : false,
            },
            {
                field: "appeal",
                headerName: t("trademarkForm:appeal"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, true),
                valueGetter: (params: GridValueGetterParams) =>
                    getLinkValue(
                        params,
                        t("trademarkForm:view"),
                        "applicationNumber",
                        "/appeal/trademarks"
                    ),
                headerAlign: "center",
                width: 130,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("appeal")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("appeal") ?? false
                    : false,
            },
            {
                field: "refusal",
                headerName: t("trademarkForm:refusal"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, true),
                valueGetter: (params: GridValueGetterParams) =>
                    getLinkValue(
                        params,
                        t("trademarkForm:view"),
                        "applicationNumber",
                        "/refusedtrademark"
                    ),
                headerAlign: "center",
                width: 130,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("refusal")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("refusal") ?? false
                    : false,
            },
        ],
        [visibleColumns, fieldInfo, i18n.language]
    );
}
