import RobustaTLProBoldWoff2 from './356EBC_2B_0.woff2';
import RobustaTLProBoldWoff from './356EBC_2B_0.woff';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const robustaTLProBoldFont: CSSProperties = {
    fontFamily: 'RobustaTLPro-Bold',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
      url(${RobustaTLProBoldWoff2}) format('woff2'),
      url(${RobustaTLProBoldWoff}) format('woff')
    `,
};

export default robustaTLProBoldFont;