import React, { useEffect } from "react";
import DetailedForm from "./DetailedForm/DetailedForm";
import { ACTIONS } from "../../storage/dataReducer";
import { FilterOperandType, FilterValue } from "../../models/FilterModel";
import { DetailedFormTableRow } from "./DetailedForm/DetailedFormTable/DetailedFormTable";
import { DefaultFetchDataProps } from "../../services/baseService";
import { useLocation } from "react-router-dom";
import { parse } from "qs";

export interface PrintDetailedForm {
    filterBy: string;
    rowSets: DetailedFormTableRow[][];
    timelines?: JSX.Element[];
    rowsPerPage: number;
    dataResolver: (props: DefaultFetchDataProps) => Promise<any>;
    isDataResolved: boolean;
    match: any;
}

function useQuery() {
    return parse(useLocation().search);
}

export default function GridBasePrintForm(props: PrintDetailedForm) {
    const {
        rowSets,
        timelines,
        filterBy,
        rowsPerPage,
        dataResolver,
        isDataResolved,
        match,
    } = props;
    const query = useQuery();

    const filterValues = React.useMemo(() => {
        let ids = query.id;
        const qids = query["?id"];

        if (qids?.length > 0 && ids) {
            // @ts-ignore
            ids.unshift(qids[0]);
        } else if (qids?.length > 0) {
            ids = qids;
        }

        return ids;
    }, []);

    const filter = React.useMemo(() => {
        // @ts-ignore
        return filterValues.map((value) => {
            return {
                Field: filterBy,
                Operator: FilterOperandType.Equal,
                Value1: value,
                Value2: null,
            } as FilterValue;
        });
    }, []);

    useEffect(() => {
        async function GetData() {
            await dataResolver({
                page: 0,
                rowsPerPage: rowsPerPage,
                sortModel: null,
                filter: filter,
                action: ACTIONS.GET_ROWS,
            });
        }

        if (isDataResolved) {
            GetData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    useEffect(() => {
        if (rowSets?.length > 0) {
            setTimeout(() => {
                window.print();
                window.close();
            }, 1000);
        }
    }, [rowSets]);

    return (
        <React.Fragment>
            {rowSets.map((rowSet, index) => {
                return (
                    <DetailedForm
                        key={index}
                        // @ts-ignore
                        ids={filterValues}
                        rows={rowSet}
                        match={match}
                        printView
                        showTimeline={timelines?.length > 0}
                        // TODO: Check that timeline count matches rowSet count.
                        timeline={
                            timelines?.length > 0 ? timelines[index] : null
                        }
                    />
                );
            })}
        </React.Fragment>
    );
}
