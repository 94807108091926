import * as React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import {
    GridCellParams,
    GridCellValue,
    GridRowId,
} from "@material-ui/data-grid";
import { Box, Button } from "@material-ui/core";
import Image from "material-ui-image";
import { Link } from "@material-ui/core";

interface GridCellExpandProps {
    value: GridCellValue;
    getValue: (id: GridRowId, field: string) => GridCellValue;
    width: number;
    isLink: boolean;
    isImage: boolean;
    openInNewTab?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            alignItems: "center",
            lineHeight: "24px",
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
            "& .cellValue": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
        },
    })
);

export interface ILinkValue {
    url?: string;
    label: string;
}

export function isOverflown(element: Element): boolean {
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
}

const GridCellExpand = React.memo(function GridCellExpand(
    props: GridCellExpandProps
) {
    const { width, value, isLink, isImage, openInNewTab } = props;
    const wrapper = React.useRef<HTMLDivElement | null>(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const classes = useStyles();
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);

    const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue?.current!);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv?.current);
        setShowFullCell(true);
    };

    const handleMouseLeave = () => {
        setShowFullCell(false);
    };

    React.useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }

        function handleKeyDown(nativeEvent: KeyboardEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
                setShowFullCell(false);
            }
        }

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);

    const NewTabLink = (value: ILinkValue) => {
        return (
            <Link target="_blank" href={value.url} style={{ display: "block" }}>
                {value.label}
            </Link>
        );
    };

    return (
        <div
            ref={wrapper}
            className={classes.root}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                ref={cellDiv}
                style={{
                    height: 1,
                    width,
                    display: "block",
                    position: "absolute",
                    top: 0,
                }}
            />
            {isLink && value && !openInNewTab && (
                <div ref={cellValue}>
                    {Array.isArray(value) ? (
                        value.map((item, index) => (
                            <Button
                                color="primary"
                                key={index}
                                component={RouterLink}
                                to={(item as ILinkValue).url}
                                ref={cellValue}
                                className="cellValue"
                            >
                                {(item as ILinkValue).label}
                            </Button>
                        ))
                    ) : (
                        <Button
                            color="primary"
                            component={RouterLink}
                            to={(value as ILinkValue).url}
                            ref={cellValue}
                            className="cellValue"
                        >
                            {(value as ILinkValue).label}
                        </Button>
                    )}
                </div>
            )}
            {isLink && value && openInNewTab && (
                <div ref={cellValue} style={{ maxHeight: "100%" }}>
                    {Array.isArray(value) ? (
                        value.map((item, index) =>
                            (item as ILinkValue).url ? (
                                <NewTabLink
                                    key={index}
                                    label={(item as ILinkValue).label}
                                    url={(item as ILinkValue).url}
                                />
                            ) : (
                                <div className="cellValue">
                                    {(item as ILinkValue).label}
                                </div>
                            )
                        )
                    ) : (value as ILinkValue).url ? (
                        <NewTabLink
                            label={(value as ILinkValue).label}
                            url={(value as ILinkValue).url}
                        />
                    ) : (
                        <div className="cellValue">
                            {(value as ILinkValue).label}
                        </div>
                    )}
                </div>
            )}
            {isImage && value && (
                <div ref={cellValue}>
                    {(value &&
                        (Array.isArray(value) ? (
                            <Box display="flex">
                                {(value as string[]).map((url) => {
                                    return (
                                        <Box width={51}>
                                            <Image
                                                src={url}
                                                style={{ objectFit: "contain" }}
                                            />
                                        </Box>
                                    );
                                })}
                            </Box>
                        ) : (
                            <Box width={51}>
                                <Image
                                    src={value as string}
                                    style={{ objectFit: "contain" }}
                                />
                            </Box>
                        ))) || <div></div>}
                </div>
            )}
            {((!isLink && !isImage) || !value) && (
                <div ref={cellValue} className="cellValue">
                    {value}
                </div>
            )}
            {!isLink && !isImage && showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width, marginLeft: -17 }}
                >
                    <Paper
                        elevation={1}
                        style={{ minHeight: wrapper.current!.offsetHeight - 3 }}
                    >
                        <Typography
                            variant="body2"
                            style={{ padding: 8, whiteSpace: "pre-line" }}
                        >
                            {value}
                        </Typography>
                    </Paper>
                </Popper>
            )}
            {isLink && openInNewTab && showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width, marginLeft: -17 }}
                >
                    <Paper
                        elevation={1}
                        style={{ minHeight: wrapper.current!.offsetHeight - 3 }}
                    >
                        <div style={{ padding: 8, whiteSpace: "pre-line" }}>
                            {Array.isArray(value) ? (
                                value.map((item, index) =>
                                    (item as ILinkValue).url ? (
                                        <NewTabLink
                                            key={index}
                                            label={(item as ILinkValue).label}
                                            url={(item as ILinkValue).url}
                                        />
                                    ) : (
                                        <div className="cellValue">
                                            {(item as ILinkValue).label}
                                        </div>
                                    )
                                )
                            ) : (value as ILinkValue).url ? (
                                <NewTabLink
                                    label={(value as ILinkValue).label}
                                    url={(value as ILinkValue).url}
                                />
                            ) : (
                                <div className="cellValue">
                                    {(value as ILinkValue).label}
                                </div>
                            )}
                        </div>
                    </Paper>
                </Popper>
            )}
        </div>
    );
});

export default function renderCellExpand(
    params: GridCellParams,
    isLink: boolean,
    isImage: boolean = false,
    openInNewTab: boolean = false
) {
    let value = null;
    if (params.value !== null && params.value !== undefined) {
        if (isLink || isImage) {
            value = params.value;
        } else if (
            params.formattedValue !== null &&
            params.formattedValue !== undefined
        ) {
            value = params.formattedValue.toString();
        }
    }
    return (
        <GridCellExpand
            value={value ?? ""}
            width={params.colDef.width}
            getValue={params.getValue}
            isLink={isLink}
            isImage={isImage}
            openInNewTab={openInNewTab}
        />
    );
}
