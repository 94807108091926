import { DetailedFormTableRow } from "../../components/FormDataGrid/DetailedForm/DetailedFormTable/DetailedFormTable";
import {
    formatEnumValue,
    formatSeniorityOrPriorityValue,
    formatResourcePersonObjectType,
    formatPersonObjectType,
} from "../../components/FormDataGrid/formDataUtils";
import { GetShortDateString } from "../../global/utils";
import { INIDcodes } from "../../global/constants";
import { TableCellValue } from "../../components/FormDataGrid/DetailedForm/DetailedFormTable/DetailedFormTableCell/CellContent/TableCellContent";
import { useStore } from "../../storage/StoreProvider";
import { useTranslation } from "react-i18next";

export function DefaultRows(trademarks: any, printView?: boolean) {
    const [state, ,] = useStore();
    const trademarkEnums = state.trademarkEnums;
    const { t } = useTranslation();

    function GetSeniorityOrPriority(items: any) {
        if (!items.length) return undefined;
        return {
            headers: [
                t("common:number"),
                t("common:date"),
                t("common:country"),
            ],
            values: formatSeniorityOrPriorityValue(items),
        };
    }

    return trademarks.map((trademark) => {
        return [
            {
                caption: " ",
                name: t("trademarkForm:statusLabel"),
                value:
                    trademarkEnums && trademark.status !== undefined
                        ? formatEnumValue(
                              trademark.status,
                              "status",
                              trademarkEnums
                          )
                        : undefined,
                field: "",
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 111,
                name: t("trademarkForm:registrationNumberLabel"),
                value: trademark.registrationNumber,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 151,
                name: t("trademarkForm:registrationDate"),
                value: trademark.registrationDate
                    ? GetShortDateString(trademark.registrationDate)
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 210,
                name: t("trademarkForm:applicationNumberLabel"),
                value: trademark.applicationNumber,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 220,
                name: t("trademarkForm:applicationDateLabel"),
                value: trademark.applicationDate
                    ? GetShortDateString(trademark.applicationDate)
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkForm:receptionDateLabel"),
                value: trademark.receptionDate
                    ? GetShortDateString(trademark.receptionDate)
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 641,
                name: t("trademarkForm:divisionApplication"),
                value: trademark.division?.applicationNumber
                    ? `${trademark.division.applicationNumber} ${
                          trademark.division.applicationDate
                              ? GetShortDateString(
                                    trademark.division.applicationDate
                                )
                              : ""
                      }`
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 646,
                name: t("trademarkForm:divisionRegistration"),
                value: trademark.division?.registrationNumber
                    ? `${trademark.division.registrationNumber} ${
                          trademark.division.registrationNumber
                              ? GetShortDateString(
                                    trademark.division?.registrationDate
                                )
                              : ""
                      }`
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 181,
                name: t("trademarkForm:potentialExpirationDate"),
                value: trademark.potentialExpirationDate
                    ? GetShortDateString(trademark.potentialExpirationDate)
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 450,
                name: t("trademarkForm:officialPublicationDateLabel"),
                value: trademark.officialPublicationDate
                    ? GetShortDateString(trademark.officialPublicationDate)
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 531,
                name: t("trademarkForm:viennaClassesLabel"),
                value: trademark.vienna?.join("; "),
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 451,
                name: t("trademarkForm:registrationFinishDate"),
                value: trademark.registrationFinishDate
                    ? GetShortDateString(trademark.registrationFinishDate)
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkForm:registrationEndDate"),
                value: trademark.registrationEndDate
                    ? GetShortDateString(trademark.registrationEndDate)
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkForm:oppositionPeriod"),
                value:
                    trademark.oppositionPeriodStart ||
                    trademark.oppositionPeriodEnd
                        ? {
                              headers: [
                                  t("trademarkForm:oppositionPeriodStartLabel"),
                                  t("trademarkForm:oppositionPeriodEndLabel"),
                              ],
                              values: [
                                  [
                                      {
                                          text: trademark.oppositionPeriodStart
                                              ? GetShortDateString(
                                                    trademark.oppositionPeriodStart
                                                )
                                              : "",
                                      } as TableCellValue,
                                      {
                                          text: trademark.oppositionPeriodEnd
                                              ? GetShortDateString(
                                                    trademark.oppositionPeriodEnd
                                                )
                                              : "",
                                      } as TableCellValue,
                                  ],
                              ],
                          }
                        : undefined,
                type: "table",
            } as DetailedFormTableRow,
            {
                caption: 540,
                name: t("trademarkForm:trademarkReproduction"),
                value:
                    trademark.urls && trademark.urls["Image"]
                        ? trademark.urls["Image"]
                        : undefined,
                type: "image",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkForm:verbalNameLabel"),
                value: trademark.verbalName,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 551,
                name: t("trademarkForm:markCharacterLabel"),
                value:
                    trademarkEnums && trademark.isCollective !== undefined
                        ? formatEnumValue(
                              trademark.isCollective,
                              "markCharacter",
                              trademarkEnums
                          )
                        : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 550,
                name: t("trademarkForm:tradeMarkTypeIdLabel"),
                value:
                    trademarkEnums && trademark.markType !== undefined
                        ? formatEnumValue(
                              trademark.markType,
                              "markType",
                              trademarkEnums
                          )
                        : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption:
                    trademark.urls && trademark.urls["MediaFile"]
                        ? INIDcodes[trademark.markType]
                        : undefined,
                name: t("trademarkForm:markType"),
                value:
                    trademark.urls && trademark.urls["MediaFile"]
                        ? trademark.urls["MediaFile"]
                        : undefined,
                type: "media",
            } as DetailedFormTableRow,
            {
                caption: 571,
                name: t("trademarkForm:description"),
                value: trademark.description,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 526,
                name: t("trademarkForm:disclamation"),
                value: trademark.disclamation,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 591,
                name: t("trademarkForm:colourDescription"),
                value: trademark.colourDescription,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 230,
                name: t("trademarkForm:exhibitionPriority"),
                value:
                    trademark.claims?.exhibitionPriority &&
                    trademark.claims.exhibitionPriority.length
                        ? {
                              headers: [t("common:name"), t("common:date")],
                              values: trademark.claims?.exhibitionPriority.map(
                                  (exhibitionPriority) => {
                                      return [
                                          {
                                              text: exhibitionPriority.name,
                                          } as TableCellValue,
                                          {
                                              text: exhibitionPriority.date
                                                  ? GetShortDateString(
                                                        exhibitionPriority.date
                                                    )
                                                  : "",
                                          } as TableCellValue,
                                      ];
                                  }
                              ),
                          }
                        : undefined,
                type: "table",
            } as DetailedFormTableRow,
            {
                caption: 880,
                name: t("trademarkForm:claims"),
                value: trademark.claims?.irTransformation
                    ? `${
                          trademark.claims?.irTransformation?.number
                      } ${GetShortDateString(
                          trademark.claims?.irTransformation?.date
                      )}`
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 300,
                name: t("trademarkForm:claimsPriority"),
                value:
                    trademark.claims?.priority &&
                    trademark.claims.priority.length
                        ? {
                              headers: [
                                  t("common:number"),
                                  t("common:date"),
                                  t("common:country"),
                              ],
                              values: formatSeniorityOrPriorityValue(
                                  trademark.claims.priority
                              ),
                          }
                        : undefined,
                type: "table",
            } as DetailedFormTableRow,
            {
                caption: 399,
                name: t("trademarkForm:claimsSeniority"),
                value: trademark.claims?.seniority?.length
                    ? // Registration type.
                      GetSeniorityOrPriority(
                          trademark.claims.seniority.filter((p) => p.type === 1)
                      )
                    : undefined,
                type: "table",
            } as DetailedFormTableRow,
            {
                caption: 600,
                name: trademark.claims?.ctmConversion
                    ? trademark.claims.ctmConversion?.partial
                        ? t("trademarkForm:ctmPartialConversion", {
                              number: trademark.claims.ctmConversion?.number,
                          })
                        : t("trademarkForm:ctmConversion", {
                              number: trademark.claims.ctmConversion?.number,
                          })
                    : "",
                value: trademark.claims?.ctmConversion ? "" : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 600,
                name: t("trademarkForm:claimsSeniority"),
                value: trademark.claims?.seniority?.length
                    ? // Application type.
                      GetSeniorityOrPriority(
                          trademark.claims.seniority.filter((p) => p.type === 0)
                      )
                    : undefined,
                type: "table",
            } as DetailedFormTableRow,
            {
                caption: 730,
                name: t("trademarkForm:applicantOrOwnerLabel"),
                // TODO: Optimize this.
                value: trademark.persons?.length
                    ? formatPersonObjectType(trademark.persons, [0])
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 740,
                name: t("trademarkForm:representativeLabel"),
                value: trademark.persons?.length
                    ? formatPersonObjectType(trademark.persons, [1])
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: 511,
                name: t("trademarkForm:niceClassesLabel"),
                value: trademark.nica?.map((item) => {
                    console.log("item:", item);

                    return {
                        label: item?.number,
                        value: item?.term?.join("; "),
                    };
                }),
                field: "",
                type: "grid",
            } as DetailedFormTableRow,
            {
                caption: 350,
                name: t("trademarkForm:hasCTMSeniority"),
                value: trademark.ctmNumber && {
                    label: trademark.ctmNumber,
                    url: `https://euipo.europa.eu/eSearch/#details/trademarks/${trademark.ctmNumber}`,
                },
                field: "",
                type: "link",
            } as DetailedFormTableRow,
            // TODO: Add.
            {
                caption: 791,
                name: t("trademarkForm:licensee"),
                value: trademark.persons?.length
                    ? formatResourcePersonObjectType(trademark.persons, [2])
                    : undefined,
                field: "",
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkForm:distraints"),
                value:
                    trademarkEnums && trademark.distraints?.length
                        ? trademark.distraints
                              .map((value, index) =>
                                  formatEnumValue(
                                      value.type,
                                      "distraintType",
                                      trademarkEnums
                                  )
                              )
                              .join(", ")
                        : undefined,
                field: "",
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkForm:opposition"),
                value: trademark.opposition
                    ? {
                          label: t("trademarkForm:view"),
                          url: `/interpartes/trademarks/objections/${trademark.registrationNumber}`,
                      }
                    : undefined,
                field: "",
                type: "link",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkForm:revocation"),
                value: trademark.revocation
                    ? {
                          label: t("trademarkForm:view"),
                          url: `/interpartes/trademarks/revocations/${trademark.registrationNumber}`,
                      }
                    : undefined,
                field: "",
                type: "link",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkForm:invalidation"),
                value: trademark.invalidation
                    ? {
                          label: t("trademarkForm:view"),
                          url: `/interpartes/trademarks/invalidations/${trademark.registrationNumber}`,
                      }
                    : undefined,
                field: "",
                type: "link",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkForm:appeal"),
                value: trademark.appeal
                    ? {
                          label: t("trademarkForm:view"),
                          url: `/appeal/trademarks/${trademark.applicationNumber}`,
                      }
                    : undefined,
                field: "",
                type: "link",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkForm:refusal"),
                value: trademark.refusal
                    ? {
                          label: t("trademarkForm:view"),
                          url: `/refusedtrademark/${trademark.applicationNumber}`,
                      }
                    : undefined,
                field: "",
                type: "link",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("trademarkForm:pledge"),
                value: trademark.pledge ? "" : undefined,
                field: "",
                type: "string",
            } as DetailedFormTableRow,
        ];
    });
}
