import { useState } from "react";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    ClickAwayListener,
    InputAdornment,
    Tooltip,
    IconButton,
    Divider,
} from "@material-ui/core";

import InfoIcon from "@material-ui/icons/Info";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        divider: {
            height: "2em",
            margin: 4,
        },
    })
);

interface TooltipAdornmentBaseProps {
    tooltipText: string;
    clearable?: boolean;
}

type TooltipAdornmentDropdownProps =
    | {
          showArrow: true;
          toggleDropdown: () => void;
      }
    | {
          showArrow?: false;
          toggleDropdown?: never;
      };

const TooltipAdornment = ({
    tooltipText,
    clearable,
    showArrow,
    toggleDropdown,
}: TooltipAdornmentBaseProps & TooltipAdornmentDropdownProps) => {
    const classes = useStyles();

    const [openTooltip, setOpenTooltip] = useState(false);

    const handleTooltipClose = () => {
        setOpenTooltip(false);
    };

    const toggleTooltip = (e) => {
        e.stopPropagation();
        setOpenTooltip(!openTooltip);
    };

    return (
        <InputAdornment position="end">
            {
                //https://stackoverflow.com/questions/59184768/material-ui-make-iconbutton-only-visible-on-hover
                // TODO: It is not possible to clear value due to Autocomplete.tsx bug
                /*clearable && (
                  <IconButton
                      aria-label="info"
                      edge="end"
                      size="small"
                      onClick={clearValue}
                      className={clsx(classes.clearIndicator, {
                          [classes.clearIndicatorDirty]: value !== null
                      })}>
                      <ClearIcon />
                  </IconButton>
                  )*/
            }
            {showArrow && (
                <IconButton
                    aria-label="info"
                    edge="end"
                    size="small"
                    onClick={toggleDropdown}
                >
                    <ArrowDropDownIcon />
                </IconButton>
            )}
            <Divider className={classes.divider} orientation="vertical" />
            <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={handleTooltipClose}
            >
                <Tooltip
                    className="tooltip"
                    title={<p style={{ fontSize: "12px" }}> {tooltipText} </p>}
                    onClose={handleTooltipClose}
                    open={openTooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                >
                    <IconButton
                        aria-label="info"
                        color="primary"
                        edge="end"
                        size="small"
                        onClick={toggleTooltip}
                    >
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
            </ClickAwayListener>
        </InputAdornment>
    );
};

export default TooltipAdornment;
