import { Paper } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import StringCellContent from "./CellContent/StringCellContent";
import LinkCellContent from "./CellContent/LinkCellContent";
import GridCellContent from "./CellContent/GridCellContent";
import TableCellContent from "./CellContent/TableCellContent";
import DocTableCellContent from "./CellContent/DocTableCellContent";
import ImageCellContent from "./CellContent/ImageCellContent";
import HTMLCellContent from "./CellContent/HTMLCellContent";
import MediaCellContent from "./CellContent/MediaCellContent";
import Timeline from "../../Timeline";
import React from "react";

export interface DetailedFormCellProps {
    type: string;
    value: any;
}

const WrapperComponent = props => <Paper {...props} />;

export default function DetailedFormCell(props: DetailedFormCellProps) {
    const { type, value } = props;
    let component = null;
    const { t } = useTranslation();

    if (value || value.length) {
        switch (type) {
            case 'string':
                component = <StringCellContent value={value} />;
                break;
            case 'link':
                component = value.url ? <LinkCellContent url={value.url} label={value.label} /> : <StringCellContent value={value.label} />;
                break;
            case 'grid':
                component = <GridCellContent rows={value} />;
                break;
            case 'table':
                component = <TableCellContent component={WrapperComponent} labels={value.labels} headers={value.headers} values={value.values} />;
                break;
            case 'docTable':
                component = <DocTableCellContent component={WrapperComponent} labels={value.labels} headers={value.headers} values={value.values} />;
                break;
            case 'image':
                component = <ImageCellContent url={value} />;
                break;
            case 'images':
                component = Array.isArray(value) ? (
                    <React.Fragment>
                        {value.map(url => <ImageCellContent url={url} />)}
                    </React.Fragment>) : <ImageCellContent url={value} />;
                break;
            case 'html':
                component = <HTMLCellContent __html={value.__html} />;
                break;
            case 'media':
                component = <MediaCellContent url={value} />;
                break;
            case 'timeline':
                component = <Timeline component={value} />;
                break;
            default: component = <div>{t("detailedFormCell:unsupportedCellTypeMessage")}</div>;
        }
    }

    return component;
}
