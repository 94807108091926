import { FilterOperandType } from "../../models/FilterModel";
import SearchFieldType from "../../models/grid/SearchType";
import { Store } from "../../storage/StoreProvider";

export const formatFilterValue = (
    field: string,
    value: any,
    usePreciseSearch: boolean
) => {
    const currentState = Store.getState();
    const fieldMeta = currentState.trademarkRevocationSearchMeta.find(
        (f) => f.entity.name === field.replace("%", ".")
    );
    if (!fieldMeta) return [];

    switch (fieldMeta.entity.type) {
        case SearchFieldType.date:
            return {
                Field: field.replace("%", "."),
                Operator: FilterOperandType.Equal,
                Value1: value ? value.toDateString() : "",
                Value2: null,
            };
        case SearchFieldType.dateRange:
            return {
                Field: field,
                Operator: FilterOperandType.Between,
                Value1: value?.startDate
                    ? formatDate(value.startDate)
                    : value?.endDate
                    ? formatDate(value.endDate)
                    : "", // TODO: Add Date parse validation.
                Value2: value?.endDate
                    ? formatDate(value.endDate)
                    : value?.startDate
                    ? formatDate(value.startDate)
                    : "",
            };
        case SearchFieldType.niceCodes:
            return {
                Field: field.replace("%", "."),
                Operator: FilterOperandType.Equal,
                Value1: value,
                Value2: null,
            };
        case SearchFieldType.groupedList:
            return {
                Field: field.replace("%", "."),
                Operator: FilterOperandType.Equal,
                Value1: value.id,
                Value2: null,
            };
        default:
            if (fieldMeta.entity.enum) {
                return {
                    Field: field.replace("%", "."),
                    Operator: FilterOperandType.Equal,
                    Value1: value?.value ? value.value : "",
                    Value2: null,
                };
            }
            return {
                Field: field.replace("%", "."),
                Operator: usePreciseSearch
                    ? FilterOperandType.Equal
                    : FilterOperandType.Contains,
                Value1: value.trim(),
                Value2: null,
            };
    }
};

const formatDate = (date: Date) => {
    return date.toDateString();
};
