import { useLocation } from "react-router-dom";
import { parse } from "qs";
import { DefaultRows } from "./detailedFormRowDefinitions";
import { useStore } from "../../storage/StoreProvider";
import { fetchDesignData } from "../../services/designService";
import React from "react";
import DesignStatusTimeline from "../../components/FormDataGrid/Timeline/DesignStatusTimeline/DesignStatusTimeline";
import GridBasePrintForm from "../../components/FormDataGrid/GridBasePrintForm";
import { DefaultFetchDataProps } from "../../services/baseService";

export default function DesignsPrintForm({ match }) {
    const [state] = useStore();

    const rowSets = DefaultRows(state.designs, true);

    const StatusTimelines = React.useMemo(
        () =>
            state.designs?.map((design, index) => (
                <DesignStatusTimeline key={index} statusId={design.status} />
            )),
        [state.designs]
    );

    const dataResolver = async (props: DefaultFetchDataProps) => {
        await fetchDesignData({
            page: props.page,
            rowsPerPage: props.filter?.length,
            sortModel: props.sortModel,
            filter: props.filter,
            action: props.action,
        });
    };

    return (
        <GridBasePrintForm
            filterBy="applicationNumber"
            rowsPerPage={rowSets.length}
            dataResolver={dataResolver}
            rowSets={rowSets}
            match={match}
            isDataResolved={state.designs.length === 0}
            timelines={StatusTimelines}
        />
    );
}
