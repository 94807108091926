import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { useStore } from "../../storage/StoreProvider";
import { Button, useMediaQuery } from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { Theme, useTheme, withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Store } from "../../storage/StoreProvider";
import { ACTIONS } from "../../storage/dataReducer";

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function PatentsRedirectDialog() {
    const [state, ,] = useStore();

    const { t } = useTranslation();

    const handleClick = () => {
        let epoHref: string = "https://register.epo.org";
        let epoHrefParams: string = "&lng=en&tab=ueMain";

        if (state.patentApplicationNumber !== undefined) {
            epoHref =
                epoHref +
                "/application?number=EP" +
                state.patentApplicationNumber.substring(
                    0,
                    state.patentApplicationNumber.indexOf(".")
                ) +
                epoHrefParams;
        } else if (state.patentNumber !== undefined) {
            //register.epo.org does an internal redirect and loses any added params, so no point appending epoHrefParams here
            epoHref = epoHref + "/publication?number=" + state.patentNumber;
        }

        Store.dispatch({
            type: ACTIONS.PATENT_SEARCH_REDIRECT_CLOSE,
        });

        window.open(epoHref);
    };

    const handleClose = () => {
        Store.dispatch({
            type: ACTIONS.PATENT_SEARCH_REDIRECT_CLOSE,
        });
    };

    return (
        <div>
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={
                    state.patentNumber !== undefined ||
                    state.patentApplicationNumber !== undefined
                }
                onClose={() => {
                    handleClose();
                }}
            >
                <DialogContent dividers>
                    {t("patentForm:patentRedirectDialog")}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={() => {
                            handleClick();
                        }}
                    >
                        {t("common:yes")}
                    </Button>
                    <Button
                        color="primary"
                        type="button"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        {t("common:no")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
