export enum DesignStatus {
    NO_STATUS = 1,
    APPLICATION_RECEIVED = 2,
    APPLICATION_DEEMED_AS_NOT_FILLED = 3,
    APPLICATION_REJECTED = 4,
    REGISTRATION_ALLOWED = 5,
    REGISTRATION_REFUSED = 6,
    APPEAL_FILLED_FOR_NOT_FILLED_APPLICATION = 7,
    APPLICATION_WITHDRAWN = 8,
    REGISTERED = 9,
    OPPOSITION_FILLED = 10,
    REGISTRATION_RENEWED = 11,
    SURRENDERED = 12,
    INVALIDATED = 13,
    DELETED_FROM_THE_REGISTER_DUE_TO_EXPIRY = 14,
    REGISTRATION_DIVIDED = 15,
    APPEAL_FILLED_FOR_REJECTED_APPLICATION = 16,
    APPEAL_FILLED = 17, // Appeal filed for refused registration
    DIVIDED = 18,
    REGISTRATION_EXPIRED = 19,
    REQUEST_FOR_RESTITUTION = 20,
    PUBLICATION_DEFFERED = 21,
    APPEAL_FILED_FOR_REGISTERED_DESIGN = 22,
    REGISTRATION_PROCESS_SUSPENDED = 23,
}

export const getActiveStep = (statusId: number) => {
    switch (statusId) {
        case DesignStatus.APPLICATION_RECEIVED:
            return 1;
        case DesignStatus.REGISTRATION_ALLOWED:
        case DesignStatus.APPLICATION_DEEMED_AS_NOT_FILLED:
        case DesignStatus.APPLICATION_REJECTED:
        case DesignStatus.REGISTRATION_REFUSED:
        case DesignStatus.APPLICATION_WITHDRAWN:
        case DesignStatus.REGISTRATION_PROCESS_SUSPENDED:
            return 2;
        case DesignStatus.REGISTERED:
        case DesignStatus.APPEAL_FILLED_FOR_NOT_FILLED_APPLICATION:
        case DesignStatus.APPEAL_FILLED_FOR_REJECTED_APPLICATION:
        case DesignStatus.PUBLICATION_DEFFERED:
        case DesignStatus.APPEAL_FILLED:
            return 3;
        case DesignStatus.REGISTRATION_EXPIRED:
        case DesignStatus.DELETED_FROM_THE_REGISTER_DUE_TO_EXPIRY:
        case DesignStatus.SURRENDERED:
        case DesignStatus.OPPOSITION_FILLED:
        case DesignStatus.APPEAL_FILED_FOR_REGISTERED_DESIGN:
            return 4;
        case DesignStatus.INVALIDATED:
            return 5;
        default:
            return -1; // No status
    }
};