import { useTranslation } from "react-i18next";
import { DetailedFormTableRow } from "../../components/FormDataGrid/DetailedForm/DetailedFormTable/DetailedFormTable";
import {
    formatEnumValue,
    formatResourcePersonObjectType,
} from "../../components/FormDataGrid/formDataUtils";
import { GetShortDateString } from "../../global/utils";
import { useStore } from "../../storage/StoreProvider";

export function DefaultRows(designAppeals: any, printView?: boolean) {
    const [state, ,] = useStore();
    const designAppealEnums = state.designAppealEnums;
    const { t } = useTranslation();

    return designAppeals.map((designAppeal) => {
        if (designAppeal === {}) return [];
        return [
            {
                caption: " ",
                name: t("designAppealForm:title"),
                value: designAppeal.title,
                field: "",
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designAppealForm:number"),
                value: designAppeal.number,
                field: "",
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designAppealForm:applicant"),
                value: designAppeal.persons?.length
                    ? formatResourcePersonObjectType(designAppeal.persons, [2])
                    : undefined,
                field: "",
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designAppealForm:result"),
                value:
                    designAppealEnums && designAppeal.result
                        ? formatEnumValue(
                            designAppeal.result,
                            "result",
                            designAppealEnums
                        )
                        : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designAppealForm:substantiations"),
                value: designAppeal.substantiations?.join(" "),
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designAppealForm:decision"),
                value: designAppeal.decision,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designAppealForm:decisionDate"),
                value: designAppeal.decisionDate
                    ? GetShortDateString(designAppeal.decisionDate)
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designAppealForm:firstCourtDecision"),
                value: designAppeal.firstCourtDecision,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designAppealForm:secondCourtDecision"),
                value: designAppeal.secondCourtDecision,
                type: "string",
            } as DetailedFormTableRow,
        ];
    });
}
