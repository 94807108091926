import React from "react";
import { useTranslation } from "react-i18next";
import { DetailedFormTableRow } from "../../components/FormDataGrid/DetailedForm/DetailedFormTable/DetailedFormTable";
import {
    formatEnumValue,
    formatResourcePersonObjectType,
} from "../../components/FormDataGrid/formDataUtils";
import { GetShortDateString } from "../../global/utils";
import { useStore } from "../../storage/StoreProvider";

export function DefaultRows(patentAppeals: any, printView?: boolean) {
    const [state, ,] = useStore();
    const patentAppealEnums = state.patentAppealEnums;
    const { t } = useTranslation();

    return patentAppeals.map((patentAppeal) => {
        if (patentAppeal === {}) return [];
        return [
            {
                caption: " ",
                name: t("patentAppealForm:title"),
                value: patentAppeal.title,
                field: "",
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("patentAppealForm:number"),
                value: patentAppeal.number,
                field: "",
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("patentAppealForm:applicant"),
                value: patentAppeal.persons?.length
                    ? formatResourcePersonObjectType(patentAppeal.persons, [2])
                    : undefined,
                field: "",
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("patentAppealForm:result"),
                value:
                    patentAppealEnums && patentAppeal.result
                        ? formatEnumValue(
                            patentAppeal.result,
                            "result",
                            patentAppealEnums
                        )
                        : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("patentAppealForm:substantiations"),
                value: patentAppeal.substantiations?.join(" "),
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("patentAppealForm:decision"),
                value: patentAppeal.decision,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("patentAppealForm:decisionDate"),
                value: patentAppeal.decisionDate
                    ? GetShortDateString(patentAppeal.decisionDate)
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("patentAppealForm:firstCourtDecision"),
                value: patentAppeal.firstCourtDecision,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("patentAppealForm:secondCourtDecision"),
                value: patentAppeal.secondCourtDecision,
                type: "string",
            } as DetailedFormTableRow,
        ];
    });
}
