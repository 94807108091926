import { createStyles, makeStyles, Theme, Box, Paper, Grid, List, ListItem, Divider, ListItemIcon, Tooltip } from '@material-ui/core';
import PrintSharpIcon from '@material-ui/icons/PrintSharp';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { stringify } from 'qs';
import React from 'react';
import clsx from 'clsx';
import { getParentPath } from './formDataUtils';
import { useTranslation } from 'react-i18next';

// Theme-dependent styles.
// createStyles is a helper function for TS compile.
const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        paddingLeft: 20,
    },
    select: {
        paddingTop: 37,
    },
    icon: {
        minWidth: 0,
    },
}));

function ListItemLink(props) {
    return <ListItem button component={RouterLink} {...props} />;
}

export interface ActionBarProps {
    ids: string[];
    useParentPath?: boolean;
    func?: () => any;
}

export default function ActionBar({
    ids,
    useParentPath,
    func,
}: ActionBarProps) {
    const location = useLocation();
    const classes = useStyles();
    const path = useParentPath ? getParentPath(location.pathname) : location.pathname;
    const { t } = useTranslation();

    const query = stringify(
        { id: ids },
        { arrayFormat: 'brackets', encode: false }
    );

    // Desktop view.
    const displayDesktop = () => {
        return (
            <Box display="flex" className={func ? clsx(classes.root, classes.select) : classes.root}>
                <Paper variant="outlined">
                    <List>
                        <ListItemLink to={`${path}/print/?${query}`} target="_blank">
                            <Tooltip title={t("common:printTooltip")}>
                                <ListItemIcon className={classes.icon}>
                                    <PrintSharpIcon />
                                </ListItemIcon>
                            </Tooltip>
                        </ListItemLink>
                        {func && (
                            <React.Fragment>
                                <Divider />
                                <ListItem button>
                                    <Tooltip title={t("common:showSelectionTooltip")}>
                                        <ListItemIcon onClick={func} className={classes.icon}>
                                            <PlaylistAddCheckIcon />
                                        </ListItemIcon>
                                    </Tooltip>
                                </ListItem>
                            </React.Fragment>
                        )}
                    </List>
                </Paper>
            </Box>
        )
    };

    return (
        <Grid item xs={12} md={1}>
            {displayDesktop()}
        </Grid>
    );
}