import FormDataGrid from "../../components/FormDataGrid/FormDataGrid";
import { fetchPasData, fetchPasSearchMeta } from "../../services/pasService";
import { DefaultColumns } from "./columnDefinitions";
import { defaultColumnTypes } from "./columnTypes";
import { formatFilterValue } from "./recordFilter";
import { PasSearchFields } from "./PasSearchFields";
import { useStore } from "../../storage/StoreProvider";
import { useEffect, useMemo } from "react";
import { getDataKey } from "../../services/baseService";
import Resource from "../../models/grid/Resource";
import { FilterOperandType, FilterValue } from "../../models/FilterModel";

export default function PasGrid() {
    const [state, , getState] = useStore();

    const key = getDataKey(Resource.PAS);
    const getRows = useMemo(() => {
        if (!state.pases) return [];
        return state.pases.map((pas) => {
            return pas[key];
        });
    }, [state]);

    function rowIdSelector(rows: any) {
        return rows.map((pas) => {
            return encodeURIComponent(pas.pasNumber);
        });
    }

    function rowFilterBuilder(value: any) {
        return {
            Field: "PasNumber",
            Operator: FilterOperandType.Equal,
            Value1: value.pasNumber,
            Value2: null,
        } as FilterValue;
    }

    return (
        <FormDataGrid
            defaultColumns={DefaultColumns()}
            searchFields={PasSearchFields()}
            onFetchData={fetchPasData}
            filterValueFormatter={formatFilterValue}
            customColumnTypes={defaultColumnTypes(state.pasEnums)}
            loadError={state.error}
            rows={getRows ?? []}
            rowIdSelector={rowIdSelector}
            initialSort={[
                {
                    field: "pasApplicationDate",
                    sort: "desc",
                },
            ]}
        />
    );
}
