import { GridCellParams, GridValueGetterParams } from "@material-ui/data-grid";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getPasPersonValue } from "../../components/FormDataGrid/formDataUtils";
import renderCellExpand, {
    ILinkValue,
} from "../../components/FormDataGrid/RenderCellExpand";
import { GetShortDateString } from "../../global/utils";
import { GridStorage } from "../../storage/browserStorage";
import { useStore } from "../../storage/StoreProvider";
import GridColDefExtended from "../GridColDefExtended";

function getLinkValue(
    params: GridValueGetterParams,
    label?: string,
    key?: string,
    url?: string
): ILinkValue {
    if (!params.value) return null;
    const pageId = (
        key
            ? (params.getValue(params.id, key) as ILinkValue)?.label
            : params.value
    ).toString();
    const pageUrl = `${url || "pas"}/${encodeURIComponent(pageId)}`;
    const urlLabel = label || pageId;
    return { url: pageUrl, label: urlLabel } as ILinkValue;
}

function getInventionNameValue(params: GridValueGetterParams) {
    const value = params.value as any;
    return value?.patentBasicInfo?.titleLV;
}

function getPatentRegistrationNumberValue(params: GridValueGetterParams) {
    const value = params.getValue(params.id, "patent") as any;
    return value?.patentBasicInfo?.publicationNumber;
}

function getProductAndTradeNameValue(params: GridValueGetterParams) {
    const productName = params.getValue(params.id, "product");
    const tradeName = params.getValue(params.id, "tradeName");
    return `${productName ? `${productName}` : ""} ${
        tradeName ? `, ${tradeName}` : ""
    }`;
}

export function DefaultColumns(): GridColDefExtended[] {
    const { t, i18n } = useTranslation();
    const storage = GridStorage.getInstance();
    const visibleColumns = storage.getColumns();
    const [state, ,] = useStore();
    const fieldInfo = state.pasFieldInfo?.sortable;

    return React.useMemo(
        () => [
            {
                field: "pasStatusId",
                headerName: t("pasForm:status"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 350,
                type: "statusType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("pasStatusId")
                    : false,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("pasStatusId") ?? false
                    : false,
            },
            {
                field: "pasNumber",
                headerName: t("pasForm:pasNumber"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, true),
                valueGetter: getLinkValue,
                headerAlign: "center",
                width: 350,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("pasNumber")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("pasNumber") ?? false
                    : false,
            },
            {
                field: "pasApplicationDate",
                headerName: t("pasForm:pasApplicationDate"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 130,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("pasApplicationDate")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("pasApplicationDate") ?? false
                    : false,
            },
            {
                field: "applicantOrOwner",
                headerName: t("pasForm:applicantOrOwner"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                valueGetter: getPasPersonValue,
                headerAlign: "center",
                width: 350,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("applicantOrOwner")
                    : false,
                hideByDefault: false,
                sortable: false,
            },
            {
                field: "representative",
                headerName: t("pasForm:representative"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                valueGetter: getPasPersonValue,
                headerAlign: "center",
                width: 350,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("representative")
                    : true,
                hideByDefault: true,
                sortable: false,
            },
            {
                field: "product%tradeName",
                headerName: t("pasForm:productTradeName"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                valueGetter: getProductAndTradeNameValue,
                headerAlign: "center",
                width: 250,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("product%tradeName")
                    : true,
                hideByDefault: true,
                sortable: false,
            },
            {
                field: "validTo",
                headerName: t("pasForm:validTo"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 200,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("validTo")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("validTo") ?? false
                    : false,
            },
            {
                field: "marketAuthLVNumber",
                headerName: t("pasForm:marketAuthLV"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 200,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("marketAuthLVNumber")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("marketAuthLVNumber") ?? false
                    : false,
            },
            {
                field: "marketAuthEUNumber",
                headerName: t("pasForm:marketAuthEU"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 200,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("marketAuthEUNumber")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("marketAuthEUNumber") ?? false
                    : false,
            },
            {
                field: "patentRegistrationNumber",
                headerName: t("pasForm:patentApplicationNumber"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                valueGetter: getPatentRegistrationNumberValue,
                headerAlign: "center",
                width: 150,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("patentRegistrationNumber")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("patentRegistrationNumber") ?? false
                    : false,
            },
            {
                field: "patentInventionName",
                headerName: t("pasForm:inventionTitle"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                valueGetter: getInventionNameValue,
                headerAlign: "center",
                width: 250,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("patentInventionName")
                    : true,
                hideByDefault: true,
                sortable: false,
            },
            {
                field: "pasNextAnnualFee",
                headerName: t("pasForm:nextAnnualFee"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 200,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("pasNextAnnualFee")
                    : false,
                hideByDefault: false,
                sortable: false,
            },
        ],
        [visibleColumns, fieldInfo, i18n.language]
    );
}
