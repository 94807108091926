import { Box, Step, StepLabel } from "@material-ui/core";
import Stepper from '@material-ui/core/Stepper';
import React from "react";
import { ColorlibConnector, ColorlibStepIcon, EmptyStepIcon, LabelConnector, StartEmptyStepIcon, useStyles } from "../TimelineBase";
import ForwardIcon from '@material-ui/icons/Forward';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export interface TrademarkExtendableTimelineProps {
    activeStep: number;
    lastStep: string;
    lastStepLabel?: string;
    isEnd?: boolean;
    startDash?: boolean;
    endDash?: boolean;
    additionalSteps?: string[];
    additionalLabels?: string[];
    ignoreDefaultLabels?: boolean;
}

const getStepLabels = () => {
    return [i18next.t("trademarkStatusTimeline:stepLabel1")];
}

const getSteps = () => {
    return [i18next.t("trademarkStatusTimeline:step1")];
}

export default function TrademarkExtendableTimeline({
    activeStep,
    lastStep,
    isEnd,
    startDash,
    endDash,
    lastStepLabel,
    additionalSteps,
    additionalLabels,
    ignoreDefaultLabels }: TrademarkExtendableTimelineProps) {
    const classes = useStyles();
    // Empty string to preserve right positioning
    const labels = ignoreDefaultLabels ? [''] : getStepLabels();
    const steps = getSteps();

    // TODO: Add documentation
    // TODO: It would be better to rewrite Material-UI Stepper to avoid code clutter.
    return (
        <React.Fragment>
            <Box pt={!startDash || !endDash ? 5 : 0}>
                <ForwardIcon className={classes.icon} />
            </Box>
            <Box flexGrow={1} className={classes.timeline}>
                {(!startDash || !endDash) && (<Stepper alternativeLabel activeStep={-1} connector={<LabelConnector />}>
                    {labels.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={(props) => StartEmptyStepIcon(props, label)}></StepLabel>
                        </Step>
                    ))}
                    {additionalLabels?.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={(props) => StartEmptyStepIcon(props, label)}></StepLabel>
                        </Step>
                    ))}
                    {lastStepLabel && (
                        <Step>
                            <StepLabel StepIconComponent={(props) => EmptyStepIcon(props, lastStepLabel)}></StepLabel>
                        </Step>
                    )}
                    <Step className={classes.emptyStep}></Step>
                    {!isEnd && <Step className={classes.emptyStep}></Step>}
                    {!isEnd && <Step className={classes.emptyStep}></Step>}
                    {!isEnd && <Step className={classes.emptyStep}></Step>}
                    {!isEnd && endDash && <Step className={classes.emptyStep}></Step>}
                    {!isEnd && endDash && <Step className={classes.emptyStep}></Step>}
                </Stepper>)}
                <Stepper
                    alternativeLabel
                    activeStep={startDash ? activeStep + 3 : (endDash ? activeStep + 2 : activeStep)}
                    connector={<ColorlibConnector />}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel className={classes.label} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                    {startDash && <Step className={classes.emptyStep}></Step>}
                    {startDash && <Step className={classes.emptyStep}></Step>}
                    {startDash && <Step className={classes.emptyStep}></Step>}
                    <Step>
                        <StepLabel className={classes.label} StepIconComponent={ColorlibStepIcon}>{lastStep}</StepLabel>
                    </Step>
                    {endDash && <Step className={classes.emptyStep}></Step>}
                    {endDash && <Step className={classes.emptyStep}></Step>}
                    {additionalSteps?.length && additionalSteps.map((step) => (
                        <Step key={step}>
                            <StepLabel className={classes.label} StepIconComponent={ColorlibStepIcon}>{step}</StepLabel>
                        </Step>
                    ))}
                    {!isEnd && <Step className={classes.emptyStep}></Step>}
                    {!isEnd && <Step className={classes.emptyStep}></Step>}
                    {!isEnd && <Step className={classes.emptyStep}></Step>}
                </Stepper>
            </Box>
        </React.Fragment>
    );
}