import { useTranslation } from 'react-i18next';
import AudioPlayer from 'material-ui-audio-player';

export interface MediaCellContentProps {
    url: string;
}

export default function MediaCellContent(props: MediaCellContentProps) {
    const { url } = props;
    const { t } = useTranslation();

    return (
            <AudioPlayer
                elevation={0}
                width="500px"
                variation="default"
                spacing={3}
                download={true}
                order="standart"
                src={url}
            />
    )
}
