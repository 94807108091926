enum Resource {
    Trademark,
    TrademarkAppeal,
    Patent,
    PatentAppeal,
    Design,
    DesignAppeal,
    Persons,
    Image,
    Images,
    Enums,
    Documents,
    Opposition,
    Objection,
    TrademarkOpposition,
    DecisionFile,
    Events,
    Referenced,
    SearchMeta,
    Publications,
    Payments,
    PAS,
    DesignOpposition,
    RefusedTrademark,
    FieldInfo,
    TrademarkInvalidation,
    TrademarkRevocation,
}

export default Resource;
