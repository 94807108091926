import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
    text: {
        whiteSpace: "pre-wrap",
    }
}));

export interface StringCellContentProps {
    value: string;
}

export default function StringCellContent(props: StringCellContentProps) {
    const classes = useStyles();
    const { value } = props;

    return (
        <div className={classes.text}>
            {value}
        </div>
    );
}
