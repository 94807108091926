import { createStyles, makeStyles, Theme, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, Link } from "@material-ui/core";
import { TableCellLabel } from "./TableCellContent";

const useStyles = makeStyles((theme: Theme) => createStyles({
    cell: {
        fontWeight: "bold",
    },
    linkCell: {
        verticalAlign: "text-bottom",
    },
}));

export interface DocTableCellValue {
    label: string;
    link: {
        url: string;
        label: string;
    };
}

export interface DocTableCellContentProps {
    component: any;
    labels: TableCellLabel[];
    headers: string[];
    values: DocTableCellValue[];
}

export default function DocTableCellContent(props: DocTableCellContentProps) {
    const classes = useStyles();
    const { component, headers, values } = props;

    return (
        <Box display="flex">
            <Box>
                <TableContainer component={component} variant="outlined" elevation={0}>
                    <Table size="small">
                        {headers && (
                            <TableHead>
                                <TableRow>
                                    {headers.map((header, index) => {
                                        return (
                                            <TableCell key={index} align="left" className={classes.cell}>
                                                {header}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                        )}
                        <TableBody>
                            {values?.map((value, index) => {
                                return (
                                    <TableRow className={classes.linkCell} key={index}>
                                        {value.label && <TableCell>{value.label}</TableCell>}
                                        {value.link && (
                                            <TableCell>
                                                <Link target="_blank" href={value.link?.url} >
                                                    {value.link?.label}
                                                </Link>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box flexGrow={1} />
        </Box >
    );
}
