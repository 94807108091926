import {
    Collapse,
    createStyles,
    IconButton,
    makeStyles,
    TableCell,
    TableRow,
    Theme,
    Typography,
} from "@material-ui/core";
import React from "react";
import DetailedFormCell from "../DetailedFormTableCell/DetailedFormCell";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { LocalStorage } from "../../../../../storage/browserStorage";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        name: {
            fontWeight: "bold",
            [theme.breakpoints.up("md")]: {
                minWidth: 200,
            },
        },
    })
);

export interface CollapsibleRowProps {
    type: string;
    value: any;
    name?: string;
    caption?: string | number;
    isOpenByDefault?: boolean;
}

export default function CollapsibleRow(props: CollapsibleRowProps) {
    const classes = useStyles();
    const { name, caption, type, isOpenByDefault, value } = props;
    const ls = LocalStorage.getInstance();
    const [open, setOpen] = React.useState(
        type === "timeline"
            ? ls.getTimelineState()?.open ?? isOpenByDefault
            : isOpenByDefault
    );

    // TODO: Create CollapsibleRow for Timeline
    //////////////////////////////////////////////
    const SetTimelineOpenState = () => {
        ls.setTimelineState({
            open: !open,
        });
        setOpen(!open);
    };
    //////////////////////////////////////////////

    return (
        <React.Fragment>
            <TableRow key={name}>
                {type !== "timeline" && (
                    <React.Fragment>
                        <TableCell>
                            <Typography variant="caption">
                                {caption
                                    ? typeof caption === "string"
                                        ? caption
                                        : `(${caption})`
                                    : ""}
                            </Typography>
                        </TableCell>
                        <TableCell
                            component="th"
                            scope="row"
                            className={classes.name}
                        >
                            {name}
                        </TableCell>
                    </React.Fragment>
                )}
                {(type === "timeline" && (
                    <React.Fragment>
                        <TableCell colSpan={3}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <DetailedFormCell type={type} value={value} />
                            </Collapse>
                        </TableCell>
                        <TableCell align="right">
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={SetTimelineOpenState}
                            >
                                {open ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </IconButton>
                        </TableCell>
                    </React.Fragment>
                )) || (
                    <React.Fragment>
                        <TableCell colSpan={1}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <DetailedFormCell type={type} value={value} />
                            </Collapse>
                        </TableCell>
                        <TableCell align="right">
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}
                            >
                                {open ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </IconButton>
                        </TableCell>
                    </React.Fragment>
                )}
            </TableRow>
        </React.Fragment>
    );
}
