import DesignDefaultTimeline from "./DesignDefaultTimeline";
import { DesignStatus, getActiveStep } from "./DesignStatus";
import DesignExtendableTimeline from "./DesignExtendableTimeline";
import { useTranslation } from 'react-i18next';

export interface DesignStatusTimelineProps {
    statusId: number;
}

export default function DesignStatusTimeline({ statusId }: DesignStatusTimelineProps) {
    const activeStep = getActiveStep(statusId) - 1;
    let component = null;
    const { t } = useTranslation();

    // For 5 or more steps - use DesignDefaultTimeline.
    // For 1-5 steps - use DesignExtendableTimeline.
    // Fix for conflicting status - DesignExtendableTimelineStatus.
    switch (statusId) {
        case DesignStatus.APPLICATION_DEEMED_AS_NOT_FILLED:
            component = <DesignExtendableTimeline
                activeStep={activeStep}
                lastStep={t("trademarkStatusTimeline:lastStep1")}
                isEnd />;
            break;
        case DesignStatus.APPLICATION_REJECTED:
            component = <DesignExtendableTimeline
                activeStep={activeStep}
                lastStep={t("designStatusTimeline:lastStep2")}
                isEnd />;
            break;
        case DesignStatus.REGISTRATION_REFUSED:
            component = <DesignExtendableTimeline
                activeStep={activeStep}
                lastStep={t("designStatusTimeline:additionalStep1")}
                isEnd />
            break;
        case DesignStatus.APPLICATION_WITHDRAWN:
            component = <DesignExtendableTimeline
                activeStep={activeStep}
                lastStep={t("designStatusTimeline:lastStep4")}
                additionalLabels={[" "]}
                ignoreDefaultLabels
                isEnd
                startDash />;
            break;
        case DesignStatus.APPEAL_FILLED_FOR_NOT_FILLED_APPLICATION:
            component = <DesignExtendableTimeline
                activeStep={activeStep}
                lastStep={t("designStatusTimeline:lastStep1")}
                lastStepLabel={t("designStatusTimeline:lastStepLabel2")}
                additionalSteps={[t("designStatusTimeline:additionalStep2")]} />;
            break;
        case DesignStatus.PUBLICATION_DEFFERED:
            component = <DesignExtendableTimeline
                activeStep={activeStep}
                lastStep={t("designStatusTimeline:lastStep1")}
                lastStepLabel={t("designStatusTimeline:step2")}
                additionalSteps={[t("designStatusTimeline:additionalLabel4")]} />;
            break;
        case DesignStatus.APPEAL_FILLED_FOR_REJECTED_APPLICATION:
            component = <DesignExtendableTimeline
                activeStep={activeStep}
                lastStep={t("designStatusTimeline:lastStep2")}
                lastStepLabel={t("designStatusTimeline:lastStepLabel2")}
                additionalSteps={[t("designStatusTimeline:additionalStep3")]} />;
            break;
        case DesignStatus.APPEAL_FILLED: // Appeal filed for refused registration
            component = <DesignExtendableTimeline
                activeStep={activeStep}
                lastStep={t("designStatusTimeline:additionalStep1")}
                additionalLabels={[t("designStatusTimeline:additionalLabel2")]}
                additionalSteps={[t("designStatusTimeline:additionalStep5")]} />;
            break;
        case DesignStatus.REGISTRATION_PROCESS_SUSPENDED:
            component = <DesignExtendableTimeline
                activeStep={activeStep}
                lastStep={t("designStatusTimeline:lastStep14")}
                additionalLabels={[" "]}
                ignoreDefaultLabels
                startDash />;
            break;
        case DesignStatus.REGISTRATION_EXPIRED:
            component = <DesignDefaultTimeline
                activeStep={activeStep}
                lastStep={t("designStatusTimeline:lastStep6")}
                isDashed
                showLastLabel
            />;
            break;
        // Zīme izslēgta no reģistra pēc īpašnieka lūguma
        case DesignStatus.SURRENDERED:
            component = <DesignDefaultTimeline
                activeStep={activeStep}
                lastStep={t("designStatusTimeline:lastStep12")}
                showLastLabel
                isEnd
            />;
            break;
        // Zīme izslēgta no reģistra
        case DesignStatus.DELETED_FROM_THE_REGISTER_DUE_TO_EXPIRY:
            component = <DesignDefaultTimeline
                activeStep={activeStep}
                lastStep={t("designStatusTimeline:lastStep7")}
                showLastLabel
                isEnd
            />;
            break;
        case DesignStatus.OPPOSITION_FILLED:
            component = <DesignDefaultTimeline
                activeStep={activeStep}
                additionalSteps={[t("designStatusTimeline:additionalStep7")]}
                showLastLabel
                lastLabel={t("designStatusTimeline:lastLabel1")}
            />;
            break;
        case DesignStatus.INVALIDATED:
            component = <DesignDefaultTimeline
                activeStep={activeStep}
                lastStep={t("designStatusTimeline:lastStep10")}
                additionalSteps={[t("designStatusTimeline:additionalStep8")]}
                additionalLabels={[t("designStatusTimeline:additionalLabel3")]}
                lastLabel={t("designStatusTimeline:lastLabel3")}
                showLastLabel
                isDashed
                isEnd
            />;
            break;
        case DesignStatus.APPEAL_FILED_FOR_REGISTERED_DESIGN:
            component = <DesignDefaultTimeline
                activeStep={activeStep}
                lastStep={t("designStatusTimeline:lastStep13")}
                isDashed
            />;
            break;
        default: // 3
            component = <DesignDefaultTimeline activeStep={activeStep} showLastLabel />;
            break;
    }

    return component;
}
