import React, { useEffect, useState } from "react";

// constants
import { NICE_GOODS, NICE_SERVICES } from "../global/constants";

// utils
import { GridStorage } from "../storage/browserStorage";
import { useForm } from "react-final-form";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Grid,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";

import { TextField } from "./TextField";
import TooltipAdornment from "./TooltipAdornment";

import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        root: {
            "& .MuiOutlinedInput-adornedEnd": {
                paddingRight: "10px",
            },
            "&:hover $clearIndicatorDirty, & .Mui-focused $clearIndicatorDirty":
            {
                visibility: "visible",
            },
            "& .MuiInputLabel-shrink": {
                overflow: "visible !important",
                textOverflow: "clip !important",
            },
        },
    })
);

interface GroupedListFieldProps {
    name: string;
    label: string;
    tooltipText: string;
    options: any[];
    defaultValue?: any[];
}

const GroupedListField = (props: GroupedListFieldProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const storage = GridStorage.getInstance();
    const filterModel = storage.getFilterModel();

    const form = useForm();

    const [isOpen, setOpen] = useState(false);
    const [value, setValue] = useState<any[]>(
        filterModel && props.name in filterModel ? props.defaultValue : []
    );

    const handleChange = (val: any) => {
        form.change(
            props.name,
            !value
                ? [val]
                : value.some((v) => v.id === val.id)
                    ? value.filter((v) => v.id !== val.id)
                    : [...value, val]
        );
    };

    useEffect(() => {
        return form.registerField(
            props.name,
            (fieldState) => {
                setValue(fieldState.value);
            },
            {
                value: true,
            }
        );
    }, []);

    return (
        <>
            <TextField
                name={props.name}
                label={props.label}
                value={value ? value.map((val) => val.name).join(", ") : ""}
                onClick={() => {
                    setOpen(true);
                }}
                size="small"
                variant="outlined"
                margin="dense"
                className={classes.root}
                InputLabelProps={
                    {
                        style: {
                            // @ts-ignore
                            maxWidth: "100%",
                            paddingRight: 60,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        },
                    }
                }
                InputProps={{
                    endAdornment: (
                        <TooltipAdornment tooltipText={props.tooltipText} />
                    )
                }}
            />
            <Dialog open={isOpen} fullWidth>
                <DialogTitle>
                    <Typography variant="h6">{props.label}</Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        {props.options.map((group, index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={
                                                    group.items &&
                                                    group.items.every(
                                                        (v) =>
                                                            value &&
                                                            value.includes(v)
                                                    )
                                                }
                                                onChange={(event, checked) => {
                                                    if (event.target.checked) {
                                                        form.change(
                                                            props.name,
                                                            _.uniq([
                                                                ...(Array.isArray(
                                                                    value
                                                                )
                                                                    ? value
                                                                    : []),
                                                                ...group.items,
                                                            ])
                                                        );
                                                    } else {
                                                        form.change(
                                                            props.name,
                                                            value.filter(
                                                                (val) =>
                                                                    !group.items.includes(
                                                                        val
                                                                    )
                                                            )
                                                        );
                                                    }
                                                }}
                                            />
                                        }
                                        label={group.groupTitle}
                                    />
                                </Grid>
                                <Grid item container spacing={1}>
                                    {group.items &&
                                        group.items.map((item, index) => (
                                            <Grid key={index} item>
                                                <Button
                                                    color={
                                                        value &&
                                                            value.some(
                                                                (val) =>
                                                                    val.id ===
                                                                    item.id
                                                            )
                                                            ? "primary"
                                                            : "default"
                                                    }
                                                    variant="contained"
                                                    onClick={() => {
                                                        handleChange(item);
                                                    }}
                                                >
                                                    {item.name}
                                                </Button>
                                            </Grid>
                                        ))}
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            form.change(props.name, []);
                        }}
                    >
                        {t("searchForm:reset")}
                    </Button>
                    <Button
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        {t("searchForm:approve")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GroupedListField;
