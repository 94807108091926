import { GridCellParams, GridValueGetterParams } from "@material-ui/data-grid";
import GridColDefExtended from "../GridColDefExtended";
import { useTranslation } from "react-i18next";
import { getTrademarkOppositionPersonValue } from "../../components/FormDataGrid/formDataUtils";
import renderCellExpand, {
    ILinkValue,
} from "../../components/FormDataGrid/RenderCellExpand";
import { GridStorage } from "../../storage/browserStorage";
import { useStore } from "../../storage/StoreProvider";
import React from "react";

export function DefaultColumns(): GridColDefExtended[] {
    const { t, i18n } = useTranslation();
    const storage = GridStorage.getInstance();
    const visibleColumns = storage.getColumns();
    const [state, ,] = useStore();
    const fieldInfo = state.trademarkInvalidationFieldInfo?.sortable;

    function getOppossiteObjectsValue(
        params: GridValueGetterParams,
        url: string
    ): ILinkValue[] {
        if (!(params.value as any)?.length) return null;
        return (params.value as any).map((item) => {
            const pageId = item.number;
            const urlLabel = pageId ? `${pageId} - ${item.title}` : item.title;
            const pageUrl = item.url ?? `${url || "trademark"}/${pageId}`;
            return {
                url: pageId ? pageUrl : null,
                label: urlLabel,
            } as ILinkValue;
        });
    }

    //Todo: surely this can be extracted and reused
    function getExternalLink(pageId: string, title?: string): ILinkValue {
        // TODO: Dirty fix. This will break if registrationNumber format will change.
        if (pageId?.startsWith("WO")) {
            const idParts = pageId.split(" ");
            const id = idParts.slice(1, idParts.length).join("");
            return {
                url: `https://www3.wipo.int/madrid/monitor/en/showData.jsp?ID=ROM.${id}`,
                label: title ?? pageId,
            } as ILinkValue;
        } else if (pageId?.startsWith("EUTM")) {
            const idParts = pageId.split(" ");
            const id = idParts.slice(1, idParts.length).join("");
            return {
                url: `https://euipo.europa.eu/eSearch/#details/trademarks/${id}`,
                label: title ?? pageId,
            } as ILinkValue;
        }
    }

    function getTrademarkLinkValue(
        params: GridValueGetterParams,
        label?: string,
        key?: string,
        url?: string
    ): ILinkValue {
        if (!params.value) return null;
        const pageId = (
            key
                ? (params.getValue(params.id, key) as ILinkValue)?.label
                : params.value
        ).toString();
        const urlLabel = label || pageId;

        const externalLink = getExternalLink(pageId, urlLabel);
        if (externalLink) return externalLink;

        const pageUrl = `${url || "trademark"}/${pageId}`;
        return { url: pageUrl, label: urlLabel } as ILinkValue;
    }

    function getEntityUrlLink(
        params: GridValueGetterParams,
        key: string
    ): ILinkValue {
        if (!params.value) return null;
        const urls = params.getValue(params.id, "urls");
        if (!urls && !urls[key]) return null;
        const pageUrl = urls[key];
        const urlLabel = params.value;
        return { url: pageUrl, label: urlLabel } as ILinkValue;
    }

    return React.useMemo(
        () => [
            {
                field: "title",
                headerName: t("trademarkInvalidationForm:title"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 300,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("title")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("title") ?? false
                    : false,
            },
            {
                field: "number",
                headerName: t("trademarkInvalidationForm:number"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, true, false, true),
                valueGetter: (params) =>
                    getTrademarkLinkValue(params, null, null, "/trademark"),
                headerAlign: "center",
                width: 190,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("number")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("number") ?? false
                    : false,
            },
            {
                field: "applicant",
                headerName: t("trademarkInvalidationForm:owner"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                valueGetter: getTrademarkOppositionPersonValue,
                headerAlign: "center",
                width: 300,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("applicant")
                    : false,
                hideByDefault: false,
                sortable: false,
            },
            {
                field: "substantiations",
                headerName: t("trademarkInvalidationForm:groundsOfDecision"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 300,
                type: "viennaType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("substantiations")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("substantiations") ?? false
                    : false,
            },
            {
                field: "representative",
                headerName: t("trademarkInvalidationForm:applicant"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                valueGetter: getTrademarkOppositionPersonValue,
                headerAlign: "center",
                width: 300,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("representative")
                    : false,
                hideByDefault: false,
                sortable: false,
            },
            {
                field: "result",
                headerName: t("trademarkInvalidationForm:result"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 300,
                type: "enumType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("result")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("result") ?? false
                    : false,
            },
            {
                field: "decision",
                headerName: t("trademarkInvalidationForm:decision"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, true, false, true),
                valueGetter: (params) =>
                    getEntityUrlLink(params, "DecisionFile"),
                headerAlign: "center",
                width: 300,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("decision")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("decision") ?? false
                    : false,
            },
            {
                field: "decisionDate",
                headerName: t("trademarkInvalidationForm:decisionDate"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 130,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("decisionDate")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("decisionDate") ?? false
                    : false,
            },
            {
                field: "nica",
                headerName: t("trademarkInvalidationForm:nica"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 170,
                type: "nicaType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("nica")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("nica") ?? false
                    : false,
            },
            {
                field: "firstCourtDecision",
                headerName: t("trademarkInvalidationForm:firstCourtDecision"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 350,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("firstCourtDecision")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("firstCourtDecision") ?? false
                    : false,
            },
            {
                field: "secondCourtDecision",
                headerName: t("trademarkInvalidationForm:secondCourtDecision"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 350,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("secondCourtDecision")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("secondCourtDecision") ?? false
                    : false,
            },
            {
                field: "oppossiteObjects",
                headerName: t("trademarkInvalidationForm:oppossiteObjects"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, true, false, true),
                valueGetter: (params) =>
                    getOppossiteObjectsValue(params, "/trademark"),
                headerAlign: "center",
                width: 400,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("oppossiteObjects")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("oppossiteObjects") ?? false
                    : false,
            },            
        ],
        [visibleColumns, fieldInfo, i18n.language]
    );
}
