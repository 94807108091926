import IcoMoonWoff from './icomoon2c29.woff';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const icoMoonFont: CSSProperties = {
    fontFamily: 'icomoon',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontDisplay: 'block',
    src: `      
      url(${IcoMoonWoff}) format('woff')
    `,
};

export const icoMoonSet: { [key: string]: string } = {
    sphere: '"\\e914"',
    search: '"\\e926"',
};