import { GridSortItem, GridSortModel } from "@material-ui/data-grid";

// Inspired by: https://betterprogramming.pub/creating-localstorage-wrapper-with-typescript-7ff6b71b35cb
interface BrowserStorage {
    getItem(key: string): string | null;
    setItem(key: string, value: string): void;
    removeItem(key: string): void;
}

export enum BrowserStorageType {
    Local,
    Session,
}

export default abstract class Storage<T extends string> {
    private readonly storage: BrowserStorage;

    public constructor(
        type: BrowserStorageType,
        getStorage = (type: BrowserStorageType): BrowserStorage =>
            type === BrowserStorageType.Session
                ? window.sessionStorage
                : window.localStorage
    ) {
        this.storage = getStorage(type);
    }

    protected get(key: T): string | null {
        return this.storage.getItem(key);
    }

    protected set(key: T, value: string): void {
        this.storage.setItem(key, value);
    }

    protected clearItem(key: T): void {
        this.storage.removeItem(key);
    }

    protected clearItems(keys: T[]): void {
        keys.forEach((key) => this.clearItem(key));
    }
}

enum Session {
    EXTENDED_SEARCH_FIELDS = "extended_search_fields",
    PAGE = "page",
    ROWS_PER_PAGE = "rows_per_page",
    LOCATION = "path_name",
    SEARCH_FILTER = "search_filter",
    FILTER_MODEL = "filter_model",
    COLUMNS = "columns",
    SORT_MODEL = "sort_model",
    ROW_SIZE = "row_size",
    PRECISE_SEARCH = "precise_search",
}

enum Local {
    LANGUAGE = "language",
    TIMELINE = "timeline",
    LOCATION = "location",
}

export class GridStorage extends Storage<Session> {
    private static instance?: GridStorage;

    constructor() {
        super(BrowserStorageType.Session);
    }

    public static getInstance() {
        if (!this.instance) {
            this.instance = new GridStorage();
        }

        return this.instance;
    }

    public getExtendedSearchFields() {
        const fields = this.get(Session.EXTENDED_SEARCH_FIELDS);
        return fields ? JSON.parse(fields) : undefined;
    }

    public setExtendedSearchFields(fields: any) {
        this.set(Session.EXTENDED_SEARCH_FIELDS, JSON.stringify(fields));
    }

    public clearExtendedSearchFields() {
        this.clearItems([Session.EXTENDED_SEARCH_FIELDS]);
    }

    public getPreciseSearchFields() {
        const fields = this.get(Session.PRECISE_SEARCH);

        try {
            return fields ? JSON.parse(fields) : undefined;
        } catch (error) {
            return undefined;
        }
    }

    public setPreciseSearchFields(fields: any) {
        this.set(Session.PRECISE_SEARCH, JSON.stringify(fields));
    }

    public clearPreciseSearchFields() {
        this.clearItems([Session.PRECISE_SEARCH]);
    }

    public getPage() {
        const page = this.get(Session.PAGE);
        return page ? parseInt(page) : undefined;
    }

    public setPage(page: number): number {
        this.set(Session.PAGE, page.toString());
        return page;
    }

    public getRowsPerPage() {
        const rowsPerPage = this.get(Session.ROWS_PER_PAGE);
        return rowsPerPage ? parseInt(rowsPerPage) : undefined;
    }

    public setRowsPerPage(rowsPerPage: number) {
        this.set(Session.ROWS_PER_PAGE, rowsPerPage.toString());
    }

    public getLocation() {
        return this.get(Session.LOCATION);
    }

    public setLocation(location: string) {
        this.set(Session.LOCATION, location);
    }

    public getSearchFilter() {
        const filter = this.get(Session.SEARCH_FILTER);
        return filter ? JSON.parse(filter) : undefined;
    }

    public setSearchFilter(filter: any) {
        this.set(Session.SEARCH_FILTER, JSON.stringify(filter));
    }

    public getFilterModel() {
        const filterModel = this.get(Session.FILTER_MODEL);
        return filterModel ? JSON.parse(filterModel) : undefined;
    }

    public setFilterModel(filter: any) {
        this.set(Session.FILTER_MODEL, JSON.stringify(filter));
    }

    public clearSearchFilter() {
        this.clearItems([Session.SEARCH_FILTER]);
        this.clearItems([Session.FILTER_MODEL]);
    }

    public getColumns() {
        const columns = this.get(Session.COLUMNS);
        return columns ? JSON.parse(columns) : undefined;
    }

    public setColumns(columns: any) {
        this.set(Session.COLUMNS, JSON.stringify(columns));
    }

    public clearColumns() {
        this.clearItems([Session.COLUMNS]);
    }

    public getSortModel() {
        const sortModel = this.get(Session.SORT_MODEL);
        return sortModel ? JSON.parse(sortModel) : undefined;
    }

    public setSortModel(sortModel: GridSortItem[]): GridSortModel {
        this.set(Session.SORT_MODEL, JSON.stringify(sortModel));
        return sortModel;
    }

    public clearSortModel() {
        this.clearItems([Session.SORT_MODEL]);
    }

    public getRowSize() {
        const rowSize = this.get(Session.ROW_SIZE);
        return rowSize ? rowSize : undefined;
    }

    public setRowSize(rowSize: string) {
        this.set(Session.ROW_SIZE, rowSize);
        return rowSize;
    }

    public clearRowSize() {
        this.clearItems([Session.ROW_SIZE]);
    }

    public clear() {
        this.clearItems([
            Session.PAGE,
            Session.ROWS_PER_PAGE,
            Session.LOCATION,
            Session.COLUMNS,
            Session.SEARCH_FILTER,
            Session.FILTER_MODEL,
            Session.EXTENDED_SEARCH_FIELDS,
            Session.SORT_MODEL,
            Session.ROW_SIZE,
            Session.PRECISE_SEARCH,
        ]);
    }
}

export class LocalStorage extends Storage<Local> {
    private static instance?: LocalStorage;

    constructor() {
        super(BrowserStorageType.Local);
    }

    public static getInstance() {
        if (!this.instance) {
            this.instance = new LocalStorage();
        }

        return this.instance;
    }

    public getLanguage() {
        const lang = this.get(Local.LANGUAGE);
        return lang;
    }

    public setLanguage(lang: string) {
        this.set(Local.LANGUAGE, lang);
    }

    public getTimelineState() {
        const timelineState = this.get(Local.TIMELINE);
        return timelineState ? JSON.parse(timelineState) : undefined;
    }

    public setTimelineState(timelineState: any) {
        this.set(Local.TIMELINE, JSON.stringify(timelineState));
    }

    public clearTimelineState() {
        this.clearItems([Local.TIMELINE]);
    }

    public getLocation() {
        const location = this.get(Local.LOCATION);
        return location;
    }

    public setLocation(location: string) {
        this.set(Local.LOCATION, location);
    }

    public clearLocation() {
        this.clearItems([Local.LOCATION]);
    }
}
