import { useTranslation } from "react-i18next";
import { DetailedFormTableRow } from "../../components/FormDataGrid/DetailedForm/DetailedFormTable/DetailedFormTable";
import { TableCellValue } from "../../components/FormDataGrid/DetailedForm/DetailedFormTable/DetailedFormTableCell/CellContent/TableCellContent";
import {
    formatEnumValue,
    formatResourcePersonObjectType,
} from "../../components/FormDataGrid/formDataUtils";
import { useStore } from "../../storage/StoreProvider";

export function DefaultRows(oppositions: any, printView?: boolean) {
    const [state, ,] = useStore();
    const oppositionEnums = state.designOppositionEnums;
    const { t } = useTranslation();
    return oppositions.map((opposition) => {
        if (opposition === {}) return [];
        return [
            {
                caption: " ",
                name: t("designOppositionForm:title"),
                value: opposition.title,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designOppositionForm:number"),
                value: opposition.number,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designOppositionForm:owner"),
                value: opposition.persons?.length
                    ? formatResourcePersonObjectType(opposition.persons, [1])
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designOppositionForm:applicant"),
                value: opposition.persons?.length
                    ? formatResourcePersonObjectType(opposition.persons, [2])
                    : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designOppositionForm:result"),
                value:
                    oppositionEnums && opposition.result !== undefined
                        ? formatEnumValue(
                            opposition.result,
                            "result",
                            oppositionEnums
                        )
                        : undefined,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designOppositionForm:groundsOfDecision"),
                value: opposition.substantiations?.join(" "),
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designOppositionForm:decision"),
                value: opposition.decision,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designOppositionForm:firstCourtDecision"),
                value: opposition.firstCourtDecision,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designOppositionForm:secondCourtDecision"),
                value: opposition.secondCourtDecision,
                type: "string",
            } as DetailedFormTableRow,
            {
                caption: " ",
                name: t("designOppositionForm:oppossiteObjects"),
                value: opposition.oppossiteObjects?.length
                    ? {
                        headers: [
                            t("designOppositionForm:numberHeader"),
                            t("designOppositionForm:titleHeader"),
                        ],
                        values: opposition.oppossiteObjects.map((item) => [
                            {
                                text: item.number,
                            } as TableCellValue,
                            {
                                text: item.title,
                            } as TableCellValue,
                        ]),
                    }
                    : undefined,
                type: "table",
            } as DetailedFormTableRow,
        ];
    });
}
