import { LocalStorage } from "../storage/browserStorage";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const defaultLanguage = "lv-LV";

const getLanguage = () => {
    const ls = LocalStorage.getInstance();
    if (!ls.getLanguage()) {
        ls.setLanguage(defaultLanguage);
        return defaultLanguage;
    }
    return ls.getLanguage();
};

i18n.use(initReactI18next).init({
    fallbackLng: defaultLanguage,
    lng: getLanguage(),
    resources: {
        en: {
            // [EN] Common form translations
            common: require("./locales/en/common.json"),
            mainMenu: require("./locales/en/mainMenu.json"),
            datePicker: require("./locales/en/datePicker.json"),
            mediaCellContent: require("./locales/en/mediaCellContent.json"),
            detailedFormCell: require("./locales/en/detailedFormCell.json"),
            // [EN] Timeline translations
            trademarkStatusTimeline: require("./locales/en/trademarkStatusTimeline.json"),
            designStatusTimeline: require("./locales/en/designStatusTimeline.json"),
            patentStatusTimeline: require("./locales/en/patentStatusTimeline.json"),
            // [EN] Grid and form translations
            customGrid: require("./locales/en/customGrid.json"),
            gridInfo: require("./locales/en/gridInfo.json"),
            pageTitle: require("./locales/en/pageTitle.json"),
            searchForm: require("./locales/en/searchForm.json"),
            designForm: require("./locales/en/designForm.json"),
            trademarkForm: require("./locales/en/trademarkForm.json"),
            patentForm: require("./locales/en/patentForm.json"),
            pasForm: require("./locales/en/pasForm.json"),
            designAppealForm: require("./locales/en/designAppealForm.json"),
            trademarkAppealForm: require("./locales/en/trademarkAppealForm.json"),
            patentAppealForm: require("./locales/en/patentAppealForm.json"),
            designOppositionForm: require("./locales/en/designOppositionForm.json"),
            trademarkOppositionForm: require("./locales/en/trademarkOppositionForm.json"),
            refusedTrademarkForm: require("./locales/en/refusedTrademarkForm.json"),
            trademarkInvalidationForm: require("./locales/en/trademarkInvalidationForm.json"),
            trademarkRevocationForm: require("./locales/en/trademarkRevocationForm.json"),
        },
        lv: {
            // [LV] Common translations
            common: require("./locales/lv/common.json"),
            mainMenu: require("./locales/lv/mainMenu.json"),
            datePicker: require("./locales/lv/datePicker.json"),
            mediaCellContent: require("./locales/lv/mediaCellContent.json"),
            detailedFormCell: require("./locales/lv/detailedFormCell.json"),
            // [LV] Timeline translations
            trademarkStatusTimeline: require("./locales/lv/trademarkStatusTimeline.json"),
            designStatusTimeline: require("./locales/lv/designStatusTimeline.json"),
            patentStatusTimeline: require("./locales/lv/patentStatusTimeline.json"),
            // [LV] Grid and form translations
            customGrid: require("./locales/lv/customGrid.json"),
            gridInfo: require("./locales/lv/gridInfo.json"),
            pageTitle: require("./locales/lv/pageTitle.json"),
            searchForm: require("./locales/lv/searchForm.json"),
            designForm: require("./locales/lv/designForm.json"),
            trademarkForm: require("./locales/lv/trademarkForm.json"),
            patentForm: require("./locales/lv/patentForm.json"),
            pasForm: require("./locales/lv/pasForm.json"),
            designAppealForm: require("./locales/lv/designAppealForm.json"),
            trademarkAppealForm: require("./locales/lv/trademarkAppealForm.json"),
            patentAppealForm: require("./locales/lv/patentAppealForm.json"),
            designOppositionForm: require("./locales/lv/designOppositionForm.json"),
            trademarkOppositionForm: require("./locales/lv/trademarkOppositionForm.json"),
            refusedTrademarkForm: require("./locales/lv/refusedTrademarkForm.json"),
            trademarkInvalidationForm: require("./locales/lv/trademarkInvalidationForm.json"),
            trademarkRevocationForm: require("./locales/lv/trademarkRevocationForm.json"),
        },
    },
    //ns: ['translations'],
    defaultNS: "common",
});

i18n.languages = ["en-US", "lv-LV"];

// https://github.com/i18next/react-i18next/issues/909
export default i18n;
