import FormDataGrid from "../../components/FormDataGrid/FormDataGrid";
import { DefaultColumns } from "./columnDefinitions";
import { defaultColumnTypes } from "./columnTypes";
import { formatFilterValue } from "./recordFilter";
import { PatentAppealsSearchFields } from "./PatentAppealsSearchFields";
import { useStore } from "../../storage/StoreProvider";
import { fetchPatentAppealData } from "../../services/patentAppealService";
import { FilterOperandType, FilterValue } from "../../models/FilterModel";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import qs from "qs";

interface IPatentAppelsQueryString {
    number?: string;
}

function useQuery() {
    const { search } = useLocation();

    return useMemo(
        () =>
            qs.parse(search, {
                ignoreQueryPrefix: true,
            }),
        [search]
    );
}

export default function PatentAppealGrid() {
    const [state, ,] = useStore();

    const queryParams: IPatentAppelsQueryString = useQuery();

    function rowIdSelector(rows: any) {
        return rows.map((patentAppeal) => {
            return patentAppeal.number;
        });
    }

    const filters = (params: IPatentAppelsQueryString) => {
        const tmp: FilterValue[] = [];

        for (const param in params) {
            if (Object.prototype.hasOwnProperty.call(params, param)) {
                const value = params[param];
                tmp.push({
                    Field: param,
                    Operator: FilterOperandType.Equal,
                    Value1: value,
                    Value2: null,
                });
            }
        }

        return tmp;
    };

    return (
        <FormDataGrid
            defaultColumns={DefaultColumns()}
            searchFields={PatentAppealsSearchFields(queryParams)}
            onFetchData={fetchPatentAppealData}
            filterValueFormatter={formatFilterValue}
            customColumnTypes={defaultColumnTypes(state.patentAppealEnums)}
            loadError={state.error}
            rows={state.patentAppeals ?? []}
            rowIdSelector={rowIdSelector}
            initialFilter={filters(queryParams)}
        />
    );
}
