import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import DetailedForm from "../../components/FormDataGrid/DetailedForm/DetailedForm";
import { ACTIONS } from "../../storage/dataReducer";
import { DefaultRows } from "./detailedFormRowDefinitions";
import { useStore } from "../../storage/StoreProvider";
import { FilterOperandType, FilterValue } from "../../models/FilterModel";
import {
    fetchPatentData,
    getPatentDocuments,
    getPatentEvents,
    getReferencedPatents,
} from "../../services/patentService";
import PatentStatusTimeline from "../../components/FormDataGrid/Timeline/PatentStatusTimeline/PatentStatusTimeline";
import { useTranslation } from "react-i18next";

interface PatentParamTypes {
    id: string;
}

export default function PatentsDetailedForm({ match }) {
    const [state, , getState] = useStore();
    const [patentData, setPatentData] = useState({});
    const [statusId, setStatusId] = useState(null);
    const { id } = useParams<PatentParamTypes>();
    const { i18n } = useTranslation();
    const [usedLanguage, setUsedLanguage] = useState(i18n.language);
    const patentRows = patentData ? DefaultRows([patentData])[0] : [];

    useEffect(() => {
        async function GetData() {
            const filter = [
                {
                    Field: "applicationNumber",
                    Operator: FilterOperandType.Equal, // TODO: Change to Equals for date value.
                    Value1: id,
                    Value2: null, // TODO: Add the Value2 for interval values.
                } as FilterValue,
            ];
            await fetchPatentData({
                page: 0,
                rowsPerPage: 1,
                sortModel: null,
                filter: filter,
                action: ACTIONS.GET_ROWS,
            });
        }

        const currentState = getState();
        const patent = currentState.patents.find(
            (p) => p.patentBasicInfo.applicationNumber === id
        );
        if (!patent || i18n.language !== usedLanguage) {
            GetData();
            setUsedLanguage(i18n.language);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    useEffect(() => {
        async function getRows() {
            const currentState = getState();
            const patent = currentState.patents.find(
                (p) => p.patentBasicInfo.applicationNumber === id
            );
            if (!patent) return;

            const documents =
                patent.documentInfo ??
                (await getPatentDocuments(
                    patent.patentBasicInfo.urls["Documents"]
                ));
            const events =
                patent.events ??
                (await getPatentEvents(patent.patentBasicInfo.urls["Events"]));
            const refPatents =
                patent.refPatents ??
                (await getReferencedPatents(
                    patent.patentBasicInfo.urls["Referenced"]
                ));

            setStatusId(patent.patentBasicInfo?.statusId);
            setPatentData({
                ...patent,
                documentInfo: documents,
                events: events,
                refPatents: refPatents,
            });
        }
        getRows();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.patentEnums, id]);

    return (
        <DetailedForm
            ids={[id]}
            rows={patentRows}
            match={match}
            showTimeline
            showBackButton
            timeline={<PatentStatusTimeline statusId={statusId} />}
            title={
                patentData && (patentData as any).patentBasicInfo
                    ? (patentData as any).patentBasicInfo.publicationNumber
                    : null
            }
        />
    );
}
