// Inspired by: https://transang.me/get-state-callback-with-usereducer/
import { Reducer, ReducerAction, ReducerState, useCallback, useMemo, useReducer, useRef } from "react";

/**
 * Similar to Redux Thunk:
 * - Allows to add middlewares
 * - Provides a convenient way to get the current state
 * Middlewares are good to use for logging or auth token assignment.
 * @param reducer 
 * @param initState 
 * @param initializer 
 * @param middlewares 
 * @returns array of the following function
 */
const useEnhancedReducer = <R extends Reducer<any, any>>(
    reducer: R,
    initState: ReducerState<R>,
    initializer?: Parameters<typeof useReducer>[2],
    middlewares: Array<(
        state: ReducerState<R>
    ) => (
            getState: () => ReducerState<R>
        ) => (
                next: (action: ReducerAction<R>) => any
            ) => (
                    action: ReducerAction<R>
                ) => any> = []
) => {
    const lastState = useRef(initState);
    const getState = useCallback(() => lastState.current, []);
    const enhancedReducer = useRef((
        state: ReducerState<R>,
        action: ReducerAction<R>
    ) => lastState.current = reducer(
        state,
        action
    )).current; // To prevent reducer called twice, per: https://github.com/facebook/react/issues/16295
    const [state, dispatch] = useReducer(
        enhancedReducer,
        initState,
        initializer
    );
    const middlewaresRef = useRef(middlewares);
    // Use useMemo instead of useRef to avoid redundant calculation
    const enhancedDispatch = useMemo(() => middlewaresRef.current.reduceRight(
        (acc, mdw) => action => mdw(state)(getState)(acc)(action),
        dispatch
    ), []);
    return [state, enhancedDispatch, getState];
}

export default useEnhancedReducer;