import { OutlinedInputProps } from "@material-ui/core/OutlinedInput";
import { Field } from "react-final-form";
import DateRangePickerBase from "./DateRangePickerBase";

interface DateRangePickerProps {
    name: string;
    StartDateInputProps?: Partial<OutlinedInputProps>;
    EndDateInputProps?: Partial<OutlinedInputProps>;
    startDateLimit?: number;
    className?: string;
    label?: string;
    defaultValue?: any;
}

export default function DateRangePicker(
    props: DateRangePickerProps
): JSX.Element {
    const {
        name,
        label,
        className,
        StartDateInputProps,
        EndDateInputProps,
        startDateLimit,
        defaultValue,
    } = props;
    return (
        <Field
            name={name}
            defaultValue={defaultValue}
            render={(fieldProps) => (
                <DateRangePickerBase
                    name={fieldProps.input.name}
                    label={label}
                    onChange={(value: any) => fieldProps.input.onChange(value)}
                    startDate={fieldProps.input.value?.startDate ?? null}
                    endDate={fieldProps.input.value?.endDate ?? null}
                    startDateLimit={startDateLimit}
                    StartDateInputProps={StartDateInputProps ?? null}
                    EndDateInputProps={EndDateInputProps}
                    className={className}
                />
            )}
        />
    );
}
