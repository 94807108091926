import React from "react";
import {
    createStyles,
    Theme,
    makeStyles,
    OutlinedInputProps,
    InputLabel,
    useMediaQuery,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import locale from "date-fns/locale/lv";
import { DatePickerToolbar } from "../DatePicker/DatePickerToolbar";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface DatePickerBaseProps {
    value: any;
    InputProps?: Partial<OutlinedInputProps>;
    label?: string;
    name?: string;
    className?: string;
    onChange?: (value: any) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        startDateField: {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
        endDateField: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        },
        label: {
            padding: theme.spacing(0, 0, 1, 0),
            color: theme.palette.secondary.contrastText,
        },
    })
);

if (locale && locale.options) {
    locale.options.weekStartsOn = 1;
}

export default function DatePickerBase(props: DatePickerBaseProps) {
    const classes = useStyles();
    const useMobileView = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("sm")
    );
    const { t } = useTranslation();

    const handleDateChange = (newDate?: Date) => {
        props.onChange(newDate);
    };

    console.log("here!");

    return (
        <React.Fragment>
            <InputLabel htmlFor={props.name} className={classes.label}>
                {props.label}
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    placeholder="dd/mm/yyyy"
                    InputProps={{
                        ...props.InputProps,
                        classes: {
                            notchedOutline: useMobileView
                                ? ""
                                : classes.startDateField,
                        },
                    }}
                    value={props.value}
                    InputAdornmentProps={{ position: "start" }}
                    onChange={(date) => {
                        if (!date) {
                            handleDateChange(null);
                        }
                    }}
                    onAccept={(date) => {
                        handleDateChange(date);
                    }}
                    onBlur={(e) => {
                        handleDateChange(
                            e.target.value
                                ? moment(e.target.value, "DD/MM/YYYY").toDate()
                                : null
                        );
                    }}
                    margin="dense"
                    size="small"
                    fullWidth
                    invalidDateMessage={t("datePicker:invalidDateMessage")}
                    ToolbarComponent={(props) => DatePickerToolbar(props)}
                    views={["year", "month", "date"]}
                    maxDateMessage={t("datePicker:maxDateMessage")}
                />
            </MuiPickersUtilsProvider>
        </React.Fragment>
    );
}
