import React from "react";
import {
    Link as RouterLink,
    LinkProps as RouterLinkProps,
} from "react-router-dom";
import { createStyles, makeStyles, MenuItem, Theme } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import clsx from "clsx";

export interface ListItemLinkProps {
    text: string;
    color: string;
    to: string;
    level: number;
}

// Theme-dependent styles.
// createStyles is a helper function for TS compile.
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:hover": {
                background: "inherit",
            },
        },
        active: {
            color: theme.palette.accent.main,
            "&::after": {
                left: 0,
                top: 0,
                bottom: 0,
                position: "absolute",
                width: ".25em",
                borderRadius: "0 .3125em .3125em 0",
                background: theme.palette.accent.main,
                float: "left",
                verticalAlign: "middle",
                marginRight: ".625em",
                content: '""',
            },
        },
        level1: {
            paddingLeft: "3em",
        },
    })
);

export default function ListItemLink(props: ListItemLinkProps) {
    const { text, to, level } = props;
    const classes = useStyles();
    const location = useLocation();

    const renderLink = React.useMemo(
        () =>
            React.forwardRef<any, Omit<RouterLinkProps, "to">>(
                (itemProps, ref) => (
                    <RouterLink to={to} ref={ref} {...itemProps} />
                )
            ),
        [to]
    );

    const isActive = location.pathname.startsWith(to);

    return (
        <MenuItem
            className={clsx(
                classes.root,
                isActive ? classes.active : null,
                level == 1 ? classes.level1 : null
            )}
            dense
            component={renderLink}
        >
            {text}
        </MenuItem>
    );
}
