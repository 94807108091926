import {
    Button,
    Checkbox,
    FormControlLabel,
    TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
    allGridColumnsSelector,
    GridColDef,
    GridPanelContent,
    GridPanelFooter,
    GridPanelHeader,
    GridPanelWrapper,
    useGridSelector,
    useGridSlotComponentProps,
} from "@material-ui/data-grid";
import * as React from "react";
import { useTranslation } from "react-i18next";
import GridColDefExtended from "../../forms/GridColDefExtended";
import { GridStorage } from "../../storage/browserStorage";

const useStyles = makeStyles(
    {
        container: {
            padding: "8px 0px 8px 8px",
        },
        column: {
            display: "flex",
            justifyContent: "space-between",
            padding: "1px 8px 1px 7px",
        },
        switch: {
            marginRight: 4,
        },
        dragIcon: {
            justifyContent: "flex-end",
        },
    },
    { name: "MuiDataGridColumnsPanel" }
);

export interface GridColumnsPanelProps {
    defaultColumns: GridColDefExtended[];
}

const checkFieldName = "__check__";

export function GridColumnsPanel(props: GridColumnsPanelProps) {
    const classes = useStyles();
    const { defaultColumns } = props;
    const { apiRef } = useGridSlotComponentProps();
    const searchInputRef = React.useRef<HTMLInputElement>(null);
    const columns = useGridSelector(apiRef, allGridColumnsSelector);
    const [searchValue, setSearchValue] = React.useState("");
    const storage = GridStorage.getInstance();
    const { t } = useTranslation();

    const toggleColumn = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            const { name } = event.target as HTMLInputElement;
            const column = apiRef.current.getColumn(name);

            apiRef.current.setColumnVisibility(name, !!column.hide);
            const savedColumns =
                storage.getColumns() ??
                columns.filter((c) => !c.hide).map((c) => c.field);

            if (savedColumns.length && !column.hide) {
                const index = savedColumns.indexOf(name);
                savedColumns.splice(index, 1);
            } else {
                savedColumns.push(name);
            }

            storage.setColumns(savedColumns);
            const checkCol = apiRef!.current.getColumn(checkFieldName);

            if (
                checkCol.hide ||
                (apiRef!.current.getVisibleColumns().length === 2 &&
                    savedColumns.find((col) => col.field === name))
            ) {
                apiRef.current.setColumnVisibility(
                    checkFieldName,
                    !!checkCol.hide
                );
            }
        },
        [apiRef]
    );

    const toggleAllColumns = React.useCallback(
        (value: boolean) => {
            const updatedColumns = columns.map((col) => {
                col.hide = value;
                return col;
            });

            apiRef.current.updateColumns(updatedColumns);

            // TODO: Maybe combine this in one lamda function?
            let savedColumns = [];
            if (!value) {
                savedColumns = columns.map((col) => col.field);
            } else {
                savedColumns = [];
            }

            storage.setColumns(savedColumns);
        },
        [apiRef, columns]
    );

    const showDefaultColumns = React.useCallback(() => {
        const updatedColumns = columns.map((col, index) => {
            col.hide =
                col.field === checkFieldName
                    ? false
                    : defaultColumns[index - 1].hideByDefault;
            return col;
        });

        apiRef.current.updateColumns(updatedColumns);

        // TODO: Maybe combine this in one lamda function?
        const savedColumns = defaultColumns
            .filter((col) => !col.hideByDefault)
            .map((col) => col.field);
        storage.setColumns(savedColumns);
    }, [apiRef, columns]);

    const showAllColumns = React.useCallback(
        () => toggleAllColumns(false),
        [toggleAllColumns]
    );
    const loadDefaultColumns = React.useCallback(
        () => showDefaultColumns(),
        [showDefaultColumns]
    );
    const hideAllColumns = React.useCallback(
        () => toggleAllColumns(true),
        [toggleAllColumns]
    );

    const handleSearchValueChange = React.useCallback((event) => {
        setSearchValue(event.target.value);
    }, []);

    const currentColumns = React.useMemo(
        () =>
            !searchValue
                ? columns.filter((col) => col.field !== checkFieldName)
                : columns.filter(
                      (column) =>
                          column.field !== checkFieldName &&
                          (column.field
                              .toLowerCase()
                              .indexOf(searchValue.toLowerCase()) > -1 ||
                              (column.headerName &&
                                  column.headerName
                                      .toLowerCase()
                                      .indexOf(searchValue.toLowerCase()) > -1))
                  ),
        [columns, searchValue]
    );

    React.useEffect(() => {
        searchInputRef.current!.focus();
    }, []);

    return (
        <GridPanelWrapper>
            <GridPanelHeader>
                <TextField
                    label={apiRef.current.getLocaleText(
                        "columnsPanelTextFieldLabel"
                    )}
                    placeholder={apiRef.current.getLocaleText(
                        "columnsPanelTextFieldPlaceholder"
                    )}
                    inputRef={searchInputRef}
                    value={searchValue}
                    onChange={handleSearchValueChange}
                    variant="standard"
                    fullWidth
                />
            </GridPanelHeader>
            <GridPanelContent>
                <div className={classes.container}>
                    {currentColumns.map(
                        (column) =>
                            column.field !== checkFieldName && (
                                <div
                                    key={column.field}
                                    className={classes.column}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                className={classes.switch}
                                                checked={!column.hide}
                                                onClick={toggleColumn}
                                                name={column.field}
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label={
                                            column.headerName || column.field
                                        }
                                    />
                                </div>
                            )
                    )}
                </div>
            </GridPanelContent>
            <GridPanelFooter>
                <Button onClick={hideAllColumns} color="primary">
                    {apiRef.current.getLocaleText("columnsPanelHideAllButton")}
                </Button>
                {defaultColumns && (
                    <Button onClick={loadDefaultColumns} color="primary">
                        {t("common:default")}
                    </Button>
                )}
                <Button onClick={showAllColumns} color="primary">
                    {apiRef.current.getLocaleText("columnsPanelShowAllButton")}
                </Button>
            </GridPanelFooter>
        </GridPanelWrapper>
    );
}

export default GridColumnsPanel;
