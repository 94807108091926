import { Box, MenuList, Paper, Theme, useMediaQuery } from "@material-ui/core";
import { GetMenuData } from "./menuData";
import ListItemLink from "./ListItemLink";

// Theme-dependent styles.
// createStyles is a helper function for TS compile.
/*const useStyles = makeStyles((theme: Theme) => createStyles({    
}));*/

export default function AppMenu() {
    const menuData = GetMenuData();
    const useMobileView = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("sm")
    );

    const getMenuItems = () => {
        return menuData.map(({ label, href, level }) => {
            return (
                <ListItemLink
                    key={label}
                    color="inherit"
                    to={href}
                    text={label}
                    level={level}
                />
            );
        });
    };

    // Desktop view.
    const displayDesktop = () => {
        return (
            <Box display="flex">
                <Box flexGrow={1} />
                <Paper variant="outlined">
                    <MenuList>{getMenuItems()}</MenuList>
                </Paper>
            </Box>
        );
    };

    // Mobile view.
    const displayMobile = () => {
        return (
            <Box flexGrow={1}>
                <Paper variant="outlined">
                    <MenuList>{getMenuItems()}</MenuList>
                </Paper>
            </Box>
        );
    };

    return useMobileView ? displayMobile() : displayDesktop();
}
