import FieldWithTooltip from "../../components/FieldWithTooltip";
import { SearchField } from "../../components/FormDataGrid/SearchForm";
import { Collection } from "../../models/DefaultResponseModel";
import SearchType from "../../models/grid/SearchType";
import { GridStorage } from "../../storage/browserStorage";
import { useStore } from "../../storage/StoreProvider";

export const DesignOppositionsSearchFields = (
    searchMeta: Collection[],
    initialValues?: any
): SearchField[] => {
    const [state, ,] = useStore();
    const enums = state.designOppositionEnums;
    const storage = GridStorage.getInstance();
    const filterModel = storage.getFilterModel();

    return searchMeta.map((fieldMeta) => {
        const field = fieldMeta.entity;
        const fieldName = field.name.replace(".", "%");

        let options = [];

        if (enums && enums[field.enum]) {
            switch (field.type) {
                case SearchType.orderedMultiList:
                    if (enums[field.enum]) {
                        options = enums[field.enum].map((element) => ({
                            label: element.name,
                            value: element.id,
                        }));
                    }
                    break;
                case SearchType.multiList:
                case SearchType.list:
                    options = Object.keys(enums[field.enum]).map((key) => ({
                        label: enums[field.enum][key],
                        value: key,
                    }));
                    break;
                default:
                    break;
            }
        }

        return {
            size: 11,
            field: (
                <FieldWithTooltip
                    name={fieldName}
                    label={field.label}
                    tooltipText={field.tooltip}
                    multiple
                    type={
                        (field.type as SearchType) === SearchType.date
                            ? SearchType.dateRange
                            : (field.type as SearchType)
                    }
                    preciseSearch={["Title", "Number"].includes(fieldName)}
                    options={options}
                    defaultValue={
                        initialValues && field.name in initialValues
                            ? [initialValues[field.name]]
                            : filterModel && filterModel[fieldName]
                    }
                />
            ),
            label: field.label,
            name: field.name,
            showOnExtendedSearch: !field.show,
            defaultStateOnExtendedSearch: field.defaultStateOnExtendedSearch,
            classificationUrl: field.classificationUrl,
        };
    });
};
