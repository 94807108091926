import { FilterOperandType } from "../../models/FilterModel";

export const formatFilterValue = (field: string, value: any, usePreciseSearch: boolean) => {
    // TODO: Improve this.
    switch (field) {
        // Date intervals.
        case 'applicationDate':
        case 'publicationDate':
        case 'PublicationDate':
        case 'PublicationADate':
        case 'grantDate':
        case 'nextAnnualFee':
            return {
                Field: field,
                Operator: FilterOperandType.Between, // TODO: Precise location.
                Value1: value?.startDate ? formatDate(value.startDate) : (value?.endDate ? formatDate(value.endDate) : ''), // TODO: Add Date parse validation.
                Value2: value?.endDate ? formatDate(value.endDate) : (value?.startDate ? formatDate(value.startDate) : ''),
            };
        // Single date value.
        case 'Priorities%FilingDate':
            return {
                Field: field.replace('%', '.'),
                Operator: FilterOperandType.Equal,
                Value1: value ? formatDate(value) : '',
                Value2: null,
            };
        // Enums.
        case 'statusId':
        case 'DocumentKindId':
            return {
                Field: field,
                Operator: FilterOperandType.Equal, // Does not work with FilterOperandType.Contains.
                Value1: value?.value ? value.value : '',
                Value2: null,
            };
        default:
            return {
                Field: field.replace('%', '.'),
                Operator: usePreciseSearch ? FilterOperandType.Equal : FilterOperandType.Contains, // TODO: Change to Equals for date value.
                Value1: value.trim(),
                Value2: null, // TODO: Add the Value2 for interval values.
            };
    }
}

const formatDate = (date: Date) => {
    return date.toDateString();
}