import { Box, Step, StepLabel } from "@material-ui/core";
import Stepper from '@material-ui/core/Stepper';
import React from "react";
import { ColorlibConnector, ColorlibStepIcon, EmptyStepIcon, EmptyStepIconWithLogo, LabelConnector, LastEmptyStepIcon, useStyles } from "../TimelineBase";
import ForwardIcon from '@material-ui/icons/Forward';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export interface EPDefaultTimelineProps {
    activeStep: number;
    lastStep?: string;
    showLastLabel?: boolean;
    isEnd?: boolean;
    isDashed?: boolean;
    lastLabel?: string;
    additionalSteps?: string[];
    additionalLabels?: string[];
}

const getStepLabels = () => {
    return [i18next.t("patentStatusTimeline:stepLabel2"), i18next.t("patentStatusTimeline:stepLabel5"), ];
}

const getSteps = () => {
    return [i18next.t("patentStatusTimeline:step4"), i18next.t("patentStatusTimeline:step5"), i18next.t("patentStatusTimeline:step6"), ];
}

export default function EPDefaultTimeline({
    activeStep,
    lastStep,
    showLastLabel,
    isEnd,
    isDashed,
    lastLabel,
    additionalSteps,
    additionalLabels }: EPDefaultTimelineProps) {
    const classes = useStyles();
    const labels = getStepLabels();
    const steps = getSteps();
    const { t } = useTranslation();
    const defaultLastLabel = t("patentStatusTimeline:defaultLastLabelMessage2");

    return (
        <React.Fragment>
            <Box pt={5} flexShrink={1}>
                <ForwardIcon className={classes.icon} />
            </Box>
            <Box flexGrow={2} className={classes.timeline}>
                <Stepper alternativeLabel activeStep={-1} connector={<LabelConnector />}>
                    {labels.map((label, index) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={(props) => index === 0 ? EmptyStepIconWithLogo(props, `${process.env.PUBLIC_URL}/EPO_logotips.png`) : EmptyStepIcon(props, label)}></StepLabel>
                        </Step>
                    ))}
                    {additionalSteps && isDashed && <Step className={classes.emptyStep}></Step>}
                    {additionalLabels?.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={(props) => EmptyStepIcon(props, label)}></StepLabel>
                        </Step>
                    ))}
                    {showLastLabel && !lastLabel && (
                        <Step>
                            <StepLabel StepIconComponent={(props) => LastEmptyStepIcon(props, defaultLastLabel)}></StepLabel>
                        </Step>
                    )}
                    {showLastLabel && lastLabel && !additionalSteps && (
                        <Step>
                            <StepLabel StepIconComponent={(props) => EmptyStepIcon(props, lastLabel)}></StepLabel>
                        </Step>
                    )}
                    {showLastLabel && lastLabel && additionalSteps && isDashed
                        && (
                            <Step className={classes.emptyStep}></Step>
                        )}
                    {showLastLabel && lastLabel && additionalSteps
                        && (
                            <Step>
                                <StepLabel StepIconComponent={(props) => EmptyStepIcon(props, lastLabel)}></StepLabel>
                            </Step>
                        )}
                    {<Step className={classes.emptyStep}></Step>}
                    {<Step className={classes.emptyStep}></Step>}
                    {<Step className={classes.emptyStep}></Step>}
                    {!additionalSteps && !isEnd && <Step className={classes.emptyStep}></Step>}
                    {!additionalSteps && isEnd && <Step className={classes.emptyStep}></Step>}
                    {additionalSteps && !isEnd && <Step className={classes.emptyStep}></Step>}
                    {isDashed && additionalSteps && !isEnd && <Step className={classes.emptyStep}></Step>}
                    {isDashed && !additionalSteps && <Step className={classes.emptyStep}></Step>}
                    {isDashed && !additionalSteps && <Step className={classes.emptyStep}></Step>}
                    {isDashed && !additionalSteps && <Step className={classes.emptyStep}></Step>}
                </Stepper>
                <Stepper
                    alternativeLabel
                    activeStep={(lastStep ? activeStep + 3 : activeStep) + (isDashed ? additionalSteps?.length ?? 0 : 0)}
                    connector={<ColorlibConnector />}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel className={classes.label} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                    {isDashed && additionalSteps && <Step className={classes.emptyStep}></Step>}
                    {isDashed && additionalSteps && <Step className={classes.emptyStep}></Step>}
                    {additionalSteps?.map((label) => (
                        <Step key={label}>
                            <StepLabel className={classes.label} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                    {!additionalSteps && <Step className={classes.emptyStep}></Step>}
                    <Step className={classes.emptyStep}></Step>
                    {lastStep && <Step className={classes.emptyStep}></Step>}
                    {!lastStep && additionalSteps && <Step className={classes.emptyStep}></Step>}
                    {lastStep && <Step>
                        <StepLabel className={classes.label} StepIconComponent={ColorlibStepIcon}>{lastStep}</StepLabel>
                    </Step>}
                    {!isEnd && lastStep && <Step className={classes.emptyStep}></Step>}
                    {!isEnd && <Step className={classes.emptyStep}></Step>}
                    {!isEnd && !additionalSteps && <Step className={classes.emptyStep}></Step>}
                </Stepper>
            </Box>
        </React.Fragment>
    );
}