import { createStyles, makeStyles, TableCell, TableRow, Theme, Typography } from "@material-ui/core";
import DetailedFormCell from "../DetailedFormTableCell/DetailedFormCell";

const useStyles = makeStyles((theme: Theme) => createStyles({
    name: {
        fontWeight: "bold",
        [theme.breakpoints.up('md')]: {
            minWidth: 200,
        },
        paddingTop: 0,
        paddingBottom: 0
    },
    cellPadding: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    cellPaddingHoriz: {
        paddingRight: 0,
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
    }
}));

export interface RowProps {
    name: string;
    caption: string | number;
    type: string;
    value: any;
    printView?: boolean;
}

export default function Row(props: RowProps) {
    const classes = useStyles();
    const { name, caption, type, value, printView} = props;

    return (
        <TableRow key={name}>
            <TableCell className={props.printView && caption ? classes.cellPadding : (props.printView && !caption ? classes.cellPaddingHoriz : "")}>
                <Typography variant="caption">{caption ? (typeof caption === 'string' ? caption : `(${caption})`) : ""}</Typography>
            </TableCell>
            <TableCell component="th" scope="row" className={classes.name}>
                {name}
            </TableCell>
            <TableCell className={props.printView && caption ? classes.cellPadding : (props.printView && !caption ? classes.cellPaddingHoriz : "")}>
                <DetailedFormCell type={type} value={value} />
            </TableCell>
            <TableCell className={props.printView && caption ? classes.cellPadding : (props.printView && !caption ? classes.cellPaddingHoriz : "")} />
        </TableRow>
    );
}
