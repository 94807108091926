import React from "react";
import lilac from '../themes/lilac';

export const themes = {
    lilac,
};

// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
export const ThemeContext = React.createContext({
    // Default values.
    theme: themes.lilac,
    toggleTheme: () => { },
});