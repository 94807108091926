import {
    ButtonBase,
    ButtonBaseProps,
} from "@material-ui/core";
import {
    Link as RouterLink,
    LinkProps as RouterLinkProps,
} from "react-router-dom";
import React from "react";


export interface ButtonRouterLinkProps extends ButtonBaseProps {
    to: string;
}

export default function ButtonRouterLink (
    props: React.PropsWithChildren<ButtonRouterLinkProps>
) {
    const { to, className, children } = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef<any, Omit<RouterLinkProps, "to">>(
                (itemProps, ref) => (
                    <RouterLink to={to} ref={ref} {...itemProps} />
                )
            ),
        [to]
    );

    return (
        <ButtonBase className={className} component={renderLink}>
            {children}
        </ButtonBase>
    );
};