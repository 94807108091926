import { GridCellParams, GridValueGetterParams } from "@material-ui/data-grid";
import renderCellExpand, {
    ILinkValue,
} from "../../components/FormDataGrid/RenderCellExpand";
import { getPatentPersonValue } from "../../components/FormDataGrid/formDataUtils";
import { GetShortDateString } from "../../global/utils";
import { useTranslation } from "react-i18next";
import { GridStorage } from "../../storage/browserStorage";
import GridColDefExtended from "../GridColDefExtended";
import { useStore } from "../../storage/StoreProvider";
import React from "react";

function getLinkValue(
    params: GridValueGetterParams,
    label?: string,
    key?: string,
    url?: string
): ILinkValue {
    if (!params.value) return null;
    const pageId = (
        key
            ? (params.getValue(params.id, key) as ILinkValue)?.label
            : params.value
    ).toString();
    const pageUrl = `${url || "patents"}/${pageId}`;
    const urlLabel = label || pageId;
    return { url: pageUrl, label: urlLabel } as ILinkValue;
}

function getPublicationLinkValue(params: GridValueGetterParams): ILinkValue {
    if (!params.value) return null;
    const label = params.value;
    const pageUrl = params.getValue(params.id, "applicationNumber") as ILinkValue;
    const url = `patents/${pageUrl}`;
    return { url: pageUrl?.url || url, label: label } as ILinkValue;
}

function getPasLinks(params: GridValueGetterParams): ILinkValue[] {
    if (!params.value) return null;
    return (params.value as any[]).map((doc) => {
        const pas = doc.entity.pasBasicInfo;
        return {
            url: `pas/${encodeURIComponent(pas.pasNumber)}`,
            label: pas.pasNumber,
        } as ILinkValue;
    });
}

function getLicenseeValue(params: GridValueGetterParams) {
    const values = params.value as any[];
    const result = values?.map((value) => {
        const date = value.agreementDate
            ? GetShortDateString(value.agreementDate)
            : "";
        const info = value.info;
        return date ? `${date} - ${info}` : info;
    });
    return result?.length ? result.join(",\n") : undefined;
}

export function DefaultColumns(): GridColDefExtended[] {
    const { t, i18n } = useTranslation();
    const storage = GridStorage.getInstance();
    const visibleColumns = storage.getColumns();
    const [state, ,] = useStore();
    const fieldInfo = state.patentFieldInfo?.sortable;

    return React.useMemo(
        () => [
            {
                field: i18n.language === "lv-LV" ? "titleLV" : "titleEN",
                headerName: t("patentForm:titleLV"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 350,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("titleLV")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("titleLV") ?? false
                    : false,
            },
            {
                field: "statusId", // Create enum class
                headerName: t("patentForm:statusIdLabel"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 350,
                type: "statusType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("statusId")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("statusId") ?? false
                    : false,
            },
            {
                field: "applicationNumber",
                headerName: t("patentForm:applicationNumber"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, true),
                valueGetter: getLinkValue,
                headerAlign: "center",
                width: 150,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("applicationNumber")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("applicationNumber") ?? false
                    : false,
            },
            {
                field: "applicationDate", // Create enum class
                headerName: t("patentForm:applicationDate"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 130,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("applicationDate")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("applicationDate") ?? false
                    : false,
            },
            {
                field: "publicationNumber",
                headerName: t("patentForm:publicationNumber"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, true),
                valueGetter: getPublicationLinkValue,
                headerAlign: "center",
                width: 150,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("publicationNumber")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("publicationNumber") ?? false
                    : false,
            },
            {
                field: "publicationDate",
                headerName: t("patentForm:publicationDateLabel"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 150,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("publicationDate")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("publicationDate") ?? false
                    : false,
            },
            {
                field: "ipc",
                headerName: t("patentForm:ipcNumberLabel"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 170,
                type: "ipcNumberType",
                hide: visibleColumns ? !visibleColumns?.includes("ipc") : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("ipc") ?? false
                    : false,
            },
            {
                field: "applicantOrOwner",
                headerName: t("patentForm:applicantOrOwner"),
                valueGetter: getPatentPersonValue,
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 300,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("applicantOrOwner")
                    : true,
                hideByDefault: true,
                sortable: false,
            },
            {
                field: "designer",
                headerName: t("patentForm:inventors"),
                valueGetter: getPatentPersonValue,
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 300,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("designer")
                    : true,
                hideByDefault: true,
                sortable: false,
            },
            {
                field: "representative",
                headerName: t("patentForm:representative"),
                valueGetter: getPatentPersonValue,
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 300,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("representative")
                    : true,
                hideByDefault: true,
                sortable: false,
            },
            {
                field: "pas",
                headerName: t("patentForm:pasNumber"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, true),
                valueGetter: getPasLinks,
                headerAlign: "center",
                width: 200,
                type: "string",
                hide: visibleColumns ? !visibleColumns?.includes("pas") : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("pas") ?? false
                    : false,
            },
            {
                field: "nextAnnualFee",
                headerName: t("patentForm:nextAnnualFee"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                headerAlign: "center",
                width: 200,
                type: "dateType",
                hide: visibleColumns
                    ? !visibleColumns?.includes("nextAnnualFee")
                    : false,
                hideByDefault: false,
                sortable: fieldInfo
                    ? fieldInfo.includes("nextAnnualFee") ?? false
                    : false,
            },
            {
                field: "appealValue",
                headerName: t("patentForm:appealValue"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, true),
                valueGetter: (params: GridValueGetterParams) =>
                    getLinkValue(
                        params,
                        t("patentForm:viewLabel"),
                        "applicationNumber",
                        "/appeal/patents"
                    ),
                headerAlign: "center",
                width: 130,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("appealValue")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("appealValue") ?? false
                    : false,
            },
            {
                field: "licencees",
                headerName: t("patentForm:licencees"),
                renderCell: (params: GridCellParams) =>
                    renderCellExpand(params, false),
                valueGetter: getLicenseeValue,
                headerAlign: "center",
                width: 300,
                type: "string",
                hide: visibleColumns
                    ? !visibleColumns?.includes("licencees")
                    : true,
                hideByDefault: true,
                sortable: fieldInfo
                    ? fieldInfo.includes("licencees") ?? false
                    : false,
            },
        ],
        [visibleColumns, fieldInfo, i18n.language]
    );
}
