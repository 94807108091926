import {
    Box,
    makeStyles,
    Theme,
    createStyles,
    Container,
    Hidden,
} from "@material-ui/core";
import Header from "../Header";
import AppMenu from "../AppMenu";
import Footer from "../Footer";
import ScrollToTop from "../ScrollToTop";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexGrow: 1,
            padding: theme.spacing(0, 0, 0, 3),
            [theme.breakpoints.down("md")]: {
                padding: 0,
            },
        },
        container: {
            display: "flex",
            alignItems: "flex-start",
            padding: theme.spacing(3, 0, 3, 0),
            [theme.breakpoints.down("sm")]: {
                padding: theme.spacing(3, 0, 3, 0),
            },
        },
        offsetBackground: {
            backgroundColor: theme.palette.accent.light,
            height: "170px",
            position: "absolute",
            width: "100%",
            zIndex: -1,
        },
    })
);

const DefaultTemplate = (props: React.PropsWithChildren<{}>) => {
    const { children } = props;
    const classes = useStyles();

    return (
        <React.Fragment>
            <Header />
            <div className={classes.offsetBackground} />
            <ScrollToTop />
            <Container className={classes.container}>
                <AppMenu />
                <Hidden smDown>
                    <Box className={classes.root}>{children}</Box>
                </Hidden>
            </Container>
            <Footer />
        </React.Fragment>
    );
};

export default DefaultTemplate;
