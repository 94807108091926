import { SearchField } from "../../components/FormDataGrid/SearchForm";
import FieldWithTooltip from "../../components/FieldWithTooltip";
import SearchType from "../../models/grid/SearchType";
import { useStore } from "../../storage/StoreProvider";
import { useTranslation } from "react-i18next";
import { GridStorage } from "../../storage/browserStorage";

export function PasSearchFields(): SearchField[] {
    const [state, ,] = useStore();
    const enums = state.pasEnums;
    const { t } = useTranslation();
    const storage = GridStorage.getInstance();
    const filterModel = storage.getFilterModel();

    return state.pasSearchMeta.map((fieldMeta) => {
        const field = fieldMeta.entity;
        const fieldName = field.name.replace(".", "%");
        return {
            size: 11,
            field: (
                <FieldWithTooltip
                    name={fieldName}
                    label={field.label}
                    tooltipText={field.tooltip}
                    multiple
                    startDateLimit={15}
                    type={field.type as SearchType}
                    options={
                        enums && enums[field.enum]
                            ? Object.keys(enums[field.enum]).map((key) => ({
                                  label: enums[field.enum][key],
                                  value: key,
                              }))
                            : []
                    }
                    defaultValue={filterModel && filterModel[fieldName]}
                />
            ),
            label: field.label,
            name: field.name,
            showOnExtendedSearch: !field.show,
            defaultStateOnExtendedSearch: field.defaultStateOnExtendedSearch,
        };
    });
}
