import axios, { AxiosError, AxiosResponse } from "axios";
import { ACTIONS } from "../storage/dataReducer";
import DefaultRequestModel from "../models/DefaultRequestModel";
import DefaultResponseModel, { Collection } from "../models/DefaultResponseModel";
import * as Base from './baseService';
import { Store } from '../storage/StoreProvider';
import EnumsResponseModel from "../models/responses/EnumsResponseModel";
import Resource from "../models/grid/Resource";
import FieldInfoResponseModel from "../models/responses/FieldInfoResponseModel";
import i18n from "../i18n/config";

export async function fetchPasData(props: Base.DefaultFetchDataProps): Promise<any> {
    const resource = Resource.PAS;
    const endpoint = (await Base.getBackendDefinedAPI(resource)) ?? Base.getFrontendDefinedAPI(resource);
    // https://github.com/facebook/react/issues/15344
    // To improve current solution due to react bug - use React Redux.
    return new Promise(async resolve => {
        Store.dispatch({ type: ACTIONS.GET_ROWS });
        // Check if we should return promise immediately
        // It is used for data retrieval without filter validation
        if (props.action === ACTIONS.GET_ROWS) resolve({});

        await axios.get<DefaultRequestModel, AxiosResponse<DefaultResponseModel>>(endpoint, {
            params: Base.buildGridDataRequest(props as Base.DefaultProps),
            headers: Base.defaultRequestHeaders(),
        })
            .then(async response => {
                const data = await Base.buildGridData(response.data);
                const rows = await Promise.all(data.rows.map(async row => {
                    // All grid data is placed under dataKey for easier data extraction
                    // Following actions are to fill the needs of the grid component
                    const dataKey = Base.getDataKey(Resource.PAS);
                    const rowData = row[dataKey];
                    if (rowData) {
                        // Gets additional data
                        const personsResourceKey = Resource[Resource.Persons];
                        const persons = await Base.getResourceData<DefaultResponseModel>(
                            Resource.Persons,
                            row.urls && personsResourceKey in row.urls ? row.urls[personsResourceKey] : null);

                        const publicationsResourceKey = Resource[Resource.Publications];
                        const publications = await Base.getResourceData<DefaultResponseModel>(
                            Resource.Publications,
                            row.urls && publicationsResourceKey in row.urls ? row.urls[publicationsResourceKey] : null);

                        const documentsResourceKey = Resource[Resource.Documents];
                        const documents = await Base.getResourceData<DefaultResponseModel>(
                            Resource.Documents,
                            row.urls && documentsResourceKey in row.urls ? row.urls[documentsResourceKey] : null);

                        const eventsResourceKey = Resource[Resource.Events];
                        const events = await Base.getResourceData<DefaultResponseModel>(
                            Resource.Events,
                            row.urls && eventsResourceKey in row.urls ? row.urls[eventsResourceKey] : null);

                        const paymentsResourceKey = Resource[Resource.Payments];
                        const payments = await Base.getResourceData<DefaultResponseModel>(
                            Resource.Payments,
                            row.urls && paymentsResourceKey in row.urls ? row.urls[paymentsResourceKey] : null);

                        const patentResourceKey = Resource[Resource.Patent];
                        const patent = await Base.getResourceData<Collection>(
                            Resource.Patent,
                            row.urls && patentResourceKey in row.urls ? row.urls[patentResourceKey] : null);

                        const patentData = {
                            ...patent?.entity,
                            urls: patent?.hrefs ? Base.convertHrefsToDictionary(patent.hrefs) : null
                        }
                        // Builds grid row
                        let pasRow = { ...row };
                        // Sets additional data
                        pasRow[dataKey] = {
                            ...rowData,
                            patent: patentData,
                            persons: persons?.collection,
                            documents: documents?.collection,
                            events: events?.collection,
                            payments: payments?.collection,
                            urls: row.urls
                        };
                        return pasRow;
                    }
                    return row;
                }));

                const payload = {
                    ...data,
                    rows: rows
                };
                Store.dispatch({
                    type: ACTIONS.PAS_ROWS, payload: payload,
                });
                resolve(payload);

                // Fetch enums after getting urls to other resources                
                const enumsResourceKey = Resource[Resource.Enums];
                fetchPasEnums(payload.urls && enumsResourceKey in payload.urls ? payload.urls[enumsResourceKey] : null);
                // Fetch search meta after getting urls to other resources                
                const searchMetaResourceKey = Resource[Resource.SearchMeta];
                fetchPasSearchMeta(payload.urls && searchMetaResourceKey in payload.urls ? payload.urls[searchMetaResourceKey] : null);

                const fieldsResourceKey = Resource[Resource.FieldInfo];
                fetchPasFieldInfo(payload.urls && fieldsResourceKey in payload.urls ? payload.urls[fieldsResourceKey] : null);
            })
            .catch((e: AxiosError) => {
                const gridError = Base.buildGridDataError(e, i18n.t(['pasForm:failedToLoad']));
                Store.dispatch(gridError);
                resolve(gridError.payload);
            });
    });
}

export async function fetchPasEnums(url?: string) {
    Store.dispatch({ type: ACTIONS.GET_PAS_ENUMS });
    try {
        const enumResponse = await Base.getResourceData<EnumsResponseModel>(Resource.Enums, url);
        Store.dispatch({
            type: ACTIONS.PAS_ENUMS, payload: {
                enums: enumResponse.enums,
            }
        });
    } catch (e) {
        Store.dispatch({
            type: ACTIONS.ERROR_PAS_ENUMS,
            payload: { error: Base.formatErrorResponse(e) ?? 'Nesanāca ielādēt PAS enum vērtības.' }
        });
    }
}

export async function fetchPasFieldInfo(url?: string) {
    Store.dispatch({ type: ACTIONS.GET_PAS_FIELD_INFO });
    try {
        const fieldResponse = await Base.getResourceData<FieldInfoResponseModel>(Resource.FieldInfo, url);
        Store.dispatch({
            type: ACTIONS.PAS_FIELD_INFO, payload: {
                fields: fieldResponse.fields,
            }
        });
    } catch (e) {
        Store.dispatch({
            type: ACTIONS.ERROR_PAS_FIELD_INFO,
            payload: { error: Base.formatErrorResponse(e) ?? 'Nesanāca ielādēt PAS field info vērtības.' }
        });
    }
}


export async function fetchPasSearchMeta(url?: string) {
    Store.dispatch({ type: ACTIONS.GET_PAS_SEARCH_META });
    try {
        const response = await Base.getResourceData<DefaultResponseModel>(Resource.SearchMeta, url);
        Store.dispatch({
            type: ACTIONS.PAS_SEARCH_META, payload: {
                searchMeta: response.collection,
            }
        });
    } catch (e) {
        Store.dispatch({
            type: ACTIONS.ERROR_PAS_SEARCH_META,
            payload: { error: e.response ? `Servera kļūda — ${e.response.data}` : 'Nesanāca ielādēt meklēšanas lauku meta informāciju.' }
        });
    }
}

export async function getPASPatent(url: string) {
    try {
        const pas = await Base.getResourceData<Collection>(Resource.Patent, url);
        return pas.entity;
    } catch (e) {
        console.error(e);
        return null;
    }
}

export async function getPASEvents(url: string) {
    try {
        const events = await Base.getResourceData<DefaultResponseModel>(Resource.Events, url);
        return events.collection;
    } catch (e) {
        console.error(e);
        return null;
    }
}