import { GridValueGetterParams } from "@material-ui/data-grid";
import { GetShortDateString } from "../../global/utils";
import { TableCellValue } from "./DetailedForm/DetailedFormTable/DetailedFormTableCell/CellContent/TableCellContent";
import { useTranslation } from "react-i18next";

export const formatEnumValue = (value: any, key: string, enums?: any) => {
    let result = "";
    //if (INIDcodes[value]) result += `(${INIDcodes[value]}) `;
    if (enums[key] && enums[key][value]) result += enums[key][value];
    return result;
};

/**
 * This function is used to format seniority and priority values.
 * @param items - an array of items with structure {number?: string; date?: string, country?: string}.
 * @returns - table structure values (see DetailedForm.tsx, table row type).
 */
export function formatSeniorityOrPriorityValue(items: any[]) {
    const values = [];
    if (!items.length) return undefined;

    items.forEach((item) => {
        const num = item.number ?? "";
        const date = item.date ? GetShortDateString(item.date) : "";
        const country = item.country ?? undefined;
        values.push(
            country
                ? [
                      {
                          text: num,
                      } as TableCellValue,
                      {
                          text: date,
                      } as TableCellValue,
                      {
                          text: country,
                      } as TableCellValue,
                  ]
                : [
                      {
                          text: num,
                      } as TableCellValue,
                      {
                          text: date,
                      } as TableCellValue,
                  ]
        );
    });

    return values;
}

export const getTrademarkPersonValue = (
    params: GridValueGetterParams
): string => {
    const personArray = params.getValue(params.id, "persons") as any[];
    // ...\LRPV\PATIS.NET\Patis\Databases\WebApp.API\Models\Domain\TradeMarks\TradeMarks.cs
    // TODO: Get role values from the trademark enums in english.
    switch (params.field) {
        case "representative":
            return formatPersonObjectType(personArray, [1]) as string;
        case "applicantOrOwner":
            return formatPersonObjectType(personArray, [0]) as string;
        case "licensee":
            return formatPersonObjectType(personArray, [2]) as string;
        default:
            return "";
    }
};

export const getDesignPersonValue = (params: GridValueGetterParams): string => {
    const personArray = params.getValue(params.id, "persons") as any[];
    switch (params.field) {
        case "representative":
            return formatPersonObjectType(personArray, [2]) as string;
        case "applicantOrOwner":
            return formatPersonObjectType(personArray, [1]) as string;
        case "designer":
            return formatPersonObjectType(personArray, [3]) as string;
        case "licensee":
        case "licensees":
            return formatPersonObjectType(personArray, [4]) as string;
        default:
            return "";
    }
};

export const getPasPersonValue = (params: GridValueGetterParams): string => {
    const personArray = params.getValue(params.id, "persons") as any[];
    switch (params.field) {
        case "applicantOrOwner":
            return formatPasPersonObjectType(personArray, [0, 1]) as string;
        case "representative":
            return formatPasPersonObjectType(personArray, [2]) as string;
        case "designer":
            return formatPasPersonObjectType(personArray, [3]) as string;
        default:
            return "";
    }
};

export const getPatentPersonValue = (params: GridValueGetterParams): string => {
    const personArray = params.getValue(params.id, "persons") as any[];
    // ...\LRPV\PATIS.NET\Patis\Databases\WebApp.API\Models\Domain\Patents\Patents.cs
    // TODO: Get role values from the patent enums in english.
    switch (params.field) {
        case "representative":
            return formatPatentPersonObjectType(personArray, [2]) as string;
        case "applicantOrOwner":
            return formatPatentPersonObjectType(personArray, [1, 3]) as string;
        case "designer":
            return formatPatentPersonObjectType(personArray, [4]) as string;
        default:
            return "";
    }
};

// TODO: Improve this section
///////////////////////////////////////////////////////////////////////////////////////////
/////////-------------------------------APPEALS-----------------------------------/////////
///////////////////////////////////////////////////////////////////////////////////////////
export const getDesignAppealPersonValue = (
    params: GridValueGetterParams
): string => {
    const personArray = params.getValue(params.id, "persons") as any[];
    switch (params.field) {
        case "applicant":
            return formatResourcePersonObjectType(personArray, [1]) as string;
        case "owner":
            return formatResourcePersonObjectType(personArray, [0]) as string;
        case "licensee":
            return formatResourcePersonObjectType(personArray, [2]) as string;
        default:
            return "";
    }
};

export const getTrademarkAppealPersonValue = (
    params: GridValueGetterParams
): string => {
    const personArray = params.getValue(params.id, "persons") as any[];
    switch (params.field) {
        case "applicant":
            return formatResourcePersonObjectType(personArray, [1]) as string;
        case "owner":
            return formatResourcePersonObjectType(personArray, [0]) as string;
        case "licensee":
            return formatResourcePersonObjectType(personArray, [2]) as string;
        default:
            return "";
    }
};

export const getTrademarkOppositionPersonValue = (
    params: GridValueGetterParams
): string => {
    const personArray = params.getValue(params.id, "persons") as any[];
    switch (params.field) {
        case "applicant":
            return formatResourcePersonObjectType(personArray, [2]) as string;
        case "representative":
            return formatResourcePersonObjectType(personArray, [1]) as string;
        default:
            return "";
    }
};

export const getPatentAppealPersonValue = (
    params: GridValueGetterParams
): string => {
    const personArray = params.getValue(params.id, "persons") as any[];
    switch (params.field) {
        case "applicant":
            return formatResourcePersonObjectType(personArray, [1]) as string;
        case "owner":
            return formatResourcePersonObjectType(personArray, [0]) as string;
        case "licensee":
            return formatResourcePersonObjectType(personArray, [2]) as string;
        default:
            return "";
    }
};

export const getDesignOppositionPersonValue = (
    params: GridValueGetterParams
): string => {
    const personArray = params.getValue(params.id, "persons") as any[];
    switch (params.field) {
        case "applicant":
            return formatResourcePersonObjectType(personArray, [2]) as string;
        case "representative":
            return formatResourcePersonObjectType(personArray, [1]) as string;
        default:
            return "";
    }
};
///////////////////////////////////////////////////////////////////////////////////////////

export const formatPersonObjectType = (persons: any[], roles: number[]) => {
    if (!persons?.length) return [];
    let result = [];
    const keys = ["fullname", "company", "address"];
    // TODO: Optimize this.
    for (let person of persons) {
        if (!roles.includes(person.role)) continue;
        let personInfo = [];
        for (let key of keys) {
            switch (key) {
                case "fullname":
                    if (person["name"] && person["lastname"])
                        personInfo.push(
                            `${person["name"]} ${person["lastname"]}`
                        );
                    else if (person["name"]) personInfo.push(person["name"]);
                    break;
                case "address":
                    if (person.address?.street)
                        personInfo.push(person.address.street);
                    if (person.address?.city)
                        personInfo.push(person.address.city);
                    if (person.address?.postCode)
                        personInfo.push(person.address.postCode);
                    if (person.address?.country)
                        personInfo.push(person.address.country);
                    break;
                default:
                    if (person[key]) personInfo.push(person[key]);
                    break;
            }
        }
        personInfo.length > 3
            ? result.push(personInfo[0] + ", ", personInfo.slice(1).join(", "))
            : result.push(personInfo.join(", "));
    }
    return result.length ? result.join("\n") : undefined;
};

export const formatResourcePersonObjectType = (
    persons: any[],
    roles: number[]
) => {
    if (!persons?.length) return [];
    let result = [];
    const keys = ["fullname", "company"];
    // TODO: Optimize this.
    for (let person of persons) {
        if (!roles.includes(person.role)) continue;
        let personInfo = [];
        for (let key of keys) {
            switch (key) {
                case "fullname":
                    if (person["name"] && person["lastname"])
                        personInfo.push(
                            `${person["name"]} ${person["lastname"]}`
                        );
                    else if (person["name"]) personInfo.push(person["name"]);
                    break;
                default:
                    if (person[key]) personInfo.push(person[key]);
                    break;
            }
        }
        personInfo.length > 3
            ? result.push(personInfo[0] + ", ", personInfo.slice(1).join(", "))
            : result.push(personInfo.join(", "));
    }
    return result.length ? result.join("\n") : undefined;
};

export const formatPatentPersonObjectType = (
    persons: any[],
    roles: number[]
) => {
    if (!persons?.length) return [];
    let result = [];
    const keys = ["fullname", "address"];
    // TODO: Optimize this.
    for (let person of persons) {
        if (!roles.includes(person.entity.personrole)) continue;
        let personInfo = [];
        for (let key of keys) {
            switch (key) {
                case "fullname":
                    if (person.entity["name"])
                        personInfo.push(person.entity["name"]);
                    break;
                case "address":
                    if (person.entity.street)
                        personInfo.push(person.entity.street);
                    if (person.entity.city) personInfo.push(person.entity.city);
                    if (person.entity.postCode)
                        personInfo.push(person.entity.postCode);
                    if (person.entity.countryCode)
                        personInfo.push(person.entity.countryCode);
                    break;
                default:
                    if (person.entity[key]) personInfo.push(person.entity[key]);
                    break;
            }
        }
        personInfo.length > 3
            ? result.push(personInfo[0] + ", ", personInfo.slice(1).join(", "))
            : result.push(personInfo.join(", "));
    }
    return result.length ? result.join("\n") : undefined;
};

export const formatPasPersonObjectType = (persons: any[], roles: number[]) => {
    if (!persons?.length) return [];
    let result = [];
    const keys = ["fullname", "address"];
    // TODO: Optimize this.
    for (let person of persons) {
        if (!roles.includes(person.entity.personrole)) continue;
        let personInfo = [];
        for (let key of keys) {
            switch (key) {
                case "fullname":
                    if (person.entity["name"])
                        personInfo.push(person.entity["name"]);
                    break;
                case "address":
                    if (person.entity.street)
                        personInfo.push(person.entity.street);
                    if (person.entity.city) personInfo.push(person.entity.city);
                    if (person.entity.postCode)
                        personInfo.push(person.entity.postCode);
                    if (person.entity.countryCode)
                        personInfo.push(person.entity.countryCode);
                    break;
                default:
                    if (person.entity[key]) personInfo.push(person.entity[key]);
                    break;
            }
        }
        personInfo.length > 3
            ? result.push(personInfo[0] + ", ", personInfo.slice(1).join(", "))
            : result.push(personInfo.join(", "));
    }
    return result.length ? result.join("\n") : undefined;
};

export const getParentPath = (current: string, destination?: string) => {
    let parentPath;
    if (current === "/" && destination) {
        parentPath = `/${destination}`;
    } else {
        const arr = current.split("/");
        const currPage = arr[arr.length - 1];
        parentPath = arr
            .filter((item) => {
                return item !== currPage;
            })
            .join("/");
    }
    return parentPath;
};
