import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { Field } from 'react-final-form';
import DatePickerBase from './DatePickerBase';

interface DatePickerProps {
    name: string;
    InputProps?: Partial<OutlinedInputProps>;
    className?: string;
    label?: string;
    defaultValue?: any;
}

export default function DatePicker(props: DatePickerProps): JSX.Element {
    const { name, label, className, InputProps, defaultValue } = props;

    return (
        <Field
            name={name}
            defaultValue={defaultValue}
            render={fieldProps => (
                <DatePickerBase
                    name={fieldProps.input.name}
                    label={label}
                    onChange={(value: any) => fieldProps.input.onChange(value)}
                    value={fieldProps.input.value === '' ? null : fieldProps.input.value}
                    InputProps={InputProps}
                    className={className}
                />
            )}
        />
    );
}