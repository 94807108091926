import React, { useEffect, useState } from "react";

// constants
import { NICE_GOODS, NICE_SERVICES } from "../global/constants";

// utils
import { GridStorage } from "../storage/browserStorage";
import { useForm } from "react-final-form";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Grid,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";

import { TextField } from "./TextField";
import TooltipAdornment from "./TooltipAdornment";

import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        root: {
            "& .MuiOutlinedInput-adornedEnd": {
                paddingRight: "10px",
            },
            "&:hover $clearIndicatorDirty, & .Mui-focused $clearIndicatorDirty":
                {
                    visibility: "visible",
                },
        },
    })
);

interface NiceClassesFieldProps {
    name: string;
    label: string;
    tooltipText: string;
    defaultValue?: any;
}

const NiceClassesField = (props: NiceClassesFieldProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const storage = GridStorage.getInstance();
    const filterModel = storage.getFilterModel();

    const form = useForm();

    const [isOpen, setOpen] = useState(false);
    const [value, setValue] = useState<string[]>(
        filterModel && props.name in filterModel ? props.defaultValue : []
    );

    const sections = [
        {
            list: NICE_GOODS,
            name: t("searchForm:niceGoods"),
        },
        {
            list: NICE_SERVICES,
            name: t("searchForm:niceServices"),
        },
    ];

    const rangeFormat = (array: string[]) => {
        const result = array
            .sort((a, b) => Number(a) - Number(b))
            .reduce(function (r, a, i, aa) {
                r.push(
                    !i || Number(aa[i - 1]) - Number(a) + 1
                        ? a
                        : r.pop().split("-")[0] + "-" + a
                );
                return r;
            }, []);

        return result;
    };

    const handleChange = (val: string) => {
        form.change(
            props.name,
            !value
                ? [val]
                : value.includes(val)
                ? value.filter((v) => v != val)
                : [...value, val]
        );
    };

    useEffect(() => {
        return form.registerField(
            props.name,
            (value) => {
                setValue(value.value);
            },
            {
                value: true,
            }
        );
    }, []);

    return (
        <>
            <TextField
                name={props.name}
                label={props.label}
                value={value ? rangeFormat(value).join(", ") : ""}
                onClick={() => {
                    setOpen(true);
                }}
                size="small"
                variant="outlined"
                margin="dense"
                className={classes.root}
                InputProps={{
                    endAdornment: (
                        <TooltipAdornment tooltipText={props.tooltipText} />
                    ),
                }}
            />
            <Dialog open={isOpen} fullWidth>
                <DialogTitle>
                    <Typography variant="h6">
                        {t("searchForm:niceClassesTitle")}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        {sections.map((section, index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={section.list.every(
                                                    (v) =>
                                                        value &&
                                                        value.includes(v)
                                                )}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        form.change(
                                                            props.name,
                                                            _.uniq([
                                                                ...(value
                                                                    ? value
                                                                    : []),
                                                                ...section.list,
                                                            ])
                                                        );
                                                    } else {
                                                        form.change(
                                                            props.name,
                                                            value.filter(
                                                                (val) =>
                                                                    !section.list.includes(
                                                                        val
                                                                    )
                                                            )
                                                        );
                                                    }
                                                }}
                                            />
                                        }
                                        label={section.name}
                                    />
                                </Grid>
                                <Grid item container spacing={1}>
                                    {section.list.map((v) => (
                                        <Grid key={v} item>
                                            <Button
                                                color={
                                                    value && value.includes(v)
                                                        ? "primary"
                                                        : "default"
                                                }
                                                variant="contained"
                                                onClick={() => {
                                                    handleChange(v);
                                                }}
                                            >
                                                {v}
                                            </Button>
                                        </Grid>
                                    ))}
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setValue([]);
                        }}
                    >
                        {t("searchForm:reset")}
                    </Button>
                    <Button
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        {t("searchForm:approve")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default NiceClassesField;
