import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect } from "react";
import { GridStorage } from "../storage/browserStorage";
import { useTranslation } from "react-i18next";

export interface MobileTablePaginationProps {
    rows: number;
    totalRows: number;
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: React.ChangeEvent<unknown>, newPage: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        /* Styles applied to the caption Typography components if `variant="caption"`. */
        caption: {
            flexShrink: 0,
        },
    })
);

export default function MobileTablePagination(
    props: MobileTablePaginationProps
) {
    const { count, page, rows, totalRows, rowsPerPage, onPageChange } = props;
    const storage = GridStorage.getInstance();
    const classes = useStyles();
    const { t } = useTranslation();

    const defaultLabelDisplayedRows = ({ from, to, count }) =>
        count !== -1
            ? t("gridInfo:displayedRows", {
                  from: from,
                  to: to,
                  recordCount: count,
              })
            : t("gridInfo:displayedRowsNoMoreThan", { from: from, to: to });

    return (
        <React.Fragment>
            <Typography
                color="inherit"
                variant="body2"
                className={classes.caption}
            >
                {defaultLabelDisplayedRows({
                    from: count === 0 ? 0 : page * rowsPerPage + 1,
                    to: count === 0 ? 0 : page * rowsPerPage + rows,
                    count: totalRows,
                })}
            </Typography>
            <Pagination
                count={count}
                page={page + 1}
                onChange={onPageChange}
                shape="rounded"
                size="small"
                color="primary"
                showFirstButton
                showLastButton
            />
        </React.Fragment>
    );
}
