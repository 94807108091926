import { fetchPasData, getPASEvents } from "../../services/pasService";
import { FilterValue, FilterOperandType } from "../../models/FilterModel";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import DetailedForm from "../../components/FormDataGrid/DetailedForm/DetailedForm";
import { ACTIONS } from "../../storage/dataReducer";
import { DefaultRows } from "./detailedFormRowDefinitions";
import { useStore } from "../../storage/StoreProvider";
import { useTranslation } from "react-i18next";

interface PasParamTypes {
    id: string;
}

export default function PasDetailedForm({ match }) {
    const [pasData, setPasData] = useState({});
    const [state, dispatch, getState] = useStore();
    const { id } = useParams<PasParamTypes>();
    const { i18n } = useTranslation();
    const [usedLanguage, setUsedLanguage] = useState(i18n.language);
    const pasId = decodeURIComponent(id);
    const pasRows = pasData ? DefaultRows([pasData])[0] : [];

    useEffect(() => {
        async function GetData() {
            const filter = [
                {
                    Field: "PasNumber",
                    Operator: FilterOperandType.Equal,
                    Value1: pasId,
                    Value2: null,
                } as FilterValue,
            ];
            await fetchPasData({
                page: 0,
                rowsPerPage: 1,
                sortModel: null,
                filter: filter,
                action: ACTIONS.GET_ROWS,
            });
        }

        const currentState = getState();
        const pas = currentState.pases.find(
            (p) =>
                p.pasBasicInfo.pasNumber.toUpperCase() === pasId.toUpperCase()
        );
        if (!pas || usedLanguage !== i18n.language) {
            GetData();
            setUsedLanguage(i18n.language);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    useEffect(() => {
        async function getRows() {
            const currentState = getState();
            const pas = currentState.pases.find(
                (p) =>
                    p.pasBasicInfo.pasNumber.toUpperCase() ===
                    pasId.toUpperCase()
            );
            if (!pas) return;

            const events =
                pas.events ?? (await getPASEvents(pas.urls["Events"]));
            setPasData({
                ...pas,
                events: events,
            });
        }
        getRows();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.pasEnums, id]);

    return (
        <DetailedForm
            ids={[id]}
            rows={pasRows}
            match={match}
            showBackButton
            title={
                pasData && (pasData as any).pasBasicInfo
                    ? (pasData as any).pasBasicInfo.pasNumber
                    : null
            }
        />
    );
}
