import { createStyles, makeStyles, Theme, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

// Theme-dependent styles.
// createStyles is a helper function for TS compile.
const useStyles = makeStyles((theme: Theme) => createStyles({
    margin: {
        margin: theme.spacing(1),
    },
}));

export interface GridInfoProps {
    rowCount: number;
}

export default function GridInfo({ rowCount }: GridInfoProps) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        rowCount > 0 && (
            <Box display="flex" justifyContent="flex-end" className={classes.margin}>
                <Typography variant="subtitle2">
                {t("gridInfo:resultCountMessage")}: {rowCount}
                </Typography>
            </Box>)
    );
}