import { Box, Step, StepLabel } from "@material-ui/core";
import Stepper from '@material-ui/core/Stepper';
import React from "react";
import { ColorlibConnector, ColorlibStepIcon, EmptyStepIcon, LastEmptyStepIcon, EmptyStepIconWithLogo, LabelConnector, useStyles } from "../TimelineBase";
import ForwardIcon from '@material-ui/icons/Forward';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export interface EPExtendableTimelineProps {
    activeStep: number;
    lastStep: string;
    lastStepLabel?: string;
    isEnd?: boolean;
    startDash?: boolean;
    endDash?: boolean;
    additionalSteps?: string[];
    additionalLabels?: string[];
    ignoreDefaultLabels?: boolean;
    showLogo?: boolean;
    showMessage?: boolean;
    message?: string;
}

const getStepLabels = () => {
    return [i18next.t("patentStatusTimeline:stepLabel2")];
}

const getSteps = () => {
    return [i18next.t("patentStatusTimeline:step4")];
}

export default function EPExtendableTimeline({
    activeStep,
    lastStep,
    isEnd,
    startDash,
    endDash,
    lastStepLabel,
    additionalSteps,
    additionalLabels,
    ignoreDefaultLabels,
    showLogo,
    showMessage,
    message }: EPExtendableTimelineProps) {
    const classes = useStyles();
    // Empty string to preserve right positioning
    const labels = ignoreDefaultLabels ? [''] : getStepLabels();
    const steps = getSteps();
    const { t } = useTranslation();
    const defaultLastLabel = message ?? t("patentStatusTimeline:defaultLastLabelMessage2");
    
    return (
        <React.Fragment>
            <Box pt={!startDash || !endDash ? 5 : 0}>
                <ForwardIcon className={classes.icon} />
            </Box>
            <Box flexGrow={1} className={classes.timeline}>
                {(!startDash || !endDash) && (<Stepper alternativeLabel activeStep={-1} connector={<LabelConnector />}>
                    {labels.map((label, index) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={(props) => index == 0 && showLogo ? EmptyStepIconWithLogo(props, `${process.env.PUBLIC_URL}/EPO_logotips.png`) : EmptyStepIcon(props, label)}></StepLabel>
                        </Step>
                    ))}
                    {additionalLabels?.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={(props) => EmptyStepIcon(props, label)}></StepLabel>
                        </Step>
                    ))}
                    {showMessage && (
                        <Step>
                            <StepLabel StepIconComponent={(props) => LastEmptyStepIcon(props, defaultLastLabel)}></StepLabel>
                        </Step>
                    )}
                    {lastStepLabel && (
                        <Step>
                            <StepLabel StepIconComponent={(props) => EmptyStepIcon(props, lastStepLabel)}></StepLabel>
                        </Step>
                    )}
                    <Step className={classes.emptyStep}></Step>
                    {!isEnd && <Step className={classes.emptyStep}></Step>}
                    {!isEnd && <Step className={classes.emptyStep}></Step>}
                    {!isEnd && <Step className={classes.emptyStep}></Step>}
                    {!isEnd && endDash && <Step className={classes.emptyStep}></Step>}
                    {!isEnd && endDash && <Step className={classes.emptyStep}></Step>}
                </Stepper>)}
                <Stepper
                    alternativeLabel
                    activeStep={startDash ? activeStep + 3 : (endDash ? activeStep + 2 : activeStep)}
                    connector={<ColorlibConnector />}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel className={classes.label} StepIconComponent={props => ColorlibStepIcon(props, label)}>{label}</StepLabel>
                        </Step>
                    ))}
                    {startDash && <Step className={classes.emptyStep}></Step>}
                    {startDash && <Step className={classes.emptyStep}></Step>}
                    {startDash && <Step className={classes.emptyStep}></Step>}
                    <Step>
                        <StepLabel className={classes.label} StepIconComponent={props => ColorlibStepIcon(props, lastStep)}>{lastStep}</StepLabel>
                    </Step>
                    {endDash && <Step className={classes.emptyStep}></Step>}
                    {endDash && <Step className={classes.emptyStep}></Step>}
                    {additionalSteps?.length && additionalSteps.map((step) => (
                        <Step key={step}>
                            <StepLabel className={classes.label} StepIconComponent={props => ColorlibStepIcon(props, step)}>{step}</StepLabel>
                        </Step>
                    ))}
                    {!isEnd && <Step className={classes.emptyStep}></Step>}
                    {!isEnd && <Step className={classes.emptyStep}></Step>}
                    {!isEnd && <Step className={classes.emptyStep}></Step>}
                </Stepper>
            </Box>
        </React.Fragment>
    );
}