
import React from 'react';
import { icoMoonSet } from '../global/fonts/icoMoon/IcoMoon';
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import clsx from 'clsx';

interface Props {
    iconCode?: string;
}

// Theme-dependent styles.
// createStyles is a helper function for TS compile.
const useStyles = makeStyles<Theme, Props>(theme => createStyles({
    icon: {
        fontFamily: 'icomoon',
        speak: 'none',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontVariant: 'normal',
        textTransform: 'none',
        lineHeight: 1,
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        '&::before': {
            content: ({ iconCode }) => iconCode,
        }
    }
}));

const Icomoon: React.FC<{
    icon: string;
    className?: string;
    style?: React.CSSProperties;
}> = props => {

    const {
        icon,
        className = '',
        style = {},
        ...rest
    } = props;

    const iconCode = icoMoonSet[icon];
    const classes = useStyles({ iconCode: iconCode });

    if (iconCode) {
        return (
            <span
                className={clsx(className, classes.icon)}
                style={style}
                {...rest}
            />
        );
    }

    console.warn(`Icon ${icon} does not exist.`);
    return null;
}

export default Icomoon;
