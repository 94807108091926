import {
    formatEnumValue,
    formatPatentPersonObjectType,
} from "../../components/FormDataGrid/formDataUtils";
import { DetailedFormTableRow } from "../../components/FormDataGrid/DetailedForm/DetailedFormTable/DetailedFormTable";
import { GetPublicationDateFormat, GetShortDateString, numberSuffix } from "../../global/utils";
import { DocTableCellValue } from "../../components/FormDataGrid/DetailedForm/DetailedFormTable/DetailedFormTableCell/CellContent/DocTableCellContent";
import { convertHrefsToDictionary } from "../../services/baseService";
import { TableCellValue } from "../../components/FormDataGrid/DetailedForm/DetailedFormTable/DetailedFormTableCell/CellContent/TableCellContent";
import { useStore } from "../../storage/StoreProvider";
import { useTranslation } from "react-i18next";
import React from "react";
import qs from "qs";

function getDocumentsValue(documents: any, enums: any) {
    return documents?.map((doc) => {
        const links = convertHrefsToDictionary(doc.hrefs);
        return {
            label: GetShortDateString(doc.entity?.documentDate),
            link: {
                label:
                    doc.entity?.documentTypeLabel &&
                        enums?.patentDocumentTypeLabels
                        ? enums.patentDocumentTypeLabels[
                        doc.entity.documentTypeLabel
                        ]
                        : "",
                url: links["File"],
            },
        } as DocTableCellValue;
    });
}

function getEventsValue(events: any) {
    return events?.map((event) => {
        return [
            {
                text: GetShortDateString(event.entity.eventDate),
            } as TableCellValue,
            {
                text: event.entity.eventDescription,
            } as TableCellValue,
        ];
    });
}

function getRefPatentsValue(refPatents: any) {
    return [
        refPatents?.referencedPatents
            ? refPatents?.referencedPatents.collection.flatMap((ref) => {
                return [
                    {
                        text: ref.entity.applicationNumber,
                    } as TableCellValue,
                    {
                        text: ref.entity.publicationNumber,
                    } as TableCellValue,
                ];
            })
            : null,
        refPatents?.mainPatents
            ? refPatents?.mainPatents.collection.flatMap((mp) => {
                return [
                    {
                        text: mp.entity.applicationNumber,
                    } as TableCellValue,
                    {
                        text: mp.entity.publicationNumber,
                    } as TableCellValue,
                ];
            })
            : null,
    ];
}

export function DefaultRows(patents: any, printView?: boolean) {
    const [state, ,] = useStore();
    const { t, i18n } = useTranslation();

    return React.useMemo(
        () =>
            patents.map((patent) => {
                if (patent === {}) return [];
                return [
                    {
                        caption: " ",
                        name: t("patentForm:statusIdLabel"),
                        value:
                            state.patentEnums &&
                                patent.patentBasicInfo?.statusId
                                ? formatEnumValue(
                                    patent.patentBasicInfo.statusId,
                                    "status",
                                    state.patentEnums
                                )
                                : undefined,
                        field: "",
                        type: "string",
                    } as DetailedFormTableRow,
                    {
                        caption: 54,
                        name: t("patentForm:titleLV"),
                        value: patent.patentBasicInfo?.titleLV,
                        type: "string",
                    } as DetailedFormTableRow,
                    {
                        caption: " ",
                        name: t("patentForm:titleEN"),
                        value: patent.patentBasicInfo?.titleEN,
                        type: "string",
                    } as DetailedFormTableRow,
                    {
                        caption: 21,
                        name: t("patentForm:applicationNumber"),
                        value: patent.patentBasicInfo?.applicationNumber,
                        type: "string",
                    } as DetailedFormTableRow,
                    {
                        caption: 22,
                        name: t("patentForm:applicationDate"),
                        value: patent.patentBasicInfo?.applicationDate
                            ? GetShortDateString(
                                patent.patentBasicInfo.applicationDate
                            )
                            : undefined,
                        type: "string",
                    } as DetailedFormTableRow,
                    {
                        caption: 11,
                        name: t("patentForm:publicationNumber"),
                        value: patent.patentBasicInfo?.publicationNumber &&
                            patent.patentBasicInfo?.applicationNumber && {
                            label: patent.patentBasicInfo.publicationNumber,
                            url:
                                patent.patentBasicInfo.applicationNumber.indexOf(
                                    "."
                                ) !== -1
                                    ? "https://register.epo.org/application?number=EP" +
                                    patent.patentBasicInfo.applicationNumber.substring(
                                        0,
                                        patent.patentBasicInfo.applicationNumber.indexOf(
                                            "."
                                        )
                                    )
                                    : null,
                        },
                        type: "link",
                    } as DetailedFormTableRow,
                    {
                        caption: 45,
                        name: t("patentForm:publicationDate"),
                        value: patent.patentBasicInfo?.publicationDateValue
                            ? GetShortDateString(
                                patent.patentBasicInfo.publicationDateValue
                            )
                            : undefined,
                        type: "string",
                    } as DetailedFormTableRow,
                    {
                        caption: " ",
                        name: t("patentForm:patentSeniority"),
                        value: patent.seniority
                            ? {
                                headers: [
                                    t("patentForm:numberHeader"),
                                    t("patentForm:dateHeader"),
                                    t("patentForm:countryHeader"),
                                ],
                                labels: patent.seniority.flatMap((_) => {
                                    return [
                                        {
                                            inidCode: null,
                                            text: t("patentForm:application"),
                                        },
                                        {
                                            inidCode: null,
                                            text: t("patentForm:patent"),
                                        },
                                    ];
                                }),
                                values: patent.seniority.flatMap(
                                    (seniority) => {
                                        return [
                                            [
                                                {
                                                    text: seniority.refApplicationNr,
                                                } as TableCellValue,
                                                {
                                                    text: GetShortDateString(
                                                        seniority.applicationDate
                                                    ),
                                                } as TableCellValue,
                                                {
                                                    text: seniority.refOffice,
                                                } as TableCellValue,
                                            ],
                                            [
                                                {
                                                    text: seniority.refPublicationNr,
                                                } as TableCellValue,
                                                {
                                                    text: GetShortDateString(
                                                        seniority.patentDate
                                                    ),
                                                } as TableCellValue,
                                                {
                                                    text: seniority.refOffice,
                                                } as TableCellValue,
                                            ],
                                        ];
                                    }
                                ),
                            }
                            : undefined,
                        type: "table",
                    } as DetailedFormTableRow,
                    {
                        caption: 30,
                        name: t("patentForm:priority"),
                        value: patent.priorities
                            ? {
                                headers: [
                                    t("patentForm:numberHeader"),
                                    t("patentForm:dateHeader"),
                                    t("patentForm:countryHeader"),
                                ],
                                values: patent.priorities.map((priority) => {
                                    return [
                                        {
                                            text: priority.refillingNumber,
                                        } as TableCellValue,
                                        {
                                            text: GetShortDateString(
                                                priority.filingDate
                                            ),
                                        } as TableCellValue,
                                        {
                                            text: priority.refillingOffice,
                                        } as TableCellValue,
                                    ];
                                }),
                            }
                            : undefined,
                        type: "table",
                    } as DetailedFormTableRow,
                    {
                        caption: " ",
                        name: t("patentForm:pct"),
                        value: patent.pctData
                            ? {
                                headers: [
                                    t("patentForm:numberHeader"),
                                    t("patentForm:dateHeader"),
                                ],
                                labels: [
                                    {
                                        inidCode: 86,
                                        text: t("patentForm:application"),
                                    },
                                    {
                                        inidCode: 87,
                                        text: t("patentForm:publication"),
                                    },
                                ],
                                values: patent.pctData.flatMap((pct) => {
                                    return [
                                        [
                                            {
                                                text: pct.pctApplicationNumber,
                                            } as TableCellValue,
                                            {
                                                text: GetShortDateString(
                                                    pct.pctApplicationDate
                                                ),
                                            } as TableCellValue,
                                        ],
                                        [
                                            {
                                                text: pct.pctPublicationNumber,
                                            } as TableCellValue,
                                            {
                                                text: GetShortDateString(
                                                    pct.pctPublicationDate
                                                ),
                                            } as TableCellValue,
                                        ],
                                    ];
                                }),
                            }
                            : undefined,
                        type: "table",
                    } as DetailedFormTableRow,
                    {
                        caption: " ",
                        name: t("patentForm:publications"),
                        value: patent.publications
                            ? {
                                headers: [
                                    t("patentForm:journalNumberHeader"),
                                    t("patentForm:dateHeader"),
                                    t("patentForm:publicationNumberHeader"),
                                ],
                                values: patent.publications.map(
                                    (publication) => {
                                        return [
                                            {
                                                text: publication.publicationNumber,
                                            } as TableCellValue,
                                            {
                                                text: GetShortDateString(
                                                    publication.publicationDate
                                                ),
                                            } as TableCellValue,
                                            {
                                                text: GetPublicationDateFormat(
                                                    publication.publicationDate
                                                ),
                                                url: publication.url,
                                            } as TableCellValue,
                                        ];
                                    }
                                ),
                                labels: patent.publications.map(
                                    (publication) => {
                                        return {
                                            inidCode: null,
                                            text:
                                                publication.publicationTypeLabel !==
                                                    null &&
                                                    state.patentEnums
                                                        ?.patentPublicationTypeLabels
                                                    ? state.patentEnums
                                                        .patentPublicationTypeLabels[
                                                    publication
                                                        .publicationTypeLabel
                                                    ]
                                                    : "",
                                        };
                                    }
                                ),
                            }
                            : undefined,
                        type: "table",
                    } as DetailedFormTableRow,
                    {
                        caption: 51,
                        name: t("patentForm:ipcNumberLabel"),
                        value: patent.ipc
                            ?.map((item) => item.ipcNumber)
                            ?.join(", "),
                        type: "string",
                    } as DetailedFormTableRow,
                    {
                        caption: " ",
                        name: t("patentForm:cpc"),
                        value: patent.cpc
                            ?.map((item) => item.cpcNumber)
                            ?.join(", "),
                        type: "string",
                    } as DetailedFormTableRow,
                    {
                        caption: "(71)/(73)",
                        name: t("patentForm:applicantOrOwner"),
                        value: patent.patentBasicInfo?.persons
                            ? formatPatentPersonObjectType(
                                patent.patentBasicInfo.persons,
                                [1, 3]
                            )
                            : undefined,
                        type: "string",
                    } as DetailedFormTableRow,
                    {
                        caption: 72,
                        name: t("patentForm:inventors"),
                        value: patent.patentBasicInfo?.persons
                            ? (formatPatentPersonObjectType(
                                patent.patentBasicInfo.persons,
                                [4]
                            ) as string)
                            : undefined,
                        type: "string",
                    } as DetailedFormTableRow,
                    {
                        caption: 74,
                        name: t("patentForm:representative"),
                        value: patent.patentBasicInfo?.persons
                            ? (formatPatentPersonObjectType(
                                patent.patentBasicInfo.persons,
                                [2]
                            ) as string)
                            : undefined,
                        type: "string",
                    } as DetailedFormTableRow,
                    {
                        caption: " ",
                        name: t("patentForm:referencedPatent"),
                        value:
                            patent.patentBasicInfo?.urls &&
                                patent.patentBasicInfo.urls["Referenced"]
                                ? {
                                    headers: [
                                        t(
                                            "patentForm:applicationNumberHeader"
                                        ),
                                        t("patentForm:patentNumberHeader"),
                                    ],
                                    labels: [
                                        {
                                            inidCode: 62,
                                            text: t(
                                                "patentForm:parentPatentLabel"
                                            ),
                                        },
                                        {
                                            inidCode: 60,
                                            text: t(
                                                "patentForm:divisionalPatentLabel"
                                            ),
                                        },
                                    ],
                                    values: getRefPatentsValue(
                                        patent.refPatents
                                    ),
                                }
                                : undefined,
                        type: "table",
                    } as DetailedFormTableRow,
                    {
                        caption: 18,
                        name: t("patentForm:pasNumber"),
                        value: patent.patentBasicInfo?.pas
                            ? {
                                headers: null,
                                values: patent.patentBasicInfo.pas.map(
                                    (doc) => {
                                        const pas = doc.entity.pasBasicInfo;
                                        return {
                                            label: "",
                                            link: {
                                                label: pas.pasNumber ?? "",
                                                url: `/pas/${encodeURIComponent(
                                                    pas.pasNumber
                                                )}`,
                                            },
                                        } as DocTableCellValue;
                                    }
                                ),
                            }
                            : undefined,
                        type: "docTable",
                    } as DetailedFormTableRow,
                    {
                        caption: " ",
                        name: t("patentForm:eventHistory"),
                        value: patent.events
                            ? {
                                headers: [
                                    t("patentForm:dateHeader"),
                                    t("patentForm:eventDescriptionHeader"),
                                ],
                                values: getEventsValue(patent.events),
                            }
                            : undefined,
                        type: "table",
                        collapse: printView ? false : true,
                    } as DetailedFormTableRow,
                    {
                        caption: " ",
                        name: t("patentForm:nextAnnualFee"),
                        value:
                            patent.patentBasicInfo?.nextAnnualFee ||
                                patent.patentBasicInfo?.nextAnnualFeeForYear
                                ? {
                                    headers: [
                                        t("patentForm:dateHeader"),
                                        t("patentForm:commentHeader"),
                                    ],
                                    values: [
                                        [
                                            {
                                                text:
                                                    patent.patentBasicInfo
                                                        ?.nextAnnualFeeForYear <=
                                                        20
                                                        ? patent
                                                            .patentBasicInfo
                                                            ?.nextAnnualFee
                                                            ? GetShortDateString(
                                                                patent
                                                                    .patentBasicInfo
                                                                    .nextAnnualFee
                                                            )
                                                            : ""
                                                        : "",
                                            } as TableCellValue,
                                            {
                                                text: patent.patentBasicInfo
                                                    ?.nextAnnualFeeForYear
                                                    ? patent.patentBasicInfo
                                                        ?.nextAnnualFeeForYear &&
                                                        i18n.language ===
                                                        "en-US"
                                                        ? `${t(
                                                            "patentForm:for"
                                                        )}
                                                            ${numberSuffix(
                                                            patent
                                                                .patentBasicInfo
                                                                .nextAnnualFeeForYear
                                                        )
                                                        }
                                                            ${t(
                                                            "patentForm:years"
                                                        )}`
                                                        : `${t(
                                                            "patentForm:for"
                                                        )} ${patent
                                                            .patentBasicInfo
                                                            .nextAnnualFeeForYear
                                                        }. ${t(
                                                            "patentForm:years"
                                                        )}`
                                                    : "",
                                            } as TableCellValue,
                                        ],
                                    ],
                                }
                                : undefined,
                        type: "table",
                    } as DetailedFormTableRow,
                    {
                        caption: " ",
                        name: t("patentForm:paymentHistory"),
                        value: patent.payments
                            ? {
                                headers: [
                                    t("patentForm:dateHeader"),
                                    t("patentForm:sumHeader"),
                                    t("patentForm:yearHeader"),
                                ],
                                values: patent.payments.map((p) => {
                                    return [
                                        {
                                            text: p.paymentDate
                                                ? GetShortDateString(
                                                    p.paymentDate
                                                )
                                                : "",
                                        } as TableCellValue,
                                        {
                                            text: p.amount.toFixed(2) ?? "",
                                        } as TableCellValue,
                                        {
                                            text: p.forYear ?? "",
                                        } as TableCellValue,
                                    ];
                                }),
                            }
                            : undefined,
                        type: "table",
                        collapse: printView ? false : true,
                    } as DetailedFormTableRow,
                    {
                        caption: " ",
                        name: t("patentForm:description"),
                        value:
                            i18n.language === "lv-LV"
                                ? patent.patentBasicInfo?.descriptionLV
                                    ? {
                                        __html: patent.patentBasicInfo
                                            .descriptionLV,
                                    }
                                    : undefined
                                : patent.patentBasicInfo?.descriptionEN
                                    ? {
                                        __html: patent.patentBasicInfo
                                            .descriptionEN,
                                    }
                                    : undefined,
                        type: "html",
                    } as DetailedFormTableRow,
                    {
                        caption: " ",
                        name: t("patentForm:image"),
                        value:
                            patent.patentBasicInfo?.urls &&
                                patent.patentBasicInfo.urls["Image"]
                                ? patent.patentBasicInfo.urls["Image"]
                                : undefined,
                        type: "image",
                    } as DetailedFormTableRow,
                    {
                        caption: " ",
                        name: t("patentForm:fileDocuments"),
                        value:
                            patent.documents && patent.documentInfo
                                ? {
                                    headers: [
                                        t("patentForm:dateHeader"),
                                        t("patentForm:documentKindLabel"),
                                    ],
                                    values: getDocumentsValue(
                                        patent.documentInfo,
                                        state.patentEnums
                                    ),
                                }
                                : undefined,
                        type: "docTable",
                        collapse: printView ? false : true,
                    } as DetailedFormTableRow,
                    {
                        caption: " ",
                        name: t("patentForm:appealValue"),
                        value:
                            patent.patentBasicInfo?.appeal &&
                                patent.patentBasicInfo?.applicationNumber
                                ? {
                                    label: t("patentForm:viewLabel"),
                                    url: `/appeal/patents?${qs.stringify({
                                        Number: patent.patentBasicInfo
                                            .applicationNumber,
                                    })}`,
                                }
                                : undefined,
                        field: "",
                        type: "link",
                    } as DetailedFormTableRow,
                    {
                        caption: " ",
                        name: t("patentForm:pledge"),
                        value: patent.patentBasicInfo?.pledge ? "" : undefined,
                        type: "string",
                    } as DetailedFormTableRow,
                    {
                        caption: " ",
                        name: t("patentForm:licencees"),
                        value: patent.licencees?.length
                            ? {
                                headers: [t("patentForm:dateHeader"), ""],
                                values: patent.licencees.map((p) => {
                                    return [
                                        {
                                            text: p.agreementDate
                                                ? GetShortDateString(
                                                    p.agreementDate
                                                )
                                                : "",
                                        } as TableCellValue,
                                        {
                                            text: p.info ?? "",
                                        } as TableCellValue,
                                    ];
                                }),
                            }
                            : undefined,
                        type: "table",
                    } as DetailedFormTableRow,
                ];
            }),
        [patents, state.patentEnums]
    );
}
