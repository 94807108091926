import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Divider, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStore } from '../storage/StoreProvider';

export default function NotificationField() {
  const [state, dispatch,] = useStore();
  const notifications = state.notifications.map(n => <Typography variant='h4'>{n}</Typography>);

  return (
    <div>
        <Box p={3}>
          <Typography color='primary' align='center'>
            {state.notifications?.length > 0 && notifications}
          </Typography>
        </Box>
    </div>
  );
}