import * as React from 'react';
import clsx from 'clsx';
import { useUtils } from '@material-ui/pickers';
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';
import { Grid, makeStyles } from '@material-ui/core';
import { isYearOnlyView } from '@material-ui/pickers/_helpers/date-utils';
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar';
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton';
import format from 'date-fns/format';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export const useStyles = makeStyles(
    {
        toolbar: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            paddingRight: 0,
            paddingLeft: 20,
        },
        toolbarLandscape: {
            padding: 16,
        },
        dateLandscape: {
            marginRight: 16,
        },
        toolbarButton: {
            '& .MuiButton-endIcon': {
                marginLeft: -1,
                marginRight: 0,
            },
        },
        toolbarButtonSpacer: {
            marginRight: 4,
        }
    },
    { name: 'MuiPickersDatePickerRoot' }
);

// Inspired by: https://github.com/mui-org/material-ui-pickers/blob/v3-x/lib/src/DatePicker/DatePickerToolbar.tsx
export const DatePickerToolbar: React.FC<ToolbarComponentProps> = ({
    date,
    views,
    setOpenView,
    isLandscape,
    openView,
}) => {
    const utils = useUtils();
    const classes = useStyles();

    const isYearOnly = React.useMemo(() => isYearOnlyView(views as any), [views]);
    // const isYearAndMonth = React.useMemo(() => isYearAndMonthViews(views as any), [views]);

    return (
        <PickerToolbar
            isLandscape={isLandscape}
            className={clsx({
                [classes.toolbar]: !isYearOnly,
                [classes.toolbarLandscape]: isLandscape,
            })}
        >
            <Grid container>
                <Grid md={3} className={classes.toolbarButtonSpacer}>
                    <ToolbarButton
                        variant="subtitle1"
                        onClick={() => setOpenView('year')}
                        selected={openView === 'year'}
                        label={utils.getYearText(date)}
                        className={classes.toolbarButton}
                        endIcon={<ArrowDropDownIcon fontSize='small' color='secondary' />}
                    />
                </Grid>
                <Grid>
                    <ToolbarButton
                        variant="subtitle1"
                        onClick={() => setOpenView('month')}
                        selected={openView === 'month'}
                        label={utils.getMonthText(date)}
                        className={classes.toolbarButton}
                        endIcon={<ArrowDropDownIcon fontSize='small' color='secondary' />}
                    />
                </Grid>
            </Grid>
            <ToolbarButton
                variant="subtitle1"
                selected={openView === 'date'}
                onClick={() => setOpenView('date')}
                align={isLandscape ? 'left' : 'center'}
                label={format(date, "dd/MM/yyyy")}
                className={clsx({ [classes.dateLandscape]: isLandscape })}
            />

        </PickerToolbar>
    );
};