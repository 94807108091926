import React, { useEffect, useState } from "react";
import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    Grid,
    Hidden,
    makeStyles,
    createStyles,
    Theme,
    Typography,
    Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useStore } from "../../../../storage/StoreProvider";
import { LocalStorage } from "../../../../storage/browserStorage";
import BackButton from "../../../BackButton";
import ActionBar from "../../ActionBar";
import CollapsibleRow from "./DetailedFormTableRow/CollapsibleRow";
import Row from "./DetailedFormTableRow/Row";
import { getParentPath } from "../../formDataUtils";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

const WrapperComponent = (props) => <Paper {...props} />;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageBreak: {
            pageBreakAfter: "always",
            overflow: "hidden",
        },
        blankPage: {
            pageBreakAfter: "always",
        },
        exactColor: {
            colorAdjust: "exact",
        },
        title: {
            paddingLeft: theme.spacing(2),
        },
    })
);

export interface DetailedFormTableRow {
    caption: number | string;
    name: string;
    value: any;
    type: string;
    collapse?: boolean;
}

export interface DetailedFormTableProps {
    ids: string[];
    rows: DetailedFormTableRow[];
    match: any;
    timeline?: any;
    showTimeline?: boolean;
    showBackButton?: boolean;
    printView?: boolean;
    title?: string;
}

export default function DetailedFormTable(props: DetailedFormTableProps) {
    const classes = useStyles();
    const {
        ids,
        rows,
        match,
        timeline,
        showTimeline,
        showBackButton,
        printView,
        title,
    } = props;

    const { t } = useTranslation();

    const [state, , getState] = useStore();
    const [prevLink, setPrevLink] = useState(null);
    const [nextLink, setNextLink] = useState(null);

    const ls = LocalStorage.getInstance();

    useEffect(() => {
        let prevId = null;
        let nextId = null;

        let currentIndex = null;

        switch (getParentPath(match.path, "")) {
            case "/trademark":
                currentIndex = state.trademarks.findIndex(
                    (item) => item.applicationNumber === match.params.id
                );

                if (currentIndex !== -1) {
                    if (currentIndex !== 0) {
                        prevId =
                            state.trademarks[currentIndex - 1]
                                .applicationNumber;
                    }
                    if (currentIndex !== state.trademarks.length - 1) {
                        nextId =
                            state.trademarks[currentIndex + 1]
                                .applicationNumber;
                    }
                }

                break;
            case "/patents":
                currentIndex = state.patents.findIndex(
                    (item) =>
                        item.patentBasicInfo.applicationNumber ===
                        match.params.id
                );

                if (currentIndex !== -1) {
                    if (currentIndex !== 0) {
                        prevId =
                            state.patents[currentIndex - 1].patentBasicInfo
                                .applicationNumber;
                    }
                    if (currentIndex !== state.patents.length - 1) {
                        nextId =
                            state.patents[currentIndex + 1].patentBasicInfo
                                .applicationNumber;
                    }
                }

                break;
            case "/pas":
                currentIndex = state.pases.findIndex(
                    (item) =>
                        item.pasBasicInfo.pasNumber ===
                        decodeURIComponent(match.params.id)
                );

                if (currentIndex !== -1) {
                    if (currentIndex !== 0) {
                        prevId = encodeURIComponent(
                            state.pases[currentIndex - 1].pasBasicInfo.pasNumber
                        );
                    }
                    if (currentIndex !== state.pases.length - 1) {
                        nextId = encodeURIComponent(
                            state.pases[currentIndex + 1].pasBasicInfo.pasNumber
                        );
                    }
                }
                break;
            case "/design":
                currentIndex = state.designs.findIndex(
                    (item) => item.applicationNumber === match.params.id
                );

                if (currentIndex !== -1) {
                    if (currentIndex !== 0) {
                        prevId =
                            state.designs[currentIndex - 1].applicationNumber;
                    }
                    if (currentIndex !== state.designs.length - 1) {
                        nextId =
                            state.designs[currentIndex + 1].applicationNumber;
                    }
                }
                break;
            default:
                console.log("undefined navigation formatter");
                break;
        }

        if (prevId) {
            setPrevLink(`${getParentPath(match.path, "")}/${prevId}`);
        } else {
            setPrevLink(null);
        }
        if (nextId) {
            setNextLink(`${getParentPath(match.path, "")}/${nextId}`);
        } else {
            setNextLink(null);
        }
    }, [match, state]);

    return (
        <Grid className={classes.blankPage} item container xs={12} md={12}>
            <Grid className={classes.pageBreak} item container xs={12} md={12}>
                {title ? (
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle1"
                            component="h6"
                            className={classes.title}
                        >
                            {title}
                        </Typography>
                    </Grid>
                ) : null}
                <Grid item xs={12} md={11}>
                    <TableContainer
                        className={classes.pageBreak}
                        component={WrapperComponent}
                        variant="outlined"
                        elevation={0}
                    >
                        <Grid container alignItems="center">
                            <Grid item>
                                {showBackButton && (
                                    <BackButton
                                        match={match}
                                        destination={""}
                                    />
                                )}
                            </Grid>
                            <Grid
                                item
                                container
                                spacing={2}
                                xs
                                justify="flex-end"
                            >
                                <Grid item>
                                    <Button
                                        disabled={!prevLink}
                                        startIcon={<ChevronLeftIcon />}
                                        component={Link}
                                        to={prevLink}
                                    >
                                        {t("common:previous")}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        disabled={!nextLink}
                                        endIcon={<ChevronRightIcon />}
                                        component={Link}
                                        to={nextLink}
                                    >
                                        {t("common:next")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Table aria-label="detailed table">
                            <TableBody className={classes.exactColor}>
                                {showTimeline &&
                                    (printView && ls.getTimelineState()
                                        ? ls.getTimelineState().open
                                        : true) && (
                                        <CollapsibleRow
                                            name=""
                                            caption=""
                                            type="timeline"
                                            value={timeline ?? -1}
                                            isOpenByDefault
                                        />
                                    )}
                                {rows &&
                                    rows.map((row, index) => {
                                        if (row.value === undefined)
                                            return null;
                                        return row.collapse ? (
                                            <CollapsibleRow
                                                key={index}
                                                name={row.name}
                                                caption={row.caption}
                                                type={row.type}
                                                value={row.value}
                                            />
                                        ) : (
                                            <Row
                                                key={index}
                                                name={row.name}
                                                caption={row.caption}
                                                type={row.type}
                                                value={row.value}
                                                printView={printView}
                                            />
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Hidden only={["xs", "sm"]}>
                    <Grid item md={1}>
                        <ActionBar ids={ids} useParentPath />
                    </Grid>
                </Hidden>
            </Grid>
        </Grid>
    );
}
