import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import DetailedForm from "../../components/FormDataGrid/DetailedForm/DetailedForm";
import { ACTIONS } from "../../storage/dataReducer";
import { DefaultRows } from "./detailedFormRowDefinitions";
import { useStore } from "../../storage/StoreProvider";
import { FilterOperandType, FilterValue } from "../../models/FilterModel";
import { fetchDesignData } from "../../services/designService";
import { useTranslation } from "react-i18next";
import DesignStatusTimeline from "../../components/FormDataGrid/Timeline/DesignStatusTimeline/DesignStatusTimeline";

interface DesignsParamTypes {
    id: string;
}

export default function DesignsDetailedForm({ match }) {
    const [designData, setDesignData] = useState({});
    const [state, dispatch, getState] = useStore();
    const [statusId, setStatusId] = useState(null);
    const { id } = useParams<DesignsParamTypes>();
    const { i18n } = useTranslation();
    const [usedLanguage, setUsedLanguage] = useState(i18n.language);
    const designRows = designData ? DefaultRows([designData])[0] : [];

    useEffect(() => {
        async function GetData() {
            const filter = [
                {
                    // TODO: This is a dirty fix for trademark appeals. Improve the solution.
                    // This may break if the format of registrationNumber or applicationNumber will change.
                    Field: id.includes(" ")
                        ? "registrationNumber"
                        : "applicationNumber",
                    Operator: FilterOperandType.Equal, // TODO: Change to Equals for date value.
                    Value1: id,
                    Value2: null, // TODO: Add the Value2 for interval values.
                } as FilterValue,
            ];
            await fetchDesignData({
                page: 0,
                rowsPerPage: 1,
                sortModel: null,
                filter: filter,
                action: ACTIONS.GET_ROWS,
            });
        }

        const currentState = getState();
        const design = currentState.designs.find(
            (d) => d.applicationNumber === id || d.registrationNumber === id
        );
        if (!design || usedLanguage !== i18n.language) {
            GetData();
            setUsedLanguage(i18n.language);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    useEffect(() => {
        async function getRows() {
            const currentState = getState();
            const design = currentState.designs.find(
                (d) => d.applicationNumber === id || d.registrationNumber === id
            );
            if (!design) return;

            setStatusId(design.status);
            setDesignData(design);
        }
        getRows();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.designEnums, id]);

    return (
        <DetailedForm
            ids={[id]}
            rows={designRows}
            match={match}
            showTimeline
            showBackButton
            timeline={<DesignStatusTimeline statusId={statusId} />}
            title={
                designData
                    ? (designData as any).registrationNumber
                        ? (designData as any).registrationNumber
                        : (designData as any).applicationNumber
                    : ""
            }
        />
    );
}
