import {
    Box,
    createStyles,
    Divider,
    IconButton,
    makeStyles,
    Menu,
    Theme,
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import React from "react";
import ListItemLink from "./ListItemLink";
import { GetMenuData } from "./menuData";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuItem: {
            padding: theme.spacing(0.5, 1, 0.5, 1),
        },
        icon: {
            marginTop: 0,
            marginRight: 0,
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
        },
        menuContainer: {
            top: 50,
        },
        menu: {
            marginTop: -10,
            "&.MuiPaper-outlined": {
                borderTop: 0,
            },
        },
        divider: {
            margin: theme.spacing(1.5, 0, 1.5, 0),
        },
        iconLabel: {
            backgroundColor: theme.palette.accent.dark,
            padding: theme.spacing(3, 3, 3, 3),
        },
        menuIcon: {
            backgroundColor: theme.palette.accent.main,
            padding: theme.spacing(0.5, 1, 0.5, 1),
        },
    })
);

export default function MobileMenu() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const menuData = GetMenuData();

    const getMenuItems = () => {
        return menuData.map(({ label, href, level }) => {
            return (
                <ListItemLink
                    key={label}
                    color="inherit"
                    to={href}
                    text={label}
                    level={level}
                />
            );
        });
    };

    return (
        <Box display="flex">
            <Box flexGrow={1} />
            <IconButton
                edge="end"
                aria-label="open menu"
                aria-haspopup="true"
                onClick={handleClick}
                disableRipple={true}
                className={classes.icon}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    className={classes.iconLabel}
                >
                    <MenuIcon />
                </Box>
            </IconButton>
            <Divider
                orientation="vertical"
                flexItem
                className={classes.divider}
            />
            <Menu
                id="app menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {getMenuItems()}
            </Menu>
        </Box>
    );
}
