import React, { useMemo } from 'react';
import useEnhancedReducer from '../hooks/useEnhancedReducer';

// This can be used outside of functional components
// This approach can be used only for client-side rendering
// For server-side rendering use middlewares or pass params to props
// https://github.com/facebook/react/issues/15738
export const Store = {
    dispatch: action => { console.error('Store is not ready'); },
    getState: (): any => { console.error('Store is not ready'); },
}

const StoreContext = React.createContext([]);

// This can be used in functional components
export const useStore = () => React.useContext(StoreContext);

StoreContext.displayName = 'Store';

export interface StoreProviderProps {
    reducer: any;
    initialState: any;
}

export const StoreProvider = (props: React.PropsWithChildren<StoreProviderProps>) => {
    const {
        reducer,
        initialState,
        children,
    } = props;

    const [globalState, dispatch, getState] = useEnhancedReducer(reducer, initialState);

    // Use the same value for every App.tsx re-render to improve performance    
    // The values will be updated only on state change
    const contextValue = useMemo(() => {
        Store.dispatch = dispatch;
        Store.getState = getState;
        return [globalState, dispatch, getState];
    }, [globalState, dispatch, getState]);

    return (
        <StoreContext.Provider value={contextValue}>
            {children}
        </StoreContext.Provider>
    );
};