import { Box, ClickAwayListener, createStyles, Divider, Grow, IconButton, makeStyles, MenuItem, MenuList, Paper, Popper, Theme, Typography } from "@material-ui/core";
import Icon from './Icon';
import React from "react";
import { useTranslation } from "react-i18next";
import { LocalStorage } from '../storage/browserStorage';

const useStyles = makeStyles((theme: Theme) => createStyles({
    menuItem: {
        padding: theme.spacing(0.5, 1, 0.5, 1),
    },
    icon: {
        marginTop: 3,
        marginRight: -1,
        paddingLeft: 11,
    },
    menuContainer: {
        top: 50,
        position: "relative",
        zIndex: 999,
    },
    menu: {
        marginTop: -10,
        '&.MuiPaper-outlined': {
            borderTop: 0,
        },
    },
    divider: {
        margin: theme.spacing(1.5, 0, 1.5, 0),
    },
    iconLabel: {
    },
}));

export default function LanguageSwitcher() {
    const classes = useStyles();
    const ls = LocalStorage.getInstance();
    const { i18n } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const { t } = useTranslation();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleLanguageSwitch = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        i18n.changeLanguage(event.target.getAttribute("value"), () => ls.setLanguage(event.target.getAttribute("value")));
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    return (
        <Box display="flex">
            <Box flexGrow={1} />
            <Divider orientation="vertical" flexItem className={classes.divider} />
            <IconButton
                ref={anchorRef}
                edge="end"
                aria-label="Switch language"
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="inherit"
                disableRipple={true}
                className={classes.icon}
            >
                <Box display="flex" flexDirection="column" className={classes.iconLabel}>
                    <Icon icon="sphere" />
                    <Typography variant="caption">{t("common:language")}</Typography>
                </Box>
            </IconButton>
            <Divider orientation="vertical" flexItem className={classes.divider} />
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal className={classes.menuContainer} placement='top-end'>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper variant="outlined" elevation={0} square className={classes.menu}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    <MenuItem onClick={handleLanguageSwitch} value="en-US" className={classes.menuItem} disableGutters>English</MenuItem>
                                    <MenuItem onClick={handleLanguageSwitch} value="lv-LV" className={classes.menuItem} disableGutters>Latviešu</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
}