import FormDataGrid from "../../components/FormDataGrid/FormDataGrid";
import { DefaultColumns } from "./columnDefinitions";
import { defaultColumnTypes } from "./columnTypes";
import { formatFilterValue } from "./recordFilter";
import { DesignsSearchFields } from "./DesignsSearchFields";
import { useStore } from "../../storage/StoreProvider";
import { fetchDesignData } from "../../services/designService";
import { FilterOperandType, FilterValue } from "../../models/FilterModel";

export default function DesignsGrid() {
    const [state, , getState] = useStore();

    function rowIdSelector(rows: any) {
        return rows.map((design) => {
            return design.applicationNumber;
        });
    }

    return (
        <FormDataGrid
            defaultColumns={DefaultColumns()}
            searchFields={DesignsSearchFields(
                state.designSearchMeta,
                state.designEnums
            )}
            onFetchData={fetchDesignData}
            filterValueFormatter={formatFilterValue}
            customColumnTypes={defaultColumnTypes(state.designEnums)}
            loadError={state.error}
            rows={state.designs ?? []}
            rowIdSelector={rowIdSelector}
            hidePreciseSearch
            initialSort={[
                {
                    field: "applicationDate",
                    sort: "desc",
                },
            ]}
        />
    );
}
