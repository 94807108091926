import {
    Box,
    createStyles,
    Hidden,
    makeStyles,
    Paper,
    Theme,
    Typography,
} from "@material-ui/core";
import React from "react";
import {
    Link as RouterLink,
    LinkProps as RouterLinkProps,
} from "react-router-dom";
import ButtonRouterLink from "./ButtonRouterLink";
import { useTranslation } from "react-i18next";

// Theme-dependent styles.
// createStyles is a helper function for TS compile.
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            backgroundColor: "#ccc",
            minHeight: "400px",
            minWidth: "560px",
            height: `calc(100vh - 70px - (${theme.spacing(3)}px * 2) - 64px)`,
        },
        leftBlock: {
            display: "flex",
            height: "50%",
            margin: theme.spacing(0, 0, 0.5, 0),
            position: "relative",
            justifyContent: "flex-start",
        },
        rightBlock: {
            display: "flex",
            height: "50%",
        },
        leftTopBlockContent: {
            background: `linear-gradient(315deg, ${theme.palette.primary.main} 20%, ${theme.palette.accent.main} 80%)`,
            margin: theme.spacing(0, 0.5, 0, 0),
            width: "50%",
            position: "relative",
        },
        rightTopBlockContent: {
            background: `linear-gradient(45deg, ${theme.palette.primary.main} 20%, ${theme.palette.accent.main} 80%)`,
            width: "50%",
            position: "relative",
        },
        leftBottomBlockContent: {
            background: `linear-gradient(220deg, ${theme.palette.primary.main} 20%, ${theme.palette.accent.main} 80%)`,
            margin: theme.spacing(0, 0.5, 0, 0),
            width: "50%",
            position: "relative",
        },
        rightBottomBlockContent: {
            background: `linear-gradient(135deg, ${theme.palette.primary.main} 20%, ${theme.palette.accent.main} 80%)`,
            width: "50%",
            position: "relative",
        },
        leftSubBlockContent1: {
            width: `calc(40% - ${theme.spacing(0.15)}px)`,
            borderBottom: `${theme.spacing(0.5)}px solid ${
                theme.palette.accent.light
            }`,
            height: "calc(100% / 5)",
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 2,
        },
        leftSubBlockContent2: {
            width: `calc(40% - ${theme.spacing(0.15)}px)`,
            borderBottom: `${theme.spacing(0.5)}px solid ${
                theme.palette.accent.light
            }`,
            height: `calc(100% / 5 + 1px)`,
            position: "absolute",
            top: "calc(100% / 5)",
            right: 0,
            zIndex: 2,
        },
        leftSubBlockContent3: {
            width: `calc(40% - ${theme.spacing(0.15)}px)`,
            borderBottom: `${theme.spacing(0.5)}px solid ${
                theme.palette.accent.light
            }`,
            height: "calc(100% / 5)",
            position: "absolute",
            top: "calc(2 * 100% / 5)",
            right: 0,
            zIndex: 2,
        },
        leftSubBlockContent4: {
            width: `calc(40% - ${theme.spacing(0.15)}px)`,
            borderBottom: `${theme.spacing(0.5)}px solid ${
                theme.palette.accent.light
            }`,
            height: "calc(100% / 5)",
            position: "absolute",
            top: "calc(3 * 100% / 5)",
            right: 0,
            zIndex: 2,
        },
        leftSubBlockContent5: {
            width: `calc(40% - ${theme.spacing(0.15)}px)`,
            borderBottom: `${theme.spacing(0.5)}px solid ${
                theme.palette.accent.light
            }`,
            height: "calc(100% / 5)",
            position: "absolute",
            bottom: 0,
            right: 0,
            zIndex: 2,
        },
        rightSubBlockContent1: {
            width: `calc(40% - ${theme.spacing(0.15)}px)`,
            borderBottom: `${theme.spacing(0.5)}px solid ${
                theme.palette.accent.light
            }`,
            height: "50%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 2,
        },
        rightSubBlockContent2: {
            width: `calc(40% - ${theme.spacing(0.15)}px)`,
            borderBottom: `${theme.spacing(0.5)}px solid ${
                theme.palette.accent.light
            }`,
            height: "50%",
            position: "absolute",
            top: "50%",
            left: 0,
            zIndex: 2,
        },
        link: {
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            width: "100%",
        },
        text: {
            color: theme.palette.common.white,
        },
        subBlockTextContainer: {
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
        },
    })
);

export default function InfoBlocks() {
    const classes = useStyles();
    const { t } = useTranslation();

    const displayDesktop = () => {
        return (
            <Box className={classes.root}>
                <Box className={classes.leftBlock}>
                    <Paper
                        elevation={0}
                        square
                        className={classes.leftTopBlockContent}
                        //to="patents"
                    >
                        <ButtonRouterLink
                            to="/patents"
                            className={classes.link}
                        >
                            <Typography
                                align="left"
                                variant="subtitle1"
                                className={classes.text}
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "20%",
                                    width: "25%",
                                }}
                            >
                                {t("mainMenu:patents")}
                            </Typography>
                        </ButtonRouterLink>
                    </Paper>
                    <Paper
                        elevation={0}
                        square
                        className={classes.rightTopBlockContent}
                    >
                        <ButtonRouterLink to="/pas" className={classes.link}>
                            <Typography
                                variant="subtitle1"
                                align="right"
                                className={classes.text}
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "10%",
                                    width: "30%",
                                }}
                            >
                                {t("mainMenu:pas")}
                            </Typography>
                        </ButtonRouterLink>
                    </Paper>
                    <Paper
                        elevation={0}
                        square
                        style={{
                            width: "40%",
                            height: "50%",
                            position: "absolute",
                            left: "30%",
                            bottom: 0,
                        }}
                    >
                        <Box className={classes.subBlockTextContainer}>
                            <ButtonRouterLink
                                to="/appeal/patents"
                                className={classes.link}
                            >
                                <Typography variant="subtitle1" align="center">
                                    {t("mainMenu:appealPatents")}
                                </Typography>
                            </ButtonRouterLink>
                        </Box>
                    </Paper>
                </Box>
                <Box className={classes.rightBlock}>
                    <Paper
                        elevation={0}
                        square
                        className={classes.leftBottomBlockContent}
                    >
                        <ButtonRouterLink
                            to="/trademark"
                            className={classes.link}
                        >
                            <Typography
                                variant="subtitle1"
                                align="left"
                                className={classes.text}
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "20%",
                                    width: "25%",
                                }}
                            >
                                {t("mainMenu:trademarks")}
                            </Typography>
                        </ButtonRouterLink>
                        <Paper
                            elevation={0}
                            square
                            className={classes.leftSubBlockContent1}
                        >
                            <Box className={classes.subBlockTextContainer}>
                                <ButtonRouterLink
                                    to="/appeal/trademarks"
                                    className={classes.link}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        align="center"
                                    >
                                        {t("mainMenu:appealTrademarksShort")}
                                    </Typography>
                                </ButtonRouterLink>
                            </Box>
                        </Paper>
                        <Paper
                            elevation={0}
                            square
                            className={classes.leftSubBlockContent2}
                        >
                            <Box className={classes.subBlockTextContainer}>
                                <ButtonRouterLink
                                    to="/objections/trademarks"
                                    className={classes.link}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        align="center"
                                    >
                                        {t("mainMenu:oppositionTrademarks")}
                                    </Typography>
                                </ButtonRouterLink>
                            </Box>
                        </Paper>
                        <Paper
                            elevation={0}
                            square
                            className={classes.leftSubBlockContent3}
                        >
                            <Box className={classes.subBlockTextContainer}>
                                <ButtonRouterLink
                                    to="/interpartes/trademarks/invalidations"
                                    className={classes.link}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        align="center"
                                    >
                                        {t("mainMenu:invalidationTrademarks")}
                                    </Typography>
                                </ButtonRouterLink>
                            </Box>
                        </Paper>
                        <Paper
                            elevation={0}
                            square
                            className={classes.leftSubBlockContent4}
                        >
                            <Box className={classes.subBlockTextContainer}>
                                <ButtonRouterLink
                                    to="/interpartes/trademarks/revocations"
                                    className={classes.link}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        align="center"
                                    >
                                        {t("mainMenu:revocationTrademarks")}
                                    </Typography>
                                </ButtonRouterLink>
                            </Box>
                        </Paper>
                        <Paper
                            elevation={0}
                            square
                            className={classes.leftSubBlockContent5}
                        >
                            <Box className={classes.subBlockTextContainer}>
                                <ButtonRouterLink
                                    to="/refusedtrademark"
                                    className={classes.link}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        align="center"
                                    >
                                        {t("mainMenu:refusedTrademarks")}
                                    </Typography>
                                </ButtonRouterLink>
                            </Box>
                        </Paper>
                    </Paper>

                    <Paper
                        elevation={0}
                        square
                        className={classes.rightBottomBlockContent}
                    >
                        <ButtonRouterLink to="/design" className={classes.link}>
                            <Typography
                                variant="subtitle1"
                                align="right"
                                className={classes.text}
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "20%",
                                    width: "20%",
                                }}
                            >
                                {t("mainMenu:designs")}
                            </Typography>
                        </ButtonRouterLink>
                        <Paper
                            elevation={0}
                            square
                            className={classes.rightSubBlockContent1}
                        >
                            <Box className={classes.subBlockTextContainer}>
                                <ButtonRouterLink
                                    to="/appeal/designs"
                                    className={classes.link}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        align="center"
                                    >
                                        {t("mainMenu:appealDesigns")}
                                    </Typography>
                                </ButtonRouterLink>
                            </Box>
                        </Paper>
                        <Paper
                            elevation={0}
                            square
                            className={classes.rightSubBlockContent2}
                        >
                            <Box className={classes.subBlockTextContainer}>
                                <ButtonRouterLink
                                    to="/objections/designs"
                                    className={classes.link}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        align="center"
                                    >
                                        {t("mainMenu:oppositionDesigns")}
                                    </Typography>
                                </ButtonRouterLink>
                            </Box>
                        </Paper>
                    </Paper>
                </Box>
            </Box>
        );
    };

    return <React.Fragment>{displayDesktop()}</React.Fragment>;
}
