import { CssBaseline } from "@material-ui/core";
import DefaultTemplate from "./components/templates/DefaultTemplate";
import { ThemeContextProvider } from "./global/contexts/ThemeContextProvider";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import InfoBlocks from "./components/InfoBlocks";
import { StoreProvider } from "./storage/StoreProvider";
import { initialState, reducer } from "./storage/dataReducer";
import FormRoutes from "./FormRoutes";

function App() {
    return (
        <StoreProvider initialState={initialState} reducer={reducer}>
            <ThemeContextProvider>
                <div className="App">
                    <CssBaseline />
                    <Router>
                        <FormRoutes />                        
                    </Router>
                </div>
            </ThemeContextProvider>
        </StoreProvider>
    );
}

export default App;
