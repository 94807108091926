import TrademarkDefaultTimeline from "./TrademarkDefaultTimeline";
import { TrademarkStatus, getActiveStep } from "./TrademarkStatus";
import TrademarkExtendableTimeline from "./TrademarkExtendableTimeline";
import { useTranslation } from 'react-i18next';

export interface TrademarkStatusTimelineProps {
    statusId: number;
}

export default function TrademarkStatusTimeline({ statusId }: TrademarkStatusTimelineProps) {
    const activeStep = getActiveStep(statusId) - 1;
    let component = null;
    const { t } = useTranslation();

    // For 5 or more steps - use TrademarkDefaultTimeline.
    // For 1-5 steps - use TrademarkExtendableTimeline.
    switch (statusId) {
        case TrademarkStatus.APPLICATION_DEEMED_AS_NOT_FILLED:
            component = <TrademarkExtendableTimeline
                activeStep={activeStep}
                lastStep={t("trademarkStatusTimeline:lastStep1")}
                isEnd />;
            break;
        case TrademarkStatus.APPLICATION_REJECTED:
            component = <TrademarkExtendableTimeline
                activeStep={activeStep}
                lastStep={t("trademarkStatusTimeline:lastStep2")}
                isEnd />;
            break;
        case TrademarkStatus.REGISTRATION_REFUSED:
            component = <TrademarkExtendableTimeline
                activeStep={activeStep}
                additionalSteps={[t("trademarkStatusTimeline:additionalStep1")]}
                lastStepLabel={t("trademarkStatusTimeline:lastStepLabel1")}
                lastStep={t("trademarkStatusTimeline:lastStep3")}
                isEnd />
            break;
        case TrademarkStatus.APPLICATION_WITHDRAWN:
            component = <TrademarkExtendableTimeline
                activeStep={activeStep}
                lastStep={t("trademarkStatusTimeline:lastStep4")}
                additionalLabels={[" "]}
                ignoreDefaultLabels
                isEnd
                startDash />;
            break;
        case TrademarkStatus.APPEAL_FILLED_FOR_NOT_FILLED_APPLICATION:
            component = <TrademarkExtendableTimeline
                activeStep={activeStep}
                lastStep={t("trademarkStatusTimeline:lastStep1")}
                lastStepLabel={t("trademarkStatusTimeline:lastStepLabel2")}
                additionalSteps={[t("trademarkStatusTimeline:additionalStep2")]} />;
            break;
        case TrademarkStatus.APPEAL_FILLED_FOR_REJECTED_APPLICATION:
            component = <TrademarkExtendableTimeline
                activeStep={activeStep}
                lastStep={t("trademarkStatusTimeline:lastStep2")}
                lastStepLabel={t("trademarkStatusTimeline:lastStepLabel2")}
                additionalSteps={[t("trademarkStatusTimeline:additionalStep3")]} />;
            break;
        case TrademarkStatus.APPEAL_FILLED:
            component = <TrademarkExtendableTimeline
                activeStep={activeStep}
                lastStep={t("trademarkStatusTimeline:lastStep3")}
                additionalLabels={[t("trademarkStatusTimeline:additionalLabel1"), t("trademarkStatusTimeline:additionalLabel2")]}
                additionalSteps={[t("trademarkStatusTimeline:additionalStep1"), t("designStatusTimeline:additionalStep5")]} />;
            break;
        case TrademarkStatus.APPEAL_FILLED_FOR_ACCEPTED_APPLICATION:
            component = <TrademarkExtendableTimeline
                activeStep={activeStep}
                lastStep={t("trademarkStatusTimeline:lastStep3")}
                additionalSteps={[t("trademarkStatusTimeline:additionalStep6")]}
                lastStepLabel={t("trademarkStatusTimeline:lastStepLabel2")}
                endDash
            />;
            break;
        case TrademarkStatus.REQUEST_FOR_THE_CONTINUED_PROCESSING_FILLED:
            component = <TrademarkExtendableTimeline
                activeStep={activeStep}
                lastStep={t("trademarkStatusTimeline:lastStep5")}
                additionalLabels={[" "]}
                ignoreDefaultLabels
                startDash
            />;
            break;
        case TrademarkStatus.REGISTRATION_PROCESS_SUSPENDED:
            component = <TrademarkExtendableTimeline
                activeStep={activeStep}
                lastStep={t("trademarkStatusTimeline:lastStep13")}
                additionalLabels={[" "]}
                ignoreDefaultLabels
                startDash />;
            break;
        case TrademarkStatus.REGISTRATION_EXPIRED:
            component = <TrademarkDefaultTimeline
                activeStep={activeStep}
                lastStep={t("trademarkStatusTimeline:lastStep6")}
                isDashed
                showLastLabel
            />;
            break;
        // Zīme izslēgta no reģistra pēc īpašnieka lūguma
        case TrademarkStatus.SURRENDERED:
            component = <TrademarkDefaultTimeline
                activeStep={activeStep}
                lastStep={t("trademarkStatusTimeline:lastStep12")}
                showLastLabel
                isEnd
            />;
            break;
        // Zīme izslēgta no reģistra
        case TrademarkStatus.DELETED_FROM_THE_REGISTER_DUE_TO_EXPIRY:
            component = <TrademarkDefaultTimeline
                activeStep={activeStep}
                lastStep={t("trademarkStatusTimeline:lastStep7")}
                showLastLabel
                isEnd
            />;
            break;
        case TrademarkStatus.OPPOSITION_FILLED:
            component = <TrademarkDefaultTimeline
                activeStep={activeStep}
                additionalSteps={[t("trademarkStatusTimeline:additionalStep7")]}
                showLastLabel
                lastLabel={t("trademarkStatusTimeline:lastLabel1")}
            />;
            break;
        case TrademarkStatus.REQUEST_FOR_INVALIDATION_FILLED:
            component = <TrademarkDefaultTimeline
                activeStep={activeStep}
                lastStep={t("trademarkStatusTimeline:lastStep8")}
                lastLabel={t("trademarkStatusTimeline:lastLabel2")}
                showLastLabel
                isDashed
            />;
            break;
        case TrademarkStatus.REQUEST_FOR_REVOCATION_FILLED:
            component = <TrademarkDefaultTimeline
                activeStep={activeStep}
                lastStep={t("trademarkStatusTimeline:lastStep9")}
                lastLabel={t("trademarkStatusTimeline:lastLabel2")}
                showLastLabel
                isDashed
            />;
            break;
        case TrademarkStatus.INVALIDATED:
            component = <TrademarkDefaultTimeline
                activeStep={activeStep}
                lastStep={t("trademarkStatusTimeline:lastStep10")}
                additionalSteps={[t("trademarkStatusTimeline:additionalStep8")]}
                additionalLabels={[t("trademarkStatusTimeline:additionalLabel3")]}
                lastLabel={t("trademarkStatusTimeline:lastLabel3")}
                showLastLabel
                isDashed
                isEnd
            />;
            break;
        case TrademarkStatus.REVOKED:
            component = <TrademarkDefaultTimeline
                activeStep={activeStep}
                lastStep={t("trademarkStatusTimeline:lastStep11")}
                additionalSteps={[t("trademarkStatusTimeline:additionalStep9")]}
                additionalLabels={[t("trademarkStatusTimeline:additionalLabel3")]}
                lastLabel={t("trademarkStatusTimeline:lastLabel3")}
                showLastLabel
                isDashed
                isEnd
            />;
            break;
        default: // 4            
            component = <TrademarkDefaultTimeline activeStep={activeStep} showLastLabel />;
            break;
    }

    return component;
}
